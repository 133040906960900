<template>

  <v-dialog
    v-model="show"
    max-width="700px"
  >
    <v-card class="pa-2">
      <v-card-title v-if="currentUser && editfield">
        <v-row  >
          <v-col cols="12" class="">
            <h3>Edit {{editfield.name}} of {{pcap.origname}}</h3>
          </v-col>
          <v-col cols="12">
            <v-row  >
                <v-col cols="12">
                    <template>
                        <v-textarea solo v-if="editfield.id == 'description'" v-model="value"></v-textarea>
                        <v-text-field solo v-else v-model="value"></v-text-field>
                    </template>
                </v-col>
                <v-col cols="12">
                <v-btn color="primary" @click="apply">Apply</v-btn>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import ApiPcapmeta from "@/common/api/pcapmeta";

export default {
  props: ["pcap", "field", "editfield"],
  mixins: [commonmethods],
  name: "singleedit",
  data() {
    return {
      value : "",
      show: true
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error"])
  },
  mounted() {
    this.value = this.pcap[this.editfield.id]
  },
  created: function() {
  },
  watch: {
    editfield(val) {
        this.show = this.editfield
    },
  },
  methods: {
    apply() {
            let editedpcap = this.pcap
            editedpcap[this.editfield.id] = this.value
            ApiPcapmeta.update(editedpcap)
            .then(() => {
                this.error.msg = "";
                editedpcap[this.editfield.id] = this.value
                this.$emit('changed', editedpcap)
            })
            .catch(({ response }) => {
                this.$emit('changed')
                this.error.msg = response["error"];
            });

    }
  }
};
</script>

<style>
</style>