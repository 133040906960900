<template>
    <v-row align="center" justify="center">
        <template v-for="price in prices">
            <v-card class="mx-auto" max-width="500">
                <v-img :src="price.img" height="200px"></v-img>

                <v-card-title>
                    {{ price.name }}
                </v-card-title>

                <div style="font-size: 36px; font-weight: bold; margin-left: 16px; ">
                    {{ price.price }}
                </div>


                <v-card-subtitle>
                    {{ price.description }}
                </v-card-subtitle>


                <v-card-text class="">

                    {{ price.detaildescription }}
                    <v-list dense disabled>
                        <v-list-item v-for="(item, i) in price.features" :key="i">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2" v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                </v-card-text>

                <v-divider></v-divider>
                <!-- <v-card-actions>
                    <b v-if="hasSubscription(currentUser)" color="blue lighten-2" text>
                        You already haven an active plan
                    </b>
                    <v-btn
                        v-else-if="currentUser && 'deactivation_date' in currentUser && currentUser.deactivation_date && !hasSubscription(currentUser)"
                        @click="openCheckout()" color="blue lighten-2" text>Plan
                        expired, <router-link to="/pricing">buy new
                            access</router-link>)</v-btn>

                    <v-btn v-else-if="currentUser" @click="openCheckout()" color="blue lighten-2" text>
                        Get Started
                    </v-btn>

                    <v-btn v-else @click="openCheckout()" color="blue lighten-2" text>
                        Create an account first, then pay
                    </v-btn>

                    {{ paymentLink }}
                    <v-spacer></v-spacer>

                </v-card-actions> -->


            </v-card>
        </template>
    </v-row>
</template>


<script>

import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";
import ApiUser from "@/common/api/user";

export default {
    name: "pricing",
    data() {
        return {
            show: true,
            paymentLink: "",
            prices: [
                {   // production: https://buy.stripe.com/14k7vMb6R3jpgzm5kw
                    // dev: https://buy.stripe.com/test_dR603GgIBaPO1dS3ch
                    payment_link: "https://buy.stripe.com/test_dR603GgIBaPO1dS3ch", // dev
                    price: "150$",
                    name: "1 Year Full Access",
                    description: "One-time one year full access",
                    img: 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
                    detaildescription: "Access the whole PacketSafari PCAP archive including many PCAPs with a well-written short description, Capture-the-Flags (CTFs) PCAPs and case-studies for one year",
                    features: [

                        {
                            icon: "check",
                            text: "Access the whole PacketSafari archive"
                        },
                        {
                            icon: "check",
                            text: "Maximum upload file size for PCAPs 5 MB instead 1MB"
                        },
                        {
                            icon: "check",
                            text: "Total storage of 200MB"
                        },
                        {
                            icon: "check",
                            text: "No automatic renewal"
                        }
                    ]
                }]
        };
    },
    created: function () { },
    mounted() { },
    mixins: [permissionchecks, commonmethods],
    computed: {
        ...mapGetters(["currentAnalysis", "currentUser", "error"]),
    },
    watch: {},
    methods: {
        openCheckout: function ()  {
            if (!this.currentUser) {
                this.$router.push({ name: "Register" });
                return
            }

            Paddle.Setup({
                token: 'live_d07ea8378be05179792b9fdc791'
            });

            let successurl = 'https://app.packetsafari.com/profile/'+this.currentUser.username

            Paddle.Checkout.open({
                email: this.currentUser.email,
                settings: {
                    displayMode: "overlay",
                    theme: "light",
                    successUrl: successurl,
                    showAddTaxId: "true"
                },
                items: [{ priceId: 'pri_01hq0yz6edjrbnv0x07wc80gge', quantity: 1 }],
                customData: {
                    "userId": this.currentUser.username
                }
            });
        }
    },
};
</script>

<style></style>