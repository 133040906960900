<template>
  <v-card class="pb-2">
    <v-dialog v-model="adddialog" width="400px">
      <v-card>
        <v-card-title>Add Entry</v-card-title>
        <v-card-text>
          <v-text-field
            label="Layer Type"
            placeholder="udp.port"
            v-model="newentry.layertype"
          ></v-text-field>
          <v-text-field
            label="Selector"
            placeholder="18388"
            v-model="newentry.selector"
          ></v-text-field>
          <v-text-field
            label="Decode as Protocol"
            placeholder="rtp"
            v-model="newentry.decodeasproto"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addEntry(newEntry)">Add to list</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title class="headline" primary-title
      >Decode as settings</v-card-title
    >

    <v-card-text class="py-2"
      >Decode as allows you to force the decode of a certain protocol
      <linktodoc doc="analyze/overview/#decode-as" text="Learn why and how to use decode as"></linktodoc>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="entries.entries"
            hide-default-footer
            disable-pagination=""
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item["layertype"] }}</td>
                <td>{{ props.item["selector"] }}</td>
                <td>{{ props.item["decodeasproto"] }}</td>
                <td>
                  <v-icon color="info" small v-on:click="editEntry(props.item)"
                    >edit</v-icon
                  >
                  <!--
                  <v-icon
                    color="info"
                    small
                    v-on:click="deleteEntry(props.item)"
                    >delete</v-icon
                  >-->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="4" class="px-2 py-2">
          <v-btn @click="newEntry">Add</v-btn>
        </v-col>
        <v-col cols="4" class="px-2 py-2">
        <v-btn @click="applyEntries">Apply</v-btn>
        </v-col>

        <v-col cols="4" class="px-2 py-2">
        <v-btn @click="reset">Reset all</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Linktodoc from "@/components/basics/Linktodoc";

import ApiDecodeas from "@/common/api/decodeas";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";

export default {
  props: {
    pcapid: null,
  },
  components: {Linktodoc},
  name: "decodeas",
  data() {
    return {
      tags: [],
      entries: {
        pcapid: "",
        entries: [],
      },
      newentry: {
        layertype: "",
        selector: "",
        decodeasproto: "",
      },
      adddialog: false,
      headers: [
        {
          text: "Layer Type",
          align: "left",
          value: "layertype",
          sortable: false,
        },
        { text: "Selector", value: "layertype", sortable: false },
        { text: "Decode as", value: "selector", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  created: function () {},
  mounted() {
    this.entries.pcapid = this.pcapid;

    this.getEntries();
  },
  mixins: [commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  watch: {},
  methods: {
    newEntry: function () {
      this.newentry = {
        layertype: "",
        selector: "",
        decodeasproto: "",
      };
      this.adddialog = !this.adddialog;
    },
    addEntry: function (entry) {
      const index = this.entries.entries.indexOf(entry);
      this.entries.entries.push(this.newentry);
      this.adddialog = false;
    },
    applyEntries: function () {
      if (this.entries.entries.length == 0) {
        this.reset();
        this.currentAnalysis.decodecacheinvalidate = true;
        
        return;
      }
      ApiDecodeas.create(this.entries)
        .then(({ data }) => {
          this.getEntries();
          this.adddialog = false;
          this.currentAnalysis.packetlistcacheinvalidate = true
          //this.currentAnalysis.applyfilter = true;
          //this.currentAnalysis.filterneedsupdate = true;
        })
        .catch(({ response }) => {});
    },
    getEntries: function () {
      ApiDecodeas.get(this.entries)
        .then(({ data }) => {
          this.entries.entries = data;
        })
        .catch(({ response }) => {});
    },
    reset: function () {
      ApiDecodeas.reset(this.entries)
        .then(({ data }) => {
          this.entries.entries = [];
          this.adddialog = false;
          this.currentAnalysis.packetlistcacheinvalidate = true
          // this.currentAnalysis.applyfilter = true;
          // this.currentAnalysis.filterneedsupdate = true;
        })
        .catch(({ response }) => {});
    },
    deleteEntry: function (entry) {
      const index = this.entries.entries.indexOf(entry);
      this.entries.entries.splice(index, 1);
    },
    editEntry: function (entry) {
      this.newEntry = entry;
      this.adddialog = true;
    },
  },
};
</script>

<style >
</style>
