<template>
  <v-row v-if="tags.length > 0" >
    <v-col class="text-center" cols="12">
      <h3 v-if="currentUser && tags.length">Tags</h3>
      <h3 v-else>Tags</h3>
    </v-col>
    <v-col class="text-center pt-1" cols="12">
      <tag :tag="tag" v-for="tag in tags" v-bind:key="tag.id" class="ma-1"></tag>
    </v-col>
  </v-row>
</template>

<script>

import ApiTags from "@/common/api/tags";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";
import Tag from "./Tag";

export default {
  components: {Tag},
  name: "tagcloud",
  data() {
    return {
      tags: []
    };
  },
  created: function() {},
  mounted() {
    if (this.currentUser) {
      this.getTags();
    } else {
      this.getRecentTags();
    }
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"])
  },
  watch: {},
  methods: {
    gotoTag: function(i) {
      this.$router.push({
        name: "PCAPs",
        params: {
          type: "search",
          query: "tagname: " + i.name
        }
      }).catch(err => {});
    },
    getTags: function() {
      ApiTags.list({"listtype" : "popular", "type" : "pcap"})
        .then(({ data }) => {
          this.tags = data.tags;
        })
        .catch(({ response }) => {});
    },
    getRecentTags: function() {
      ApiTags.list({"listtype" : "recent", "type" : "pcap"})
        .then(({ data }) => {
          this.tags = data.tags;
        })
        .catch(({ response }) => {});
    }
  }
};
</script>

<style >
</style>
