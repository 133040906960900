<template>

<v-dialog v-model="currentAnalysis.decodeasdialog" width="550px">
    <decodeas :pcapid="currentAnalysis.pcapid"></decodeas>
</v-dialog>

</template>

<script>

import { mapGetters } from "vuex";
import Decodeas from "@/components/settings/Decodeas";

export default {
  components: { Decodeas },
  name: "decodeasdialog",
  data() {
    return {
      tags: []
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {},
  beforeDestroy() {},

  methods: {}
};
</script>

<style>
</style>
