<template>
    <v-snackbar v-model="currentAnalysis.showFilterBoxOverlay"  min-width="95%" min-height="120" :dark="false" timeout="-1" style="opacity: 0.95; bottom: 200px">
        <v-row width="100%">
            <v-col width="100%" class="monofont" style="font-size: xx-large; word-wrap: break-word; line-height: 32px">
                {{text}}
            </v-col>
        </v-row>

        <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          class="mr-4"
          v-bind="attrs"
          @click="currentAnalysis.showFilterBoxOverlay = false"
        >
          Close
        </v-btn>
      </template>

    </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        multiLine: true,
        snackbar: true,
        text: null,
    }),
    computed: {
        ...mapGetters(["currentAnalysis"]),
    },
    watch: {
        "currentAnalysis.filterboxoverlay.text": function (n) {
            this.text = n
        },
    }
}
</script>
<style>

</style>