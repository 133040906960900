<template>
  <v-container fluid>

    <v-btn v-on:click="newc" v-if="newcapture == null ">New</v-btn>

    <v-row>
      <v-col>
        <v-card class="pa-4">

       <v-text-field
        v-model="newcapture.id"
        label="ID"
        required
      ></v-text-field>

       <v-text-field
        v-model="newcapture.name"
        label="Name"
        required
      ></v-text-field>

    

        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-btn v-on:click="add">Add</v-btn>
      </v-col>
    </v-row>


  <v-row>
    <v-col>
    <v-data-table
      :headers="headers"
      :items="captures">
    </v-data-table>
       </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {

  },
  mixins: [],
  props: {
  },
  name: "awscloudcapture",
  data() {
    return {

      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'name' }
      ],
      newcapture: 
      {
        id : null,
        name: null,

        srchost: null,
        srceeni: null,
        srcip: null,

        dsthost: null,
        dsteni: null,
        dstip: null,
        dstsg: null,
        


        destlocation: "s3",

        securitygroup : {

        },

        mirrortarget : {
          name: null,
          description: null,
          targettype: null,
          targeteni: null,
          targetnlb: null
        },

        mirrorfilter : {
          name: null,
          description: null,
          amazondns: true,
          inbound: [],
          outbound: []
        },

        mirrorsession: {
          name: null,
          description: null,
          mirrorsourceeni: null,
          mirrortarget: null,
          additional : {
            session: null,
            vni: 424242,
            packetlength: null,
            filter: null
          }
        },

        tags: [],
      },
      captures: [
        {
          id: 1123322,
          name: "Capture 1",
          description: "My capture one"
        }
      ],
      color: '#ffffff'
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  watch: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    newc(){
      let n = {

      }
      this.newcapture = n
    },
    add(){
      this.captures.push(this.newcapture)
      this.newcapture= null
    }
  }
};
</script>

<style>
</style>
