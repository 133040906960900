export const IOGRAPH_PRESETS = [
    {
        name: "ICMP",
        description: "",
        values: [
            {
                name: "Packets per second",
                key: 0,
                type: "packets",
                filter: "",
                color: "#000000",
                style: "fill",
            },
            {
                name: "ICMP",
                key: 1,
                type: "packets",
                filter: "ip.proto == 1",
                color: "#FFD166",
                style: "fill",
            },
            {
                name: "Retransmissions",
                key: 2,
                type: "packets",
                filter: "tcp.analysis.retransmission",
                color: "#A9173A",
                style: "fill",
            },
        ],
    },
    {
        name: "TCP BIF",
        values: [
            {
                name: "Packets per second",
                key: 0,
                type: "packets",
                filter: "",
                color: "#000000",
                style: "fill",
            },
            {
                name: "Bytes in flight",
                key: 1,
                type: "max",
                filter: "",
                color: "#158DF9",
                style: "fill",
                aggregation_field: "tcp.analysis.bytes_in_flight"
            },
            {
                name: "SACK Right Edge",
                key: 2,
                type: "max",
                filter: "",
                color: "#2A9D8F",
                style: "fill",
                aggregation_field: "tcp.options.sack_re"
            },
            {
                name: "Window Size",
                key: 3,
                type: "max",
                filter: "",
                color: "#A9173A",
                style: "fill",
                aggregation_field: "tcp.window_size"
            }
        ]
    },
    {
        name: "TCP BIF",
        values: [
            {
                name: "Packets per second",
                key: 0,
                type: "packets",
                filter: "",
                color: "#000000",
                style: "fill",
            },
            {
                name: "Bytes in flight",
                key: 1,
                type: "max",
                filter: "",
                color: "#158DF9",
                style: "fill",
                aggregation_field: "tcp.analysis.bytes_in_flight"
            },
            {
                name: "TCP Sequence",
                key: 2,
                type: "max",
                filter: "",
                color: "#2A9D8F",
                style: "fill",
                aggregation_field: "tcp.analysis.bytes_in_flight"
            },
        ],
    },
    {
        name: "TCP Sequence",
        oneaxis: true,
        values: [
            {
                name: "TCP Sequence A to B",
                key: 0,
                type: "avg",
                filter: "",
                color: "#2A9D8F",
                style: "line",
                aggregation_field: "tcp.seq"
            },
            {
                name: "TCP ACK B to A",
                key: 1,
                type: "avg",
                filter: "",
                color: "#FFD166",
                style: "fill",
                aggregation_field: "tcp.ack"
            },
            {
                name: "Bytes in flight",
                key: 2,
                type: "avg",
                filter: "",
                color: "#000000",
                style: "fill",
                aggregation_field: "tcp.analysis.bytes_in_flight"
            },
            {
                name: "TCP Window B to A",
                key: 3,
                type: "avg",
                filter: "",
                color: "#158DF9",
                style: "fill",
                aggregation_field: "tcp.window_size"
            },            
        ]
    },
    {
        name: "TCP Retransmissions",
        oneaxis: true,
        values: [
            {
                name: "Packets per second",
                key: 0,
                type: "packets",
                filter: "",
                color: "#000000",
                style: "line",
            },
            {
                name: "Retransmissions",
                key: 1,
                type: "sum",
                filter: "",
                color: "#ff0000",
                style: "fill",
                aggregation_field: "tcp.analysis.retransmission"
            }
        ]
    },
    {
        name: "TCP Length",
        oneaxis: true,
        values: [
            {
                name: "TCP length A to B",
                key: 0,
                type: "avg",
                filter: "",
                color: "#00ff00",
                style: "fill",
                aggregation_field: "tcp.len"
            },
            {
                name: "TCP length B to A",
                key: 1,
                type: "avg",
                filter: "",
                color: "#0000ff",
                style: "fill",
                aggregation_field: "tcp.len"
            },

        ]
    },
    {
        name: "TCP RTT",
        oneaxis: true,
        values: [
            {
                name: "RTT AB",
                key: 0,
                type: "avg",
                filter: "",
                color: "#2A9D8F",
                style: "fill",
                aggregation_field: "tcp.analysis.ack_rtt"
            },
            {
                name: "RTT BA",
                key: 1,
                type: "avg",
                filter: "",
                color: "#158DF9",
                style: "fill",
                aggregation_field: "tcp.analysis.ack_rtt"
            },
            
        ]
    },
    {
        name: "IP TTL",
        oneaxis: true,
        values: [
            {
                name: "TTL AB",
                key: 0,
                type: "min",
                filter: "",
                color: "#133E3C",
                style: "fill",
                aggregation_field: "ip.ttl"
            },
            {
                name: "TTL BA",
                key: 1,
                type: "min",
                filter: "",
                color: "#158DF9",
                style: "fill",
                aggregation_field: "ip.ttl"
            },

        ]
    }
]

export const iographhelper = {

    methods: {
        copy_preset: function (name) {
            let findex = IOGRAPH_PRESETS.findIndex(( preset ) => preset.name === name);
            let object = IOGRAPH_PRESETS[findex]
            return JSON.parse(JSON.stringify(object))
        },
    }
}