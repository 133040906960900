import ApiService from "@/common/api.service";

const ApiPacketlist = {
    exportobject(params){
        return ApiService.downloadtoken(params)
    },
    tap(params){
        return ApiService.post("sharkd/tap", params)
    },
    tcpgraph(params){
        return ApiService.post("/ajax/pcap/tcpgraph", params)
    },
    indexedpacketlist(params){

        return ApiService.post("ajax/pcap/indexedpacketlist", params)
        /*
        return ApiService.post("ajax/pcap/indexedpacketlist", params).then(({ data }) => {
            

            let packit = { "data" : []}
    
            for(let d of data["hits"]["hits"]){
                packit["data"].push(d["_source"])
            }

            packit["total"] = data["hits"]["total"]["value"]

            try{    
                packit["aggs"] = data["aggregations"]

            } catch {

            }
    
            return packit
        })*/
    },
    packetlist(params){
        if(params.filter == null){
            params.filter= ""
        }

        return ApiService.post("ajax/pcap/packetlist", params)
    },
    follow(params){
        return ApiService.post("ajax/pcap/follow", params)
    },
    fixtcporder(params){
        return ApiService.post("ajax/pcap/fixtcporder", params)
    },
    timeline(params){
        return ApiService.post("ajax/pcap/timeline", params)
    },
    iograph(params){
        return ApiService.post("ajax/pcap/iograph", params)
    }
}

export default ApiPacketlist