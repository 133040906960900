import ApiService from "@/common/api.service";

const ApiRoles = {

    get(roleid){
        return ApiService.query("ajax/role/" + roleid)
    },
    list(term){
        if(term){
            return ApiService.query("ajax/roles/"+term)
        } else{
            return ApiService.query("ajax/roles")
        }

    },
    create(role){
        return ApiService.post("ajax/role", role)
    },
    update(role){
        return ApiService.put("ajax/role/"+role.id, role)
    },
    delete(roleid){
        return ApiService.delete("ajax/role/" + roleid)
    }
}

export default ApiRoles