<template>
    <v-row   class="ma-3 pa-0">
      <v-col cols="11" class="pb-4 text-center" v-if="displayedoptions">{{displayedoptions.ipsrc}}:{{displayedoptions.srcport}} to {{displayedoptions.ipdst}}:{{displayedoptions.dstport}}</v-col>
      <v-col cols="11" class="pb-4">
        <div class="tcptrace" ref="chartdiv"></div>
      </v-col>

      <v-col cols="4">
          <v-btn v-on:click="resetZoom" class="ml-10 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">zoom_out_map</v-icon>
              </template>
              
              <span>Reset zoom</span>
            </v-tooltip>
          </v-btn>
          <v-btn v-on:click="reverseDirection" class="ml-10 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">mdi-swap-horizontal</v-icon>
              </template>
              
              <span>Reverse Direction</span>
            </v-tooltip>
          </v-btn>
      </v-col>
  
      <v-col cols="1">
        Cursor behaviour
      </v-col>
      <v-col cols="5">
          <v-combobox v-if="chart" class="ml-10 ml-2"
            hide-details
            v-model="cursorbehaviour"
            :items="cursoroptions"
            item-text="display"
            item-value="value"
            label="Cursor behaviour"
            solo
          ></v-combobox>
      </v-col>
    </v-row>
</template>

<script>
import moment from "moment-timezone";
import oui from "oui";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_animated);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { navigation } from "@/common/navigation";
import { first } from '@amcharts/amcharts4/.internal/core/utils/Array';

export default {
  name: "tcptrace",
  props: {
    connection: null,
    pcapid: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    }
  },

  components: {

  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      displayedoptions: null,
      reversedir: false,
      zoomSwitch: false,
      zoomlevel: null,
      interval: null,
      intervals: [
        "1d",
        "1h",
        "1m",
        "1s",
        "100ms",
        "10ms",
        "1ms"
      ],
      cursoroptions: [
        {
          "display": "Select",
          "value": "selectXY",
        },
        {
          "display": "Zoom",
          "value": "zoomXY",
        },
        {
          "display": "Pan",
          "value": "panXY",
        }
      ],
      cursorbehaviour: {
          "display": "Select",
          "value": "selectXY"
      },
      origstartDate: null,
      origendDate: null,
      startDate: null,
      endDate: null,
      chart: null,
      iconn: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
      zoominprogress: false,
      syncircle: null
    };
  },
  watch: {
    "connection": function(n, o){
        this.iconn = this.getIndexedconnectionFromAggregationFormat(n);
    },
    "cursorbehaviour": function(n, o){
      this.chart.cursor.behavior = n.value
    }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.iindex = this.index;
    this.ipcapid = this.pcapid
    this.iconn = this.getIndexedconnectionFromAggregationFormat(this.connection);
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  beforeDestroy() {
      
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    reverseDirection(){
      if (this.chart) {
        this.chart.dispose();
        this.chart = null
      }
      this.reversedir = !this.reversedir
      this.iconn = this.getIndexedconnectionFromAggregationFormat(this.connection);
    },
    resetZoom(){
      this.interval = null
      this.startDate = null
      this.endDate = null
      this.zoominprogress = false
      this.chart.dispose();
      this.chart = null
      this.iconn = this.getIndexedconnectionFromAggregationFormat(this.connection);
    },
    handleChangedCursor(ev) {
      
      var range = ev.target.xRange;
      var axis = ev.target.chart.xAxes.getIndex(0);

      var yrange = ev.target.yRange;
      var yaxis = ev.target.chart.yAxes.getIndex(0);

      var ystart = yaxis.toAxisPosition(yrange.start)
      var ystop = yaxis.toAxisPosition(yrange.end)
      
      var startnum = axis.toAxisPosition(range.start);
      var endnum = axis.toAxisPosition(range.end);
      
      var s = this.moment.utc(this.startDate);
      var e = this.moment.utc(this.endDate);

      /* duration in milliseconds */
      var duration = e - s;
      this.zoomlevel = duration
      // frame to add left and right to have some slack, percentage of duration
      var additionalframe  = duration * 0.05

      let startmoment = this.moment.utc(s + duration * startnum)
      let s1 = startmoment.subtract(additionalframe, "ms")
      let endmoment = this.moment.utc(s + duration * endnum)
      let e1 = endmoment.add(additionalframe, "ms")
      let start = startmoment.toISOString();
      let end = endmoment.toISOString();

      
      let newduration = endmoment - startmoment
      let interval = Math.floor(newduration / 500) + "ms"

      this.getIndexedconnectionFromAggregationFormat(this.connection, start, end, interval);

      
      yaxis.min = yaxis.getPositionLabel(ystop)
      yaxis.max = yaxis.getPositionLabel(ystart)
      this.chart.cursor.selection.hide();
    },
    handleChangedValueAxis(ev){
      return
      var yaxis = ev.target.chart.yAxes.getIndex(0);

      var ystart = ev.target.minZoomed
      var ystop = ev.target.maxZoomed

      yaxis.min = yaxis.getPositionLabel(ystart)
      yaxis.max = yaxis.getPositionLabel(ystop)
    },
    handleChanged(ev) {
      

      if(ev.target.min == ev.target.minZoomed && ev.target.max == ev.target.maxZoomed)
        return

      var startmoment = this.moment.utc(new Date(ev.target.minZoomed))
      var endmoment = this.moment.utc(new Date(ev.target.maxZoomed))

      var duration = endmoment - startmoment
      var additionalframe  = duration * 0.001
      
      startmoment = startmoment.subtract(additionalframe, "ms")
      endmoment = endmoment.add(additionalframe, "ms")

      var start = startmoment.toISOString();
      var end = endmoment.toISOString();

      if(this.zoominprogress){
        return
      }
      
      let newduration = endmoment - startmoment
      this.zoomlevel = newduration
      let interval = Math.floor(newduration / 500) + "ms"

      this.zoominprogress = true

      // var range = ev.target.xRange;
      // var axis = ev.target.chart.xAxes.getIndex(0);

      // var startnum = axis.toAxisPosition(range.start);
      // var endnum = axis.toAxisPosition(range.end);
      // 
      // let start = this.moment.utc(s + duration * startnum).toISOString();
      // let end = this.moment.utc(s + duration * endnum).toISOString();
      // this.timelinestart = start;
      // this.timelineend = end;
      
      this.getIndexedconnectionFromAggregationFormat(this.connection, start, end, interval);
    },
    getIndexedconnection(src, dst, proto, srcport, dstport, start=null, end=null, interval = null){
        
        let options = {
            index : "none",
            pcapid: this.ipcapid,
            ipsrc: src,
            srcport: srcport,
            ipdst: dst,
            dstport: dstport
        }

        if(this.reversedir){
          options.ipsrc = dst
          options.srcport = dstport
          options.ipdst = src
          options.dstport = srcport
        } else{
          options.ipsrc = src
          options.srcport = srcport
          options.ipdst = dst
          options.dstport = dstport
        }

        if(start){
          options.start = start
        }

        if(end){
          options.end = end
        }

        if(this.interval){
          options.interval = this.interval
        }
        
        
        if(interval){
          this.interval = interval
          options.interval = interval
        }

        this.displayedoptions = options
        

      ApiPacketlist.tcpgraph(options)
        .then(({ data }) => {

            
            let forward = []
            let reverse = []
            
            if("aggregations" in data){ // if we have aggregated data
            
              try{

 
              for (let b of data["aggregations"]["forward"]["forwardovertime"]["buckets"]){
                forward.push(
                  {
                    time: moment.utc(b["key_as_string"]).tz(moment.tz.guess()).toDate(),
                    epoch: b["key"],
                    seq: b["seq"]["value"]
                  }
                )
              }

              for (let d of data["aggregations"]["reverse"]["reverseovertime"]["buckets"]){
                if(d["receivewindow"]["value"] == null || d["ack"]["value"] == null){
                  continue
                }
                reverse.push(
                  {
                    time: moment.utc(d["key_as_string"]).tz(moment.tz.guess()).toDate(),
                    ack: d["ack"]["value"],
                    epoch: d["key"],
                    window: d["receivewindow"]["value"] + d["ack"]["value"]
                  }
                )
              }
              } catch(e){
                
              }

            } else if ("forward" in data && data["forward"].hits.hits.length > 0) { // if we got hits

              

              for (let b1 of data["forward"]["hits"]["hits"]){
                
                let ts1 = moment(parseInt(b1["_source"]["timestamp"]))
                let e = {
                  time: ts1.tz(moment.tz.guess()).toDate(),
                  seq: b1["_source"]["tcp#seq"][0]
                }

                if("tcp#flags" in b1["_source"]){
                  e["flags"] = this.tcp_flags_human_readable(b1["_source"]["tcp#flags"][0])
                }

                if("tcp#len" in b1["_source"]){
                  e["len"] = b1["_source"]["tcp#len"][0]
                }

                forward.push(e)
              }
              
              for (let d1 of data["reverse"]["hits"]["hits"]){

                let ts2 = moment(parseInt(d1["_source"]["timestamp"]))

                if(d1["_source"]["tcp#window_size"][0] == null || d1["_source"]["tcp#ack"][0] == null){
                  continue
                }
                reverse.push(
                  {
                    time: ts2.tz(moment.tz.guess()).toDate(),
                    ack: d1["_source"]["tcp#ack"][0],
                    window: d1["_source"]["tcp#window_size"][0] + d1["_source"]["tcp#ack"][0]
                  }
                )
              }
              
            } else {
              log.console("No data received")
            }

            
            // let firstpackettime = null

            // for(let item of data.data){
              
            //   // time starts at first packet with 0
            //   if(firstpackettime == null){
            //     firstpackettime = item["frame#time_relative"]
            //     item["frame#time_relative"] = 0
            //   } else{
            //     item["frame#time_relative"] = item["frame#time_relative"] - firstpackettime
            //   }

            //   if(item["ip#src"] == src){
            //     forward.push(item)
            //   }else{
            //     
            //     item["window"] = item["tcp#ack"][0] + item["tcp#window_size_value"][0]
            //     reverse.push(item)
            //   }
            // }
            try{
              this.plotTcpTrace(forward, reverse)
            }catch(e) {
              
            }
            
        })
        .catch(error => {
          this.zoominprogress = false
          var err = error.response.data["error"];
        });
    },
    getIndexedconnectionFromAggregationFormat(connection, start = null, end = null, interval = null){
        let src, dst, proto, srcport, dstport;

        src= connection.src.ip;
        dst= connection.dst.ip;
        srcport = connection.src.port;
        dstport = connection.dst.port;
        proto = connection.type;

        return this.getIndexedconnection(src, dst, proto, srcport, dstport, start, end, interval);
    },
    getSeriesType(){
        
        if(!this.interval == null && this.interval.includes("ms") && this.interval.length < 5){
          return new am4charts.StepLineSeries()
        } else {
          return new am4charts.StepLineSeries()
        }
    },
    plotTcpTrace(forward, reverse){
      
        if (this.chart != null) {
          //this.chart.dispose();
          this.startDate = forward[0]["time"];
          this.endDate = forward[forward.length-1]["time"];

          this.chart.series.values[0].data = forward
          this.chart.series.values[1].data = reverse
          this.chart.series.values[2].data = reverse
          this.chart.invalidateData();
          //
          this.syncircle.width = 6
          this.syncircle.height =6
          //seqseries.strokeWidth = 1
          this.zoominprogress = false
          return 
        }  else {
        }
        this.startDate = forward[0]["time"];
        this.endDate =forward[forward.length-1]["time"];
        
        // if(this.chart){
        //   this.chart.seqseries.data = forward
        //   this.chart.bifseries.data = reverse
        //   this.chart.wndseries.data = reverse
        //   this.zoominprogress = false
        //   return
        // }
        am4core.useTheme(am4themes_animated);
        am4core.options.minPolylineStep = 5;
        var chart = am4core.create("tcptrace", am4charts.XYChart);

        // Create axes
        var timeAxis = chart.xAxes.push(new am4charts.DateAxis());
        timeAxis.groupData = true;
        //timeAxis.keepSelection = true;
        
        //timeAxis.events.on("endchanged", this.handleChanged);

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //valueAxis.keepSelection = true;
        valueAxis.min = -1000

        //timeAxis.events.on("endchanged", this.handleChanged);
        //valueAxis.events.on("endchanged", this.handleChangedValueAxis);


        var seqseries = chart.series.push(this.getSeriesType());
        seqseries.name = "Sequence numbers";
        seqseries.dataFields.valueY = 'seq';
        seqseries.dataFields.dateX = "time";
        //seqseries.dataFields.valueX = "epoch";
        seqseries.startLocation = 0.5;
        seqseries.stroke = am4core.color("darkblue");
        seqseries.strokeWidth = 1
        seqseries.data = forward

        var bullet = seqseries.bullets.push(new am4charts.Bullet());
        this.syncircle = bullet.createChild(am4core.Circle);
        this.syncircle.width = 0;
        this.syncircle.height = 0;
        this.syncircle.fill = "#fff"
        this.syncircle.tooltipText = "Value: [bold]{len}[/] Flags: [bold]{flags}[/]";

        // this.syncircle.adapter.add("width", function(disabled, target) {
          
        //   if(this.zoomlevel && this.zoomlevel < 10000000){
        //     return 6
        //   }
        //   return 0
        // });

        // this.syncircle.adapter.add("height", function(disabled, target) {
        //   if(this.zoomlevel && this.zoomlevel < 10000000){
        //     return 6
        //   }
        //   return 0
        // });

        this.syncircle.adapter.add("fill", function(disabled, target) {
          if(!target.dataItem || !target.dataItem.dataContext || !target.dataItem.dataContext.flags){
            return
          }
          let flags = target.dataItem.dataContext.flags

          if(flags.includes("P")){
            return am4core.color("blue")
          } 

          return am4core.color("#fff")
        });

        var bifseries = chart.series.push(this.getSeriesType());
        bifseries.name = "Acknowledgements (rev)";
        bifseries.dataFields.valueY = "ack";
        bifseries.dataFields.dateX = "time";
        bifseries.startLocation = 0.5;
        bifseries.stroke = am4core.color("darkred");
        bifseries.strokeWidth = 1
        bifseries.data = reverse

        // var bulletack = bifseries.bullets.push(new am4charts.Bullet());
        // var circleack = bulletack.createChild(am4core.Circle);
        // circleack.width = 6;
        // circleack.height = 6;
        // circleack.fill = "#fff"
        // circleack.tooltipText = "Value: [bold]{ack}[/]";

        var wndseries = chart.series.push(this.getSeriesType());
        wndseries.name = "Receive Window";
        wndseries.dataFields.valueY = "window";
        wndseries.dataFields.dateX = "time";
        wndseries.startLocation = 0.5;
        wndseries.stroke = am4core.color("green");
        wndseries.strokeWidth = 1
        wndseries.data = reverse

        // var bulletwnd = wndseries.bullets.push(new am4charts.Bullet());
        // var circlewnd = bulletwnd.createChild(am4core.Circle);
        // circlewnd.width = 6;
        // circlewnd.height = 6;
        // circlewnd.fill = "#fff"
        // circlewnd.tooltipText = "Value: [bold]{window}[/]";

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = this.cursorbehaviour.value
        seqseriess.events.off("selectionextremeschanged", valueAxis.handleSelectionExtremesChange, valueAxis, false)
        chart.cursor.events.on("selectended", this.handleChangedCursor);
        //chart.mouseWheelBehavior = "zoomXY";
        chart.mouse
        chart.plotContainer.mouseOptions.sensitivity = 5;
        chart.legend = new am4charts.Legend();
        this.chart = chart
        this.zoominprogress = false
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.cpchartcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.tcptrace {
  width: 100%;
  height: 480px;
}

.container {
  height: 100%;
  max-width: 100%;
    overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
