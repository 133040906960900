<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="tags"
        chips
        solo
        :items="availabletags"
        :search-input.sync="searchtag"
        :filter="(v) => v"
        cache-items
        item-value="id"
        :label="label"
        multiple
        :disabled="!isowner"
      >
        <template slot="selection" slot-scope="data">

          <tag
            :close="isowner"
            @click:close="remove(data.item)"
            :tag="data.item"
            :selected="data.selected"
            class="chip--select-multi"
            @click="select"
            @input="remove(data.item)"
          ></tag>


        </template>
        <template slot="item" slot-scope="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title>
                <tag
                  :goto="false"
                  :tag="data.item"
                  class="chip--select-multi"
                ></tag>
                {{ data.item.description }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="updateTags">Save</v-btn>
      </v-card-actions>
      <!--<v-btn color="primary" text @click="currentAnalysis.pcaplist.tagdialog = false">Close</v-btn>-->
      <v-spacer></v-spacer>
    </v-col>
  </v-row>
</template>

<script>
import ApiTag from "@/common/api/tag";
import ApiTags from "@/common/api/tags";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import Tag from "../Tag";
export default {
  props: ["label", "pcaps", "type", "isowner"],

  mixins: [commonmethods],
  components: {Tag},
  name: "masstagging",
  data() {
    return {
      objectids: [],
      availabletags: [],
      tags: [],
      searchtag: null,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic", "error"]),
  },
  mounted() {
    for (let p of this.pcaps) {
      this.objectids.push(p.id);
    }
    if (this.objectids) {
      this.loadTags();
    }
  },
  watch: {
    pcaps(n) {
      this.objectids = [];
      if (n) {
        for (let p of n) {
          this.objectids.push(p.id);
        }
        if (this.objectids) {
          this.loadTags();
        }
      }
    },
    searchtag(val) {
      val && this.lookupTag(val);
    },
  },
  methods: {
    addTagToTags(tagid) {
      ApiTag.get({ tagid: tagid, type: "pcap" }).then(({ data }) => {
        if (data.length == 1) {
          this.availabletags.push(data[0]);
          this.tags.push(data[0]["id"]);
        }
      });
    },
    loadTags() {
      var u = {
        type: "pcap",
        objectids: this.objectids,
        listtype: "objectids",
      };

      // dont load all tags FIXMEw
      ApiTags.list(u).then(({ data }) => {
        let tags = data.tags;
        this.availabletags.push(...tags);
        this.tags = this.availabletags;
      });
    },
    lookupTag() {
      ApiTag.search({ term: this.searchtag, type: "pcap" }).then(({ data }) => {
        this.availabletags = data;
      });
    },
    updateTags() {
      var u = {
        type: "pcap",
        objectids: this.objectids,
        tagids: [],
      };

      for (let tag of this.tags) {
        u.tagids.push(tag);
      }

      ApiTags.update(u).then(() => {
        this.error.type = "info";
        this.error.msg = "Successfully tagged";
        this.currentAnalysis.pcaplist.tagdialog = false;
      });
    },
    remove(item) {
      const index = this.tags.indexOf(item);
      this.tags.splice(index, 1);
    },
  },
};
</script>

<style>
</style>