<template>
  <v-row class="pa-2">
    <v-overlay :value="showcolorpicker" z-index="2">
      <v-color-picker
        class="ma-2"
        show-swatches
        :swatches="palette"
        v-model="color"
      ></v-color-picker>
      <v-text-field v-model="color"></v-text-field>

      <v-btn @click="confirmColor"> Confirm </v-btn>
    </v-overlay>
    <v-col cols="12"
      >Press enter in the rule field in order to syntactically verify the rule.
      The background will change to green or red depending on the
      validity.</v-col
    >

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="coloringrules"
        hide-default-footer
        v-on:change="updateModel"
        ref="sortableTable"
        disable-pagination
      >
        <template slot="item" slot-scope="props">
          <tr class="sortableRow" :key="itemKey(props.item)">
            <td class="px-1" style="width: 0.1%">
              <v-btn
                style="cursor: move"
                icon
                class="sortHandle"
                :disabled="disabled"
              >
                <v-icon>drag_handle</v-icon>
              </v-btn>
            </td>
            <td>
              <v-checkbox
              class="pt-0 mt-0 ml-0 ml-0 pb-2"
                :disabled="disabled"
                v-model="props.item['show']"
                primary
                hide-details
                @input="updateModel"
              ></v-checkbox>
            </td>
            <td>
              <v-text-field
                solo
                :disabled="disabled"
                v-model="props.item['rulename']"
                label="Coloringrule Name"
                hide-details
                maxlength="200"
                @input="updateModel"
              ></v-text-field>
            </td>

            <td>
              <displayfilterbox
                :disabled="disabled"
                v-model="props.item['rule']"
                @input="updateModel"
                class="monofont"
              ></displayfilterbox>
              <!--<v-text-field
              solo
              v-model="props.item['rule']"
              label="Coloringrule Filter"
              hide-details
              maxlength="200"
              ></v-text-field>-->
            </td>
            <td>
              <v-btn
                :color="props.item['background']"
                class=""
                :disabled="disabled"
                @click="selectColor(props.item, 'background')"
              ></v-btn>
            </td>
            <td>
              <v-btn
                :color="props.item['foreground']"
                class=""
                :disabled="disabled"
                @click="selectColor(props.item, 'foreground')"
              ></v-btn>
            </td>
            <td>
              <div
                class="rulepreview"
                v-bind:style="{
                  color: props.item['foreground'],
                  background: props.item['background'],
                }"
              >
                Example
              </div>
            </td>
            <td>
              <v-icon
                v-if="!disabled"
                color="info"
                v-on:click="removeRule(props.item)"
                >delete</v-icon
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" class="mt-4">
      <v-btn
        class="ma-2"
        small
        color="info"
        v-on:click="newRule"
        v-if="!disabled"
      >
        Add Rule
        <v-icon>add</v-icon>
      </v-btn>

      <v-btn
        class="ma-2"
        v-if="!disabled"
        small
        color="info"
        @click.native="openFileDialog"
      >
        Import
        <v-icon right dark> cloud_upload</v-icon>
      </v-btn>
      <input
        type="file"
        id="file-upload"
        style="display: none"
        @change="onFileChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import Sortable from "sortablejs";
import { mapGetters } from "vuex";

import Displayfilterbox from "./Displayfilterbox";

export default {
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Displayfilterbox,
  },
  name: "coloringrules",
  data() {
    return {
      color: "#ffffff",
      palette: [
        ["#264653","#2a9d8f","#e9c46a","#f4a261","#e76f51"],
        ["#ef476f","#ffd166","#06d6a0","#118ab2","#073b4c"],
        ["#f94144","#f3722c","#f8961e","#f9c74f","#90be6d","#43aa8b","#577590"],
        ["#ffbc42","#d81159","#8f2d56","#218380","#73d2de"],
        ["#ff99c8","#fcf6bd","#d0f4de","#a9def9","#e4c1f9"]
      ],
      currentcoloritem: null,
      currentcolorlayer: "foreground",
      showcolorpicker: false,
      itemKeys: new WeakMap(),
      uploadedrules: null,
      currentItemKey: 0,
      coloringrules: [],
      rules: {
        csscolor: (value) => {
          var isok = this.validateCssColor(value);
          return isok;
        },
      },

      headers: [
        {
          sortable: false,
        },
        {
          text: "Show",
          align: "left",
          width: "5%",
        },
        { text: "Rule Name", value: "rulename", width: "30%" },
        { text: "Rule", value: "rule", width: "46%" },
        { text: "Background", value: "background", width: "2%" },
        { text: "Foreground", value: "foreground", width: "2%" },
        { text: "Preview", value: "preview", width: "10%" },
        { text: "Actions", value: "actions", width: "5%" },
      ],
    };
  },
  created: function () {},
  mounted() {
    this.makeDefaults();
    new Sortable(
      this.$refs.sortableTable.$el.getElementsByTagName("tbody")[0],
      {
        draggable: ".sortableRow",
        handle: ".sortHandle",
        onEnd: this.dragReorder,
      }
    );
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error"]),
  },
  watch: {
    uploadedrules: function (n) {
      this.convert_rules_to_wp(n);
    },
  },
  methods: {
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (files.length > 0) {
        var reader = new FileReader();
        reader.readAsText(files[0]);
        reader.onload = () => {
          this.uploadedrules = reader.result;
        };
      }
    },
    decimal_to_hex: function (d, padding) {
      var hex = Number(d).toString(16);
      padding =
        typeof padding === "undefined" || padding === null
          ? (padding = 2)
          : padding;

      while (hex.length < padding) {
        hex = "0" + hex;
      }

      return hex;
    },
    selectColor: function (c, where) {
      this.currentcoloritem = c;
      this.currentcolorlayer = where;
      this.color = c[where];
      this.showcolorpicker = !this.showcolorpicker;
    },
    confirmColor: function () {
      this.showcolorpicker = false;

      if(this.color.length == 9){
        this.color = this.color.slice(0,7)
      }

      this.currentcoloritem[this.currentcolorlayer] = this.color;
    },
    ws_color_to_wp_color: function (c) {
      let rin = parseInt(c[0]);
      let gin = parseInt(c[1]);
      let bin = parseInt(c[2]);

      let rout = Math.round(rin / 257);
      let gout = Math.round(gin / 257);
      let bout = Math.round(bin / 257);


      let rhex = this.decimal_to_hex(rout, 2);
      let ghex = this.decimal_to_hex(gout, 2);
      let bhex = this.decimal_to_hex(bout, 2);

      let out = "#" + rhex + ghex + bhex;
      return out;
    },
    convert_rule_to_wp: function (rule) {
      var rsplit = rule.split("@");
      var colors = rsplit[3].split("[");
      var bg = colors[1].split("]")[0].split(",");
      var fg = colors[2].split("]")[0].split(",");

      let fgt = this.ws_color_to_wp_color(fg);
      let bgt = this.ws_color_to_wp_color(bg);

      var n = {};
      n["rulename"] = rsplit[1];
      n["rule"] = rsplit[2];
      n["background"] = bgt;
      n["foreground"] = fgt;
      n["show"] = true;
      return n;
    },
    convert_rules_to_wp: function (rules) {
      var rarray = rules.split(/\n/);
      for (let rule of rarray) {
        if (!rule.startsWith("#") && rule.includes("@")) {
          let t = this.convert_rule_to_wp(rule);

          this.coloringrules.push(t);
          this.updateModel();
        }
      }
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.coloringrules.splice(oldIndex, 1)[0];
      this.coloringrules.splice(newIndex, 0, movedItem);
      this.$emit("input", this.coloringrules);
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    makeDefaults() {
      for (let r of this.value) {
        if (r["background"] === undefined) {
          r["background"] = "#ffffff";
        }
        if (r["foreground"] === undefined) {
          r["foreground"] = "#000000";
        }
        this.coloringrules.push(r);
      }
    },
    updateColor(c) {
      let valid = this.validateCssColor(c);
      if (!valid) {
        this.error.type = "error";
        this.error.msg = "Invalid color";
      } else {
        this.updateModel();
      }
    },
    updateModel: function () {
      this.$emit("input", this.coloringrules);
    },
    newRule() {
      var n = {};
      n["rulename"] = "None";
      n["rule"] = "Nothing";
      n["background"] = "white";
      n["foreground"] = "#000000";
      this.coloringrules.push(n);
      this.updateModel();
    },
    removeRule(rule) {
      this.coloringrules.splice(this.coloringrules.indexOf(rule), 1);
      this.updateModel();
    },
    validateCssColor(stringToTest) {
      //Alter the following conditions according to your need.
      if (stringToTest === "") {
        return false;
      }
      if (stringToTest === "inherit") {
        return false;
      }
      if (stringToTest === "transparent") {
        return false;
      }

      var image = document.createElement("img");
      image.style.color = "rgb(0, 0, 0)";
      image.style.color = stringToTest;
      if (image.style.color !== "rgb(0, 0, 0)") {
        return true;
      }
      image.style.color = "rgb(255, 255, 255)";
      image.style.color = stringToTest;
      return image.style.color !== "rgb(255, 255, 255)";
    },
  },
};
</script>

<style >
.rulepreview {
  padding: 7px 7px 7px 7px;
  font-family: monospace !important;
  font-size: 150%;
}
</style>
