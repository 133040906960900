<template>
  <v-row class="pa-2" align="center" justify="center">
  <v-col cols="12">
  <v-data-table :headers="headers" :items="columns" hide-default-footer ref="sortableTable" disable-pagination>
    <template slot="item" slot-scope="props">
      <tr class="sortableRow" :key="itemKey(props.item)">
        <td class="px-1" style="width: 0.1%">
          <v-btn style="cursor: move" icon class="sortHandle" :disabled="disabled">
            <v-icon>drag_handle</v-icon>
          </v-btn>
        </td>

        <td>
          <v-checkbox :disabled="disabled" primary v-model="props.item['hide']" v-on:change="save"></v-checkbox>
        </td>
        <td>
          <v-text-field
            :disabled="disabled"
            solo
            v-model="props.item['headerName']"
            label="Field"
            hide-details
            maxlength="200"
            @input="save"
          ></v-text-field>
        </td>

        <td>
          <v-text-field
            :disabled="disabled"
            solo
            v-model="props.item['field']"
            label="Field"
            hide-details
            maxlength="200"
            @input="save"
          ></v-text-field>
        </td>
        <td>
          <v-slider
            :disabled="disabled"
            v-model="props.item['width']"
            :max="500"
            :min="0"
            thumb-label="always"
            step="10"
            ticks
            @input="save"
          ></v-slider>
        </td>
        <td>
          <v-checkbox
            :disabled="disabled"
            v-model="props.item['suppressSizeToFit']"
            v-on:change="save"
            primary
            hide-details
          ></v-checkbox>
        </td>
        <td>
        <v-select
          :items="renderers"
           v-model="props.item['cellRendererFramework']"
          label="Standard"
          dense
        ></v-select>
        </td>
        <td>
          <v-icon :disabled="disabled" small @click="deleteItem(props.item) " @input="save">delete</v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
  </v-col>
  <v-col cols="12" class="mt-4">
          <v-btn class="ma-2" small color="info" v-on:click="newColumn" :disabled="disabled">
        Add Column
        <v-icon>add</v-icon>
      </v-btn>
  </v-col>
  </v-row>
</template>

<script>
import { displayfilter } from "@/common/displayfilter";
import { renderermethods } from "@/common/renderers";

import { mapGetters } from "vuex";
import Sortable from "sortablejs";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: null
  },
  mixins: [displayfilter, renderermethods],
  name: "columnconfiguration",
  data() {
    return {
      renderers : this.renderOptions(),
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      columns: [],
      headers: [
        {
          sortable: false
        },
        {
          text: "Hide",
          align: "left",
          width: "5%"
        },
        { text: "Description", value: "headerName" },
        { text: "Field", value: "field" },
        { text: "Width", value: "width" },
        { text: "Supress size to fit", value: "suppressSizeToFit" },
        { text: "Renderer", value: "cellRendererFramework" },
        { text: "Actions", value: "actions" }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {
    for (let r of this.value) {
      r.field = this.df_es_field_to_wireshark(r.field)

      this.columns.push(r);
    }
    new Sortable(
      this.$refs.sortableTable.$el.getElementsByTagName("tbody")[0],
      {
        draggable: ".sortableRow",
        handle: ".sortHandle",
        onEnd: this.dragReorder
      }
    );
  },
  beforeDestroy() {},
  methods: {
    renderOptions(){
      let renderers = this.getRenderers()
      return renderers
    },
    deleteItem(item) {
      this.columns.splice(this.columns.indexOf(item), 1);
      this.save();
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.columns.splice(oldIndex, 1)[0];
      this.columns.splice(newIndex, 0, movedItem);
      this.save();
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    save() {
      this.updateModel()

    },
    updateModel: function() {

      this.$emit("input", this.columns);
    },
    newColumn() {
      var n = {};
      n.field = "",
      n.headerName = "",
      n.hide = false,
      n.suppressSizeToFit = true,
      n.width = 80
      this.columns.push(n);
      this.updateModel();
    },
  }
};
</script>

<style>
</style>
