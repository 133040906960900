export default {}

// features

// fonts Verdana, Arial, Helvetica, Georgia, Garamond, Courier New, Bookman, Impact

export const FRONTEND_VERSION = process.env.VUE_APP_FRONTEND_VERSION
export const FRONTEND_BUILD = process.env.VUE_APP_FRONTEND_BUILD
export const DEFAULT_TABLEFONT = "monospace"
export const DEFAULT_FONTSIZE = "16px"

export const CURRENT_TOS_VERSION = "1"
// unix timestamp in milli (base64) / all text md5

export const AGGRID_LICENSE = "CompanyName=Ripka Technologies S.L.,LicensedApplication=packetsafari,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-014310,ExpiryDate=15_March_2022_[v2]_MTY0NzMwMjQwMDAwMA==2276892de640d4dcfbe4f28d3f4e3dc7"
export const BACKEND = process.env.VUE_APP_BACKEND_URL
export const PCAP_URL = process.env.VUE_APP_PCAP_URL
export const API_URL = process.env.VUE_APP_API_URL
export const STATIC_URL = process.env.VUE_APP_STATIC_URL
export const AVATAR_URL = process.env.VUE_APP_AVATAR_URL
export const DOCUMENTATION_URL = process.env.VUE_APP_DOCS_URL

export const ADMIN_ROLE_ID = "admins"

export const UPLOAD_URL = API_URL + '/ajax/upload/pcap'
export const UPLOAD_ANON_URL = API_URL + '/ajax/uploadanon/pcap'
export const UPLOAD_URL_CHUNKED = API_URL + '/ajax/uploadchunk/pcap'
export const UPLOAD_URL_CHUNKED_FAST = API_URL + '/ajax/upload/pcap'

export const UPLOAD_TLS_URL_CHUNKED_FAST = API_URL + '/ajax/upload/tlskeys'

export const AVATAR_UPLOAD_URL = API_URL + '/ajax/upload/avatar'
export const ROLE_AVATAR_UPLOAD_URL = API_URL + '/ajax/upload/roleavatar'
export const TAG_AVATAR_UPLOAD_URL = API_URL + '/ajax/upload/tagavatar'
// avatars uploaded by users
export const AVATAR_PATH = AVATAR_URL + '/'


export const PRIVACY_URL = "https://www.packetsafari.com/page/privacy/"
export const CONTACT_URL = "/legal/contact/"
export const TOS_URL =  "https://www.packetsafari.com/page/tospacketsafari/"
// preinstalled avatars in vuejs app
export const DEFAULT_AVATARS_PATH = STATIC_URL + '/images/'

export const DEFAULT_AVATARS = ["generic.jpg", "genericrole.jpg", "adminrole.jpg", "generictag.jpg"]

export const AVATAR_GENERIC = DEFAULT_AVATARS_PATH + "generic.jpg"
export const ROLE_AVATAR_GENERIC = DEFAULT_AVATARS_PATH + "genericrole.jpg"
export const ROLE_AVATAR_ADMIN = DEFAULT_AVATARS_PATH + "adminrole.jpg"
export const TAG_AVATAR_GENERIC = DEFAULT_AVATARS_PATH + "generictag.jpg"

export const PACKETSAFARI_LOGO = STATIC_URL + "/images/" + "packetsafari-title-logo.png"

export const LOADING_ANIMATION = STATIC_URL + "/images/" + "loading.gif"

export const DEFAULT_PROFILE = "DefaultProfile"

export const INFO = {
    service: {
        trialperiod: "7 days",
        name: "PacketSafari",
        type: "PCAP analysis",
        subject: "PCAP",
        subjecttext: "packet captures",
        subjects: "PCAPs",
    },
    sites: ["www.packetsafari.com", "www.wavepacket.de"],
    company: {
        legislative: "Spain",
        lagislativeadj: "Spanish",
        type: "Spanish limited company",
        name: "Ripka Technologies S.L.",
        vatid: "ESB02732717",
        nif: "B02732717",
        street: "Calle Reina 10 - Bajo",
        city: "Valencia",
        region: "Valenica",
        country: "Spain",
        zip: "46011",
        email: "packetsafari  @  o r i p k a .de"
    }
}