<template>
  <v-row
    class="ma-7"
    v-if="followdata"
  >

    <v-col
      cols="12"
      class="mb-8"
    >
      From {{followdata.chost}}:{{followdata.cport}} to {{followdata.shost}}:{{followdata.sport}}
    </v-col>

    <v-col
      cols="12"
      class="mb-8"
    >
      <v-switch v-model="inbytes">
        <template slot="label">In hex</template>
      </v-switch>
    </v-col>

    <v-col
      cols="12"
      v-for="(value, key) in followdata.payloads"
      v-bind:key="key"
      class="mt-0 mb-0 pb-0 pt-3"
    >

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            @click="gotopacket(value.n)"
            v-bind="attrs"
            v-on="on"
            v-if="value.s == 1"
            class="blue lighten-5 blue--text followascii"
          >
            {{decodebase64(value.d)}}
          </div>
          <div
            v-else
            @click="gotopacket(value.n)"
            v-bind="attrs"
            v-on="on"
            class="red lighten-5 red--text followascii"
          >
            {{decodebase64(value.d)}}
          </div>

        </template>
        <span>Click to go to packet: {{value.n}}</span>
      </v-tooltip>

    </v-col>

  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";

export default {
  name: "Follow",
  props: {},
  components: {},
  mixins: [displayfilter],
  data() {
    return {
      inbytes: false,
      followdata: null,
    };
  },
  created() {},
  mounted() {
    this.followdata = this.currentAnalysis.followdata;
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    colorforpacket(n){
      this.currentAnalysis
      return ""
    }
  },
  beforeDestroy() {},
  methods: {
    gotopacket(n){
      this.$eventHub.$emit("goto", n);
      this.currentAnalysis.showFollow = false;

    },
    base64ToHex(str) {
      const raw = atob(str);
      let result = "";
      for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += hex.length === 2 ? hex : "0" + hex;
      }
      return result.toUpperCase();
    },

    decodebase64(s) {
      if (this.inbytes) return this.base64ToHex(s);
      return window.atob(s).replace(/[^ -~]/g, ".");
    },
  },
};
</script>

<style>
.followascii {
  font-family: monospace;
}
</style>
