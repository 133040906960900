<template>
  <div>
    <template>
      <v-stepper v-model="e1" non-linear>
        <v-stepper-header>
          <v-stepper-step editable :complete="e1 > 1" step="1">
            PCAP Upload
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step editable :complete="e1 > 2" step="2">
            Add information
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3">
            Finish
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <p class="mt-8">
              Does this PCAP contain a problem you are trying to solve?
            </p>

            <p >
              <a href="https://packetsafari.com/aishark" target="_blank"><b>👋 Upload to AI Shark instead for an automated analysis 👋</b></a> 
            </p>

            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="6" md="6">
                  <v-btn
                    color="primary"
                    class="ma-2 white--text"
                    @click="gotoStep2(true)"
                  >
                    Yes
                  </v-btn>
                </v-col>

                <v-col cols="6" md="6">
                  <v-btn
                    color="secondary"
                    class="ma-2 white--text"
                    @click="gotoStep2(false)"
                  >
                    No
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row v-if="isProblem">
              <v-col cols="12" class="noselect">
                <h3>Add a problem description to this PCAP</h3>
                <p>Best practices for a network problem description</p>
                <ul>
                  <li>
                    Start with a <b>general introductory sentence</b> about the
                    trace
                  </li>
                  <li>In which <b>environment</b> was this trace taken?</li>
                  <li>
                    Which devices are the different <b>MAC / IP addresses</b>?
                  </li>
                  <li>What is the <b>expected</b> behaviour?</li>
                  <li>What is the <b>experienced</b> behaviour?</li>
                  <li>
                    We are trying to build an archive of high-quality trace
                    files. Please add a
                    <b
                      >meaningful description or
                      <a href="https://app.packetsafari.com/register"
                        >register</a
                      ></b
                    >
                    to upload tracefiles without this requirement. Spam is deleted automatically.
                  </li>
                </ul>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      :error-messages="descriptionErrors"
                      outlined
                      maxlength="2000"
                      label="Problem Description (max 2000 characters)"
                      v-model="fields.description"
                      hint="Be concise"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12" class="noselect">
                <h3>Add metadata to your PCAP.</h3>
                <p>
                  We are trying to build an archive of high-quality trace files.
                  Add a
                  <b
                    >meaningful description or
                    <a href="https://app.packetsafari.com/register"
                      >register</a
                    ></b
                  >
                  to upload tracefiles without this requirement. Spam is deleted automatically.
                </p>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      minlength="50"
                      :error-messages="descriptionErrors"
                      required
                      maxlength="160"
                      label="Short description (min 50, max 160 characters)"
                      v-model="fields.description"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-btn color="primary" @click="gotoStep3()">
              Continue
            </v-btn>

            <v-btn text @click="cancel()">
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form ref="form" lazy-validation>
              <div class="ma-4">
                <h3 v-if="fields.description" class="mt-0 mb-2">Description</h3>

                <p>
                  {{ fields.description }}
                </p>

                <h3 class="mt-4 mb-2">Uploaded files</h3>

                <v-list dense class="mb-4">
                  <v-list-item-group color="primary">
                    <v-list-item v-for="(item, i) in pcaps" :key="i">
                      <v-list-item-icon>
                        <v-icon>check</v-icon>
                      </v-list-item-icon>
                      {{ item.upload.filename }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <div v-if="isProblem" class="mb-8 mt-2">
                  <v-checkbox v-model="fields.wanthelp">
                    <template v-slot:label>
                      <div>
                        I want help to interpret this trace file from
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://www.packetsafari.com/consulting/"
                              @click.stop
                              v-on="on"
                            >
                              a PacketSafari analysts
                            </a>
                          </template>
                          Consulting option
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>

                  <v-text-field
                    v-if="fields.wanthelp && !currentUser"
                    maxlength="250"
                    label="Email"
                    :rules="emailRules"
                    :required="fields.wanthelp"
                    v-model="fields.email"
                  ></v-text-field>
                </div>

                <div v-if="!currentUser && !isProblem" class="mb-8 mt-2 noselect">
                  We
                  <b
                    >highly recommend to
                    <router-link :to="{ name: 'Register' }"
                      >register before you next upload.</router-link
                    ></b
                  >
                  This enables you to organize your pcaps using tags, create
                  your own analyses, profiles and change more metadata. PCAPs
                  uploaded as registered user are <b>private by default</b>.
                </div>

                <v-btn color="primary" @click="apply()" :disabled="!valid">
                  Finish
                </v-btn>

                <v-btn text @click="cancel()">
                  Cancel
                </v-btn>
              </div>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </div>
</template>

<script>
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import axios from 'axios'

export default {
  props: ["value", "pcaps"],
  mixins: [commonmethods],
  name: "uploadPcapDialog",
  data() {
    return {
      e1: 1,
      descriptionErrors: [],
      isProblem: false,
      fields: this.value,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        (v) => !this.isContentModeratedEmail(v) || "E-mail must be valid",
      ],
    };
  },
  watch: {
    descriptionErrors(n, o) {
      this.notifyLambdaData({errors: this.descriptionErrors, description: this.fields.description})
    },
  },
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error"]),
    valid() {
      let l = this.fields.email;

      if (!this.isProblem) {
        return true;
      }

      /* anonymous via email*/

      console.log(this.fields);
      if (this.fields.wanthelp) {
        if (!this.currentUser && this.$refs?.form?.validate()) {
          return true;
        }

        if (this.currentUser) {
          return true;
        }
      }

      if (!this.fields.wanthelp) {
        return true;
      }

      return false;
    },
  },
  mounted() {},
  created: function() {},
  methods: {
    detectSentences(text) {
      const sentences = text.split(".");
      const keywords = [
        "Add metadata to your PCAP",
        "Add a meaningful description or register to upload tracefiles without this requirement",
      ];
      for (const sentence of sentences) {
        for (const keyword of keywords) {
          if (sentence.includes(keyword)) {
            return true;
          }
        }
      }
      return false;
    },
    notifyLambdaData(indata) {
      return
      let c = { data: indata, description: "Invalid description" };
      axios
        .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", c)
        .then((res) => { })
        .catch((error) => {
        })
        .finally(() => {
        });
    },
    /*write a function that detects long words with low entropy. kind of gibberish text input like: sdijfidsfjosfijsadijoasoifasdjfios or saidfojasdiofj348r934rfhjsdiufsd*/
    isKeyboardGibberish(text) {
      const MIN_ENTROPY = 2.5;
      const MIN_WORD_LENGTH = 10;
      let total_entropy = 0;
      let total_words = 0;

      text = text.replace(/[^\w\s]/gi, ""); // Remove non-alphanumeric characters

      text.split(" ").forEach((word) => {
        if (word.length >= MIN_WORD_LENGTH) {
          const unique_chars = new Set(word.split(""));
          const entropy = Math.log2(Math.pow(unique_chars.size, word.length));
          total_entropy += entropy;
          total_words++;
        }
      });

      const avg_entropy = total_entropy / total_words;

      return avg_entropy < MIN_ENTROPY;
    },

    isLoremIpsum(text) {
      const loremIpsumWords = [
        "Lorem",
        "ipsum",
        "dolor",
        "sit",
        "amet",
        "consectetur",
        "adipiscing",
        "elit",
        "sed",
        "do",
        "eiusmod",
        "tempor",
        "incididunt",
        "ut",
        "labore",
        "et",
        "dolore",
        "magna",
        "aliqua",
      ];

      const words = text.split(" ");
      let isLoremIpsum = true;

      for (let i = 0; i < words.length; i++) {
        if (loremIpsumWords.indexOf(words[i]) >= 0) {
          return true;
        }
      }

      return false;
    },
    isContentModeratedEmail(text) {
      const offensiveWords = [
        "fuck",
        "let me alone",
        "come on",
        "for sake ",
        "of sanity",
        "puta",
        "cojones",
        "stupid",
        "ashamed",
        "madre",
        "cago",
        "en tu",
        "joder",
        "gilli",
        "gilipollas",
        "mierda",
        "cojones",
        "coño",
        "tonterias",
        "hostia",
        "shit",
        "cunt",
        "cock",
        "piss",
        "bullshit",
        "????",
        "pussy",
        "sucks",
        "suck",
        "dick",
        "asshole",
        "bitch",
        "bastard",
        "motherfucker",
        "nigger",
        "faggot",
        "whore",
        "slut",
        "twat",
        "douche",
        "fucker",
        "cum",
        "jizz",
        "cumshot",
        "blowjob",
        "handjob",
        "anal",
        "vagina",
        "penis",
        "testicles",
        "masturbate",
        "orgasm",
        "erection",
        "sperm",
        "butt",
        "fisting",
        "golden shower",
        "incest",
        "fucking",
        "Why i",
        "pedophile",
        "rape",
        "necrophilia",
        "bestiality",
      ];

      let isModerated = false;

      for (let i = 0; i < offensiveWords.length; i++) {
        if (text.toLowerCase().includes(offensiveWords[i])) {
          isModerated = true;
          break;
        }
      }

      return isModerated;
    },

    isGibberish(text) {
      const words = text.split(" ");
      const numWords = words.length;
      const numUniqueWords = new Set(words).size;
      const wordRatio = numUniqueWords / numWords;
      const letterRatio = text.replace(/[^a-zA-Z]/g, "").length / text.length;
      const shannonEntropy = this.calculateShannonEntropy(text);

      if (numWords < 5) {
        return true; // Text is too short to analyze
      }

      if (wordRatio < 0.5) {
        return true; // Text has too many repeated words
      }

      if (letterRatio < 0.4) {
        return true; // Text has too few letters
      }

      if (shannonEntropy < 3.5) {
        return true; // Text has low entropy
      }

      return false; // Text is not gibberish
    },
    isContentModerated(text) {
      const offensiveWords = [
      "fuck",
        "let me alone",
        "come on",
        "for sake ",
        "of sanity",
        "puta",
        "cojones",
        "stupid",
        "ashamed",
        "madre",
        "cago",
        "en tu",
        "joder",
        "gilli",
        "gilipollas",
        "mierda",
        "cojones",
        "coño",
        "tonterias",
        "hostia",
        "shit",
        "cunt",
        "cock",
        "pussy",
        "sucks",
        "suck",
        "dick",
        "asshole",
        "bitch",
        "bastard",
        "motherfucker",
        "nigger",
        "faggot",
        "whore",
        "slut",
        "twat",
        "douche",
        "fucker",
        "cum",
        "jizz",
        "cumshot",
        "blowjob",
        "handjob",
        "anal",
        "vagina",
        "penis",
        "testicles",
        "masturbate",
        "orgasm",
        "erection",
        "sperm",
        "butt",
        "fisting",
        "golden shower",
        "incest",
        "fucking",
        "Why i",
        "pedophile",
        "rape",
        "necrophilia",
        "bestiality",
      ];

      const words = text.split(" ");
      let isModerated = false;

      for (let i = 0; i < words.length; i++) {
        if (offensiveWords.includes(words[i].toLowerCase())) {
          isModerated = true;
          break;
        }
      }

      return isModerated;
    },

    calculateShannonEntropy(text) {
      const frequencies = {};
      for (let i = 0; i < text.length; i++) {
        const char = text[i];
        frequencies[char] = (frequencies[char] || 0) + 1;
      }

      let shannonEntropy = 0;
      for (const char in frequencies) {
        const frequency = frequencies[char] / text.length;
        shannonEntropy -= frequency * Math.log2(frequency);
      }

      return shannonEntropy;
    },
    validate() {
      this.$refs.form.validate();
    },
    gotoStep2(isProblem) {
      this.isProblem = isProblem;
      this.fields.wanthelp = isProblem;
      this.e1 = 2;
    },
    gotoStep3() {
      console.log("Current user " + this.currentUser);
      debugger
      for (let i of this.pcaps) {
        if (
          i.upload.filename == "dump.pcap" ||
          i.upload.filename == "trace.pcap"
        ) {
          this.descriptionErrors = ["Invalid file"];
          return
        }
      }

      if (this.currentUser) {
        this.descriptionError = [];
        this.e1 = 3;
        return;
      }

      if (this.isProblem) {
        if (this.fields.description.length < 50) {
          this.descriptionErrors = [
            "You need to provide a well-written description, longer than 50 characters",
          ];
        } else if (this.fields.description.length > 2000) {
          this.descriptionErrors = [
            "You need to provide a well-written description, shorter than 2000 characters",
          ];
        } else if (this.isGibberish(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that is not gibberish",
          ];
        } else if (this.isLoremIpsum(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that is not lorem ipsum",
          ];
        } else if (this.isContentModerated(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description is not offensive",
          ];
        } else if (this.isKeyboardGibberish(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that does not contain gibberish",
          ];
        } else if (this.detectSentences(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that does contain a proper description",
          ];
        } else {
          this.descriptionError = [];
          this.e1 = 3;
        }
      } else {
        if (this.fields.description.length < 50) {
          this.descriptionErrors = [
            "You need to provide a well-written description, longer than 50 characters.",
          ];
        } else if (this.fields.description.length > 160) {
          this.descriptionErrors = [
            "You need to provide a well-written description, shorter than 160 characters",
          ];
        } else if (this.isGibberish(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that is not gibberish",
          ];
        } else if (this.isLoremIpsum(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that is not lorem ipsum",
          ];
        } else if (this.isContentModerated(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description is not offensive",
          ];
        } else if (this.isKeyboardGibberish(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that does not contain gibberish",
          ];
        } else if (this.detectSentences(this.fields.description)) {
          this.descriptionErrors = [
            "You need to provide a description that does contain a proper description",
          ];
        } else {
          this.descriptionError = [];
          this.e1 = 3;
        }
      }
    },
    apply() {
      this.$emit("changed", this.fields);
      this.$emit("close");
    },
    cancel() {
      this.isProblem = false;
      this.e1 = 1;
      this.$emit("changed", "");
      this.$emit("cancel");
    },
  },
};
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
