<template>
  <v-card class="pb-2">
    <v-card-title>List of profiles</v-card-title>
    <v-card-text class="py-2">
      <v-row class="pt-2">
        <v-col cols="10">
          Analysis Profiles allow you to configure various settings of the
          analyzer. Including layout, decoding settings, coloring rules,
          columns, and much more. Read the documentation for more info
          <linktodoc
            doc="settings/profiles"
            text="Learn why and how to use analysis profiles"
          ></linktodoc>
        </v-col>
      
        <v-col cols="2">
          <v-select solo :items="types" v-model="selectedtype"></v-select>
          <v-text-field v-if="selectedtype == 'search'" v-model="searchterm"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="profiles"
            :hide-default-footer="totalitems < 10"
            :options.sync="tableoptions"
            :server-items-length="totalitems"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td >
                  <v-icon
                    :disabled="!currentUser"
                    v-if="
                      props.item.id != currentAnalysis.profiles.selected['id']
                    "
                    v-on:click.prevent="selectProfile(props.item)"
                    >radio_button_unchecked</v-icon
                  >
                  <v-icon :disabled="!currentUser" v-else>radio_button_checked</v-icon>
                </td>
            
                <td>
                  <router-link
                    :to="{
                      name: 'Analysis Profile',
                      params: { profileid: props.item['id'] },
                    }"
                    >{{ props.item["name"] }}</router-link
                  >
                </td>

                <td>{{ props.item["description"] }}</td>
                <td>
                  <v-chip
                    color="primary"
                    v-if="'permissions_owner' in props.item.permissions"
                    >{{
                      props.item.permissions["permissions_owner"][0]["username"]
                    }}</v-chip
                  >
                  <v-chip color="secondary" v-else>Default</v-chip>
                </td>
                <td>
                  <v-chip
                    v-if="props.item.permissions.permissions_public"
                    color="green lighten-2"
                    >public</v-chip
                  >
                  <v-chip color="red lighten-2" v-else primary>private</v-chip>
                </td>
                <td >
                  <v-tooltip
                    bottom
                    v-if="
                      isOwner(currentUser, props.item.permissions) &&
                      isPublic(props.item.permissions)
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="px-1"
                        slot="activator"
                        color="primary"
                        small
                        v-on="on"
                        @click="togglePublic(props.item)"
                        >cloud</v-icon
                      >
                    </template>

                    <span
                      >Profile is public, click to deny public access to
                      profile</span
                    >
                  </v-tooltip>

                  <v-tooltip
                    bottom
                    v-else-if="
                      isOwner(currentUser, props.item.permissions) &&
                      !isPublic(props.item.permissions)
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="px-1"
                        slot="activator"
                        color="primary"
                        small
                        @click="togglePublic(props.item)"
                        v-on="on"
                        >lock</v-icon
                      >
                    </template>

                    <span
                      >Profile is private, click to allow public access to
                      profile</span
                    >
                  </v-tooltip>

                  <v-icon
                    v-if="isOwner(currentUser, props.item.permissions)"
                    color="info"
                    small
                    v-on:click="editProfile(props.item)"
                    >edit</v-icon
                  >

                  <v-icon
                    v-if="isOwner(currentUser, props.item.permissions)"
                    color="info"
                    small
                    v-on:click="removeProfile(props.item)"
                    >delete</v-icon
                  >
                  <v-icon
                    color="info"
                    :disabled="!currentUser"
                    small
                    v-on:click="copyProfile(props.item)"
                    >file_copy</v-icon
                  >
                </td>
    
              </tr>
            </template>
          </v-data-table>
          <!--<v-btn v-on:click="loadprofiles()">Load public profiles</v-btn>-->
        </v-col>
        <!--<v-col cols="10"></v-col>
    <v-col cols="2" class="pt-3">
      <v-btn small color="info" v-on:click="newProfile">Add Profile
        <v-icon>add</v-icon>
      </v-btn>
    </v-col>-->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import ApiProfile from "@/common/api/profile";
import { permissionchecks } from "@/common/permissions";
import Vue from "vue";
import Linktodoc from "@/components/basics/Linktodoc";
export default {
  props: ["value"],
  components: { Linktodoc },
  name: "profilelist",
  data() {
    return {
      types: ["public", "private", "search"],
      selectedtype: "public",
      searchterm: "",
      totalitems: 0,
      tableoptions: {
        itemsPerPage: 10,
      },
      profiles: [],
      c: [],
      headers: [
        {
          text: "Active",
          align: "left",
          value: "current",
          sortable: false,
        },
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false,
        },
        { text: "Description", value: "description", sortable: false },
        { text: "Owner", value: "permissions", sortable: false },
        { text: "Permissions", value: "permissions", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  created: function () {},
  mounted() {},
  mixins: [permissionchecks],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  watch: {
    selectedtype() {
      this.loadprofiles();
    },
    tableoptions: {
      handler() {
        this.loadprofiles();
      },
      deep: true,
    },
    searchterm() {
      this.loadprofiles()
    }
  },
  methods: {
    publish: function (p) {
      p.permissions.permissions_public = true;
      //p.permissions.permissions_public = true
      ApiProfile.update(p).then(({ data }) => {
        this.error.type = "info";
        this.error.msg = "Published";
        this.currentAnalysis.profileneedsupdate = true
      });
    },
    lock: function (p) {
      p.permissions.permissions_public = false;
      //p.permissions.permissions_public = false
      ApiProfile.update(p).then(({ data }) => {
        this.error.type = "info";
        this.error.msg = "Locked";
        this.currentAnalysis.profileneedsupdate = true
      });
    },
    togglePublic: function (p) {
      if (p.permissions.permissions_public) {
        this.lock(p);
      } else {
        this.publish(p);
      }
    },
    newProfile() {
      var n = {};
      n["name"] = "New profile";
      n["description"] = "Nothing";
      this.profiles.push(n);
      this.updateModel();
    },
    removeProfile(profile) {
      ApiProfile.delete(profile["id"]).then(({ data }) => {
        this.profiles.splice(this.profiles.indexOf(profile), 1);
        this.updateModel();
        this.currentAnalysis.profileneedsupdate = true
      });
    },
    loadprofiles: function () {
      let { sortBy, sortDesc, page, itemsPerPage } = this.tableoptions;

      let offset = (page - 1) * itemsPerPage;

      if (offset == null) {
        offset = 0;
      }

      if(this.selectedtype == "search" && (this.searchterm == '' || this.searchterm.length < 3)){
        return
      }

      let args = {
        type: this.selectedtype,
        sortBy: sortBy,
        sortDesc: sortDesc,
        term: this.searchterm,
        page: page,
        perpage: itemsPerPage,
        offset: offset,
      };

      ApiProfile.list(args)
        .then(({ data }) => {
          this.profiles.length = 0;
          this.profiles.push(...data.profiles);
          this.totalitems = data.total;
        })
        .catch(() => {});
    },

    editProfile: function (profile) {
      this.$router
        .push({
          name: "Analysis Profile",
          params: { profileid: profile["id"] },
        })
        .catch((err) => {});
    },
    selectProfile: function (profile) {
      
      ApiProfile.get(profile["id"])
        .then(({ data }) => {
          
          this.currentAnalysis.profiles.selected = data;
          this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
        })
        .catch(({ response }) => {});
    },
    copyProfile: function (profile) {
      ApiProfile.copyprofile(profile["id"])
        .then(({ data }) => {
          this.error.type = "Info";
          this.error.msg = "New profile created";
          this.profiles.push(data);
          this.currentAnalysis.profileneedsupdate = true
          this.updateModel();
        })
        .catch(({ response }) => {});
    },
  },
};
</script>

<style >
</style>
