<template>
  <v-dialog
    v-if="currentAnalysis.pcaplist.tagdialog"
    v-model="currentAnalysis.pcaplist.tagdialog"
    max-width="600px"
  >
    <v-card class="pa-2">
      <v-card-title>
        <v-row  >
          <v-col cols="12">
            <h3>Tagging dialog</h3>
            <br>This will only remove the tags that are common to all the selected PCAPs or add the tags to all the selected PCAPs.
          </v-col>
          <v-col cols="12">
            <br>If you want to use a new tag create it in the
            <router-link :to="{ name: 'Tags'}">tagging settings</router-link>
          </v-col>
          <v-col cols="12">
            <masstagging
              :pcaps="this.currentAnalysis.pcaplist.checkedpcaps"
              label="Enter tags for all selected PCAPs"
              :isowner="true"
              type="pcap"
            ></masstagging>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Masstagging from "./Masstagging";
import { mapGetters } from "vuex";

export default {
  components: { Masstagging },
  props: {},
  name: "tagdialog",
  data() {
    return {
      tags: []
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {},
  beforeDestroy() {},

  methods: {}
};
</script>

<style>
</style>
