<template>
  <div class="indiceslist">
    <v-data-table
      :headers="headers"
      :items="indices"
      hide-default-footer
      class="elevation-1"
      item-key="id"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td @click="props.expanded = !props.expanded">
            <!--<router-link
              :to="{ name: 'Analysis', params: { id: pcap.id, index: props.item.id }}"
            >{{props.item.indexername }}</router-link>-->
            
            <div @click="copyText(props.item.name)" >{{props.item.indexername }}</div>

            <!--<router-link :to="{ name: 'Index', params: { id: props.item.name }}">{{props.item.indexername }}</router-link>-->
          </td>
          <td @click="props.expanded = !props.expanded">{{props.item.built}}</td>
          <td>{{props.item.created_at | formatDate}}</td>
          <td>{{props.item.bulkinsertiontime | formatDuration}}</td>
          <td>{{props.item.overallindexingtime | formatDuration }}</td>
          <td class="justify-center">
            <v-icon v-if="isowner" color="red" small @click="deleteIndex(props.item)" slot="activator">delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiPcapmeta from "@/common/api/pcapmeta";
import ApiPcapindex from "@/common/api/pcapindex";
import ApiIndexerprofile from "@/common/api/indexerprofile";
import { mapGetters } from "vuex";

export default {
  props: {
    indices: null,
    pcap: null,
    isowner: null
  },
  components: {},
  computed: {
    ...mapGetters(["error", "currentUser"])
  },
  name: "indiceslist",
  created: function() {
    if (this.currentUser) {
      this.loadindexers();
    }
  },

  data() {
    return {
      availableindexers: [],
      selectedindexer: null,
      thispcap: null,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "indexername"
        },
        { text: "Indexed", value: "built", sortable: true },
        { text: "Created", value: "created_at", sortable: true },
        {
          text: "Bulk insertion time (s)",
          value: "bulkinsertiontime",
          sortable: true
        },
        {
          text: "Total indexing time (s)",
          value: "overallindexingtime",
          sortable: true
        },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    deleteIndex: function(index) {
      ApiPcapindex.delete(this.pcap.id, index.id)
        .then(() => {
          this.error.msg = "";
          this.indices.splice(this.indices.indexOf(index), 1);
        })
        .catch(() => {
          this.pcaps = [];
        });
    },
    reloadpcap(pcap) {
      ApiPcapmeta.get(pcap.id)
        .then(({ data }) => {
          this.indices = data["indices"];
        })
        .catch(() => {});
    },
    copyText (text) {
      navigator.clipboard.writeText(text);
    },
    loadindexers() {
      this.availableindexers = [];
      ApiIndexerprofile.list()
        .then(({ data }) => {
          this.availableindexers.push(...data);
        })
        .catch(({ response }) => {
          this.error.msg = response["error"];
          this.pcaps = [];
        });
    }
  }
};
</script>

<style>
</style>