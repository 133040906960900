<template>
  <v-row
  
    class="mx-2"
    v-if="getPcapField(pcapfield) || (!getPcapField(pcapfield) && !hideempty)"
  >
    <v-col cols="4" class="my-1 py-1">
      {{ items[pcapfield] }}
    </v-col>

    <v-col
      v-if="pcapfield == 'hastlssecrets' && getPcapField(pcapfield)"
      cols="8"
      class="my-1 py-1"
      style="word-break: break-all"
    >
      Yes,

      <a style="text-decoration: none" href="#" @click.prevent="downloadSecrets"
        >download</a
      >
    </v-col>
    <v-col v-else cols="8" class="my-1 py-1">
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'PCAPs',
          params: {
            type: currentAnalysis.pcaplist.type,
            query: pcapfield + ':' + getPcapFieldQuoted(pcapfield),
          },
        }"
      >
        <template
          v-if="
            pcapfield == 'endtime' ||
            pcapfield == 'starttime' ||
            pcapfield == 'timestamp'
          "
          >{{ getPcapField(pcapfield) | formatDate }}</template
        >
        <template v-else-if="pcapfield == 'filesize' || pcapfield == 'datasize' || pcapfield == 'averagepacketsize'">
            {{ getPcapField(pcapfield) | humbytes }} / {{(getPcapField(pcapfield)) }} bytes
        </template>
        <template v-else-if="pcapfield == 'databitrate'">
            {{ getPcapField(pcapfield) | humbitrate }} / {{(getPcapField(pcapfield)) }} bits/s
        </template>
        <template v-else-if="pcapfield == 'databyterate'">
            {{ getPcapField(pcapfield) | humbyterate }} / {{(getPcapField(pcapfield)) }} byte/s
        </template>
        <template v-else-if="pcapfield == 'captureduration'">
            {{ getPcapField(pcapfield) | formatDurationHuman }} / {{(getPcapField(pcapfield)) }} seconds
        </template>
        <template v-else>{{ getPcapField(pcapfield) }}</template>
      </router-link>
    </v-col>
  </v-row>

  <!-- <v-list-item dense v-if='getPcapField(pcapfield) || (!getPcapField(pcapfield) && !hideempty)'>
    <v-list-item-content class="ma-0 pa-0">
      <v-list-item-title>
          {{items[pcapfield]}}

      </v-list-item-title>

    </v-list-item-content>
  </v-list-item> -->
</template>

<script>
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import ApiPacketlist from "@/common/api/packetlist";

export default {
  props: {
    pcapfield: null,
    pcap: null,
    hideempty: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [commonmethods],
  name: "pcapdetailvalue",
  filters: {
    humbytes: function(size) {
      if(size == 0) {return "0 B"}
      var i = Math.floor( Math.log(size) / Math.log(1024) );
      return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    },
    humbitrate: function(size) {
      if(size == 0) {return "0 bit/s"}
      var i = Math.floor( Math.log(size) / Math.log(1000) );
      return ( size / Math.pow(1000, i) ).toFixed(2) * 1 + ' ' + ['bit/s', 'kbit/s', 'Mbit/s', 'Gbit/s', 'Tbit/s'][i];
    },
    humbyterate: function(size) {
      if(size == 0) {return "0 bit/s"}
      var i = Math.floor( Math.log(size) / Math.log(1024) );
      return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['byte/s', 'kbyte/s', 'Mbyte/s', 'Gbyte/s', 'Tbyte/s'][i];
    },
  },
  data() {
    return {
      items: {
        origname: "Original name",
        //"filename" : "Name on disk (admin only)",
        filesize: "File size",
        filetype: "File type",
        fileencapsulation: "File encapsulation",
        timestamp: "Uploaded",
        ap: "Analysis package",
        numberofpackets: "Number of packets",
        datasize: "Total bytes",
        starttime: "Start time",
        endtime: "End time",
        captureduration: "Capture duration in s",
        databitrate: "Data bit rate / s",
        databyterate: "Date byte rate / s",
        averagepacketrate: "Average packet rate / s ",
        averagepacketsize: "Average packet size",
        filetimeprecision: "File time precision",
        packetsizelimit: "Packet size limit",
        packetsizelimitmax: "Packet size limit max",
        packetsizelimitmin: "Packet size limit min",
        captureapplication: "Capture application",
        capturecomment: "Capture comment",
        capturehardware: "Capture hardware",
        "captureoper-sys": "Capture operating system",
        stricttimeorder: "Strict time order",
        importid: "Google Drive import ID",
        ripemd160: "RIP160",
        sha1: "SHA1",
        sha256: "SHA256",
        id: "ID",
        credits: "Credits",
        source_url: "Source URL",
        license: "License",
        hastlssecrets: "TLS Secrets",
      },
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic"]),
  },
  methods: {
    getPcapField(field) {
      return this.pcap[field];
    },
    downloadSecrets(token) {
      let params = {
        token: "ssl-secrets",
        pcapid: this.pcap.id,
      };

      ApiPacketlist.exportobject(params)
        .then(({ data }) => {
          this.stats = data["taps"][0];
        })
        .catch(() => {});
    },
    getPcapFieldQuoted(field) {
      return '"' + this.getPcapField(field) + '"';
    },
  },
};
</script>

<style >
</style>