var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0",staticStyle:{"display":"inline"}},[(_vm.canEditCurrentProfile)?_c('v-menu',{attrs:{"position-x":_vm.filterbuttonmenux,"position-y":_vm.filterbuttonmenuy,"absolute":"","offset-y":""},model:{value:(_vm.showfilterbuttonmenu),callback:function ($$v) {_vm.showfilterbuttonmenu=$$v},expression:"showfilterbuttonmenu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editfilterbutton(_vm.menubutton)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.removefilterbutton(_vm.menubutton)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?[_c('v-chip',{staticClass:"mx-1 my-0",attrs:{"label":"","small":"","color":"secondary"},on:{"click":function($event){_vm.showbuttons = !_vm.showbuttons}}},[_vm._v("Filters")])]:_vm._e(),(_vm.groupedbuttons != null && ((_vm.$vuetify.breakpoint.mobile && _vm.showbuttons) || !_vm.$vuetify.breakpoint.mobile))?[_vm._l((_vm.groupedbuttons),function(grouporbutton,index){return [(grouporbutton.type == 'button')?_c('v-chip',{key:index,staticClass:"mx-1 my-0",attrs:{"label":"","small":"","color":"primary"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.doshowfilterbuttonmenu($event, grouporbutton.idx)},"click":function($event){return _vm.applyDisplayFilterAndClose(grouporbutton.rule)}}},[_vm._v(_vm._s(grouporbutton.rulename))]):_c('v-menu',{key:index,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.mobile? 'mx-1 mt-1' : 'mx-1',attrs:{"label":"","small":"","color":"primary"}},'v-chip',attrs,false),on),[_vm._v(_vm._s(grouporbutton.groupname))])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((grouporbutton.buttons),function(button,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(button.rulename)},on:{"click":function($event){return _vm.applyDisplayFilterAndClose(button.rule)},"contextmenu":function($event){$event.preventDefault();return _vm.doshowfilterbuttonmenu($event, button.idx)}}})],1)}),1)],1)]})]:_vm._e(),(
      _vm.currentAnalysis.profiles.selected.filterbuttons &&
      _vm.currentAnalysis.profiles.selected.filterbuttons.length <= 20 &&
      _vm.canEditCurrentProfile
    )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"slot":"activator","icon":"","x-small":"","text":""},on:{"click":_vm.addbutton},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter-plus")])],1)]}}],null,false,967522022)},[_c('span',[_vm._v("Add filter button")])]):(false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"slot":"activator","disabled":"","icon":"","small":"","text":""},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter-plus")])],1)]}}])},[_c('span',[_vm._v("To add new buttons please create a new profile")])]):_vm._e(),(_vm.currentAnalysis.showfilterbuttondialog)?_c('filterbuttondialog',{on:{"updated":_vm.groupbuttons},model:{value:(_vm.menubutton),callback:function ($$v) {_vm.menubutton=$$v},expression:"menubutton"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }