<template>
  <div class="timeline" ref="chartdiv"></div>
</template>

<script>
import ApiPacketlist from "@/common/api/packetlist"
import moment from "moment-timezone";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_material);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  name: "Timeline",
  props: {
    id: {
      type: String,
      default: ""
    },
    indexid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      displayfilter: null,
      axis: null,
      moment: moment,
      timeline: null
    };
  },
  mounted() {
    this.fetchTimeline();

    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    //    chart.paddingRight = 20;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    //valueAxis.renderer.minWidth = 35;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";

    series.tooltipText = "{valueY.value}";
    chart.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();

    // chart.events.on("datavalidated", function () {
    //     var myrange = {
    //     	start : 0.1,
    //     	end : 0.2
    //     }
    //   dateAxis.zoom(myrange);
    //   this.$eventHub.$emit("zoomrange", myrange);
    // }.bind(this));

    //scrollbarX.events.on('rangechanged', this.handleChanged);
    this.axis = dateAxis;
    //this.axis.events.on('datarangechanged',this.handleChanged)
    scrollbarX.events.on("up", this.handleChanged);

    //chart.events.on('visibilitychanged', this.handleChanged);

    scrollbarX.series.push(series);

    chart.scrollbarX = scrollbarX;

    chart.plotContainer.disabled = true;
    chart.leftAxesContainer.visible = false;
    chart.rightAxesContainer.visible = false;
    chart.bottomAxesContainer.visible = false;
    this.chart = chart;

    //this.axis.maxZoomFactor = 0.2
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    handleChanged() {
      // var params = { start: null, end: null };

      if (!(this.chart.scrollbarX === undefined || this.chart === undefined)) {
        var s = this.chart.scrollbarX.range.start;
        var e = this.chart.scrollbarX.range.end;

        if (s == 0 && e == 1) {
          return;
        }

        this.updateDatesFromSelection(s, e);
      }
      //console.log(this.e.start)
    },
    updateDatesFromSelection(start, end) {
      var c = this.currentAnalysis;
      var t = c.totalf;

      var s = this.moment.utc(c.startDate);
      var e = this.moment.utc(c.endDate);

      if (t == 0) {
        return;
      }

      /* duration in milliseconds */

      var duration = e - s;

      // just some boundaries, maybe throw error
      if (duration > 10000000) {
        return;
      }

      c.selectStartDate = this.moment.utc(s + duration * start).toISOString();
      c.selectEndDate = this.moment.utc(s + duration * end).toISOString();

      this.$store.dispatch(UPDATE_ANALYSIS, c);
      this.$eventHub.$emit("update", null);
    },
    fetchTimeline() {

      let params = {
        pcapid: this.id,
        start: 0,
        end: 10000 // FIXME hardcoded
      }
      if(this.displayfilter){
        params.filter = this.displayfilter
      }

      if(this.indexid && !this.indexid == "none"){
        params.index = this.indexid
      }

      ApiPacketlist.timeline(params).then(({ data }) => {
        let chartdata = [];

        var c = this.currentAnalysis;
        c.startDate = data["histogram"][0]["key_as_string"];
        c.endDate =
          data["histogram"][data["histogram"].length - 1]["key_as_string"];
        this.$store.dispatch(UPDATE_ANALYSIS, c);

        for (let elem of data["histogram"]) {
          var moment = this.moment;
          var mydate = moment
            .utc(elem["key_as_string"])
            .tz(moment.tz.guess())
            .toDate();

          chartdata.push({
            date: mydate,
            name: elem["key"],
            value: elem["doc_count"]
          });
        }
        this.chart.data = chartdata;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.timeline {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #bec3c6;
}

/* stupidity, no ids in the elements */
#analyze
  > div
  > div:nth-child(2)
  > div
  > div
  > svg
  > g
  > g
  > g:nth-child(1)
  > g:nth-child(2)
  > g
  > g
  > g
  > g
  > g
  > g
  > g:nth-child(1) {
  display: none;
}
</style>
