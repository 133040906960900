<template>

  <v-dialog
    v-if="currentAnalysis.pcaplist.editcap"
    v-model="currentAnalysis.pcaplist.editcap"
    max-width="800px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-row  >
          <v-col cols="12">
            <h3>Edit PCAP</h3>
            <br>
          </v-col>
          <v-col cols="12">
          </v-col>
          <v-col cols="12">
            <v-row  >

                <v-col cols="12" class="px-2">
                    <v-text-field :placeholder="placeholderfilename" label="New Filename" v-model="newname"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-switch label="Only save special packets" v-model="dosaveselection"></v-switch>
                </v-col>
                <v-col cols="3" class="px-2">
                  <v-select :disabled="!dosaveselection" v-model="selectedOption" :items="selectOptions" label="Packets to save">
                  </v-select>
                </v-col>
                <v-col cols="3" class="px-2">
                  <v-text-field v-if="selectedOption != 'Displayed'" :disabled="!dosaveselection" :placeholder="placeholder" :label="selectedOption" v-model="ranges"></v-text-field>
                </v-col>

                <v-col cols="4" class="px-2">
                  <v-switch label="Activate packet slicing" v-model="doslicing"></v-switch>
                </v-col>
                <v-col cols="8" class="px-2">
                  <v-text-field :disabled="!doslicing" placeholder="256" label="Packet slicing" v-model="slicing"></v-text-field>
                </v-col>

                <v-col cols="4" class="px-2">
                  <v-switch label="Edit timestamps" v-model="doedittimestamps"></v-switch>
                </v-col>
                <v-col cols="8" class="px-2">
                  <v-text-field :disabled="!doedittimestamps" placeholder="3.14 " label="Timestamp offset (-)3.14 in seconds" v-model="timestampoffset"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-alert v-if="submitted" type="success">
                        Edit task submitted, it will show up shortly in your <router-link
                            
                            :to="{ name: 'PCAPs', params: { type: 'private', query : ''+newname+'*' }}"
                        ><span>pcap list</span></router-link>

                    </v-alert>    
                </v-col>
                <v-col cols="12">
                <v-btn @click="editcap">Queue editing task</v-btn>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import ApiEditcap from "@/common/api/editcap";

export default {
  props: ["indices", "pcaps"],
  mixins: [commonmethods],
  name: "editcap",
  data() {
    return {
        ranges: null,
        filter: null,
        newname: null,
        submitted: false,
        selectOptions : ["First to last marked", "Marked", "Displayed", "Custom"],
        dosaveselection: false,
        selectedOption: "Marked",
        placeholder: "",
        placeholderdefault: "1 2 10-20 35-50",
        placeholderfilename:"My selected packets",
        slicing: null,
        doslicing: false,
        doedittimestamps: false,
        timestampoffset: null
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic", "error"])

  },
  mounted() {
    this.setselectoption(this.selectedOption)
    this.placeholderfilename = "modified-"+this.currentAnalysis.pcap.origname
  },
  created: function() {

  },
  watch: {
    "selectedOption": function(n, o){
      this.setselectoption(n)
    }
  },
  methods: {
    setselectoption: function(n){
      
      if(n == "First to last marked"){
        let m = this.markedtuple()
        if(!m){
          this.ranges = null,
          this.placeholder = "No packets marked"
        } else {
          this.ranges = m.first+"-"+m.last
        }
      }
      if(n == "Marked"){
        let m = this.marked()
        if(m == ""){
          this.ranges = null
          this.placeholder = "No packets marked"
        } else {
          this.ranges = this.marked()
        }
      }
      if(n == "Displayed"){
        let y = this.currentAnalysis.filter
        if(y !== undefined && y != null && y !=""){
          this.filter = null
          this.placeholder = "No packets displayed"
        } else {
          this.filter = y
        }
        this.filter = this.currentAnalysis.filter
      }
      if(n == "Custom"){
        this.ranges =  null
        this.placeholder = this.placeholderdefault
      }
    },
    markedtuple: function(){
      let cachekey = this.currentAnalysis.pcapid;
  
      if (!(cachekey in this.currentAnalysis.packetlist.marked)){
        return null
      }
  
      let markedpackets = this.currentAnalysis.packetlist.marked[cachekey]
      if(markedpackets.length == 0) {
        return null
      }
      markedpackets.sort((a, b) => a - b);
      
      let tuple = {
        first : markedpackets[0],
        last : markedpackets[markedpackets.length-1]
      }
      return tuple
    },
    marked: function () {

      let cachekey = this.currentAnalysis.pcapid;
  
      if (!(cachekey in this.currentAnalysis.packetlist.marked)){
        return ""
      }
      
      let markedpackets = this.currentAnalysis.packetlist.marked[cachekey]

      markedpackets.sort((a, b) => a - b);

      if (markedpackets.length != 0){
        return markedpackets.join(" ")
      }
      return ""
    },

    editcap(){
        let params = {
            "pcapid" : this.currentAnalysis.pcapid,
            "newname": this.newname,
            "editparams": {
              
            }
        }

        if(this.doslicing){
          params["editparams"]["slicing"] = this.slicing
        }

        if(this.ranges){
          params["editparams"]["ranges"] = this.ranges
        }

  
        if(this.filter){
          params["editparams"]["filter"] = this.filter
        }

        if(this.timestampoffset){
          params["editparams"]["timestampoffset"] = this.timestampoffset
        }

        ApiEditcap.newasrange(params)
        .then(() => {
            this.error.msg = "";
            this.submitted = true;
        })
        .catch(({ response }) => {
            this.error.msg = response["error"];
        });
    }
  }
};
</script>

<style>

</style>