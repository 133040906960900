<template>
  <v-row justify="center">
    <v-card v-if="profile" width="750px">
      <v-card-title class="headline" primary-title>
        <b>{{ profile.name }}</b>
      </v-card-title>

      <v-card-text>
        <v-row class="pb-4">
          <v-col cols="4" class="px-4">
            <v-text-field
              :disabled="disabled"
              v-model="profile.name"
              label="Profile Name"
              hide-details
              maxlength="200"
            ></v-text-field>
          </v-col>
          <v-col cols="8" class="px-4">
            <v-textarea
              :disabled="disabled"
              v-model="profile.description"
              label="Profile Description"
              hide-details
              maxlength="600"
              rows="1"
            />
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-list subheader three-line>
          <v-subheader>General Settings</v-subheader>
          <togglepreference
            :disabled="disabled"
            title="Show decode"
            description="Show decode"
            v-model="profile.showdecode"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Show hex view"
            description="Show hex view"
            v-model="profile.showhexview"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Show rfc view"
            description="Show rfc view"
            v-model="profile.showrfcview"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Close navigation drawer on PCAP open"
            description="Close the navigation drawer when a PCAP is opened"
            v-model="profile.closeNavDrawerOnPcapOpen"
          ></togglepreference>


          <togglepreference
            :disabled="disabled"
            title="Toggle dark theme"
            description="Toggle theme"
            v-model="profile.theme"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Allow tagging while uploading"
            description="Toggle tag upload"
            v-model="profile.massuploadtagging"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Toggle tag cloud on home screen"
            description="Toggle tag cloud"
            v-model="profile.showtagcloud"
          ></togglepreference>


          <togglepreference
            :disabled="disabled"
            title="Dense PCAP list"
            description="Dense PCAP list with less information"
            v-model="profile.simplepcaplistheaders"
          ></togglepreference>

          <preference
            :disabled="disabled"
            title="Recently opened PCAPs list"
            icon="blur_linear"
            width="300px"
            description="Maximum number of recently opened PCAPs in navigation"
            v-model="profile.pcapClientHistory"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.pcapClientHistory"
                :max="20"
                :min="0"
                persistent-hint
                step="1"
                ticks
              ></v-slider>
            </template>
          </preference>

          <preference
            :disabled="disabled"
            title="PCAPs per Page"
            icon="format_list_bulleted"
            width="300px"
            description="Number of pcaps to load per page."
            v-model="profile.pcapsPerPage"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.pcapsPerPage"
                :max="200"
                :min="20"
                persistent-hint
                step="20"
                ticks
              ></v-slider>
            </template>
          </preference>


          <preference
            title="Backend Settings"
            icon="settings"
            width="1200px"
            description="Change Backend configuration"
            v-on:save="saveProfile"
          >
            <template slot="inputslot" slot-scope="">
              <wiresharksettings
                v-model="profile.wiresharksettings"
                :profileid="profileid"
              />
            </template>
          </preference>

          <preference
            title="Profile import/export"
            icon="import_export"
            width="1200px"
            description="Import or export a profile"
          >
            <template slot="inputslot" slot-scope="">
              <profileexport v-model="profile" :profileid="profileid" />
            </template>
          </preference>

          <preference
            :disabled="disabled"
            title="Wireshark Profile Import"
            icon="format_color_fill"
            description="Import a Wireshark zipped profile"
            v-on:save="saveProfile"
          >
            <template slot="inputslot" slot-scope="">
              <zipprofileimport
                :disabled="disabled"
                v-model="profile"
              />
            </template>
          </preference>

        </v-list>

        <v-divider></v-divider>

        <v-list subheader three-line>
          <v-subheader>Appearance</v-subheader>

          <preference
            :disabled="disabled"
            title="Table Font"
            icon="text_fields"
            width="300px"
            description="Sets font for tables in analyzer view"
          >
            <template slot="descriptionSlot" slot-scope="">
              <v-container>
              <v-row>
                <v-col cols="6" class="pt-7 pb-2">Table Font</v-col>
                <v-col cols="6">
                  <v-select
                    solo
                    class="pb-2 pr-2"
                    hide-details
                    v-model="profile.tableFont"
                    :items="getAvailableFonts()"
                  ></v-select>
                </v-col>
              </v-row>
              </v-container>
            </template>
          </preference>
          
          <togglepreference
            :disabled="disabled"
            title="Colorize Packets"
            description="Colorize Packets in Packet List"
            v-model="profile.colorPackets"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Show Mini Map"
            description="The minimap shows the packets' background color in the scrollbar (Chrome only)"
            v-model="profile.minimap"
          ></togglepreference>

          <togglepreference
            v-if="currentAnalysis.features.packetinfo"
            :disabled="disabled"
            title="Show current Packet Information"
            description="Show current Packet Information based on coloring rules"
            v-model="profile.currentPacketInfo"
          ></togglepreference>

          <togglepreference
            v-if="currentAnalysis.features.packetinfo"
            :disabled="disabled"
            title="Highlight decode changes"
            description="Highlight changes when updating decode"
            v-model="profile.highlightDecodeChanges"
          ></togglepreference>

          <fullscreenpreference
            :disabled="disabled"
            title="Coloringrules Configuration"
            icon="format_color_fill"
            description="Add, remove or modify the Coloringrules"
            v-on:save="saveProfile"
          >
            <template slot="preferences" slot-scope="">
              <coloringrules
                :disabled="disabled"
                v-model="profile.coloringrules"
              />
            </template>
          </fullscreenpreference>


          <preference
            v-if="false"
            :disabled="disabled"
            title="Selected Packet Coloring"
            icon="blur_linear"
            description="Color of selected row in packet list"
            v-model="examplecolor"
          >
            <template slot="descriptionSlot" slot-scope="">
              Background
              <v-btn
                :color="profile.selectedbg"
                class="ml-2 mr-2 mb-1"
                :disabled="disabled"
                x-small
                @click="selectColor(profile.selectedbg, 'background')"
              ></v-btn>

              Foreground
              <v-btn
                :color="profile.selectedfg"
                class="ml-2 mr-2 mb-1"
                x-small
                :disabled="disabled"
                @click="selectColor(profile.selectedfg, 'foreground')"
              ></v-btn>
              <v-overlay :value="showcolorpicker" z-index="4">
                <v-card class="pa-4">
                  <v-color-picker
                    class="ma-2"
                    show-swatches
                    v-model="color"
                  ></v-color-picker>
                  <v-btn @click="confirmColor"> Confirm </v-btn>
                </v-card>
              </v-overlay>
            </template>
          </preference>

          <preference
            :disabled="disabled"
            title="Relative Time Digits"
            icon="watch"
            width="300px"
            description="Decimal digits for time display format"
            v-model="profile.relativeTimeDigits"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.relativeTimeDigits"
                :max="0"
                :min="20"
                persistent-hint
                step="1"
                ticks
              ></v-slider>
            </template>
          </preference>

        </v-list>

        <v-divider></v-divider>
        <v-list subheader three-line>
          <v-subheader>Filter History</v-subheader>

          <preference
            :disabled="disabled"
            title="Display Filter History Length"
            icon="blur_linear"
            width="300px"
            description="Maximum number of cached entries in the display filter history"
            v-model="profile.displayfilterHistoryMax"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.displayfilterHistoryMax"
                :max="1000"
                :min="0"
                persistent-hint
                step="10"
                ticks
              ></v-slider>
            </template>
          </preference>

          <preference
            v-if="currentAnalysis.features.filterhistory"
            :disabled="disabled"
            title="Clear display filter history"
            icon="clear"
            width="300px"
            description="Clears the display filter history"
          >
            <template slot="inputslot" slot-scope="">Unimplemented</template>
          </preference>

          <preference
            v-if="currentAnalysis.features.filterhistory"
            :disabled="disabled"
            title="Display Filter History"
            icon="history"
            width="300px"
            description="Show or modify the display filter history"
          >
            <template slot="inputslot" slot-scope="">Unimplemented</template>
          </preference>

          <preference
            v-if="currentAnalysis.features.filterhistory"
            :disabled="disabled"
            title="Display Filter Buttons"
            icon="filter_none"
            width="300px"
            description="Show, add or modify the display buttons"
          >
            <template slot="inputslot" slot-scope="">Unimplemented</template>
          </preference>
        </v-list>

        <v-divider></v-divider>

        <v-list subheader three-line v-if="currentAnalysis.features.timeline">
          <v-subheader>Analysis Settings</v-subheader>

          <togglepreference
            :disabled="disabled"
            title="Switch Panes"
            description="Switch panes from horizonal to vertical mode"
            v-model="profile.switchPanes"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Show Timeline"
            description="Show Timeline in during Analysis"
            v-model="profile.showTimeline"
          ></togglepreference>

        </v-list>

        <v-divider></v-divider>

        <v-list subheader three-line>
          <v-subheader>Packet List</v-subheader>

          <togglepreference
            :disabled="disabled"
            title="Packetlist grouping"
            description="Allow for grouping of packetlist columns"
            v-model="profile.packetlistgrouping"
          ></togglepreference>

          <togglepreference
            :disabled="disabled"
            title="Infinite mode"
            description="Infinite mode only loads a part of the trace initally. Client side mode loads all packets at once, this may take a longer time when you first open the trace but enables client side sorting of packets."
            v-model="profile.infinitepacketlist"
          ></togglepreference>

          <preference
            v-if="!profile.infinitepacketlist"
            :disabled="disabled"
            title="Maximum packets in client side mode"
            icon="blur_linear"
            width="300px"
            description="Maximum number of packets fetched and displayed in the packet list. If you need more than 100000 packets to be displayed switch to infinite mode."
            v-model="profile.maxPackets"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.maxPackets"
                :max="100000"
                :min="100"
                persistent-hint
                step="1000"
                ticks
              ></v-slider>
            </template>
          </preference>

          <togglepreference
            :disabled="disabled"
            title="Skip counting matching packets"
            description="Enhances load times with filters, but only gets up to 'Maximum packets in client side mode' without knowing how many actually matched in the backend."
            v-model="profile.skipmatchcount"
          ></togglepreference>

          <preference
            v-if="profile.infinitepacketlist"
            :disabled="disabled"
            title="Cache Block Size in infinite mode"
            icon="cached"
            width="300px"
            description="Number of packets loaded per cache block. Helps smoothen fast scrolling. But may cause high initial loading time."
            v-model="profile.cacheBlockSize"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.cacheBlockSize"
                :max="10000"
                :min="100"
                persistent-hint
                step="250"
                ticks
              ></v-slider>
            </template>
          </preference>


          <preference
            :disabled="disabled"
            title="Row Buffer"
            icon="cached"
            width="300px"
            description="Maximum number of packets pre-rendered in the packet list. Helps smoothen fast scrolling. But may cause high initial loading time."
            v-model="profile.rowBuffer"
          >
            <template slot="inputslot" slot-scope="">
              <v-slider
                :disabled="disabled"
                v-model="profile.rowBuffer"
                :max="100"
                :min="10"
                persistent-hint
                step="10"
                ticks
              ></v-slider>
            </template>
          </preference>
        </v-list>

        <v-list subheader three-line>
          <v-subheader>Column configuration</v-subheader>

          <togglepreference
            :disabled="disabled"
            title="Advanced Rendering"
            description="Enable advanced rendering of cells (may cause slow packet list)"
            v-model="profile.enableCustomCellRenders"
          ></togglepreference>

          <fullscreenpreference
            :disabled="disabled"
            title="Column Configuration"
            icon="view_column"
            description="Change the Packet List columnconfiguration"
            v-on:save="saveProfile"
          >
            <template slot="preferences" slot-scope="">
              <columnconfiguration
                :disabled="disabled"
                v-model="profile.columns"
              />
            </template>
          </fullscreenpreference>
        </v-list>

        <v-divider></v-divider>
        <v-list
          subheader
          three-line
          v-if="currentAnalysis.features.indexerconfiguration"
        >
          <v-subheader>Indexer</v-subheader>

          <preference
            :disabled="disabled"
            title="Prefered Indexer"
            icon="blur_linear"
            width="300px"
            description="Change the prefered indexer for this profile"
            v-model="profile.preferedIndexer"
          >
            <template slot="inputslot" slot-scope="">
              <v-select
                :disabled="disabled"
                v-model="profile.preferedIndexer"
                :items="currentAnalysis.indexerprofiles.available"
                item-text="name"
                item-value="id"
              ></v-select>
            </template>
          </preference>
        </v-list>


      </v-card-text>

      <v-card-actions>
        <v-btn @click="saveProfile" :disabled="disabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Coloringrules from "./Coloringrules";
import Columnconfiguration from "./Columnconfiguration";
import Wiresharksettings from "@/components/settings/Wiresharksettings";
import Profileexport from "@/components/settings/Profileexport";
import Preference from "./Preference";
import Togglepreference from "./Togglepreference";
import Fullscreenpreference from "./Fullscreenpreference";
import { displayfilter } from "@/common/displayfilter";
import { commonmethods } from "@/common/common";
import ApiProfile from "@/common/api/profile";
import { permissionchecks } from "@/common/permissions";
import Zipprofileimport from './Zipprofileimport.vue';

export default {
  components: {
    Preference,
    Zipprofileimport,
    Coloringrules,
    Columnconfiguration,
    Wiresharksettings,
    Profileexport,
    Togglepreference,
    Fullscreenpreference,
  },
  mixins: [permissionchecks, displayfilter, commonmethods],
  props: {
    profileid: {
      type: String,
      default: "None",
    },
  },
  name: "analysisprofile",
  data() {
    return {
      profile: null,
      color: "#ffffff",
      currentcolorlayer: "foreground",
      showcolorpicker: false,
    };
  },
  watch: {
    "profile.theme": function(n) {

      if(this.currentAnalysis.profiles.selected.id == this.profile.id){
        this.$vuetify.theme.dark = n
      }
    }
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),

    examplecolor: {
      get() {
        return this.profile.selectedfg + "/" + this.profile.selectedbg;
      },
      set() {
        return this.profile.selectedfg + "/" + this.profile.selectedbg;
      },
    },
    disabled: function () {
      if (
        !("permissions" in this.profile) ||
        this.isOwner(this.currentUser, this.profile.permissions)
      ) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.loadprofile();
  },
  beforeDestroy() {},
  methods: {
    loadprofile: function () {
      ApiProfile.get(this.profileid)
        .then(({ data }) => {
          if (!("wiresharksettings" in data)) {
            this.$set(data, "wiresharksettings", []);
          }
          this.profile = data;
        })
        .catch(() => {});
    },
    selectColor: function (c, where) {
      this.currentcolorlayer = where;
      if (where == "foreground") {
        this.color = c;
      } else if (where == "background") {
        this.color = c;
      }
      this.showcolorpicker = !this.showcolorpicker;
    },
    confirmColor: function () {
      this.showcolorpicker = false;
      if (this.currentcolorlayer == "foreground") {
        this.profile.selectedfg = this.color;
      } else if (this.currentcolorlayer == "background") {
        this.profile.selectedbg = this.color;
      }
    },
    loadProfileIntoComponent: function () {},
    updateProfileModel: function(profile){

      for (let i = 0; i < this.currentAnalysis.profiles.available.length; i++) {
        if(this.currentAnalysis.profiles.available[i].id == profile.id){
          this.currentAnalysis.profiles.available[i] = profile
        }
      }
      if(this.currentAnalysis.profiles.selected.id == profile.id){
        this.currentAnalysis.profiles.selected = profile
      }
    },
    saveProfile: function () {
      // some cleanups

      for (let c of this.profile.columns) {
        c.field = this.df_wireshark_field_to_es(c.field);
      }

      ApiProfile.update(this.profile)
        .then(() => {
          this.updateProfileModel(this.profile)
          //this.selectProfile(this.profile.id);
          this.error.type = "Info";
          this.error.msg = "Saved the profile";
          //this.currentAnalysis.profileneedsupdate = true
        })
        .catch((error) => {
          this.error.msg = error.response.data.error;
        });
    },
    selectProfile: function (profileid) {
      ApiProfile.get(profileid)
        .then(({ data }) => {
          this.currentAnalysis.profiles.selected = data;
        })
        .catch((error) => {
          this.error.msg = error.response.data.error;
        });
    },
    copyProfile: function (profile) {
      ApiProfile.copyprofile(profile)
        .then(({ data }) => {
          this.error.type = "Info";
          this.error.msg = "New profile id " + data["id"];
          this.currentAnalysis.profileneedsupdate = true
        })
        .catch((error) => {
          this.error.msg = error.response.data.error;
        });
    },
  },
};
</script>

<style>
</style>
