<template>
  <v-row justify="center" class="mt-6">
    <v-card v-if="profile" width="500px">
      <v-dialog v-model="dialogpl" width="900">
        <v-card>
          <v-card-title class="headline" primary-title>List of Analysis Profiles</v-card-title>
          <v-card-text>
            <profilelist></profilelist>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="currentAnalysis.indexerdialog" width="900">
        <v-card>
          <v-card-title class="headline" primary-title>Indexer configuration</v-card-title>
          <v-card-text>
            <indexer></indexer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogni" width="900">
        <v-card>
          <v-card-title class="headline" primary-title>Create a new indexer</v-card-title>
          <v-card-text>
            <indexerprofilenew
              :key="dialognikey"
              @close="dialogni = !dialogni ; dialognikey += 1;"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogipl" width="900">
        <v-card>
          <v-card-title class="headline" primary-title>List of Indexer Profiles</v-card-title>
          <v-card-text>
            <indexerprofilelist v-on:newindexer="dialogni = !dialogni"></indexerprofilelist>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-list subheader three-line>
          <v-subheader>Profiles</v-subheader>

          <v-list-item @click="gotoCurrent" v-if="currentUser">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="gotoCurrent">
              <v-list-item-title>Current analysis profile</v-list-item-title>
              <v-list-item-subtitle>Modify the coloring rules, layout and other settings</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>list</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="analysisprofilesList">
              <v-list-item-title>Analysis Profiles</v-list-item-title>
              <v-list-item-subtitle>Select and modify different analysis profiles</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop v-if="currentAnalysis.features.indexerconfiguration">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>list</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="dialogipl =! dialogipl"  v-if="currentUser">
              <v-list-item-title>Indexer Profiles</v-list-item-title>
              <v-list-item-subtitle>Delete or modify indexer profiles</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="pb-3" v-if="currentUser"></v-divider>

        <v-list subheader three-line v-if="currentUser"">
          <v-subheader>Settings</v-subheader>

          <v-list-item @click="userprofile" v-if="currentUser">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>style</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="userprofile">
              <v-list-item-title>User Profile</v-list-item-title>
              <v-list-item-subtitle>Show user profile</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="tagList" v-if="currentUser">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>style</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="tagList">
              <v-list-item-title>Tags</v-list-item-title>
              <v-list-item-subtitle>Add, delete and modify tags</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="roleList"  v-if="currentUser">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>supervised_user_circle</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="roleList">
              <v-list-item-title>Roles</v-list-item-title>
              <v-list-item-subtitle>Add, delete and modify roles</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="userList"  v-if="isAdmin(currentUser)">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>people</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="userList">
              <v-list-item-title>Users</v-list-item-title>
              <v-list-item-subtitle>Users</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>


          <v-list-item @click="taskList"  v-if="currentUser">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>adjust</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="taskList">
              <v-list-item-title>Tasks</v-list-item-title>
              <v-list-item-subtitle>Review Tasks</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="caseList"  v-if="isAdmin(currentUser)">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>help</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="caseList">
              <v-list-item-title>Cases</v-list-item-title>
              <v-list-item-subtitle>Review Cases</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="appInfo" v-if="isAdmin(currentUser)">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>info</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-content @click.prevent="appInfo"  >
              <v-list-item-title>Application Info</v-list-item-title>
              <v-list-item-subtitle>Application Information</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!--<div>With profiles you can define applications settings and switch between them quickly. You can select a the current profile by clicking on the checkbox. You can edit a profile by clicking on the name.</div>-->
        </v-list>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Indexer from "./Indexer";
import Indexerprofilenew from "./Indexerprofilenew";
import Indexerprofilelist from "./Indexerprofilelist";
import Profilelist from "./Profilelist";
import ApiIndexerprofile from "@/common/api/indexerprofile";
import ApiProfile from "@/common/api/profile";
import { permissionchecks } from "@/common/permissions";

export default {
  components: {
    Profilelist,
    Indexer,
    Indexerprofilenew,
    Indexerprofilelist
  },
  mixins: [
    permissionchecks
  ],
  props: {},
  name: "settings",
  data() {
    return {
      dialogpl: false,
      dialogni: false,
      dialogipl: false,
      dialognikey: 0,
      colorexpand: [false],
      profile: null
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error", "indexerprofiles"])
  },
  watch: {
    currentAnalysis: {
      handler: function(newAnalysis) {
        this.profile = this.currentAnalysis.profiles.selected;
      },
      deep: true
    }
  },
  mounted() {
    if(this.currentUser){
      this.loadIndexerProfiles();

    }
  },
  created() {},
  beforeDestroy() {},
  methods: {
    roleList() {
      this.$router.push({ name: "Roles" });
    },
    userprofile() {
      this.$router.push({ name: "User Profile", params: { 'username': this.currentUser.username }}).catch(err => {}).catch(err => {});
    },
    tagList() {
      this.$router.push({ name: "Tags" }).catch(err => {}).catch(err => {});
    },
    taskList() {
      this.$router.push({ name: "Tasks" }).catch(err => {}).catch(err => {});
    },
    caseList() {
      this.$router.push({ name: "Cases" }).catch(err => {}).catch(err => {});
    },
    appInfo() {
      this.$router.push({ name: "Appinfo" }).catch(err => {}).catch(err => {});
    },
    userList() {
      this.$router.push({ name: "Users" }).catch(err => {}).catch(err => {});
    },
    analysisprofilesList() {
      this.$router.push({ name: "Analysis Profiles" }).catch(err => {}).catch(err => {});
    },
    loadIndexerProfiles() {
      ApiIndexerprofile.list()
        .then(({ data }) => {
          this.currentAnalysis.indexerprofiles.available = [];
          this.currentAnalysis.indexerprofiles.available.push(...data);
        })
        .catch(({ response }) => {});
    },
    gotoCurrent: function() {
      this.$router.push({
        name: "Analysis Profile",
        params: { profileid: this.currentAnalysis.profiles.selected.id }
      }).catch(err => {});
    },
    saveProfile: function() {
      ApiProfile.update(this.profile)
        .then(({ data }) => {
          this.selectProfile(this.profile.id);
          this.currentAnalysis.profileneedsupdate = true
          this.error.type = "Info";
          this.error.msg = "Saved the profile";
        })
        .catch(({ response }) => {

          this.error.msg = "Error saving the profile";
        });
    },
    selectProfile: function(profileid) {
      ApiProfile.get(profileid)
        .then(({ data }) => {
          this.currentAnalysis.profiles.selected = data;
        })
        .catch(({ response }) => {});
    },
    copyProfile: function(profile) {
      ApiProfile.copyprofile(profile)
        .then(({ data }) => {
          this.error.type = "Info";
          this.error.msg = "New profile id " + data["id"];
          this.currentAnalysis.profileneedsupdate = true
        })
        .catch(({ response }) => {});
    }
  }
};
</script>

<style>
</style>
