<template>
 <v-progress-linear
    :value="val"
    :color="color"

    height="25">
       <template v-slot:default="{ value }">
        {{ params.value }}
      </template>
  </v-progress-linear>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentAnalysis"]),
  },
    data() {
        return {
            val: null,
            color: "white"
        }   
    },
  mounted() {
    this.val = this.relativeToMax(this.params.value)
  },
  methods: {
      relativeToMax(v){
          if("field" in this.params.colDef){
            let colfield = this.params.colDef.field

            if(colfield in this.currentAnalysis.rendering.fields){
                let f = this.currentAnalysis.rendering.fields[colfield]["extended"]["max"]
                
                let out = Math.round((v / f) * 100)
                if(out <33) {
                    this.color = "green"
                } else if(out >= 33 && out <= 66){
                    this.color = "amber"
                } else {
                    this.color = "red"
                }
                return out;
            }
          }

          return v
      }
  }
};
</script>

<style scoped>
.cell {
    color: blue;
}
</style>