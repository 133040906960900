<template>
  <div class="">
    <v-row justify="center" class="mt-4 pb-4 mb-2 grid-list-md">
      <v-col class="d-flex flex-column justify-space-between align-center text-center " cols="12">
        <v-badge bordered color="green" content="Beta" overlap>
          <v-img max-width="250" :src="logo"
            :style="currentAnalysis.profiles.selected.theme ? 'filter: invert(1);' : ''" />
        </v-badge>

        <p class="grey--text">
          Analyze packet traces and share them with the networking community.
          <Needfunding/>
        </p>
      </v-col>

      <!--<v-col class="text-center" cols="12">
        <h3>Latest Packets</h3>
      </v-col>-->

      <v-col cols="0" sm="1" md="2" lg="3"></v-col>
      <v-col cols="12" sm="10" md="8" lg="6" class="mt-0">
        <pcaplist type="search" :noquery="false" :simple="true"></pcaplist>
      </v-col>
      <v-col cols="0" sm="1" md="2" lg="3"></v-col>

      <template v-if="currentUser && currentAnalysis.profiles.selected.showtagcloud">
      <v-col cols="2"></v-col>
      <v-col cols="8" class="mt-5">
        <tagcloud ></tagcloud>
      </v-col>
      <v-col cols="2"></v-col>
    </template>

      <v-col cols="3"></v-col>
      <v-col cols="6" class="mt-3" v-if="
        (!currentUser && currentAnalysis.features.enableanonupload) ||
        (currentUser && currentAnalysis.features.enableupload)
      ">
        <upload></upload>

      </v-col>

      <v-col cols="3"></v-col>
    </v-row>

    <v-row v-if="false">
      <v-col lg="2" md="1" sm="0"></v-col>
      <v-col lg="8" md="10" sm="12" class="mt-3">
        <searcherror :error="false"></searcherror>
      </v-col>
      <v-col lg="2" md="1" sm="0"></v-col>
    </v-row>

    <!--<v-card >

     <v-tabs fixed-tabs>
        <v-tab :key="upload">Upload PCAP</v-tab>
        <v-tab :key="recent">Recent PCAPs</v-tab>
        <v-tab-item :key="upload">
          <v-card>
            <v-card-text>
              <upload></upload>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="recent">
          <v-card>
            <v-card-text>
              <v-layout align-center justify-center>
                <pcaplist type="recent"></pcaplist>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>-->
  </div>
</template>

<script>
import Upload from "./Upload";
import Tagcloud from "./Tagcloud";
import Pcaplist from "./Pcaplist";
import { mapGetters } from "vuex";
import Searcherror from "./dialogs/Searcherror";

import { PACKETSAFARI_LOGO } from "@/common/config";
import Needfunding from "./Needfunding.vue";

export default {
  name: "Welcome",
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser"])
  },
  components: {
    Searcherror,
    Tagcloud,
    Upload,
    Pcaplist,
    Needfunding
},
  mounted() {

  },
  data() {
    return {
      logo: PACKETSAFARI_LOGO,
      msg: "Welcome to packetwave",
      upload: 1,
      recent: 2
    };
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.v-card {
  min-height: 300px;
  height: 100%;
}
</style>
