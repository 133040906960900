<template>

  <v-dialog
    v-if="currentAnalysis.pcaplist.massindex"
    v-model="currentAnalysis.pcaplist.massindex"
    max-width="500px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-row  >
          <v-col cols="12">
            <h3>Create index</h3>
            <br>
          </v-col>
          <v-col cols="12">
          </v-col>
          <v-col cols="12">
            <v-row  >
                <v-col cols="12">
                    <template v-if="currentUser">
                        <v-select
                            :items="availableindexers"
                            v-model="selectedindexer"
                            item-text="name"
                            item-value="id"
                            return-object
                            label="Select saved indexer..."
                        ></v-select>

                
                    </template>
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="force" label="Force reindexing"></v-switch>
                </v-col>
                <v-col cols="12">
                    <v-alert v-if="submitted" type="success">
                        Index task submitted, it will show up shortly in your <!--<router-link
                            
                            :to="{ name: 'PCAPs', params: { type: 'private' }}"
                        ><span>pcap list</span></router-link>-->

                    </v-alert> 
                </v-col>
                <v-col cols="12">
                <v-btn @click="createindices">Create new indices</v-btn>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiIndexerprofile from "@/common/api/indexerprofile";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import ApiPcapindex from "@/common/api/pcapindex";

export default {
  props: ["indices", "pcaps"],
  mixins: [commonmethods],
  name: "massindex",
  data() {
    return {
      status: null,
      submitted: false,
      force: false,
      objectids: [],
      availableindexers: [],
      selectedindexer: null,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic", "error"])
  },
  mounted() {
    for (let p of this.pcaps) {
      this.objectids.push(p.id);
    }
  },
  created: function() {
    if (this.currentUser) {
      this.loadindexers();
    }
  },
  watch: {
    pcaps(n) {
      this.status = null
      this.submitted = false
      this.objectids = []
      if (n) {
        for (let p of n) {
          this.objectids.push(p.id);
        }
      }
    },
  },
  methods: {
    createindices() {
      
        for(let p of this.objectids){
            ApiPcapindex.create(p, this.selectedindexer.id, this.force)
            .then(() => {
                this.error.msg = "";
                this.submitted = true
                this.status = null
            })
            .catch(({ response }) => {

                this.error.msg = "Index already exists "+response.data.status;
            });
        }
    },
    loadindexers() {
      this.availableindexers = [];
      ApiIndexerprofile.list()
        .then(({ data }) => {
          this.availableindexers.push(...data);
          this.selectedindexer = this.availableindexers[0]
        })
        .catch(({ response }) => {
          this.error.msg = response["error"];
          this.pcaps = [];
        });
    },
  }
};
</script>

<style>
</style>