<template>
    <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{content}}</v-list-item-title>
        </v-list-item-content>
     </v-list-item>
</template>

<script>

export default {
  props: {
    content: null
  },
  inheritAttrs: false,
  components: {},
  name: "simplelistitem",
  mixins: [],
  methods : {
  }
};
</script>

<style >
</style>