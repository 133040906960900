import { DEFAULT_FONTSIZE, DEFAULT_TABLEFONT, AVATAR_PATH, DEFAULT_AVATARS, TAG_AVATAR_GENERIC, AVATAR_GENERIC, ROLE_AVATAR_GENERIC } from "@/common/config";

export const commonmethods = {
    methods: {
        humanFileSize(bytes, si) {
            var thresh = si ? 1000 : 1024;
            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }
            var units = si
                ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            var u = -1;
            do {
                bytes /= thresh;
                ++u;
            } while (Math.abs(bytes) >= thresh && u < units.length - 1);
            return bytes.toFixed(1) + ' ' + units[u];
        },
        getFontSize() {
            return DEFAULT_FONTSIZE
        },
        getFont() {
            if (this.currentAnalysis.profiles.selected.tableFont) {
                let f = this.currentAnalysis.profiles.selected.tableFont
                if (this.getAvailableFonts().includes(f)) {
                    return this.currentAnalysis.profiles.selected.tableFont
                }
            } else {
                return DEFAULT_TABLEFONT
            }
            return DEFAULT_TABLEFONT
        },
        getAvailableFonts() {
            return ["Arial", "Monospace", "Consolas", "Verdana", "Helvetica", "Georgia", "Garamond", "Courier New", "Bookman", "Impact"]
        },
        applyStyle(styleDef) {
            let style = document.createElement("style");
            style.type = "text/css";
            document.head.appendChild(style);
            style.innerHTML = styleDef;
        },
        generateStyleDef(id, foreground, background, alsoselected = false) {

            if (!(this.isvaluergb(foreground) || this.isvaluergb(background))) {
                console.log("Invalid RGB values")
                return
            }

            id = this.sanitizecolorrulename(id)

            if (background != null && foreground != null) {
                if (alsoselected) {
                    return `
                    .${id} {
                        background-color: ${background} !important;
                        color: ${foreground} !important;
                    }
                    `;
                } else {
                    return `
                    .${id}:not(.ag-row-selected) {
                        background-color: ${background} !important;
                        color: ${foreground} !important;
                    }
                    `;
                }
            }

            if (background != null) {
                if (alsoselected) {
                    return `
                    .${id} {
                        color: black !important;
                        background-color: ${background} !important;
                    }
                    `;
                } else {
                    return `
                    .${id}:not(.ag-row-selected) {
                        color: black !important;
                        background-color: ${background} !important;
                    }
                    `;
                }

            }

            if (foreground != null) {
                return `
                .${id}:not(.ag-row-selected) {
                    color: ${foreground} !important;
                    background-color: white !important;
                }
                `;
            }
        },
        generateSelectedDef(id, foreground, background) {

            if (!(this.isvaluergb(foreground) || this.isvaluergb(background))) {
                console.log("Invalid RGB values")
                return
            }

            id = this.sanitizecolorrulename(id)

            if (background != null && foreground != null) {
                return `
                .${id}:.ag-row-selected {
                    background-color: ${background} !important;
                    color: ${foreground} !important;
                }
                `;
            }
        },

        ishex: function (h) {
            var a = parseInt(h, 16);
            var s = a.toString(16)
            return (s === h)
        },
        sanitizecolorrulename: function (name) {
            name = name.replace(/[^a-zA-Z0-9\- ]/g, "_");
            return name;
        },
        isvaluergb: function (value) {

            if (value === undefined || value.length != 7) {
                return false
            }

            value = value.toLowerCase()

            if (value[0] != "#") {
                return false
            }
            for (const char of value) {
                if (!this.ishex(char) && char != "#") {
                    return false
                }
            }
            return true
        },
        truncatestring: function (text, length, suffix) {
            if (text.length > 15) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        usedefaultpic: function (pic) {

            if (pic != null && pic != "" && !DEFAULT_AVATARS.includes(pic)) {
                return false;
            }
            return true;
        },
        avatarpic4user: function (item) {
            if (item != null && !this.usedefaultpic(item.profilepic)) {
                return AVATAR_PATH + item.profilepic;
            }
            return AVATAR_GENERIC;
        },
        avatarpic4role: function (item) {
            if (!this.usedefaultpic(item.rolepic)) {
                return AVATAR_PATH + item.rolepic;
            }
            return ROLE_AVATAR_GENERIC;
        },
        avatarpic4tag: function (item) {
            if (!this.usedefaultpic(item.tagpic)) {
                return AVATAR_PATH + item.tagpic;
            }
            return TAG_AVATAR_GENERIC;
        },
    }
}