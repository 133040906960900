import ApiService from "@/common/api.service";

const ApiTag = {

    get(params){
        return ApiService.get("ajax/tag", params.type, params.tagid)
    },
    update(tag){
        return ApiService.put("ajax/tag", tag)
    },
    create(tag){
        return ApiService.post("ajax/tag", tag)
    },
    search(params){
      return ApiService.get("ajax/tagsearch", params.type+"/"+params.term);
    },
    delete(tagid){   
        return ApiService.delete("ajax/tag", tagid)
    }
}

export default ApiTag