<template>
<a :href=prefix+doc target="_blank">
  {{ text }}
</a>
</template>


<script>
import { DOCUMENTATION_URL } from "@/common/config";

export default {
  props: {
    doc: null,
    text: null
  },
  data() {
    return {
        prefix: DOCUMENTATION_URL
    };
  }
};
</script>

<style >
</style>