import ApiService from "@/common/api.service";

// const LRU = require("lru-cache");
// export const profilecache = new LRU({ max: 5, maxAge: 10000 });

const ApiProfile = {

    get(profileid){
        return ApiService.query("ajax/profile/"+profileid)
    },
    getcurrentsharkdconfig(profileid){
        return ApiService.query("sharkd/dumpconf/"+profileid)
    },
    setconf(profileid, key, value){
        return ApiService.put("ajax/profile/"+profileid, profile)
    },
    list(query){
        return ApiService.post("ajax/profiles", query)
        //killa some how packetview
        //return ApiService.query("ajax/profiles/"+type, {cache: profilecache})
    },
    // create(profile){
    //     return ApiService.post("ajax/profile", profile)
    // },
    update(profile){
        // profilecache.reset();
        let toput = JSON.parse(JSON.stringify(profile));
        if(toput.columns){
            for(let c of toput.columns){
                delete c["cellStyle"]
            }
        }
        
        return ApiService.put("ajax/profile/"+profile.id, toput)
    },
    delete(id){
        // profilecache.reset();
        return ApiService.delete("ajax/profile/" + id)
    },
    copyprofile(id){
        // profilecache.reset();
        return ApiService.post("ajax/profilecopy", {id : id})
    }
}

export default ApiProfile