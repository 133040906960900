import ApiService from "@/common/api.service";

const ApiPcapindex = {

    delete(pcapid, indexid){
        return ApiService.delete("ajax/pcapindex/" + pcapid + "/" + indexid)
    },

    create(pcapid, indexerid, force=false){
        return ApiService.post("ajax/pcapindex", {pcapid : pcapid, indexerid : indexerid, force: force})
    }
}

export default ApiPcapindex