import { sharkdhelper } from "@/common/sharkdhelper"

export const statstap = {
    mixins: [sharkdhelper],
    data() {
        return {

            headernames : {
                "name" : { headerName: "Type", field: "type", rowGroup: true, hide: true },
                "count": { headerName: "Count", field: "count", aggFunc: 'sum' },
                "rate" : { headerName: "Rate", field: "rate" },
                "perc" : { headerName: "Percentage", field: "perc" },
                "burstrate" : { headerName: "Burstrate", field: "burstrate", aggFunc: 'min' },
                "bursttime" : { headerName: "Bursttime", field: "bursttime", aggFunc: 'max' },
            },
            aggridhandler: {
                "stats": {
                    name: "Statistics",
                    getColumnDefs: this.getColumnDefsStats,
                    getContextMenuItems: this.getContextMenuItemsStats,
                    formatData: this.formatDataStats,
                    isTreeData: true,
                    getDataPath: this.getDataPathStats
                }
            }
        }
    },
    methods: {
        getDataPathStats(data){
            
            return data.path.split('§');
        },
        getHeaderName(field){
            if (field in this.headernames)
                return this.headernames[field]
            return undefined
        },
        getColumnDefsStats(stats, groupTable=false) {
            var h = []
            
            let first = stats.stats[0]

            for (const entry in first) {
                let n = this.getHeaderName(entry)

                if(n !== undefined){
                    h.push(n)
                }

                if("sub" == entry){
                    for(const subval in first.sub){
                        let n = this.getHeaderName(subval)

                        if(n !== undefined){
                            h.push(n)
                        }
                    }
                }

            }
            return h;
        },
        getContextMenuItemsStats: function (params) {
            
            var currentitems = params.defaultItems.slice(0);
            var result = [
                {
                    name: "Filter Conversation",
                    action: function () {

                        var o = params.node.data["filter"]
                        this.df_apply_to_packetlist(o)
                        this.currentAnalysis.showTapstatistics = false
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                },
            ];

            for (var item of result) {
                currentitems.unshift(item);
            }

            return currentitems;
        },
        flattenhierarchy(global, entry, lastname=""){
            let out = {}           
            for(const e in entry){
                if(e in this.headernames){
                    out[e] = entry[e]
                }
            }

            if (lastname!="")
                out["path"] = lastname+"§"+entry["name"]
            else
                out["path"] = entry["name"]

            global.push(out)
            if(!("sub" in entry))
                return
            for(let sub of entry.sub){
                this.flattenhierarchy(global, sub, out["path"]);
            }
        },
        formatDataStats(stats) {
            let indata = [];
            for (const entry of stats.stats) {
                this.flattenhierarchy(indata, entry)              
            }

            return indata;
        }
    }
}