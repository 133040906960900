<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="currentAnalysis.showIOGraph"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="grey darken-3">
          <v-btn icon dark @click="currentAnalysis.showIOGraph = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>IO Graph</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="currentAnalysis.showIOGraph = false">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <iograph></iograph>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Iograph from "./Iograph";

import { mapGetters } from "vuex";

export default {
  components: {
    Iograph
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    start: {
      type: String,
      default: ""
    },
    end: {
      type: String,
      default: ""
    }
  },
  name: "iographdialog",
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style>
</style>
