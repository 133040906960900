<template>
    <v-row   align="center" justify="center">
  
      <v-card class="pb-2" max-width="900px">
        <v-card-title class="headline" primary-title>Cases</v-card-title>
  
          <v-card-text class="py-2">Current Cases
          <v-icon
            color="info"
            small
            v-on:click="getCases()"
          >refresh</v-icon>
        </v-card-text>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="cases" >
            <template slot="item" slot-scope="props">
              <tr>
                <td>
                  {{props.item["pcapid"]}}
                </td>
                <td>{{props.item['description']}}</td>
                <td>{{props.item['email']}}</td>
                <td>{{props.item["wanthelp"]}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-card>
    </v-row>
  </template>
  
  <script>
  import ApiCase from "@/common/api/case";
  import { commonmethods } from "@/common/common";
  import { mapGetters } from "vuex";
  import { permissionchecks } from "@/common/permissions";
  
  export default {
    name: "tasklist",
    data() {
      return {
        cases: [],
        adddialog: false,
        headers: [
          {
            text: "PCAP ID",
            align: "left",
            value: "pcapid",
            sortable: false
          },
          { text: "Description", value: "description", sortable: false },
          { text: "Email", value: "email", sortable: false },
          { text: "Want help", value: "wanthelp", sortable: false },
        ]
      };
    },
    created: function() {},
    mounted() {
      this.getCases();
    },
    mixins: [permissionchecks, commonmethods],
    computed: {
      ...mapGetters(["currentAnalysis", "currentUser", "error"])
    },
    watch: {},
    methods: {
        getCases: function() {
        ApiCase.list(this.type)
          .then(({ data }) => {
            this.cases = data;
          })
          .catch(({ response }) => {});
      }
    }
  };
  </script>
  
  <style >
  </style>
  