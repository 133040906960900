<template>
  <div class="timelinechart" ref="chartdiv"></div>
</template>

<script>
import moment from "moment-timezone";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_animated);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { navigation } from "@/common/navigation";

export default {
  name: "Timelinechart",
  props: {
    pcapid: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    },
    connections: null,
    ctype: null
  },

  components: {

  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      c: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
    };
  },
  watch: {
    "connections": function(n, o){
        this.c = this.connections
        this.apply()
    }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.c = this.connections
    this.iindex = this.index;
    this.ipcapid = this.pcapid
    this.apply();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {

    apply(){

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        var chart = am4core.create("timelinechart", am4charts.XYChart);

        var data = [];
        var open = 100;
        var close = 120;

        for(let o of this.c){
            
            try{
                let cat = ""
                switch(this.ctype){
                  case "tcp":
                    cat = o.src.ip+":"+o.src.port+" -> "+o.dst.ip+":"+o.dst.port
                    break;
                  case "udp":
                    cat = o.src.ip+":"+o.src.port+" -> "+o.dst.ip+":"+o.dst.port
                    break
                  case "ipv4":
                    cat = o.src.ip+" -> "+o.dst.ip
                    break
                  case "ipv6":
                    cat = o.src.ip+" -> "+o.dst.ip
                    break
                  case "eth":
                    cat = o.src.eth+" -> "+o.dst.eth
                    break
                }

                if(cat.includes("Non TCP")){
                    continue
                }
                data.push(
                    {
                        category: cat,
                        open: o.src.firstpacket_time, 
                        close: o.src.lastpacket_time,
                        bytes: o.total.cummulative_bytes,
                        flags: o.flags,
                        object: o
                    }
                );
            } finally{}

        }

        chart.data = data;

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.axisFills.template.disabled = true;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 15;
        categoryAxis.renderer.inversed = true;
        //categoryAxis.renderer.inside = true;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.autoGridCount = false,
        categoryAxis.renderer.grid.template.location = 0.5;
        categoryAxis.renderer.grid.template.strokeDasharray = "1,3";

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.axisFills.template.disabled = true;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "category";
        series.dataFields.openValueX = "open";
        series.dataFields.openValueY = "bytes"
        series.dataFields.valueX = "close";
        series.tooltipText = "{openValueX.value} to {valueX.value} / {openValueY.value} bytes / {flags}";
        series.sequencedInterpolation = true;
        series.fillOpacity = 0;
        series.strokeOpacity = 1;
        series.columns.template.height = 0.01;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#eeeee");

        series.heatRules.push({
            "target": series.columns.template,
            "property": "strokeWidth",
            "min": 5,
            "max": 8,
            "dataField": "valueX",
            "logarithmic": true
        });

        
        series.heatRules.push({
          target: series.columns.template,
          property: "stroke",
          "dataField": "openValueY",
          min: am4core.color("#eeeeee"),
          max: am4core.color("#602155"),
          "logarithmic": true
        });


        var openBullet = series.bullets.create(am4charts.CircleBullet);
        openBullet.locationX = 1;
        openBullet.fill = am4core.color("#000");
        openBullet.circle.radius = 8;
        openBullet.stroke = openBullet.fill;
        openBullet.fillOpacity = 0.6;
        openBullet.dx = -10


        var closeBullet = series.bullets.create(am4charts.CircleBullet);
        closeBullet.circle.radius = 8;
        closeBullet.fill = am4core.color("blue");
        closeBullet.stroke = closeBullet.fill;
        closeBullet.fillOpacity = 0.6;
        closeBullet.dx = 10

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";

        let syncolor = am4core.color("#4caf50")
        let nosyncolor = am4core.color("grey")
        let resetcolor = am4core.color("#f44336")
        let ecncolor = am4core.color("yellow")
        let fincolor = am4core.color("blue")
        let defaultcolor = am4core.color("white")
        let defaultstroke = am4core.color("black")

        series.columns.template.events.on("hit", function(ev) {
          let obj = ev.target.dataItem.dataContext.object
          let filter = this.df_conv_filter(obj.type, obj.src.ip, obj.src.port, obj.dst.ip, obj.dst.port);
          this.df_apply_to_packetlist(filter)
          this.currentAnalysis.showConnections = false;
          this.currentAnalysis.showIOGraph = false;
          this.navigatePacketview(this.currentAnalysis.pcapid, this.currentAnalysis.index, filter);
        }, this);

        if(this.ctype == "tcp"){
          openBullet.adapter.add("fill", function(disabled, target) {
            
            let flags = target.dataItem.dataContext.flags
            if(flags.includes("S")){
              return syncolor
            } //SYN
            return nosyncolor
          });

          openBullet.adapter.add("stroke", function(disabled, target) {
            
            let flags = target.dataItem.dataContext.flags
            if(flags.includes("E")){
              return ecncolor
            }
            return defaultstroke
          });

          closeBullet.adapter.add("fill", function(disabled, target) {
            
            let flags = target.dataItem.dataContext.flags

            if(flags.includes("R")){
              return resetcolor
            } //RESET
            
            if(flags.includes("F")){
              return fincolor
            } // FIN

  
            return defaultcolor
          });

          closeBullet.adapter.add("stroke", function(disabled, target) {
            
            let flags = target.dataItem.dataContext.flags

            if(!flags.includes("F") && !flags.includes("R")){
              return defaultstroke
            }
            return defaultstroke
          });

          var legend = new am4charts.Legend();
          
          legend.parent = chart.chartContainer;
          legend.background.fill = am4core.color("#000");
          legend.background.fillOpacity = 0.05;
          legend.width = 800;

          legend.useDefaultMarker = true;

          var marker = legend.markers.template.children.getIndex(0);
          marker.cornerRadius(12, 12, 12, 12);
          marker.strokeWidth = 2;
          marker.strokeOpacity = 1;
          marker.fillOpacity = 0.6;

          legend.align = "center";
          legend.data = [
            {
              "name": "SYN / FIN",
              "fill": syncolor,
              "stroke": defaultstroke
            },

            {
              "name": "ECN SYN",
              "fill": syncolor,
              "stroke": ecncolor
            },
            {
              "name": "No SYN",
              "fill": nosyncolor,
              "stroke": defaultstroke
            },
            {
              "name": "FIN",
              "fill": fincolor,
              "stroke": defaultstroke
            },
            {
              "name": "RST",
              "fill": resetcolor,
              "stroke": defaultstroke
            },
            {
              "name": "No FIN/RST",
              "fill": defaultcolor,
              "stroke": defaultstroke
            }
          ];

          marker.adapter.add("stroke", strokeAdapter);

          function strokeAdapter(radius, target) {
            
            if (!target.dataItem) {
              return ;
            }

            return target.dataItem.dataContext.stroke
          };

        }

        // show all connections
        var cellSize = 22;
        chart.events.on("datavalidated", function(ev) {
          
          // Get objects of interest
          var chart = ev.target;
          var categoryAxis = chart.yAxes.getIndex(0);
          
          // Calculate how we need to adjust chart height
          var adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

          // get current chart height
          var targetHeight = chart.pixelHeight + adjustHeight;

          // Set it on chart's container
          chart.svgContainer.htmlElement.style.height = targetHeight + "px";
        });



        this.chart = chart
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.timelinechartcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.timelinechart {
  min-height: 200px;
  width: 100%;
  height: 480px;
}

.container {
  height: 100%;
  max-width: 100%;
  overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
