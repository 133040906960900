<template>
  <div v-if="handler">
    <v-card class="ma-5">
      <v-card-title>
        {{handler.name}}
      </v-card-title>
    <v-card-text class="pl-5 pr-5">
    <v-switch class="pa-0" v-model="groupTable" label="Group data"></v-switch>
    <ag-grid-vue
      style="width: 100%; height: 100%"
      :class="theme"
      :gridOptions="gridOptions"
      id="tapstatsgrid"
    ></ag-grid-vue>
    </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { AGGRID_LICENSE } from "@/common/config";
LicenseManager.setLicenseKey(
  AGGRID_LICENSE
);

import ApiPacketlist from "@/common/api/packetlist";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { sharkdhelper } from "@/common/sharkdhelper";
import { conversations } from "./conversations";
import { endpoints } from "./endpoints";
import { serviceresponsetime } from "./serviceresponsetime";
import { statstap } from "./statstap";
import { exportobjects} from "./exportobjects";
import { seqa } from "./seqa";
import { expertinfo } from "./expertinfo";
import { rtp } from "./rtp";

export default {
  name: "taptable",
  props: {
    value: null,
    pcapid: null
  },
  watch: {
    groupTable(n, o) {
      this.mainRefreshView();
    },
    value(n, o) {
      if(n.tap == o.tap){
        return
      }
      this.statistics = this.value;
      this.type = this.statistics.tap;
      this.handler = this.selectHandler();
      
      this.mainFullUpdate();
    }
  },
  components: { AgGridVue },
  mixins: [
    displayfilter,
    sharkdhelper,
    conversations,
    endpoints,
    serviceresponsetime,
    statstap,
    exportobjects,
    seqa,
    expertinfo,
    rtp
  ],
  data() {
    return {
      type: null,
      statistics: null,

      handler: null,
      aggridhandler: {
      },

      groupTable: false,

      gridOptions: {
        domLayout: "autoHeight",

        context: {
          parent: this
        },
        defaultColDef: {
          sortable: true,
          resizable: true
        },
        rowSelection: "single",
        rowGroupPanelShow: 'always',
        getContextMenuItems: this.mainGetContextMenuItems,
        columnDefs: [],
        getDataPath: this.getDataPath,
        getMainMenuItems: function(params) {}.bind(this),
        onGridReady: function(params) {
          this.mainFullUpdate();
        }.bind(this),
        onRowDataChanged: function(params) {}.bind(this),
        onRowClicked: function(params) {}.bind(this),
        onRowSelected: function(params) {}.bind(this)
      }
    };
  },
  created() {},
  mounted() {
    
    this.statistics = this.value;
    this.type = this.statistics.tap;
    this.handler = this.selectHandler();
    //this.mainFullUpdate();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
      theme: function() {
      if (this.currentAnalysis.profiles.selected.theme){
        return "ag-theme-balham-dark"
      }
      return "ag-theme-balham"
    }
  },
  beforeDestroy() {},
  methods: {
      download_object(token){

        let params = {
          token : token,
          pcapid : this.pcapid,
        }

        ApiPacketlist.exportobject(params).then(({ data }) => {
          
          this.stats = data["taps"][0]
        })
        .catch(() => {});
      },
      mainGetContextMenuItems: function (params) {
        
          var currentitems = params.defaultItems.slice(0);
          let result = []
          if("filter" in params.node.data){
            var filter = 
                {
                    name: "Filter Conversation",
                    action: function () {

                        var o = params.node.data["filter"]
                        this.df_apply_to_packetlist(o)
                        this.currentAnalysis.showTapstatistics = false
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                };
            result.push(filter)
          }

          // Note: very hard coded
          var k = ["saddr", "daddr", "host"].find(e => e ==params.column.colId)
          if(k !== undefined){
            var ip = params.node.data[k]
            var lookup = 
                {
                    name: "Lookup: "+ip,
                    action: function () {
                        
                        let url = "https://whatismyipaddress.com/ip/"+ip
                        window.open(url, "_blank");
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                };
            result.push(lookup)        
          }

          if("_download" in params.node.data){
            var download = 
                {
                    name: "Download",
                    action: function () {
                      
                        var o = params.node.data["_download"]
                        this.download_object(o)
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                };
            result.push(download)    
          }

          for (var item of result) {
              currentitems.unshift(item);
          }

          return currentitems;
    },
    mainFullUpdate() {

      // force refresh
      this.gridOptions.api.setColumnDefs();
      this.gridOptions.api.setColumnDefs(this.handler.getColumnDefs(this.statistics, this.groupTable));
      
      this.gridOptions.api.setRowData(this.handler.formatData(this.statistics));
      this.gridOptions.api.sizeColumnsToFit();
    },
    mainRefreshView() {
      this.gridOptions.api.setColumnDefs();
      this.gridOptions.api.setColumnDefs(this.handler.getColumnDefs(this.statistics, this.groupTable));
      this.gridOptions.api.sizeColumnsToFit();
    },
    getDataPath(data){
      if(this.handler)
        return this.handler.getDataPath(data);
    },
    selectHandler() {
      
      let mytype = this.type.split(":")[0]
      if (mytype in this.aggridhandler) {
        
        let h = this.aggridhandler[mytype];

        if ("isTreeData" in h && h.isTreeData){
          this.gridOptions.treeData = true;
        }
        return this.aggridhandler[mytype];
      }
      return;
    }
  }
};
</script>

<style>

#tapstatsgrid .ag-root-wrapper-body {
  min-height: 500px;
}
</style>
