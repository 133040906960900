<template>
  <v-text-field
    :disabled="disabled"
    :value="value"
    label="Display filter..."
    :background-color="searchbackground"
    solo
    ref="displayfilter"
    hide-details
    maxlength="500"
    v-on:keyup="updateModel"
    v-on:keyup.enter="enterpressed"
    v-on:keyup.esc="escapepressed"
  ></v-text-field>
</template>

<script>
import { mapGetters } from "vuex";
import ApiDisplayfilter from "@/common/api/displayfilter";
export default {
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  name: "displayfilterbox",
  data() {
    return {
      searchbackground: "white",
      searchhistory: []
    };
  },
  created: function() {},
  mounted() {},
  computed: {
    ...mapGetters(["currentAnalysis"]),
    listeners() {
      const { e, ...listeners } = this.$listeners;
      e
      return listeners;
    }
  },
  watch: {},
  methods: {
    verifyfilter: function() {
      ApiDisplayfilter.verify(this.$refs.displayfilter.value)
        .then(() => {
          this.filterok();
        })
        .catch(() => {
          this.filternotok();
        });
    },
    convertfilter: function() {
      ApiDisplayfilter.displayfiltertocoloringrule(this.$refs.displayfilter.value)
        .then(() => {
          this.filterok();
        })
        .catch(() => {
          this.filternotok();
        });
    },
    enterpressed: function(e) {
      
      if (this.displayfilter == "") {
        this.searchbackground = "white";
      } else {
        this.convertfilter();
      }
      this.$emit("confirm", e);
    },
    escapepressed: function() {
      this.resetDisplayfilter();
    },
    resetDisplayfilter: function() {
      this.displayfilter = "";
      this.searchbackground = "white";
    },
    filterok: function() {
      //Note
      //running into this bug
      //https://github.com/vuetifyjs/vuetify/issues/4663
      // waiting for this pull request to pass
      //https://github.com/vuetifyjs/vuetify/pull/4879
      this.searchhistory.unshift(this.displayfilter);
      this.searchbackground = "green lighten-4";
    },
    filternotok: function() {
      this.searchbackground = "red lighten-4";
    },
    updateModel: function(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style >
</style>
