<template>
  <decode ref="decode" :id="id" :start="start" :end="end"></decode>
</template>

<script>
import Decode from "./Decode";

export default {
  components: {
    Decode
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    start: {
      type: String,
      default: ""
    },
    end: {
      type: String,
      default: ""
    }
  },
  name: "decodepopout",
  mounted() {
    window.addEventListener("storage", this.displayPacket, true);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.displayPacket, true);
  },
  methods: {
    displayPacket: function(e) {
      if (e.key == this.id) {
        var packetid = e.newValue;
        this.$eventHub.$emit("active-packet", packetid);
      }
    }
  }
};
</script>

<style >
/*
.ag-row-odd:not(.ag-row-selected) {
  background-color: #fff !important;
}*/

/*
.ag-row-selected {
  background-color: #1a5bc9 !important;
    color: white !important;
}*/

/*
.ag-theme-balham, .ag-theme-balham-dark {

  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}
*/
</style>
