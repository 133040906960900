import { sharkdhelper } from "@/common/sharkdhelper"

export const serviceresponsetime = {
    mixins: [sharkdhelper],
    data() {
        return {
            aggridhandler: {
                "srt": {
                    name: "Service Response Time",
                    getColumnDefs: this.getColumnDefsSrt,
                    getContextMenuItems: this.getContextMenuItemsSrt,
                    formatData: this.formatDataSrt
                }
            }
        }
    },
    methods: {
        getColumnDefsSrt(stats, groupTable=false) {

            var h = [

                { headerName: "Type", field: "type", rowGroup: true, hide: true },
                { headerName: "Index", field: "idx", aggFunc: 'sum' },
                { headerName: "Prodedure", field: "procedure", aggFunc: 'sum' },
                { headerName: "Calls", field: "calls", aggFunc: 'sum' },
                { headerName: "Min SRT", field: "min", aggFunc: 'min' },
                { headerName: "Max SRT", field: "max", aggFunc: 'max' },
                { headerName: "Avg SRT", field: "avg", aggFunc: 'avg', cellRenderer: this.averageSrtCellRender },
                { headerName: "Sum SRT", field: "total", aggFunc: 'sum', cellRenderer: this.averageSrtCellRender },
                { headerName: "Filter", field: "filter", hide: true }
            ];

            return h;
        },
        getContextMenuItemsSrt: function (params) {
            
            var currentitems = params.defaultItems.slice(0);
            var result = [
                {
                    name: "Filter Conversation",
                    action: function () {

                        var o = params.node.data["filter"]
                        this.df_apply_to_packetlist(o)
                        this.currentAnalysis.showTapstatistics = false
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                },
            ];

            for (var item of result) {
                currentitems.unshift(item);
            }

            return currentitems;
        },
        formatDataSrt(stats) {
            
            let indata = [];
            
            for (let c of stats.tables) {
                for(let p of c.r){

                    let out = {
                        type: c.n,
                        filter: c.f,
                        procedure: p.n,
                        idx: p.idx,
                        calls: p.num,
                        avg: p.tot/p.num,
                        min: p.min,
                        max: p.max,
                        total: p.tot,
                    }
                    indata.push(out)
                }         
            }
            return indata;
        }
    }
}