import { mapGetters } from "vuex";
import { ADMIN_ROLE_ID } from "@/common/config";

export const permissionchecks = {
    computed: {
        ...mapGetters(["currentAnalysis", "currentUser"]),
        canEditCurrentProfile(){
            
            if(this.currentUser){
                return this.canEdit(this.currentUser, this.currentAnalysis.profiles.selected.permissions)
            }
            return false
        }
    },
    methods: {

        canEdit: function(user, permissions){
            return this.isOwner(user, permissions)
        },
        hasSubscription: function(user){
            if(user && "deactivation_date" in user){
                const inputDate = new Date(user["deactivation_date"]);
                const currentDate = new Date();
                if(currentDate < inputDate){
                    return true
                }
                return false
            }
            return false
            
        },
        isAdmin: function(user){

            try{
               return user["roles"].includes(ADMIN_ROLE_ID)
            }catch(e){
                return false
            }
        },
        isOwner: function (user, permissions) {

            if (!user || !user.username || !permissions) {
                return false;
            }
            var username = user.username;
            var owner = this.getOwner(permissions)
            
            return username === owner || this.isAdmin(user);
        },
        isPublic: function (permissions) {
            if (!permissions || !permissions["permissions_public"]) {
                return false
            }

            return permissions["permissions_public"];
        },
        getOwner: function (permissions) {

            if (permissions && permissions.permissions_owner && permissions.permissions_owner.length == 1) {
                return permissions.permissions_owner[0].username
            }
            return null
        }
    }
}