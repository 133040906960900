const LRU = require("lru-cache");
const cache = new LRU({ max: 500 });
const currentpacket = undefined;
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import ApiDecode from "./api/decode";
cache.reset();

export default {
  data() {
    currentid: ""
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {
    /* communication with packetlist */
    this.$eventHub.$on("active-packet", num => {

      if (num == null) {
        this.packet = 1;
        return;
      }
      num = parseInt(num);

      /* load first packet quickly, and then the rest */
      this.fetchShowPacket(this.id, num, num)
      if (this.currentAnalysis.filter) {
        this.pcapcacheselective(this.id, num)
      } else {
        this.cachingAlgorithm(this.id, num);
      }

      localStorage.setItem(this.id, num);
    });
    this.$eventHub.$on("cachepackets", range => {
      var start = parseInt(range[0]) + 1;
      var end = range[1];

      this.pcapcache(this.id, start, end);
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("active-packet");
    this.$eventHub.$off("cachepackets");
  },
  methods: {
    renderUTCtoClientTime(time){
      let format = "MMM D, YYYY HH:mm:ss.SSSSSSSSS"
      let clientTime = moment.utc(time, format).tz(moment.tz.guess()).local().format("MMM D, YYYY HH:mm:ss.SSSSSSSSS ZZ")//.toDate()
      return clientTime
    },
    showXXXPacketHex(packet) {
      return;
    },
    showXXXPacketDecode(packet) {
      return
    },
    findLastDisplayedPacketBeforeRequestedRange(start) {
      let packetNumbers = this.currentAnalysis.packetlist.displayed
      let i =0
      for(i = 0; i < packetNumbers.length; i++){
        if(start == packetNumbers[i]){
          return packetNumbers[i] - 1
        }
      }
      console.log("Requested start not found in array; maybe can happen in popup decode")
      return null // requested start not found in array
    },
    /* start is frame number */
    fetchShowPacket: function (key, start, end) {
      /* packet numbers start at 1 and the cache index starts at 0
         * for simplicity we just don't use the first slot,
           so that the packet number correspondes to the packetnumber */
      this.currentid = key;
      this.start = start;
      this.end = end;


      var currentcache = this.checkCache(start);

      if (currentcache == -1) {
        return
      } else if (currentcache != null && currentcache != -1) {

        this.currentAnalysis.cpacket = null;
        this.currentAnalysis.cpacket = currentcache;

        //this.showXXXPacketHex(currentcache);
        //this.showXXXPacketDecode(currentcache);f
        return;
      } else {
        this.addToCache(-1, start);
        /*         for (let i = start; i < end+1; i++) {
                  this.addToCache(-1, i);
                } */
      }

      let params = {
        "range": start + "-" + end,
        "pcapid": key,
        "profileid": this.currentAnalysis.profiles.selected.id
      }

      if (this.currentAnalysis.timereferences != null) {
        params["timereferences"] = this.currentAnalysis.timereferences
      }

      let ref_frame = this.findLastDisplayedPacketBeforeRequestedRange(start)
      if(ref_frame != null){
        params["prev_frame"] = ref_frame
      }

      ApiDecode.decoderange(params).then(({ data }) => {
        var i;
        if (data == null || data.result.length == 0) {
          return null;
        }

        data = data.result

        for (i = 0; i < data.length; i++) {
          var cur = data[i].tree;

          this.addToCache(data[i], parseInt(start) + i);

          if (i == 0) {
            let p = this.checkCache(parseInt(start) + i)

            /* set the shown packet immediately, before processing the rest */

            this.currentAnalysis.cpacket = p;
            //this.showXXXPacketHex(p);
            //this.showXXXPacketDecode(p)
          }

          // adding caching here 
        }
        if (this.currentAnalysis.features.debugdecodecaching) {
          this.currentAnalysis.triggers.refreshpacketlist = true
        }
      });
    },
    cachingAlgorithm: function (key, currentid) {
      var fwdWindow = 40;
      var revWindow = 5;

      var loadAhead = 100;
      var loadBack = 10;

      for (let i = currentid + 1; i < currentid + 1 + fwdWindow; i++) {
        if (this.checkCache(i) == null) {
          this.pcapcache(this.id, i, i + loadAhead);
          return;
        }
      }

      // eslint-disable-next-line for-direction
      for (let i = currentid - 1; i < currentid - 1 - revWindow; i--) {
        if (this.checkCache(i) == null) {
          this.pcapcache(this.id, i, i - loadBack);
          return;
        }
      }
      //console.log("Everything cached");
    },
    recurseFieldsFast: function (node, output, first, lastproto, offsets) {
      var nodes;
      var showname;
      var name;
      var show;
      var filter;
      var outname;
      var i;
      var currentnode;
      var result;
      var tmpid;
      var tmpsplit;

      if (first) {
        nodes = node;
      } else {
        if (node && "n" in node) {
          nodes = node.n;
        } else {
          return null;
        }
      }

      /* termination of tree recursion */
      if (nodes === undefined || nodes.length == 0) {
        return null;
      }

      for (i = 0; i < nodes.length; i++) {
        // if (nodes[i].tagName != "field" && nodes[i].tagName != "proto") {
        //   continue;
        // }




        // let debug1 = "3 Reassembled TCP Segments (3014 bytes): #14(1319), #15(1452), #17(243)"
        // let debug2 = "TLSv1.3 Record Layer: Handshake Protocol: Multiple Handshake Messages"
        // if(nodes[i].l == debug1 || nodes[i].l == debug2){
        //   
        // }

        if (!("f" in nodes[i])) {
          
          tmpid = nodes[i].l
          name = nodes[i].l;
        } else {
          tmpsplit = nodes[i].f.split("==")
          name = tmpsplit[0].trim();
          tmpid = name + i;

          if (nodes[i].h !== undefined && tmpsplit.length > 0) {


            if ("ds" in nodes[i]) {

              offsets.push([nodes[i].h[0], nodes[i].h[1], nodes[i].f, nodes[i].ds]);
            } else {
              offsets.push([nodes[i].h[0], nodes[i].h[1], nodes[i].f, 0]);
            }

          }
        }
        showname = nodes[i].l;


        show = nodes[i].l;

        if ("g" in nodes[i] && nodes[i].g == true) {
          showname = "[" + showname + "]";
        }

        // filter = this.ignorefields;

        // if (filter.indexOf(name) > -1) {
        //   continue;
        // }

        if (output.length > 0) {
          if (lastproto != "") {
            outname = lastproto + "/" + tmpid;
          } else {
            outname = tmpid;
          }

          if (name == "") {
            outname = output[output.length - 1]["id"] + "/filler";
          }
        } else {
          outname = tmpid;
        }

        /* check for duplicate names */
        for (let j = 0; j < output.length; j++) {
          if (output[j]["id"] == outname) {

            outname = outname + ":" + i;
            i++;
          }
        }

        /* we need to change the relative time to display it for the local time of the client */
        if(name == "frame.time"){
          if(tmpsplit){
            let time = tmpsplit[1].replace(/"/g, "").trim()
            let clientTime = this.renderUTCtoClientTime(time)
            show = "Arrival Time: "+clientTime
            showname = "Arrival Time: "+clientTime
          }
        }

        
        if (showname != null) {
          /* proto geninfo is different in showname behaviour - ignore that one*/
          //currentnode = {'id' : name, 'text': showname+": "+show, "children": children};
          currentnode = {
            id: outname,
            show: showname,
            field: name.trim(),
            value: show
          };
        } else if (show != null) {
          currentnode = { id: outname, show: show, field: name, value: show };
        } else {
          // e.g. fake field wrapper
          currentnode = {
            id: outname,
            show: "Expert analysis or text",
            field: name,
            value: "Expert or text"
          };
        }

        if (!nodes[i]) {
          continue;
        }

        if ("f" in nodes[i]) {
          currentnode.filter = nodes[i].f;
        }

        if ("bitmask" in nodes[i]) {
          currentnode.bitmask = nodes[i].bitmask
        }

        if ("ds" in nodes[i]) {

          currentnode.ds = nodes[i].ds
        }

        if (currentnode["id"] != "") {
          output.push(currentnode);
        }

        result = this.recurseFieldsFast(nodes[i], output, false, outname, offsets);
        if (result == null) {
          delete currentnode["children"];
        }
      }
      return output;
    },

    pcapcache: function (key, start, end) {

      if (this.currentAnalysis.filter) {
        return this.pcapcacheselective(key)
      } else {
        return this.pcapcachefast(key, start, end);
      }

    },
    /* when we are in filteted view we dont want to cache a range but 
     * just the selected or filtered packets */
    collapseSequences: function (arr) {
      var i, val;
      var idx = 0; // Array index
      var str = ""; // The result string
      const len = arr.length; // This is for readability not performance

      // The loop is terminated in its body to manage the seperator
      while (true) {
        i = 0;
        str += val = arr[idx++];

        // Look ahead for a sequence
        while (idx + i < len && arr[idx + i] === val + i + 1) {
          i++;
        }
        // Was here a sequence found ?
        if (i > 1) {
          // add it to the string and skip over the sequence
          str += "-" + arr[(idx += i) - 1];
        }
        if (idx === len) {
          // All done then return result
          return str;
        }
        str += " "; // add the separator
      }
    },
    pcapcacheselective: function (key, processPacketCallback) {
      let displayedarr = this.currentAnalysis.packetlist.displayed
      let currentpacketnumber = this.currentAnalysis.currentpacket
      this.currentid = key;

      if (displayedarr.length == 0) {
        return
      }

      /* before we load we put something in the cache
       * so we show that we are in the process of loading
       * this avoids firing many requests while we are still
       * waiting for responses
       * we use -1 as a value for that.
       * not that functions doing fast packet load should treat
       * -1 same as null for checkCache
       */

      /* removing already cached frames */
      let cleanedarr = []
      let MAX_SELECTIONS = 80;
      let MAX_CACHE_HITS = 40
      let cachehits = 0
      let selcount = 0;

      // count only cache hits beyond the currently selected packet
      for (let fn of displayedarr) {

        // also counts loading cache hits --> -1
        if (this.checkCache(fn)) {
          if (fn > currentpacketnumber) {
            cachehits++;
          }
        } else {
          if (selcount > MAX_SELECTIONS) {
            break;
          }
          //TODO make it look back as well
          if (fn < currentpacketnumber) {
            continue // only add higher packet numbers
          }
          cleanedarr.push(fn)
          selcount++;
        }
      }

      if (selcount == 0) {
        return
      }

      if (cachehits > MAX_CACHE_HITS) {
        return
      }

      for (let item of cleanedarr) {
        this.addToCache(-1, item);
      }

      let displayedwithoutcached = this.collapseSequences(cleanedarr)

      let params = {
        "range": displayedwithoutcached,
        "pcapid": key,
        "profileid": this.currentAnalysis.profiles.selected.id
      }

      if (this.currentAnalysis.timereferences != null) {
        params["timereferences"] = this.currentAnalysis.timereferences
      }

      
      let prev_frame = this.findLastDisplayedPacketBeforeRequestedRange(cleanedarr[0])
      if(prev_frame != null){
        params["prev_frame"] = prev_frame
      }

      ApiDecode.decoderange(params).then(({ data }) => {
        var i;
        let key;
        if (data == null || data.result.length == 0) {
          return null;
        }

        data = data.result

        for (i = 0; i < data.length; i++) {
          key = data[i]["f"] // f is the frame number
          this.addToCache(data[i], key);
        }

        if (this.currentAnalysis.features.debugdecodecaching) {
          this.currentAnalysis.triggers.refreshpacketlist = true
        }

      });


    },
    pcapcachefast: function (key, start, end, processPacketCallback) {
      this.currentid = key;
      this.start = start;
      this.end = end;

      /* dont cache if the request is outside of the trace bounds */
      if (this.currentAnalysis.total) {
        if (start > this.currentAnalysis.total) {
          return
        }
      }


      /* before we load we put something in the cache
       * so we show that we are in the process of loading
       * this avoids firing many requests while we are still
       * waiting for responses
       * we use -1 as a value for that.
       * not that functions doing fast packet load should treat
       * -1 same as null for checkCache
       */

      for (let i = start; i < end + 1; i++) {
        this.addToCache(-1, i);
      }

      if (this.currentAnalysis.total) {
        if (end > this.currentAnalysis.total) {
          end = this.currentAnalysis.total
        }
        if (start > this.currentAnalysis.total) {
          return
        }
      }

      let params = {
        "range": start + "-" + end,
        "pcapid": key,
        "profileid": this.currentAnalysis.profiles.selected.id
      }

      ApiDecode.decoderange(params).then(({ data }) => {

        var i;
        if (data == null || data.result.length == 0) {
          return null;
        }

        data = data.result

        for (i = 0; i < data.length; i++) {
          this.addToCache(data[i], parseInt(start) + i);
        }
        if (this.currentAnalysis.features.debugdecodecaching) {
          this.currentAnalysis.triggers.refreshpacketlist = true
        }
      });
    },
    base64ToArrayBuffer: function (base64) {


      var binary_string = window.atob(base64);

      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes;
    },
    fromsharkdtoview(packet) {
      var decode = [];
      var offsets = []
      this.recurseFieldsFast(packet, decode, true, "", offsets);

      return { decode, offsets }
    },
    cachekey(num) {
      return parseInt(num) + "@" + this.currentAnalysis.pcapid
    },
    shortestintervals(intervals) {
      let last = 10000000
      let cur;

      intervals = intervals.sort((a, b) => ((a[0] + a[1]) - (b[0] + b[1])));
      let shortests = []

      for (let i = 0; i < intervals.length; i++) {
        cur = intervals[i][0] + intervals[i][1];
        if (cur > last) {
          return shortests
        }
        last = cur
        shortests.push(intervals[i])
      }
      return shortests
    },

    shortestinterval(intervals) {
      let last = 10000000
      let shortest = intervals.length - 1
      for (let i = intervals.length - 1; i > -1; i--) {
        if (last > intervals[i][1] && intervals[i][1] != 1) {
          shortest = i
        }
        last = intervals[i][1]
      }
      return intervals[shortest]
    },
    addToCache(element, packetnumber) {

      var toadd = element;

      if (Object.keys(element).length === 0) {
        if (element !== -1) {
          console.debug("Attempted to cache empty object")

          return
        }
      }

      if (element !== -1) {

        let { decode, offsets } = this.fromsharkdtoview(element.tree)

        toadd = {
          bytes: this.base64ToArrayBuffer(element.bytes),
          decode: decode,
          offsets: offsets
        }

        if ("ds" in element) {
          toadd.ds = element.ds
        }
      }

      cache.set(this.cachekey(packetnumber), toadd);

      //this.reportCache();
    },
    isinintervalnew(val, interval, ispadding = false) {
      let min = interval[0] + 1
      let max = interval[1] + min;

      if (ispadding) {
        return this.isinintervalnew(val + 1, interval) && this.isinintervalnew(val, interval)
      }
      return (val >= min && val < max)
    },
    getintervalsforfilter(filter, offsetdefs) {
      let candidates = []

      if (!offsetdefs) {
        return candidates
      }

      for (let i = 0; i < offsetdefs.length; i++) {
        if (filter === offsetdefs[i][2]) {
          candidates.push(offsetdefs[i])
        }
      }
      return candidates;
    },
    getintervalsforval(val, offsetdefs) {

      let candidates = []

      if (!offsetdefs) {
        return candidates
      }

      for (let i = 0; i < offsetdefs.length; i++) {
        if (this.isinintervalnew(val, offsetdefs[i])) {
          candidates.push(offsetdefs[i])
        }
      }
      return candidates;
    },
    isinintervalnzero(val, interval, ispadding = false) {
      let min = interval[0]
      let max = interval[1] + min;

      if (ispadding) {
        return this.isinintervalnzero(val + 1, interval) && this.isinintervalnzero(val - 1, interval)
      }
      return (val >= min && val < max)
    },
    getintervalsforvalzero(val, offsetdefs) {

      let candidates = []

      if (!offsetdefs) {
        return candidates
      }

      for (let i = 0; i < offsetdefs.length; i++) {
        if (this.isinintervalnzero(val, offsetdefs[i])) {
          candidates.push(offsetdefs[i])
        }
      }
      return candidates;
    },
    reportCache() {
      this.currentAnalysis.cache = cache
    },
    getFromCache(packetnumber) {
      return cache.get(this.cachekey(packetnumber))
    },
    resetCache: function () {
      cache.reset()
    },
    smartCacheEviction: function(start){
      /* in some events we need to evict decoded packets from the cache
       * these are events that cause reference or previous displayed frames being changed
       *
       * - time reference set
       * - delta time displayed filter set -> displaye packets changed -> delta time displayed changes
       * - relative time changes due to time reference
       * - filter set also changes previous displayed -> DIFFICUL
       * - a time reference will cause all frames to be evicted from cache
       * - previous frame change is ok
       * - we DO NOT need to evict frames BEFORE the time reference -> optimization
       * 
       * - TODO cache eviction on filter setting
       */
    
      /* for now nothing smart: just reset everything */
      cache.reset()
      /* load displayed frames */
      this.pcapcacheselective(this.id, start)
    },
    checkCache(packetnumber) {

      var c = this.getFromCache(packetnumber);

      if (c === undefined) {

        return null;
      } else {

        return c;
      }
    },
  }
}