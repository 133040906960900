/* eslint-disable vuetify/no-deprecated-classes */
<template>
  <v-stepper v-model="stepper">
    <v-stepper-header>
      <v-stepper-step :complete="stepper > 1" step="1">Base Indexer</v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="stepper > 2" step="2">Additional Fields</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">Name</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card flat class="pt-3 pb-4 mb-5" min-height="200px">
          <div class="pb-2">Profile name: {{newprofile.id}}</div>
          <div class="p-2 subheading">
            An indexer does specify which fields of the original packet capture will be indexed for analysis.
            Only indexed trace files can be viewed in the packet analysis view. The indexing process can take a while depending on the number of indexed fields.
            As indexing all fields is infeasible it is recommended to limit the indexer configuration to fields on is intersted in.
            As many times certain fields as e.g. the Source IP, TCP Flags and so on are interesting one can inherit a specific indexer form a base configuration without the need to specific these fields all over.
          </div>
          <v-select
            v-if="newprofile.inherit ||  !newprofile.id"
            class="pt-3"
            @change="selectprofile"
            :items="currentAnalysis.indexerprofiles.available"
            v-model="baseprofile"
            label="Select a profile to inherit from..."
          >
          
            <template slot="selection" slot-scope="data">{{data.item.name}}</template>
            <template slot="item" slot-scope="data">{{data.item.name}}</template>
          </v-select>

          <div
            v-else
            class="py-3"
          >This profile does not inherit from other profiles</div>


          <!--<template v-if="baseprofile.inherit">Show all fields
            <v-tooltip bottom v-for="f in listAllInheritedFields(baseprofile)" v-bind:key="f">
              <v-chip slot="activator">{{ f.name}}</v-chip>
              <span>{{f.description}}</span>
            </v-tooltip>
          </template>-->
          <template v-if="newprofile.id">
            <div class="py-3">Fields from all profiles</div>

            <fieldtreeview :key="newprofile.id" :fields="listAllInheritedFields(newprofile)"/>
          </template>
        </v-card>

        <v-btn color="primary" @click="gotoStep2">Continue</v-btn>

        <v-btn text @click="close">Cancel</v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card flat class="pa-5" min-height="200px">
          <div class="p-2 subheading">
            <template v-if="newprofile.inherit">
              Inheriting fields form
              <b>{{baseprofile.name}}</b>.
            </template>Here you can specify additional fields for your indexer profile. You need to add at least one field. Start typing at least three characters in order to search for fields. All Wireshark fields are supported
            <a
              href="https://www.wireshark.org/docs/dfref/"
            >Protocol Fields</a>
          </div>

          <v-row   class="pt-4">
            <v-col cols="6">
              <v-autocomplete
                :items="availablefields"
                label="New field"
                hide-no-data
                :search-input.sync="newfield"
                placeholder="Start typing to search for fields..."
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title>{{lookupdescription(data.item)}}</v-list-item-title>
                    <v-list-item-subtitle v-text="data.item"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4"/>
            <v-col cols="2">
              <v-btn color="primary" @click="enterpressed">Add</v-btn>
            </v-col>
          </v-row>

          <v-tooltip bottom  v-for="f in newprofile.fields" v-bind:key="f">
            <template v-slot:activator="{ on }">
              <v-chip close @click:close="deletefield(f)" v-on="on">{{ df_es_field_to_wireshark(f) }}</v-chip>
            </template>
            <span>{{lookupdescription(df_es_field_to_wireshark(f))}}</span>
          </v-tooltip>


          <!--<v-list subheader two-line v-if="newprofile">
            <v-list-item v-for="(key) in newprofile.fields" :key="key">
              <v-list-item-content @click="isset = !isset">
                <v-list-item-title>{{lookupdescription(key)}}</v-list-item-title>
                <v-list-item-subtitle>{{key}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-on:click="deletefield(key)">delete</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>-->
        </v-card>

        <v-btn
          color="primary"
          :disabled="newprofile.fields.length < 1"
          @click="stepper = 3"
        >Continue</v-btn>

        <v-btn text @click="close">Cancel</v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card flat class="pt-3 pb-4 mb-5" min-height="200px">
          <v-text-field
            v-model="newprofile.name"
            label="Indexer name"
            placeholder="Enter indexer name..."
            :rules="[value => !!value || 'Required.']"
          ></v-text-field>
        </v-card>

        <v-btn
          v-if="!edit"
          :disabled="!newprofile.name"
          color="primary"
          @click="addindexprofile"
        >Add Profile</v-btn>
        <v-btn
          v-if="edit"
          :disabled="!newprofile.name"
          color="primary"
          @click="updateindexerprofile"
        >Update Profile</v-btn>

        <v-btn text @click="close">Cancel</v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ApiIndexerprofile from "@/common/api/indexerprofile";
import ApiDisplayfilter from "@/common/api/displayfilter";
import Fieldtreeview from "./Fieldtreeview";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";

export default {
  props: {
    edit: null
  },
  components: { Fieldtreeview },
  name: "indexerprofilenew",
  mixins: [displayfilter],
  data() {
    return {
      stepper: 1,
      isLoading: false,
      availablefields: [],
      fielddef: [],
      newfield: "",
      baseprofile: {
        name: null,
        fields: []
      },
      newprofile: {
        name: null,
        fields: []
      }
    };
  },
  created: function() {},
  mounted() {
    
    if (this.edit) {
      this.newprofile = this.edit;
      this.baseprofile = this.getProfileById(this.newprofile.inherit);
    }
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error"])
  },
  watch: {
    newfield(val) {
      val && val !== this.select && this.autocompletefields();
    }
  },
  methods: {
    listAllInheritedFields: function(p) {
      var fields = [];

      for (let f of p.fields) {
        fields.push(f);
      }

      if (p.inherit) {
        let i = this.getProfileById(p.inherit);
        fields.push(...this.listAllInheritedFields(i));
      }
      return fields;
    },
    getProfileById: function(id) {
      for (let p of this.currentAnalysis.indexerprofiles.available) {
        if (p.id === id) {
          return p;
        }
      }
    },
    gotoStep2() {
      if (this.baseprofile && this.baseprofile.id) {
        this.newprofile.inherit = this.baseprofile.id;
      }
      this.stepper = 2;
    },
    iscached() {
      for (let item of this.availablefields) {
        if (item.startsWith(this.newfield)) return true;
      }
      return false;
    },
    autocompletefields() {
      if (this.newfield.length < 3 || this.iscached()) return;

      ApiDisplayfilter.getfields(this.newfield)
        .then(({ data }) => {
          var newlist = [];
          this.isLoading = true;

          for (let item of data) {
            newlist.push(item["field"]);
          }
          this.addfieldstofielddef(data);

          this.availablefields = newlist;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.snackbartext = "Could not lookup profile";
          this.snackbar = true;
        });
    },
    addfieldstofielddef(fields) {
      var found = false;
      for (let item of fields) {
        for (let cache of this.fielddef) {
          if (item["field"] == cache["field"]) {
            found = true;
          }
        }
        if (!found) {
          this.fielddef.push(item);
        }
        found = false;
      }
    },
    lookupdescription(fieldname) {
      
      for (let i of this.fielddef) {
        if (i["field"] == fieldname) return i["description"];
      }

      return "no description";
    },
    copyprofilefields(to, from) {
      for (let field in from) this.$set(to, field, from[field]);
    },
    selectprofile() {
      if (!this.baseprofile) {
        return;
      }
      ApiDisplayfilter.getmultifields({fields: this.baseprofile.fields})
        .then(({ data }) => {
          
          this.addfieldstofielddef(data);
          //FIXME check if this works with array detection
          //this.copyprofilefields(this.cur.fields, profile.fields)
        })
        .catch(({ response }) => {
          this.error.type = "error";
          this.error.msg =
            "Could not lookup multi thsark fields for profile " +
            this.baseprofile.name;
        });
    },
    enterpressed() {
      this.addfield();
    },
    deletefield(field) {
      this.newprofile.fields.splice(this.newprofile.fields.indexOf(field), 1);
    },
    checkFieldAlreadyAddedRecursive(profile, field) {
      if (profile.fields.includes(field)) {
        return true;
      }

      if (profile.inherit) {
        for (let p of this.currentAnalysis.indexerprofiles.available) {
          if (p.id === profile.inherit) {
            return this.checkFieldAlreadyAddedRecursive(p, field);
          }
        }
      }
      return false;
    },
    addfield() {
      if (this.newfield != "" && this.iscached()) {
        if (
          this.checkFieldAlreadyAddedRecursive(this.newprofile, this.newfield)
        ) {
          this.error.type = "error";
          this.error.msg =
            "New field is already part of your profile or the profile you are inheriting from";
        } else {
          let es = this.df_wireshark_field_to_es(this.newfield)
          this.newprofile.fields.push(es);
        }
      } else {
      }
    },
    addindexprofile() {
      ApiIndexerprofile.create(this.newprofile)
        .then(({ data }) => {
          this.currentAnalysis.indexerprofiles.available.push(data["profile"]);
          this.error.type = "info";
          this.error.msg = "Profile added";
          this.close();
        })
        .catch(({ response }) => {
          this.error.type = "error";
          this.error.msg = "Profile not added";
          this.close();
        });
    },
    updateindexerprofile() {
      ApiIndexerprofile.update(this.newprofile)
        .then(({ data }) => {
          this.error.type = "info";
          this.error.msg = "Profile updated";
          this.close();
        })
        .catch(({ response }) => {
          this.error.type = "error";
          this.error.msg = "Profile not updated";
          this.close();
        });
    },
    close() {
      this.$emit("close", null);
    }
  }
};
</script>

<style >
</style>
