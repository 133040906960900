const LRU = require("lru-cache");
const cache = new LRU({ max: 30 });
import { mapGetters } from "vuex";
cache.reset();

export default {

    computed: {
        ...mapGetters(["currentAnalysis"])
    },
    methods: {
        removeCurrentFromCache() {
            cache.del(this.cachekey())
        },
        cachekey() {
            let pcapid = this.currentAnalysis.pcapid
            let filter = this.currentAnalysis.filter
            let profile = this.currentAnalysis.profiles.selected.id
            let start = this.currentAnalysis.selectStartDate
            let end = this.currentAnalysis.selectEndDate
            let maxPackets = this.currentAnalysis.profiles.selected.maxPackets
            let timereferences = ""
            let columns = ""

            if (this.currentAnalysis.timereferences != null) {
                timereferences = Array.from(this.currentAnalysis.timereferences).join(',')
            }

            if (this.currentAnalysis.columnsparam != null) {
                columns = this.currentAnalysis.columnsparam.join(",")
            }

            let key = pcapid + "@" + filter + "@" + profile + "@" + start + "@" + end + "@" + maxPackets + "@" + timereferences + "@" + columns
            return key
        },
        packetListToCache(packets) {
            if (packets.frames_displayed > -1) {
                const copied = Object.assign({}, packets)
                //const copied = { ...packets }
                cache.set(this.cachekey(), copied);
            }
        },
        checkCache() {
            return cache.get(this.cachekey())
        },
    }
}