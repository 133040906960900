const ID_TOKEN_KEY = 'access_token'
const ID_REFRESH_TOKEN_KEY = 'refresh_token'


const JwtService = {
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY)
  },

  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token)
  },

  getRefreshToken() {
    return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY)
  },

  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY)
  },

  saveRefreshToken(token) {
    window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token)
  },

  destroyRefreshToken() {
    window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY)
  }
}

export default JwtService