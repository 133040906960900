
<template>
  <div>
    <v-row justify="center" class="works " style="margin-top: 100px">
      <div class="pulse">
        <div class="ring">
          <img @click="currentAnalysis.longoperationoverlay = false" class="pulse" alt="Loading"
            src="/images/packetsafari-logo.png" />
        </div>
      </div>
    </v-row>
    <v-row justify="center" class="works mt-10">
      <h4>{{ message }}</h4>
    </v-row>
    <v-row class="mt-10" v-if="progress">
      <v-progress-linear stream buffer-value="0" v-model="progress" height="25">
        <strong>{{ Math.ceil(progress) }}%</strong>
      </v-progress-linear>

    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["error", "currentAnalysis"]),
  },
  watch: {
    "currentAnalysis.longoperationoverlayData": {
      handler: function (n, o) {
        this.progress = n.progress
        this.message = n.text

      },
      deep: true,
    },
  },
  data() {
    return {
      message: "Loading...",
      progress: 0
    };
  },
  mounted() {
    if (this.currentAnalysis.longoperationoverlayData.text) {
      this.message = this.currentAnalysis.longoperationoverlayData.text
    }

    if (this.currentAnalysis.longoperationoverlayData.progress) {
      this.progress = this.currentAnalysis.longoperationoverlayData.progress
    }
  }
};
</script>        

<style lang="scss">
.pulse {
  background-color: #DCEDC8;
  animation: bgchange 30s ease-out infinite;
  height: 10vmax;
  width: 10vmax;
  border-radius: 100%;
  position: relative;
}

.ring {
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  opacity: 0.8;
  animation: pulsing 2s ease-out infinite;

  &:nth-of-type(1) {
    animation-delay: -0.5s;
  }

  &:nth-of-type(2) {
    animation-delay: -1s;
  }

  &:nth-of-type(3) {
    animation-delay: -1.5s;
  }
}

@keyframes bgchange {

  from,
  to {
    background-color: #DCEDC8;
  }

  10% {
    background-color: rgb(3, 201, 169);
  }

  50% {
    background-color: rgb(3, 201, 169);
  }

  40% {
    background-color: rgb(3, 201, 169);
  }

  50% {
    background-color: rgb(3, 201, 169);
  }

  70% {
    background-color: rgb(233, 212, 96);
  }

  100% {
    background-color: rgb(3, 201, 169);
  }
}

@keyframes pulsing {

  100% {
    transform: scale(1.75);
    opacity: 0;
  }
}
</style>