import { render, staticRenderFns } from "./IpRenderer.vue?vue&type=template&id=ca4a68b4&scoped=true&"
import script from "./IpRenderer.vue?vue&type=script&lang=js&"
export * from "./IpRenderer.vue?vue&type=script&lang=js&"
import style0 from "./IpRenderer.vue?vue&type=style&index=0&id=ca4a68b4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4a68b4",
  null
  
)

export default component.exports