<template>
  <v-row   class="ma-7">

    <v-col cols="12">
      <div v-if="dnsentries">DNS entries</div>
      <v-data-table
        v-if="dnsentries"
        class="elevation-1"
        :headers="headers"
        :items="dnsentries"
        hide-default-footer
        disable-pagination
        :group-by="groupby"
        show-group-by
        dense
        :expanded="expanded"
      >

        <template slot="item" slot-scope="props">
          <tr>
            <td>

            <router-link
              :to="{ name: 'Analysis', params: { id: pcap.id, filter: filternamer(props.item.name), index:  'none',  view: 'l', analysisid: 'none'}}">
                {{props.item.name }}
              </router-link>

            </td>
            <td>
            <router-link
              :to="{ name: 'Analysis', params: { id: pcap.id, filter: filterip(props.item.value), index:  'none',  view: 'l', analysisid: 'none'}}">
                {{props.item.value }}
              </router-link>              
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12">
      <div v-if="tlsentries">TLS SNI</div>
      <v-data-table
        v-if="tlsentries"
        class="elevation-1"
        :headers="headers"
        :items="tlsentries"
        hide-default-footer
        disable-pagination
        :group-by="groupby"
        show-group-by
        dense
        :expanded="expanded"
      >

        <template slot="item" slot-scope="props">
          <tr>
            <td>

            <router-link
              :to="{ name: 'Analysis', params: { id: pcap.id, filter: filtersni(props.item.name), index:  'none',  view: 'l', analysisid: 'none'}}">
                {{props.item.name }}
              </router-link>

            </td>
            <td>
            <router-link
              :to="{ name: 'Analysis', params: { id: pcap.id, filter: filtertlsip(props.item.value), index:  'none',  view: 'l', analysisid: 'none'}}">
                {{props.item.value }}
              </router-link>              
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { navigation } from "@/common/navigation";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";

export default {
  name: "Dns",
  props: {
    pcap: null
  },
  components: {  },
  mixins : [displayfilter, navigation],
  data() {
    return {
      groupby: [],
      expanded: [],
      dnsentries: null,
      tlsentries: null
    };
  },
  created() {},
  mounted() {
    
    if(this.pcap && this.pcap.dnsinfos){
        this.dnsentries = this.pcap.dnsinfos
    }
    if(this.pcap && this.pcap.tlsinfos){
        this.tlsentries = this.pcap.tlsinfos
    }
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    headers: function() {
      var h = [];

      var defaults = [
        { text: "Host", align: "left", value: "name" },
        { text: "IP", align: "left", value: "value" },
      ];

      h.push(...defaults);
      //h.push({ text: "Actions", value: null });
      return h;
    }
  },
  beforeDestroy() {},
  methods: {
    filternamer(name){
      return 'dns.qry.name == "'+name+'"'+' or dns.resp.name == "'+name+'"'
    },
    filterip(ip){
      if(ip.includes(".")){
        return 'dns.a == '+ip+" or ip.addr == "+ip
      }
      if(ip.includes(":")){
        return 'dns.aaaa == '+ip+" or ipv6.addr == "+ip
      }

    },
    filtersni(name){
      return 'tls.handshake.extensions_server_name == "'+name+'"'+' or dns.qry.name == "'+name+'"'+' or dns.resp.name == "'+name+'"'
    },
    filtertlsip(ip){
      if(ip.includes(".")){
        return "ip.addr == "+ip
      }
      if(ip.includes(":")){
        return "ipv6.addr == "+ip
      }
    },
    filtername(name) {
      var f = 'dns.qry.name == "'+name+'"';
      this.df_apply_to_packetlist(f)
      this.currentAnalysis.showConnections = false;
      this.currentAnalysis.showIOGraph = false;
      this.navigatePacketview(this.currentAnalysis.pcapid, this.currentAnalysis.index, this.f);
    },
    contextMenu(e, item) {
      e.preventDefault();
      this.menu.showMenu = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;

      this.menu.items = [
        {
          title: "DNS Filter",
          action: function() {
            this.filter(item);
          }.bind(this)
        }
      ];

      this.$nextTick(() => {
        this.menu.showMenu = true;
      });
    }
  }
};
</script>

<style>
.connections td {
  height: 24px !important;
}


</style>
