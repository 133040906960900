<template>
  <v-card elevation="0">
    <v-card-title
      class="headline"
      primary-title v-if="error"
    >Oops nothing found!</v-card-title>
    <v-card-title
      class="headline"
      primary-title v-else
    >Let me guide you on your PacketSafari!</v-card-title>

    <v-card-text class="pt-2">
       <p class="text-subtitle-2 text--primary" v-if="error">Possibly there is a problem with your search query. There are multiple types of queries: indexed field queries, dns queries, PCAP metadata queries.
      Indexed query search is similar to searching within PCAPs. But there are some subtle differences.</p>
       <p class="text-subtitle-2 text--primary" v-else>There are multiple types of ways to search for the packets you are looking for: indexed field queries, dns queries, PCAP metadata queries.
      Indexed query search is similar to searching within PCAPs. But there are some subtle differences.</p>

      <v-card elevation="0" class="pa-2 mt-3" >
       <p class="text--primary"><b>PCAP metadata queries</b> search in the meta data like capture comments, file size, uploader. Essentially the information one can see in the 'basic information' tab.</p>
            <v-row
        no-gutters
        v-for="(value, key) in pcapmetadataqueries"
        v-bind:key="key"
        class="wrap"
      >
        <v-col cols="12" sm="6" class="px-2 xs-4 mt-4 mt-sm-0"><span class="text--primary">{{value.description}}</span></v-col>
        <v-col cols="12" sm="6" class="xs-8">
          <router-link :to="{ name: 'PCAPs', params: {type : currentAnalysis.pcaplist.type, query : value.example}}">
            <span  style="font-family: monospace;" @click="currentAnalysis.pcaplist.searchhelpdialog = false">{{value.example}}</span>
          </router-link>
        </v-col>
      </v-row>
      </v-card>
      <v-card elevation="0" class="pa-2 mt-3">
       <p class="text--primary"><b>Extracted info queries</b> search in extracted information as DNS and TLS hosts</p>
            <v-row
        no-gutters
        v-for="(value, key) in extractedinfoqueries"
        v-bind:key="key"
      >
        <v-col cols="12" sm="6" class="px-2 xs-4 mt-4 mt-sm-0"><span class="text--primary">{{value.description}}</span></v-col>
        <v-col cols="12" sm="6" class="xs-8">
          <router-link :to="{ name: 'PCAPs', params: {type : currentAnalysis.pcaplist.type, query : value.example}}">
            <span  style="font-family: monospace;" @click="currentAnalysis.pcaplist.searchhelpdialog = false">{{value.example}}</span>
          </router-link>
        </v-col>
      </v-row>
      </v-card>
      <v-card elevation="0" class="pa-2 mt-3" >
       <p class="text--primary"><b>Indexed field queries</b> search in indexed fields across all indexes</p>
            <v-row
        no-gutters
        v-for="(value, key) in indexedfieldsqueries"
        v-bind:key="key"
      >
        <v-col cols="12" sm="6" class="px-2 xs-4 mt-4 mt-sm-0"><span class="text--primary">{{value.description}}</span></v-col>
        <v-col cols="12" sm="6" class="xs-8" style="word-wrap: break-word;">
          <router-link :to="{ name: 'PCAPs', params: {type : currentAnalysis.pcaplist.type, query : value.example}}">
            <span  style="font-family: monospace;" @click="currentAnalysis.pcaplist.searchhelpdialog = false">{{value.example}}</span>
          </router-link>
        </v-col>
      </v-row>
      </v-card>
      <v-card elevation="0" class="pa-2 mt-3">
       <p class="text--primary"><b>Tag queries</b> search for PCAPs that are tagged with a specific tag</p>
            <v-row
        no-gutters
        v-for="(value, key) in tagqueries"
        v-bind:key="key"
      >
        <v-col cols="12" sm="6" class="px-2 xs-4 mt-4 mt-sm-0"><span class="text--primary">{{value.description}}</span></v-col>
        <v-col cols="12" sm="6" class="xs-8">
          <router-link :to="{ name: 'PCAPs', params: {type : currentAnalysis.pcaplist.type, query : value.example}}">
            <span  style="font-family: monospace;" @click="currentAnalysis.pcaplist.searchhelpdialog = false">{{value.example}}</span>
          </router-link>
        </v-col>
      </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    error: {
      type: Boolean,
      default: true
    },
  },
  name: "searcherror",
  data() {
    return {
      allfieldsquery : [
        {
          description: "Wildcard search on all fields",
          example: "myfavourie*"
        }
      ],
      tagqueries : [
        {
          description: "Tags",
          example: "tagname:showcase"
        },
      ],
      indexedfieldsqueries: [
        {
          description: "Search an indexed string in from the info column",
          example: "_ws.col.Info=='*Server*Hello*'"
        },
        {
          description: "Search across all PCAPs for a displayfilter",
          example: "ip.proto != 1"
        },
        {
          description: "Search across all PCAPs for a MAC address",
          example: 'eth.src== "00\\:10\\:a4\\:de\\:b5\\:d0"'
        },
        {
          description: "Search across all PCAPs an IPv4 adress name",
          example: "ip.src == 64.233.166.105"
        },
        {
          description: "Search across all PCAPs an IPv6 adress name",
          example: 'ipv6.src == "fe80\\:\\:b2ea\\:bcff\\:fe45\\:b870"'
        }
      ],
      pcapmetadataqueries : [
        {
          description: "PCAPNG Capture Comment",
          example: "capturecomment: Sharkfest 2011"
        },
        {
          description: "PCAP IDs",
          example: "_id:dHJhY2UwMS5wY2FwXXXX"
        },
        {
          description: "Original name (uploaded file name)",
          example: "origname: rtp.pcapng"
        },
        {
          description: "SHA1",
          example: "sha1: 10ad22cd96ebb073822e74cc612788d9ebc50c30"
        },
/*         {
          description: "SHA256",
          example:
            "sha256: cce4523a1e4a8d942865939bf82c22fe13266f53f058976393423d53a11fd90b"
        }, */
        {
          description: "Over 1000 bits/s",
          example: "databitrate: [1000 TO *]"
        },
        {
          description: "Filesize",
          example: "filesize: [9000000 TO *]"
        },
        {
          description: "Username",
          example: "username: otr"
        }
      ],
      extractedinfoqueries : [
        {
          description: "Search across all PCAPs a DNS name",
          example: "syndication.twitter.com"
        }
      ],
      helpexamples: [
        {
          description: "Search an indexed string in from the info column",
          example: "_ws.col.Info=='*Server*Hello*'"
        },
        {
          description: "Search across all PCAPs for a displayfilter",
          example: "ip.proto != 1"
        },
        {
          description: "Search across all PCAPs for a MAC address",
          example: 'eth.src== "00\\:10\\:a4\\:de\\:b5\\:d0"'
        },

        {
          description: "Search across all PCAPs a DNS name",
          example: "syndication.twitter.com"
        },
        {
          description: "Search across all PCAPs an IPv4 adress name",
          example: "ip.src == 64.233.166.105"
        },
        {
          description: "Search across all PCAPs an IPv6 adress name",
          example: 'ipv6.src == "fe80\\:\\:b2ea\\:bcff\\:fe45\\:b870"'
        },
        {
          description: "Wildcard search on all fields",
          example: "myfavourie*"
        },
        {
          description: "Tags",
          example: "tagname:showcase"
        },
        {
          description: "PCAPNG Capture Comment",
          example: "capturecomment: Sharkfest 2011"
        },
        {
          description: "PCAP IDs",
          example: "_id:dHJhY2UwMS5wY2FwXXXX"
        },
        {
          description: "Original name (uploaded file name)",
          example: "origname: rtp.pcapng"
        },
        {
          description: "SHA1",
          example: "sha1: 10ad22cd96ebb073822e74cc612788d9ebc50c30"
        },
        {
          description: "SHA256",
          example:
            "sha256: cce4523a1e4a8d942865939bf82c22fe13266f53f058976393423d53a11fd90b"
        },
        {
          description: "Over 1000 bits/s",
          example: "databitrate: [1000 TO *]"
        },
        {
          description: "Filesize",
          example: "filesize: [9000000 TO *]"
        },
        {
          description: "Username",
          example: "username: otr"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style>


</style>
