<template>
  <v-card class="mx-auto" max-width="500px">
    <v-card-title> Capture Calculator </v-card-title>

    <v-card-subtitle>
      Generate a dumpcap command for you capture
    </v-card-subtitle>

    <v-card-text>
      <v-row class="ma-4">
        <v-col cols="12">
          <v-text-field
            v-model="basename"
            label="Capture basename"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            cols="12"
            v-model="filesize"
            label="Filesize in MB"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-switch label="Ringbuffer" v-model="ringbuffer"> </v-switch>
        </v-col>
        <v-col cols="6" v-if="ringbuffer">
          <v-text-field
            v-model="numfiles"
            label="Number of files"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-switch label="Packet Slicing" v-model="slicingon"> </v-switch></v-col>
      <v-col cols="6">
          <v-text-field
            v-if="slicingon"
            v-model="slicing"
            label="Slicing"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="capturefilter"
            label="Capture Filter"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="nic" label="Network interface"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col cols="12">
      <v-text-field
        append-icon="mdi-clipboard-multiple-outline"
        read-only
        style="font-family: monospace"
        v-model="command"
        ref="textToCopy"
        @click:append="copyText"
      ></v-text-field>
        </v-col>
        <v-col cols="12" v-if="outspace">
          Required space: {{outspace}}
      </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
dumpcap -w dssdsadsadsad -b filesize:128000 -b files:100 -s 65535 -f "sfddsfsdf"
<script>
export default {
  components: {},
  name: "capturecalculator",
  data() {
    return {
      basename: "",
      filesize: "128",
      numfiles: "100",
      slicing: 65535,
      ringbuffer: false,
      capturefilter: null,
      slicingon: false,
      nic: "",
      show: false,
    };
  },
  computed: {
    outspace: function(){
      if(this.filesize && this.numfiles && this.ringbuffer){
        return (this.filesize  / 1000) * this.numfiles +" GB"
      }
      return null
    },
    command: function () {
      let c = "dumpcap";
      if (this.basename) {
        c = c + " -w " + this.basename;
      }
      if (this.filesize) {
        c = c + " -b filesize:" + this.filesize * 1000;
      }
      if (this.numfiles && this.ringbuffer) {
        c = c + " -b files:" + this.numfiles;
      }
      if (this.slicing && this.slicingon) {
        c = c + " -s " + this.slicing;
      }
      if (this.capturefilter) {
        c = c + ' -f "' + this.capturefilter + '"';
      }
      if (this.nic) {
        c = c + " -i " + this.nic + "";
      }
      return c;
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    },
  },
};
</script>

<style>
</style>
