<template>
  <v-row
    class="ma-5 pa-0"
    v-if="currentAnalysis.pcap"
  >

      <v-overlay :value="showcolorpicker" z-index="2">
        <v-card>
          <v-card-text>
      <v-color-picker
        class="ma-2"
        show-swatches
        :swatches="palette"
        v-model="color"
      ></v-color-picker>
      <v-text-field v-model="color"></v-text-field>

      <v-btn @click="confirmColor"> Confirm </v-btn></v-card-text>
        </v-card>

    </v-overlay>
    <v-col cols="10">
      <div
        class="iograph"
        ref="chartdiv"
        id="chartdiv"
      ></div>
    </v-col>
    <v-col cols="2">
      <v-card
        class="ml-5"
        min-width="274"
      >
        <v-card-title>Graph Settings</v-card-title>

        <v-card-text>
          <v-switch
            class="ml-5 ma-2"
            v-model="zoom"
            hide-details
            solo
          >
            <template slot="label">Zoom Mode</template>
          </v-switch>

          <v-switch
            class="ml-5 ma-2"
            v-model="usesharkd"
            hide-details
            solo
          >
            <template slot="label">Use Sharkd</template>
          </v-switch>

          <v-switch
            class="ml-5 ma-2"
            color="info"
            v-model="normalize_to_seconds"
            hide-details
            solo
          >
            <template slot="label">Normalize to seconds</template>
          </v-switch>

          <v-switch
            class="ml-5 ma-2"
            color="info"
            v-model="oneaxis"
            hide-details
            solo
          >
            <template slot="label">One Axis</template>
          </v-switch>

          <!--
          <v-btn v-on:click="showConnections" class="ml-3 mt-5 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">reorder</v-icon>
              </template>
              
              <span>Show Connections</span>
            </v-tooltip>
          </v-btn>-->

          <v-btn
            v-on:click="resetZoom"
            class="ml-2 mt-5 ma-2"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  dark
                  v-on="on"
                >undo</v-icon>
              </template>

              <span>Reset zoom</span>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-on:click="loadColoringRules"
            class="ml-2 mt-5 ma-2"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  dark
                  v-on="on"
                >invert_colors</v-icon>
              </template>

              <span>Load Coloringrules</span>
            </v-tooltip>
          </v-btn>

          <!--<v-btn v-on:click="showpcap" class="ml-10 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">folder</v-icon>
              </template>
              
              <span>Show PCAP</span>
            </v-tooltip>
          </v-btn>-->

          <v-combobox
            class="ml-2 ml-2"
            hide-details
            v-model="interval"
            :items="intervals"
            label="Time interval"
            solo
            item-text="Interval"
          ></v-combobox>

          <v-combobox
            class="ml-2 ml-2"
            hide-details
            v-model="iograph_preset"
            :items="iograph_presets"
            label="IOGraph Presets"
            solo
            item-text="name"
          ></v-combobox>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      class="mt-3"
    >
      <v-card>
        <v-card-title>Plotted graphs ({{numdatapoints}} datapoints)</v-card-title>
        <v-card-text>
          <v-row
            class="py-0 mt-0 pt-0"
            v-for="(filter, index) in iofilters"
            v-bind:key="filter['key']"
          >
            <v-col
              v-if="1 == 2"
              cols="12"
              class
              style="height: 24px"
            >
              <v-subheader class="pl-1 ml-0 pb-3 mb-3">{{
                filterToString(filter["name"])
              }}</v-subheader>
            </v-col>
            <v-col
              cols="2"
              class="py-0 px-1"
            >
              <v-text-field
                solo
                v-model="filter['name']"
                label="Filter Name"
                hide-details
                maxlength="20"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
              class="py-0 px-1"
            >
              <v-combobox
                solo
                hide-details
                v-model="filter['filter']"
                label="Select a filter"
                item-text="Filter"
              ></v-combobox>

              <!--<filterbox v-model="filter['filter']" :livemode="true"></filterbox>-->
            </v-col>
            <v-col
              cols="1"
              class="py-1 mx-0"
            >

              <v-btn
                :color="filter['color']"
                class=""
                @click="selectColor(filter)"
              ></v-btn>

            </v-col>
            <v-col
              cols="1"
              class="py-0 px-1"
            >
              <v-combobox
                solo
                hide-details
                v-model="filter['style']"
                :items="styles"
                label="Style"
                item-text="Style"
              ></v-combobox>
            </v-col>
            <v-col
              cols="1"
              class="py-0 px-1"
            >
              <v-combobox
                solo
                hide-details
                v-model="filter['type']"
                :items="types"
                label="Type"
                item-text="Type"
              ></v-combobox>
            </v-col>
            <v-col
              cols="2"
              class="py-0 px-1"
            >
              <v-combobox
                solo
                hide-details
                v-model="filter['aggregation_field']"
                :items="currentAnalysis.indexedcolumns"
                label="Aggregation field"
                item-text="Aggregation field"
              ></v-combobox>
            </v-col>
            <v-col
              cols="1"
              class="pt-3 px-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="red"
                    v-on:click="removeFilter(filter)"
                    dark
                    v-on="on"
                  >delete</v-icon>
                </template>
                <span>Delete filter</span>
              </v-tooltip>

              <v-tooltip
                bottom
                v-if="index == iofilters.length - 1"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="primary"
                    v-on:click="newFilter"
                    dark
                    v-on="on"
                  >add</v-icon>
                </template>
                <span>Add filter</span>
              </v-tooltip>
            </v-col>
            <v-col cols="11"></v-col>
            <v-col cols="1">
              <v-tooltip
                bottom
                v-if="index == iofilters.length - 1"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on:click="apply"
                    color="green"
                    v-on="on"
                  >
                    Apply
                  </v-btn>
                </template>
                <span>Apply all filters</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      class="mt-3"
      v-if="usesharkd"
    >
      No zooming available in sharkd mode
    </v-col>
    <v-col
      cols="12"
      class="mt-3"
      v-if="!usesharkd"
    >
      Note that filters like "tcp.analysis.flags" or "icmp" do not work in
      indexing mode. Rewrite your filters so they contain indexed fields like
      "ip.proto == 1" (ICMP) or "tcp.analysis.retransmission"
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//import * as am4core from "@amcharts/amcharts4/core";
//import * as am4charts from "@amcharts/amcharts4/charts";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
//am4core.useTheme(am4themes_material);

import ApiPacketlist from "@/common/api/packetlist";

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { IOGRAPH_PRESETS, iographhelper } from "@/common/iograph";
import { navigation } from "@/common/navigation";

export default {
  name: "Iograph",
  props: {
    pcapid: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    },
    graphplot: {
      type: String,
      default: null
    }
  },

  components: {},
  mixins: [displayfilter, navigation, iographhelper],
  data() {
    return {
      color: "#ffffff",
      currentcoloritem: null,
      showcolorpicker: false,
      palette: [
        ["#264653","#2a9d8f","#e9c46a","#f4a261","#e76f51"],
        ["#ef476f","#ffd166","#06d6a0","#118ab2","#073b4c"],
        ["#f94144","#f3722c","#f8961e","#f9c74f","#90be6d","#43aa8b","#577590"],
        ["#ffbc42","#d81159","#8f2d56","#218380","#73d2de"],
        ["#ff99c8","#fcf6bd","#d0f4de","#a9def9","#e4c1f9"]
      ],
      dateAxis: undefined,
      numdatapoints: 0,
      oneaxis: false,
      isautointerval: false,
      numaxes: 0,
      valueaxis: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
      iograph_preset: null,
      iograph_presets: IOGRAPH_PRESETS,
      interval: "1s",
      intervals: [
        "1s",
        "100ms",
        "10ms",
        "1ms",
        "10s",
        "1h",
        "1d",
        "1w",
        "1M",
        "1y"
      ],
      colors: ["#000000", "#ff0000", "#0000ff", "#00ff00", "#FFFF00"],
      styles: ["fill", "line"],
      dataUnit: "bytes",
      aggregations: ["sum", "avg", "min", "max"],
      dataUnitsSharkd: ["bytes", "bits"],
      dataUnits: ["bytes", "bits", "kbit", "Mbit", "Gbit"],
      iofilters: [
        {
          name: "Packets per second",
          key: 0,
          type: "packets",
          filter: "",
          color: "#0000ff",
          style: "fill"
        }
      ],
      defaultfilter: [
        {
          name: "Packets per second",
          key: 0,
          type: "packets",
          filter: "",
          color: "blue",
          style: "fill"
        }
      ],
      rules: {
        required: value => !!value || "Required."
      },
      zoom: false,
      usesharkd: true,
      normalize_to_seconds: true,
      timelinestart: 0,
      timelineend: 0,
      chart: null,
      root: null,
      xAxis: null,
      cursor: null,
      selectStartedX: null,
      selectEndedX: null
    };
  },
  watch: {
    pcapid: function(n, o) {
      this.ipcapid = this.pcapid;
    },
    zoom: function(n, o) {
      this.apply();
    },
    oneaxis: function(n, o) {
      this.apply();
    },
    "currentAnalysis.analysistabs": function(n, o) {
      if (n == 1) {
        this.numaxes = 0;

        this.oneaxis = false;
        this.iofilter = this.defaultfilter;
        this.addCurrentPacketFilter();

        this.loadCustomGraph(this.currentAnalysis.customgraph);
        this.apply();
      }
    },
    "currentAnalysis.customgraph": function(n, o) {
      this.numaxes = 0;
      this.oneaxis = false;
      this.iofilter = this.defaultfilter;
      if (n) {
        this.loadCustomGraph(n);
        this.apply();
      }
    },
    iograph_preset: function(n, o) {
      var newfilters = n.values;
      this.iofilters = newfilters;
      this.apply();
    }
    // "usesharkd": function(n, o){
    //   if(n){
    //     this.chart.cursor.behavior =  null;
    //     this.chart.cursor.events.on("selectended", null);
    //   }else{
    //     this.chart.cursor.behavior = "selectX";
    //     this.chart.cursor.events.on("selectended", this.handleChanged);
    //   }
    // }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },

  mounted() {
    this.iindex = this.index;
    this.ipcapid = this.pcapid;
    this.timelinestart = this.currentAnalysis.timelinestart;
    this.timelineend = this.currentAnalysis.timelineend;
    this.addCurrentPacketFilter();
    this.loadCustomGraph(this.currentAnalysis.customgraph);
    this.apply();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    types: function() {
      if (this.usesharkd) {
        return ["packets"]
          .concat(this.dataUnitsSharkd)
          .concat(this.aggregations);
      }
      return ["packets"].concat(this.dataUnits).concat(this.aggregations);
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {

        selectColor: function (c) {
          this.currentcoloritem = c;
      // this.currentcoloritem = c;
      // this.currentcolorlayer = where;
      // this.color = c[where];
      this.showcolorpicker = !this.showcolorpicker;
      this.color = c["color"]
    },
    confirmColor: function (item) {
      this.showcolorpicker = false;
      

      if(this.color.length == 9){
        this.color = this.color.slice(0,7)
      }
      this.currentcoloritem["color"] = this.color


      // this.currentcoloritem[this.currentcolorlayer] = this.color;
    },
    getTimeUnit4Amcharts() {
      let unit = this.interval;

      let digits = unit.replace(/[^\d]/g, "");
      let runit = unit.replace(/[\d]/g, "");

      switch (runit) {
        case "s":
          return "second";
        case "ms":
          return "millisecond";
        case "m":
          return "minute";
        case "h":
          return "hour";
        case "d":
          return "day";
        case "w":
          return "week";
        case "M":
          return "month";
        case "y":
          return "year";
      }
    },
    initializeChart() {
      if (this.root != null) {
        this.root.dispose();
        this.chart.dispose();
        //return;
      }

      var root = am5.Root.new("chartdiv");
      root.utc = false;
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: !this.zoom,
          panY: !this.zoom,
          wheelX: "panX",
          wheelY: "zoomX"
        })
      );

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

      let timeunit = this.getTimeUnit4Amcharts();

      let dateAxis = am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: timeunit,
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      });

      var xAxis = chart.xAxes.push(dateAxis);

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal"
        })
      );

      if (this.zoom) {
        //dateAxis.zoom({ start: 0, end: 1 });

        var cursor = am5xy.XYCursor.new(root, {
          behavior: "selectX"
        });

        cursor.events.on("selectended", this.handleChanged.bind(this));

        cursor.events.on(
          "selectstarted",
          function() {
            this.selectStartedX = cursor.getPrivate("positionX");
          }.bind(this)
        );

        chart.set("cursor", cursor);
        cursor.lineY.set("visible", false);
      } else {
        var cursor = chart.set(
          "cursor",
          am5xy.XYCursor.new(root, {
            behavior: "none"
          })
        );
        cursor.lineY.set("visible", false);

        // dateAxis.onPrivate("selectionMin", function(value, target) {
        //   
        //   var start = new Date(value);
        //   this.selectStartedX = start;

        // }.bind(this));

        // dateAxis.onPrivate("selectionMax", function(value, target) {
        //   
        //   var end = new Date(value);
        //   this.selectEndedX = end;
        // }.bind(this));

        // chart.events.on("wheelended", this.handleChangedZoom.bind(this))
      }

      this.cursor = cursor;
      this.dateAxis = dateAxis;
      this.root = root;
      this.chart = chart;
      this.xAxis = xAxis;

      return;
      var root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);

      chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX"
        })
      );

      var cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none"
        })
      );
      cursor.lineY.set("visible", false);

      var xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: "day",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal"
        })
      );
    },

    loadCustomGraph(graph) {
      this.oneaxis = false;
      this.numaxes = 0;
      if (Object.keys(graph).length != 0) {
        this.iofilters = graph.values;
        if ("oneaxis" in graph) {
          this.oneaxis = graph.oneaxis;
        } else {
          this.oneaxis = false;
        }
      }
    },
    resetZoom() {
      this.timelinestart = undefined;
      this.timelineend = undefined;
      this.currentAnalysis.timelinestart = undefined;
      this.currentAnalysis.timelineend = undefined;
      this.fetchTimeline();
    },

    plotChart(data, series, sharkdDataArray = null) {
      let color;
      this.removeSeries();

      for (let i = 0; i < series.length; i++) {
        let s = series[i];
        
        color = am5.color(s["color"]);
        //this.chart.colors.push(color);
        this.createAxisAndSeries(
          s["name"],
          s["name"],
          false,
          "circle",
          color,
          sharkdDataArray[i]
        );
      }

      this.chart.appear(1000, 100);
    },

    getToolTipText(name, valueY) {
      try {
        var iofilter = this.getIOFilterByName(name);
        var divider = this.interval;
        var divident = iofilter["type"];
        var normalized = valueY;
        var text = "{name}: [bold]{valueY}";

        var myRegexp = /^([0-9]{0,9})([a-zA-Z]{1,2})/g;
        var match = myRegexp.exec(this.interval);

        if (match[1] == "1") {
          divider = match[2];
        }

        if (this.aggregations.includes(divident)) {
          divident = divident + "(" + iofilter["aggregation_field"] + ")";
        }

        /* if we have throughput, not packets and normalization actiated */
        if (
          !this.isautointerval &&
          this.normalize_to_seconds &&
          this.dataUnits.includes(divident)
        ) {
          switch (this.interval) {
            case "100ms":
              normalized = valueY * 10;
              break;
            case "10ms":
              normalized = valueY * 100;
              break;
            case "1ms":
              normalized = valueY * 1000;
              break;
            case "10s":
              normalized = valueY / 10;
              break;
          }
          normalized = Math.round(normalized * 100) / 100;
          text = name + ": " + normalized + " " + divident + "/s";
        } else {
          if (this.normalize_to_seconds) {
            text = text + " " + divident + "/" + divider;
          } else {
            text = text + " " + divident + "/" + divider;
          }
        }
      } catch (e) {}
      return text;
    },
    removeSeries() {
      while (this.chart.series.length > 0) {
        this.chart.series.removeIndex(0).dispose();
      }

      while (this.chart.yAxes.length) {
        this.chart.yAxes.removeIndex(0).dispose();
      }
    },
    createAxisAndSeries(field, name, opposite, icon, color, data) {
      let chart = this.chart;
      let root = this.root;
      let xAxis = this.xAxis;

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );


      // configuration migrate to v5
      //yAxis.renderer.line.strokeOpacity = 1;
      //yAxis.renderer.line.strokeWidth = 2;

     
      //yAxis.renderer.line.stroke = color;
      //yAxis.renderer.labels.template.fill = color;
      //yAxis.renderer.opposite = opposite;
      //yAxis.renderer.grid.template.disabled = true;
  
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "date",
          stroke: color,
          fill: color,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name}: [bold]{valueY}[/]"
          })
        })
      );

      //series.strokes.template.set("stroke", color);
      var iofilter = this.getIOFilterByName(name);



      // series.fills.template.set("fillPattern", am5.LinePattern.new(root, {
      //   color: color,
      //   strokeWidth: 2,
      //   fill: color
      // }));
      // series.tensionX = 0.8;

      if (iofilter != null) {
        switch (iofilter["style"]) {
          case "fill":
            series.fills.template.setAll({
              fillOpacity: 0.3,
              visible: true
            });

            break;
          case "line":
            break;
          default:
            series.fills.template.setAll({
              fillOpacity: 0.3,
              visible: true,
              fill: color
            });
        }
      }
      // series.adapter.add("tooltipText", (text, target) => {
      //   let valueY = target.tooltipDataItem.valueY;
      //   if (valueY) {
      //     return this.getToolTipText(name, valueY);
      //   } else {
      //     return text;
      //   }
      // });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);

      return;

      let valueAxis;

      if (this.numaxes < 1) {
        valueAxis = this.chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
          })
        );
        this.valueaxis = valueAxis;
      } else {
        if (this.oneaxis) {
          valueAxis = this.valueaxis;
        } else {
          valueAxis = this.chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              renderer: am5xy.AxisRendererY.new(root, {})
            })
          );
        }
      }
      this.numaxes++;

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = this.chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: valueAxis,
          valueYField: field,
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name}: [bold]{valueY}[/]"
          })
        })
      );

      // migrate to v5
      // series.adapter.add("tooltipText", (text, target) => {
      //   let valueY = target.tooltipDataItem.valueY;
      //   if (valueY) {
      //     return this.getToolTipText(name, valueY);
      //   } else {
      //     return text;
      //   }
      // });

      series.data.setAll(data);
      // series.data = data

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);

      // if (this.currentAnalysis.profiles.selected.theme) {
      //   series.stroke = am4core.color("#A0CA92");
      // }
    },

    handleChangedZoom(ev) {
      ;
      let startLocation = this.dateAxis.getPrivate("startLocation");
      if (
        ev.target.min == ev.target.minZoomed &&
        ev.target.max == ev.target.maxZoomed
      )
        return;

      var startmoment = this.moment.utc(new Date(ev.target.minZoomed));
      var endmoment = this.moment.utc(new Date(ev.target.maxZoomed));
    },
    handleChanged(ev) {
      let cursor = this.cursor;
      var startselected = this.selectStartedX;
      var endselected = this.cursor.getPrivate("positionX");
      // var range = ev.target.xRange;
      var axis = ev.target.chart.xAxes.getIndex(0);

      // var startnum = axis.toAxisPosition(start);
      // var endnum = axis.toAxisPosition(end);

      if (!this.zoom) {
        // var c = this.currentAnalysis;
        // var t = c.total;

        // var s = this.moment.utc(c.starttime);
        // var e = this.moment.utc(c.endtime);

        // if (t == 0) {
        //   return;
        // }

        // /* duration in milliseconds */
        // var duration = e - s;

        // let start = this.moment.utc(s + duration * startnum).toISOString();
        // let end = this.moment.utc(s + duration * endnum).toISOString();

        let startd = axis.positionToDate(startselected);
        let endd = axis.positionToDate(endselected);

        this.timelinestart = startd;
        this.timelineend = endd;

        this.fetchTimeline();
      } else {
        //var from = axis.getPositionLabel(startnum);
        //var to = axis.getPositionLabel(endnum);

        // if (startnum == 0 && endnum == 1) {
        //   return;
        // }

        // this.error.type = "Info";
        // this.error.msg =
        //   "Filtered for the selection in the packet list with maximum " +
        //   this.currentAnalysis.profiles.selected.maxPackets +
        //   " packets (you can edit this value in the settings)";
        let startnum = axis.positionToDate(startselected);
        let endnum = axis.positionToDate(endselected);

        this.currentAnalysis.showIOGraph = false;
        this.updateDatesFromSelection(startnum, endnum);
      }
      this.cursor.selection.hide();
    },
    updateDatesFromSelection(start, end) {
      var c = this.currentAnalysis;
      var t = c.total;

      // var s = this.moment.utc(c.pcap.starttime);
      // var e = this.moment.utc(c.pcap.endtime);

      // if (t == 0) {
      //   return;
      // }

      // /* duration in milliseconds */
      // var duration = e - s;

      // // Note: just some boundaries, maybe throw error
      // if (duration > 10000000) {
      //   return;
      // }
      // // check

      // //c.iostart = parseInt(t * start);
      // //c.ioend = parseInt(t * end);

      // c.selectStartDate = this.moment.utc(s + duration * start).toISOString();
      // c.selectEndDate = this.moment.utc(s + duration * end).toISOString();

      c.selectStartDate = start.toISOString()
      c.selectEndDate = end.toISOString()
      
      this.$store.dispatch(UPDATE_ANALYSIS, c);
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;

      this.navigatePacketview(this.ipcapid, this.iindex);
    },
    showpcap() {
      this.navigatePacketview(this.ipcapid, this.iindex);
    },

    filterToString(name) {
      var filter = this.getIOFilterByName(name);

      var out = filter["name"];

      if (out) {
        if (filter["filter"]) {
          out = out + " filtering for " + filter["filter"];
        }

        if (filter["dataunit"]) {
          out =
            out + " showing " + filter["dataunit"] + " per " + this.interval;
        }
      }

      return out;
    },
    handleClicked(ev) {
      //var item = ev.target.dataItem.component.tooltipDataItem.dataContext;
      var filtername = ev.target.dataItem.component.dataFields.valueY;
      //var date = ev.target.dataItem.component.tooltipDataItem.dataContext.date;

      //alert("Clicked on "+filtername+" with value "+item[filtername]+" at date "+date)

      var filter = null;

      for (var f of this.iofilters) {
        if (f["name"] == filtername) {
          filter = f["filter"];
        }
      }

      if (filter != null) {
        this.df_apply_to_packetlist(filter);
        // this.$eventHub.$emit("displayfilter-set", filter);
      }
    },

    convertUnit(input, unit) {
      var tv = input;
      switch (unit) {
        case "bits":
          tv = tv * 8;
          break;
        case "kbit":
          tv = (tv * 8) / 1024;
          break;
        case "Mbit":
          tv = (tv * 8) / 1024 / 1024;
          break;
        case "Gbit":
          tv = (tv * 8) / 1024 / 1024 / 1024;
          break;
        default:
          break;
      }
      return tv;
    },
    calculatedate(startdate, factor) {
      let unit = this.interval;

      let digits = unit.replace(/[^\d]/g, "");
      let runit = unit.replace(/[\d]/g, "");

      let add = factor * digits;

      let parseddate = new Date(startdate + "Z"); // +Z makes it utc

      if (this.timelinestart) {
        parseddate = this.timelinestart;
      }
      let result;

      switch (runit) {
        case "s":
          result = parseddate.setSeconds(parseddate.getSeconds() + add);
          break;
        case "ms":
          result = parseddate.setMilliseconds(
            parseddate.getMilliseconds() + add
          );
          break;
        case "m":
          result = parseddate.setMinutes(parseddate.getMinutes() + add);
          break;
        case "h":
          result = parseddate.setHours(parseddate.getHours() + add);
          break;
        case "d":
          result = parseddate.setDate(parseddate.getDate() + add);
          break;
        case "w":
          result = parseddate.setDate(parseddate.getDate() + add * 7);
          break;
        case "M":
          result = parseddate.setMonth(parseddate.getMonth() + add);
          break;
        case "y":
          result = parseddate.setFullYear(parseddate.getFullYear() + add);
          break;
      }

      return result;
    },
    showSharkdTimeline(data) {
      let outdata = [];
      let i = 0;

      let date = this.currentAnalysis.pcap.starttime;
      //    var s = this.moment.utc(c.startDate);

      for (let series of data["iograph"]) {
        let chartdata = [];
        try {
          let items = series["items"];
          this.numdatapoints = items.length;
          let iofilter = this.iofilters[i++];

          let prepitemsi = 0;

          for (let i = 0; i < items.length; i++) {
            if (typeof items[i] == "string") {
              let next_item = parseInt(items[i], 16);
              while (prepitemsi < next_item) {
                let newelem = {};
                newelem[iofilter["name"]] = 0.0;
                newelem["date"] = this.calculatedate(date, prepitemsi);
                chartdata.push(newelem);

                prepitemsi++;
              }
            } else {
              let newelem = {};
              newelem[iofilter["name"]] = items[i];
              newelem["date"] = this.calculatedate(date, prepitemsi);
              chartdata.push(newelem);
              prepitemsi++;
            }
          }
        } catch (e) {
          if ("errmsg" in series) {
            this.error.type = "error";
            this.error.msg = series["errmsg"];
          }
        }
        outdata.push(chartdata);
      }
      this.plotChart(undefined, this.iofilters, outdata);
    },
    showIndexTimeline(data) {
      let chartdata = [];

      var c = this.currentAnalysis;
      c.startDate = data["histogram"][0]["key_as_string"];
      c.endDate =
        data["histogram"][data["histogram"].length - 1]["key_as_string"];
      this.$store.dispatch(UPDATE_ANALYSIS, c);

      if ("autointerval" in data) {
        this.isautointerval = data["autointerval"];
        this.interval = data["interval"];
      } else {
        this.isautointerval = false;
      }

      for (let elem of data["histogram"]) {
        var moment = this.moment;
        var mydate = moment
          .utc(elem["key_as_string"])
          .tz(moment.tz.guess())
          .toDate();

        var newelem = {
          date: mydate
        };

        for (var f of this.iofilters) {
          if (elem[f["name"]][f["name"] + "_aggs"] != undefined) {
            newelem[f["name"]] = this.convertUnit(
              elem[f["name"]][f["name"] + "_aggs"]["value"],
              f["type"]
            );
          } else {
            newelem[f["name"]] = elem[f["name"]]["doc_count"];
          }
        }

        chartdata.push(newelem);
      }

      this.plotChart(chartdata, this.iofilters);
    },
    fetchTimeline() {
      if (!this.ipcapid) {
        return;
      }

      let params = {
        pcapid: this.ipcapid,
        filters: this.iofilters,
        interval: this.interval,
        type: "index"
      };

      if (this.usesharkd) {
        params.type = "sharkd";
      }

      for (let f of params.filters) {
        if (!f["filter"]) {
          delete f["filter"];
        }
      }

      if (this.iindex && this.iindex != "none") {
        params.index = this.iindex;
      }

      if (this.timelinestart != 0) {
        params.starttime = this.timelinestart;
        delete params.start;
      }

      if (this.timelineend != 0 && this.timelineend) {
        params.endtime = this.timelineend;
        delete params.end;
      }

      ApiPacketlist.iograph(params).then(({ data }) => {
        if (params.type == "index") {
          if (data["autointerval"] == true) {
            this.error.msg =
              "Interval setting of " +
              this.interval +
              " would result in too many data points, doing auto interval of 50 values, resulting in a smoothed curve. Select a larger interval or select a smaller time frame.";
            this.error.type = "Info";
          }

          this.showIndexTimeline(data);
        }
        if (params.type == "sharkd") {
          this.showSharkdTimeline(data);
        }
      });
    },
    getIOFilterByName(name) {
      for (var f of this.iofilters) {
        if (f.name == name) {
          return f;
        }
      }
      return null;
    },
    newFilter() {
      var n = {};
      n["key"] = this.iofilters.length + 1;
      n["name"] = "";
      n["type"] = "packets";
      n["filter"] = "";
      n["color"] = "black";
      n["aggregation_field"] = "";
      n["style"] = "fill";
      this.iofilters.push(n);
    },
    removeFilter(filter) {
      this.iofilters.splice(this.iofilters.indexOf(filter), 1);
    },
    addCurrentPacketFilter() {
      if (this.currentAnalysis.filter) {
        var n = {};
        n["key"] = this.iofilters.length + 1;
        n["name"] = "Current";
        n["type"] = "packets";
        n["filter"] = this.currentAnalysis.filter;
        n["color"] = "#ff0000";
        n["aggregation_field"] = "";
        n["style"] = "fill";
        this.iofilters.push(n);
      }
    },
    apply() {
      this.initializeChart();
      this.fetchTimeline();
    },
    showConnections() {
      this.currentAnalysis.timelinestart = this.timelinestart;
      this.currentAnalysis.timelineend = this.timelineend;
      this.currentAnalysis.showConnections = true;
    },
    loadColoringRules() {
      var rules = this.currentAnalysis.profiles.selected.coloringrules;
      var newfilters = [];
      for (let r of rules) {
        let n = {
          name: r["rulename"],
          key: newfilters.length + 1,
          type: "packets",
          filter: r["rule"],
          color: r["foreground"],
          style: "fill"
        };
        newfilters.push(n);
      }
      this.iofilters = newfilters;
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.iographcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.iograph {
  width: 100%;
  height: 480px;
}

.container {
  height: 100%;
  max-width: 100%;
  overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
