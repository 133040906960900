<template>
  <v-row class="pa-1">
    <v-col cols="12" class="mt-4">
      <v-btn
        class="ma-2"
        v-if="!disabled"
        
        color="primary"
        @click.native="openFileDialog"
      >
        Import
        <v-icon right dark>cloud_upload</v-icon>
      </v-btn>
      <input
        type="file"
        id="file-upload"
        style="display: none"
        @change="onFileChange"
      />
    </v-col>
    <v-col v-if="imported.length > 0">
      <v-card class="mx-auto" max-width="500">
        <v-list subheader>
          <v-list-item v-for="item in imported" :key="item">
            <v-list-item-avatar>
              <v-icon>check</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        You still need to save the profile, before doing any further modifications. Some special display filters may not work (eth[0], ${tcp.stream})
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import JsZip from "jszip";

export default {
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  name: "coloringrules",
  data() {
    return {
      profile: null,
      uploaded: false,
      zipfile: null,
      imported: [],
    };
  },
  created: function () {},
  mounted() {},
  computed: {
    ...mapGetters(["currentAnalysis", "error"]),
  },
  watch: {},
  methods: {
    decimal_to_hex: function (d, padding) {
      var hex = Number(d).toString(16);
      padding =
        typeof padding === "undefined" || padding === null
          ? (padding = 2)
          : padding;

      while (hex.length < padding) {
        hex = "0" + hex;
      }

      return hex;
    },
    ws_color_to_wp_color: function (c) {
      let rin = parseInt(c[0]);
      let gin = parseInt(c[1]);
      let bin = parseInt(c[2]);

      let rout = Math.round(rin / 257);
      let gout = Math.round(gin / 257);
      let bout = Math.round(bin / 257);

      let rhex = this.decimal_to_hex(rout, 2);
      let ghex = this.decimal_to_hex(gout, 2);
      let bhex = this.decimal_to_hex(bout, 2);

      let out = "#" + rhex + ghex + bhex;
      return out;
    },
    convert_rule_to_wp: function (rule) {
      var rsplit = rule.split("@");
      var colors = rsplit[3].split("[");
      var bg = colors[1].split("]")[0].split(",");
      var fg = colors[2].split("]")[0].split(",");

      let fgt = this.ws_color_to_wp_color(fg);
      let bgt = this.ws_color_to_wp_color(bg);

      var n = {};
      n["rulename"] = rsplit[1];
      n["rule"] = rsplit[2];
      n["background"] = bgt;
      n["foreground"] = fgt;
      n["show"] = true;
      return n;
    },
    convert_rules_to_wp: function (rules) {

      let out = []
      var rarray = rules.split(/\n/);
      for (let rule of rarray) {
        if (!rule.startsWith("#") && rule.includes("@")) {
          let t = this.convert_rule_to_wp(rule);

          out.push(t);
        }
      }
      this.imported.push("Coloring rules");
      this.profile.coloringrules = out;
      this.$emit("input", this.profile);
    },
    parseWiresharkButtons(input){
      var rarray = input.split(/\n/);
      let outpref = [];
      
      for (let line of rarray) {
        if (
          !line.startsWith("#") &&
          line.includes(",")) {
          
          let o = line.split(",");
          
          let bname = o[1].trim().replace(/"([^"]+(?="))"/g, '$1')
          let bfilter = o[2].trim().replace(/"([^"]+(?="))"/g, '$1')
          if(o[1].length != ""){
            outpref.push({
              rulename: bname,
              rule: bfilter
            });
          }
        }
      }


      this.profile.filterbuttons = outpref;
      this.imported.push("Wireshark buttons");
      this.$emit("input", this.profile);
    },
    parseWiresharkPreferences(input) {
      var rarray = input.split(/\n/);
      let outpref = [];
      
      for (let line of rarray) {
        if (
          !line.startsWith("#") &&
          !line.startsWith("\t") && 
          !line.startsWith("gui.") &&
          line.includes(":")) {
          
          let o = line.split(":");
          if(o[1].length != ""){
            outpref.push({
              name: o[0].trim(),
              value: o[1].trim(),
            });
          }
        }
      }
      this.profile.wiresharksettings = outpref;
      this.imported.push("Wireshark settings (without colums)");
      this.$emit("input", this.profile);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    onFileChange(e) {
      this.profile = this.value
      this.uploaded = true;
      var files = e.target.files || e.dataTransfer.files;

      var zip = new JsZip();
      if (files.length > 0) {
        zip.loadAsync(files[0] /* = file blob */).then(
          function (zip) {

            for (let [key, file] of Object.entries(zip.files)) {
              if (file.name.endsWith("colorfilters")) {
                zip
                  .file(file.name)
                  .async("string")
                  .then((colorrules) => {
                    this.convert_rules_to_wp(colorrules);
                  });
              }
              if (file.name.endsWith("dfilter_buttons")) {
                zip
                  .file(file.name)
                  .async("string")
                  .then((items) => {
                    this.parseWiresharkButtons(items);
                  });
              }
              if (file.name.endsWith("preferences")) {
                zip
                  .file(file.name)
                  .async("string")
                  .then((items) => {
                    this.parseWiresharkPreferences(items);
                  });
              }
            }
          }.bind(this)
        );
      }

      //   var files = e.target.files || e.dataTransfer.files;

      //   if (files.length > 0) {
      //     var reader = new FileReader();
      //     reader.readAsText(files[0]);
      //     reader.onload = () => {
      //       debugger;
      //       this.zipfile = reader.result;
      //       debugger;

      //       JsZip.loadAsync(this.zipfile)
      //         .then(function (zip) {
      //           debugger;
      //           let output = zip.file("preferences").async("string");
      //         })
      //         .then(function (text) {
      //           console.log(text);
      //         });
      //     };
      //   }
    },
    updateModel: function () {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style >
</style>
