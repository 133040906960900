<template>
  <v-row align="center" justify="center">
    <v-card class="mt-5 pb-2" max-width="900px" width="500px">
      <v-card-title class="headline" primary-title
        >Application Information</v-card-title
      >

      <v-card-text class="py-2">App Info</v-card-text>
      <v-col
        cols="12"
        v-if="
          this.currentAnalysis.sharkdinfo &&
          this.currentAnalysis.sharkdinfo.features
        "
      >
        <v-data-table
          :headers="headers"
          :items="infos"
          :options.sync="tableoptions"
          dense
          hide-default-footer
        >
          <template v-slot:item.value="{ item }">
            <template v-if="item.value === true || item.value === false"
              ><v-checkbox
                class="pa-0 ma-0"
                :ripple="false"
              dense
                v-model="item.value"
                readonly
              ></v-checkbox
            ></template>

            <template v-else>{{ item.value }}</template>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
  </v-row>
</template>

<script>
import ApiTask from "@/common/api/task";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";
import { FRONTEND_VERSION, FRONTEND_BUILD } from "@/common/config";

export default {
  name: "appinfo",
  data() {
    return {
      tableoptions: {
        itemsPerPage: 100,
      },
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false,
        },
        { text: "Value", value: "value", sortable: false },
      ],
    };
  },
  created: function () {},
  mounted() {},
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
    infos() {
      let i = [
        {
          name: "Sharkd Version",
          value: "versionfull" in this.currentAnalysis.sharkdinfo ? this.currentAnalysis.sharkdinfo.versionfull.split(" ")[0] : this.currentAnalysis.sharkdinfo.version ,
        },
        { name: "Frontend Version", value: FRONTEND_VERSION },
        { name: "Frontend Build Number", value: FRONTEND_BUILD },
        {
          name: "Backend Version",
          value: this.currentAnalysis.sharkdinfo.BACKEND_VERSION,
        },
        {
          name: "Backend Build Number",
          value: this.currentAnalysis.sharkdinfo.BACKEND_BUILD_NUMBER,
        },
        {
          name: "Uploads allowed",
          value: this.currentAnalysis.sharkdinfo.features.ENABLE_UPLOAD,
        },
        {
          name: "Anon upload",
          value: this.currentAnalysis.sharkdinfo.features.ENABLE_ANON_UPLOAD,
        },
        {
          name: "Cognito",
          value: this.currentAnalysis.sharkdinfo.features.ENABLE_COGNITO,
        },
        {
          name: "Login allowed",
          value: this.currentAnalysis.sharkdinfo.features.ENABLE_LOGIN,
        },
        {
          name: "Registration allowed",
          value: this.currentAnalysis.sharkdinfo.features.ENABLE_REGISTRATION,
        },
        { name: "Timeline", value: this.currentAnalysis.features.timeline },
        { name: "Iograph", value: this.currentAnalysis.features.iograph },
        { name: "Packetinfo", value: this.currentAnalysis.features.packetinfo },
        { name: "Debugging", value: this.currentAnalysis.features.debugging },
        {
          name: "Streammatching",
          value: this.currentAnalysis.features.streammatching,
        },
        {
          name: "Indexer configuration",
          value: this.currentAnalysis.features.indexerconfiguration,
        },
        {
          name: "Filterhistory",
          value: this.currentAnalysis.features.filterhistory,
        },
        { name: "Iplookup", value: this.currentAnalysis.features.iplookup },
        {
          name: "Enableusers",
          value: this.currentAnalysis.features.enableusers,
        },
        {
          name: "Range selection",
          value: this.currentAnalysis.features.enablerangeselection,
        },
        {
          name: "Indexed packetlist",
          value: this.currentAnalysis.features.enableindexedpacketlist,
        },
      ];

      return i;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style >
</style>
