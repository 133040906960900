<template>
  <v-dialog v-model="currentAnalysis.pcaplist.searchhelpdialog" width="800">
    <v-card>
      <v-card-title class="headline" primary-title >Search help</v-card-title>
      <v-card-text class="pt-2">
        Indexed query search is similar to searching within PCAPs. But there are some sublte differences.
          <v-row no-gutters v-for="(value, key) in helpexamples" v-bind:key="key" >
            <v-col class="px-2 xs-4">{{value.description}}</v-col>
            <v-col class="text-right xs-8">
              <router-link
                :to="{ name: 'PCAPs', params: {type : currentAnalysis.pcaplist.type, query : value.example}}"
              >
                <span @click="currentAnalysis.pcaplist.searchhelpdialog = false">{{value.example}}</span>
              </router-link>
            </v-col>
          </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="currentAnalysis.pcaplist.searchhelpdialog = false"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  name: "helpdialog",
  data() {
    return {
      helpexamples: [
        {
          description: "Search an indexed string in from the info column",
          example: "_ws.col.Info=='*Server*Hello*'"
        },
        {
          description: "Search across all PCAPs for a displayfilter",
          example: "ip.proto != 1"
        },
        {
          description: "Search across all PCAPs for a MAC address",
          example: "eth.src== \"00\\:10\\:a4\\:de\\:b5\\:d0\""
        },

        {
          description: "Search across all PCAPs a DNS name",
          example: "syndication.twitter.com"
        },
        {
          description: "Search across all PCAPs an IPv4 adress name",
          example: "ip.src == 64.233.166.105"
        },
        {
          description: "Search across all PCAPs an IPv6 adress name",
          example: "ipv6.src == \"fe80\\:\\:b2ea\\:bcff\\:fe45\\:b870\""
        },
        {
          description: "Wildcard search on all fields",
          example: "myfavourie*"
        },
        {
          description: "Tags",
          example: "tagname:showcase"
        },
        {
          description: "PCAPNG Capture Comment",
          example: "capturecomment: Sharkfest 2011"
        },
        {
          description: "PCAP IDs",
          example: "_id:dHJhY2UwMS5wY2FwXXXX"
        },
        {
          description: "Original name (uploaded file name)",
          example: "origname: rtp.pcapng"
        },
        {
          description: "SHA1",
          example: "sha1: 10ad22cd96ebb073822e74cc612788d9ebc50c30"
        },
        {
          description: "SHA256",
          example:
            "sha256: cce4523a1e4a8d942865939bf82c22fe13266f53f058976393423d53a11fd90b"
        },
        {
          description: "Over 1000 bits/s",
          example: "databitrate: [1000 TO *]"
        },
        {
          description: "Filesize",
          example: "filesize: [9000000 TO *]"
        },
        {
          description: "Username",
          example: "username: otr"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis"])
  },
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style>
</style>
