<template>
  <v-card flat class="pa-6">
    <v-snackbar v-model="snackbar" top>{{ snackbartext }}</v-snackbar>
    <singleedit @changed="applyedit" v-if="editpcap" :pcap="pcap" :editfield="editfield"></singleedit>
    <v-tabs background-color="transparent" centered v-model="currentAnalysis.detailstab" hide-slider>
      <template v-for="section in detailsections">
        <v-tab :key="section.name">{{ section.name }}<v-icon>info</v-icon></v-checkbox></v-tab>
      </template>

      <v-tab key="comments">Comments <v-icon>comment</v-icon></v-tab>
      <v-tab key="tags">Tags <v-icon>style</v-icon></v-tab>
      <v-tab key="analyses">Analyses <v-icon>pageview</v-icon></v-tab>
      <v-tab key="indices">Indices <v-icon>assessment</v-icon></v-tab>
      <v-tab key="permissions">Permissions <v-icon>lock</v-icon></v-tab>
      <!--<v-tab key="connections">Connections <v-icon>list</v-icon></v-tab>

      <v-tab key="dns">dns <v-icon>dns</v-icon></v-tab>-->

      <template v-for="section in detailsections">
        <v-tab-item transition="none" reverse-transition="none" :key="section.name" class="pt-3 pb-4 pr-5">
          <v-row align="center" justify="center" class="mx-2 mb-3">
            <v-col cols="12" class="mx-4" >
              <v-card class="pa-2">
                <v-card-title>
                  <v-row>
                    <v-col cols="1">
                      <router-link :to="{
                        name: 'User Profile',
                        params: { username: getOwner(pcap.permissions) },
                      }">
                        <v-avatar v-if="pcapowner" size="48" class="mr-4">
                          <img :src="avatarpic4user(pcapowner)" />
                        </v-avatar>
                        <v-avatar v-else size="48" class="mr-4">
                          <img :src="avatarpic4user(pcapowner)" />
                        </v-avatar>
                      </router-link>
                    </v-col>
                    <v-col cols="10">
                      <h3 v-if="getPcapField('description')">Description</h3>
                      <p v-else>No description provided</p>
                    </v-col>
                    <v-col cols="1" v-if="isOwner(currentUser, pcap.permissions)">
                      <v-btn @click="editdescription" class="ml-4 mr-4" icon><v-icon>edit</v-icon></v-btn>
                    </v-col>
                    <v-col v-else cols="1"></v-col>

                  </v-row>

                  <v-col v-if="isOwner(currentUser, pcap.permissions)" cols="12" class="mt-4">

                    <template class="mt-4" v-if="getPcapField('description') != undefined">


                      <p v-if="showMoreDescription || !showMoreDescriptionButton">{{ getPcapField("description") }}
                      </p>
                      <p v-else>{{ shortDescription }}</p>
                      <v-expand-transition>
                        <div v-if="!showMoreDescription && showMoreDescriptionButton">
                          <v-btn @click="showMoreDescription = true" color="primary">More</v-btn>
                        </div>
                      </v-expand-transition>

                    </template>
                    <template v-else>
                      <v-btn class="pl-4" @click="editdescription">Add description <v-icon
                          class="ml-2">edit</v-icon></v-btn>
                    </template>
                  </v-col>

                  <v-col cols="12" class="mt-4" v-else>
                    {{ getPcapField("description") }}
                  </v-col>
                </v-card-title>
                <v-card-subtitle></v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-4 d-flex flex-column">
              <v-card class="pt-0 pb-3 px-2 flex d-flex flex-column">
                <v-list>
                  <div class="ma-2 subtitle-1 font-weight-bold">File properties</div>

                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="origname"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="timestamp"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="filesize"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="filetype"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="fileencapsulation"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="id"></pcapdetailvalue>
                  <v-divider class="mt-4 mb-2"></v-divider>

                  <div class="ma-2 subtitle-1 font-weight-bold">Capture properties</div>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="packetsizelimitmin"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="packetsizelimitmax"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="filetimeprecision"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="stricttimeorder"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="captureapplication"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="capturehardware"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="capturecomment"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="captureoper-sys"></pcapdetailvalue>

                  <v-divider class="mt-4 mb-2"></v-divider>

                  <div class="ma-2 subtitle-1 font-weight-bold">Hashes</div>

                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="sha1"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="ripemd160"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="sha256"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="hastlssecrets"></pcapdetailvalue>

                </v-list>

              </v-card>
            </v-col>
            <v-col class="mr-4 d-flex flex-column ">
              <v-card class="pt-0 pb-3 px-2 flex d-flex flex-column ">

                <v-list>
                  <div class="ma-2 subtitle-1 font-weight-bold">Trace properties</div>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="numberofpackets"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="datasize"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="starttime"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="endtime"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="captureduration"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="databitrate"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="databyterate"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="averagepacketrate"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="hideempty" pcapfield="averagepacketsize"></pcapdetailvalue>

                  <v-divider class="mt-4 mb-2"></v-divider>

                  <div class="ma-2 subtitle-1 font-weight-bold">Origin</div>

                  <pcapdetailvalue :pcap="pcap" :hideempty="false" pcapfield="credits"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="false" pcapfield="license"></pcapdetailvalue>
                  <pcapdetailvalue :pcap="pcap" :hideempty="false" pcapfield="source_url"></pcapdetailvalue>

                  <v-row class="mx-2 " justify="end">
                    <v-checkbox v-model="hideempty" label="Hide empty"></v-checkbox>
                  </v-row>
                </v-list>

              </v-card>
            </v-col>

          </v-row>
          <v-row v-if="false" align="center" justify="center" no-gutters>
            <template v-for="(item, index) in section.items">
              <template v-if="item == 'TLS Secrets'">
                <v-col cols="2" class="pl-5" v-bind:key="`key-${index}`">
                  {{ item }}
                </v-col>

                <v-col v-bind:key="`value-${index}`" cols="4" style="word-break: break-all" class
                  v-if="getPcapField(index)">
                  Yes,

                  <a style="text-decoration: none" href="#" @click.prevent="downloadSecrets">download</a>
                </v-col>
                <v-col v-else cols="4" v-bind:key="`value-${index}`">
                  No
                </v-col>
              </template>

              <template v-else>
                <v-col cols="2" class="pl-5" v-bind:key="`key-${index}`">
                  {{ item }} {{ index }}
                </v-col>
                <v-col v-bind:key="`value-${index}`" cols="4" style="word-break: break-all" class>
                  <router-link style="text-decoration: none" :to="{
                    name: 'PCAPs',
                    params: {
                      type: currentAnalysis.pcaplist.type,
                      query: index + ':' + getPcapFieldQuoted(index),
                    },
                  }">
                    <template v-if="
                      index == 'endtime' ||
                      index == 'starttime' ||
                      index == 'timestamp'
                    ">{{ getPcapField(index) | formatDate }}</template>
                    <template v-else>{{ getPcapField(index) }}</template>
                  </router-link>
                </v-col>
              </template>
            </template>
            <template v-if="Object.keys(section.items).length % 2 == 1">
              <v-col cols="2" class="pl-5">
                <h3></h3>
              </v-col>
              <v-col cols="4"> </v-col>
            </template>
          </v-row>
        </v-tab-item>
      </template>
      <v-tab-item transition="none" reverse-transition="none" key="comments" class="pt-3 pb-4">
        <v-row align="center" justify="center" v-if="currentAnalysis.detailstab == 1">
          <v-col cols="12" v-if="commentitems.length == 0" align="center" justify="center" class="text-h5">No comments
            yet</v-col>
          <template v-else>
            <v-col cols="12" :key="item.id" v-for="item in commentitems" class="pt-10">
              <v-card width="800px" elevation="3" class="pa-md-4 mx-lg-auto">
                <v-row>
                  <v-col cols="2" class="pt-6 text-center">
                    <router-link :to="{
                      name: 'User Profile',
                      params: { username: item.username },
                    }">
                      <v-avatar left size="48">
                        <img center :src="avatarpic4user(item)" />
                      </v-avatar>
                    </router-link>
                    <div class="pt-1">{{ item.username }}</div>
                  </v-col>

                  <v-col cols="10">
                    <v-card-title class="pt-6" primary-title>
                      <v-textarea v-if="item.edit" v-model="item.content"></v-textarea>
                      <p v-else style="word-break: break-all" class="body-1">
                        {{ item.content }}
                      </p>
                    </v-card-title>
                    <v-card-actions right>
                      <v-spacer></v-spacer>

                      <v-icon v-if="
                        !item.edit && item.username == currentUser.username
                      " v-on:click="toggleedit(item)">edit</v-icon>
                      <v-icon v-if="
                        item.edit && item.username == currentUser.username
                      " v-on:click="toggleedit(item)">save</v-icon>
                      <v-icon v-if="item.username == currentUser.username"
                        v-on:click="deletecomment(item)">delete</v-icon>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </template>
          <template v-if="currentUser">
            <v-col cols="12">
              <v-card width="800px" elevation="0" class="pa-md-4 mx-lg-auto">
                <v-textarea v-model="newcomment" label rows="1" placeholder="Write a new comment..." solo></v-textarea>

                <v-btn text color="primary" type="submit" v-on:click="submitComment()">Submit Comment</v-btn>
              </v-card>
            </v-col>
          </template>
          <v-col cols="2"></v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item transition="none" reverse-transition="none" key="tags" class="pt-3 pb-4 px-10">
        <v-row class="grid-list-md" align="center" justify="center">
          <v-col cols="12" class="text-center py-3">
            <template v-if="currentUser">
              With tags one can organize your PCAPs and later search for them.
              Before you can use tags here you need to
              <router-link :to="{ name: 'Tags' }">create the tag first.</router-link></template>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <tags v-if="currentAnalysis.detailstab == 2" :objectid="pcap.id" :key="pcap.id" label="Tags..." type="pcap"
              :isowner="isOwner(currentUser, pcap.permissions)"></tags>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item transition="none" reverse-transition="none" key="analyses" class="pt-3 pb-4">
        <v-row class="grid-list-md" align="center" justify="center">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <analysis v-if="currentAnalysis.detailstab == 3" :analysis="analysisrecipies" :pcapid="pcap.id"
              :key="pcap.id"></analysis>

            <v-btn v-if="canEdit(currentUser, pcap.permissions)" :to="{
              name: 'Analysis',
              params: { id: pcap.id, index: 'default', analysisid: 'new' },
            }">Add a new analysis</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item transition="none" reverse-transition="none" key="indices" class="pt-3 pb-4">
        <v-row class="grid-list-md" align="center" justify="center">
          <v-col cols="2"></v-col>
          <v-col cols="8" class="px-3">
            <indiceslist v-if="currentAnalysis.detailstab == 4" :pcap="pcap" :indices="pcap.indices"
              :isowner="canEdit(currentUser, pcap.permissions)" :key="pcap.id"></indiceslist>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item transition="none" reverse-transition="none" key="permisssions" class="pt-3 pb-4 px-10">
        <newpermissions v-if="currentAnalysis.detailstab == 5" v-model="pcap.permissions" :key="pcap.id">
        </newpermissions>
        <v-btn @click="updatepcap(pcap)">Save</v-btn>
      </v-tab-item>
      <!--
      <v-tab-item
        transition="none"
        reverse-transition="none"
        key="connections"
        class="pt-3 pb-4"
      >
        <v-row class="grid-list-md" align="center" justify="center">
          <v-col cols="0"></v-col>
          <v-col cols="12" class="px-3">
            <connections
              v-if="currentAnalysis.detailstab == 6"
              :pcapid="pcap.id"
              index="none"
              :pcap="pcap"
              :key="pcap.id"
            ></connections>
          </v-col>
          <v-col cols="0"></v-col>
        </v-row>
      </v-tab-item>
      
      <v-tab-item
        transition="none"
        reverse-transition="none"
        key="dns"
        class="pt-3 pb-4"
      >
        <v-row class="grid-list-md" align="center" justify="center">
          <v-col cols="0"></v-col>
          <v-col cols="12" class="px-3">
            <dns v-if="currentAnalysis.detailstab == 6" :pcap="pcap" :key="pcap.id"></dns>
          </v-col>
          <v-col cols="0"></v-col>
        </v-row>
      </v-tab-item>-->
    </v-tabs>
  </v-card>
</template>

<script>
import Connections from "./Connections";
import Dns from "./Dns";
import Analysis from "./Analysis";
import Indiceslist from "./Indiceslist";
import Newpermissions from "./Newpermissions";
import Tags from "./settings/Tags";
import moment from "moment-timezone";
import ApiPcapmeta from "@/common/api/pcapmeta";
import ApiComment from "@/common/api/comment";
import ApiAnalysisrecipe from "@/common/api/analysisrecipe";
import { commonmethods } from "@/common/common";
import { permissionchecks } from "@/common/permissions";
import { mapGetters } from "vuex";
import ApiPacketlist from "@/common/api/packetlist";
import Hexview from "./Hexview.vue";
import Pcapdetailvalue from "./Pcapdetailvalue";
import ApiUser from "@/common/api/user";
import Singleedit from "./dialogs/Singleedit";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    pcap: null,
  },
  components: {
    Dns,
    Connections,
    Analysis,
    Indiceslist,
    Tags,
    Newpermissions,
    Singleedit,
    Hexview,
    Pcapdetailvalue,
  },
  mixins: [commonmethods, permissionchecks],
  name: "pcapdetails",
  data() {
    return {
      showMoreDescription: false,
      editpcap: false,
      editfield: null,
      pcapowner: null,
      hideempty: true,
      people: [],
      loading: false,
      searchuser: null,
      snackbar: false,
      snackbartext: "",
      tags: "tags",
      analysisrecipies: [],
      comments: "comments",
      readpermissions: [],
      writepermissions: [],
      newcomment: "",
      commentitems: [],
      detailsections: [
        {
          name: "Basic Information",
          items: {
            origname: "Original name",
            //"filename" : "Name on disk (admin only)",
            filesize: "File size",
            filetype: "File type",
            fileencapsulation: "File encapsulation",
            timestamp: "Uploaded",
            ap: "Analysis package",
            numberofpackets: "Number of packets",
            datasize: "Total bytes",
            starttime: "Start time",
            endtime: "End time",
            captureduration: "Capture duration in s",
            databitrate: "Data bit rate / s",
            databyterate: "Date byte rate / s",
            averagepacketrate: "Average packet rate / s ",
            averagepacketsize: "Average packet size",
            filetimeprecision: "File time precision",
            packetsizelimit: "Packet size limit",
            packetsizelimitmax: "Packet size limit max",
            packetsizelimitmin: "Packet size limit min",
            captureapplication: "Capture application",
            capturecomment: "Capture comment",
            capturehardware: "Capture hardware",
            "captureoper-sys": "Capture operating system",
            stricttimeorder: "Strict time order",
            importid: "Google Drive import ID",
            ripemd160: "RIP160",
            sha1: "SHA1",
            sha256: "SHA256",
            id: "ID",
            credits: "Credits",
            source_url: "Source URL",
            license: "License",
            hastlssecrets: "TLS Secrets",
          },
        },
      ],
    };
  },
  mounted() {
    this.getcomments();
    this.getanalysisrecipies();
    this.loaduser();
  },
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic"]),
    shortDescription: function () {
      return this.getPcapField("description").substring(0, 200) + "...";
    },
    showMoreDescriptionButton: function () {
      return this.getPcapField("description").length > 200
    }
  },
  methods: {
    updatepcap(p) {
      ApiPcapmeta.update(p).then(({ data }) => {
        this.snackbartext = "PCAP updated";
        this.snackbar = true;
      });
    },
    remove(item) {
      this.pcap.tags.splice(this.pcap.tags.indexOf(item), 1);
      this.pcap.tags = [...this.pcap.tags];
    },
    getPcapField(field) {
      //don't format time for now
      if (false) {
        if (field == "timestamp" || field == "starttime" || field == "endtime")
          return moment
            .utc(this.pcap[field])
            .tz(moment.tz.guess())
            .format("DD.MM.YYYY HH:mm");
      }
      return this.pcap[field];
    },
    getPcapFieldQuoted(field) {
      return '"' + this.getPcapField(field) + '"';
    },
    submitComment() {
      ApiComment.create(this.newcomment, this.pcap.id).then(({ data }) => {

        this.getcomments();
        this.newcomment = "";
        this.snackbartext = "Comment added";
        this.snackbar = true;
      });
    },
    editcommentsubmit(comment) {
      ApiComment.update(comment, this.pcap.id, comment.id).then(({ data }) => {
        this.snackbartext = "Comment updated";
        this.snackbar = true;
      });
    },
    toggleedit(item) {
      if (item.edit == true) {
        this.$set(item, "edit", false);
        this.editcommentsubmit(item);
      } else {
        this.$set(item, "edit", true);
      }
    },
    loaduser: function () {
      let owner = this.getOwner(this.pcap.permissions);
      ApiUser.getprofile(owner)
        .then(({ data }) => {
          this.pcapowner = data;
        })
        .catch(({ response }) => { });
    },
    editdescription(pcapid) {
      this.editfield = { id: "description", name: "Description" }
      this.editpcap = true
    },
    applyedit(p) {
      this.updatepcap(p)
      this.editpcap = false
    },
    downloadSecrets(token) {
      let params = {
        token: "ssl-secrets",
        pcapid: this.pcap.id,
      };

      ApiPacketlist.exportobject(params)
        .then(({ data }) => {
          this.stats = data["taps"][0];
        })
        .catch(() => { });
    },
    deletecomment(item) {
      ApiComment.delete(item.id).then(({ data }) => {
        this.snackbartext = "Comment deleted";
        this.snackbar = true;
        this.commentitems.splice(this.commentitems.indexOf(item), 1);
      });
    },
    getcomments() {
      ApiComment.list(this.pcap.id).then(({ data }) => {
        if (data.length > 0) {
          this.commentitems.splice(0, this.commentitems.length, ...data);
        }
      });
    },
    editcomment() { },
    getanalysisrecipies() {
      ApiAnalysisrecipe.list({ type: "pcap", pcapid: this.pcap.id }).then(
        ({ data }) => {
          this.analysisrecipies = data;
        }
      );
    },
  },
};
</script>

<style >
/*
.v-text-field.v-text-field--solo .v-input__control {
    min-height: 25px;
    padding: 0;
    height: 25px;
}
*/

.v-messages {
  height: 0px;
  min-height: 0px;
}
</style>