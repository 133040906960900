<template>
  <v-chip
    @click="goto && gotoTag(tag)"
    v-on="listeners"
    :input-value="selected"
    :close="close"
    text-color="white"
    :color="tag.color"
  >
    <v-avatar left v-if="!('objectids' in tag) || tag.objectids.length == 0">
       <img v-if='tag.tagpic != null && tag.tagpic != ""' :src="avatarpic4tag(tag)">
      <v-icon v-else>label</v-icon>
    </v-avatar>
    <v-avatar left v-else class="black">
      {{ tag.objectids.length }}
    </v-avatar>

    {{ tag.name }}

    <v-avatar right class="ml-2 mr-1"  v-if="!(!('objectids' in tag) || tag.objectids.length == 0)">
      <!--<v-icon>label</v-icon>-->
       <img v-if='tag.tagpic != null && tag.tagpic != ""' :src="avatarpic4tag(tag)">
      <v-icon v-else>label</v-icon>
    </v-avatar>

  </v-chip>
</template>

<script>
import { commonmethods } from "@/common/common";

export default {
  props: {
    tag: null,
    selected: null,
    goto: {
      type: Boolean,
      default: true
    },
    close: null,
  },
  inheritAttrs: false,
  components: {},
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  name: "tag",
  mixins: [commonmethods],
  methods: {
    gotoTag: function (i) {
      this.$router
        .push({
          name: "PCAPs",
          params: {
            type: "search",
            query: "tagname: " + i.name,
          },
        })
        .catch((err) => {});
    },
  },
};
</script>

<style >
</style>