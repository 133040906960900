<template>




      <v-card>
        <v-card-title class="headline">The round trip time of the TCP handshake is {{rtt}}</v-card-title>

        <v-card-text v-if="rtt">
          Based on the SYN, SYN/ACK, ACK sequence   
        </v-card-text>
            <v-col cols="11">
                <div class="hschart" ref="hsdiv"></div>
            </v-col>
            </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

</template>

<script>
import moment from "moment-timezone";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import oui from "oui";

import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_animated);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { navigation } from "@/common/navigation";

export default {
  name: "Handshakegraph",
  props: {
    connection: null,
    pcapid: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    }
  },

  components: {

  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      iconn: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
      rtt: null
    };
  },
  watch: {
    "connection": function(n, o){
        this.iconn = this.getIndexedconnectionFromAggregationFormat(n);
    },
    "iconn": function(n, o){
        //this.plotChart(client, server, location)
    }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.iindex = this.index;
    this.ipcapid = this.pcapid
    this.iconn = this.getIndexedconnectionFromAggregationFormat(this.connection);
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  beforeDestroy() {
      
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    getMacType(mac){
        let vendor = oui(mac);

        let device = {
            "Cisco Systems Inc." : "router",
            "VMware, Inc." : "endpoint",
            "Riverbed Technology, Inc." : "router",
            "REALTEK SEMICONDUCTOR CORP." : "endpoint",
            "Apple, Inc." : "endpoint"
        }
        let type = "unknown"

        try {
            type = device[vendor]
        } catch(e) {
            type = "unknown"
        }
        if (type === undefined){
            type = "unknown"
        }
        return type
    },
    getCpLocation(rtt_syn_synack, rtt_synack_ack, client, server){
        let rttratio = 1;
        let loc = {
            rttratio: 1,
            rtt: "unknown",
            ttl: "unknown",
            routed: true,
            maclocation1: "middle"
        }
        let ttl1 = client.ttl
        let ttl2 = server.ttl

        loc.rttratio = rtt_syn_synack / rtt_synack_ack

        if(rttratio > 2){
            loc.rtt = "client"
        } else if (rttratio < 0.16){
            loc.rtt = "server"
        } else {
            loc.rtt = "middle"
        }

        if(ttl1 == 64 || ttl1 == 128){

            loc.ttl = "on_or_close_to_client"

            if(ttl2 == 64 ||ttl2 == 128){
                loc.routed = false
            }
        } else {

            if( ttl2 == 64 || ttl2 == 128){
                loc.ttl = "on_or_close_to_server"
            } else {
                ttl1 = ttl1 % 64
                ttl2 = ttl2 % 64

                if(ttl1 > ttl2){
                    loc.ttl = "closer_to_client"
                } else if( ttl1 < ttl2){
                    loc.ttl = "closer_to_server"
                } else {
                    loc.ttl = "middle"
                }
            }
        }

        let maclocationtbl = {
            "endpoint" : {
                "router" : "client",
                "endpoint" : "middle",
                "unknown": "unknown" // Note not sure
            },
            "router" : {
                "router" : "unknown",
                "endpoint" : "unknown",
                "unknown": "unknown" // Note not sure
            },
            "unknown" : { // Note not sure
                "endpoint": "middle",
                "router": "middle",
                "unknown": "unknown"
            }
        }

        loc.maclocation1 = maclocationtbl[client.devicetype][server.devicetype]

        return loc
    },
    getIndexedconnection(src, dst, proto, srcport, dstport){
        
        let filter = this.df_conv_filter(proto, src, srcport, dst, dstport)
        
        let options = {
            index : "none",
            pcapid: this.ipcapid,
            filter: filter,
            start: 0,
            end : 10,
            streamsforfilter: false,
            columns: [
                "frame#time_relative",
                "ip#src",
                "ip#dst",
                "eth#src",
                "eth#dst",
                "ip#ttl",
                "tcp#dstport",
                "tcp#srcport"
            ]
        }

        options["profileid"] =  this.currentAnalysis.profiles.selected.id;
    

      ApiPacketlist.packetlist(options)
        .then(({ data }) => {
            
            let packit = { data : []}

            for(let d of data.result.packets){
                packit["data"].push(d.c)
            }
            
            let syn = packit.data[0]
            let synack = packit.data[1]
            let ack = packit.data[2]


            //0 = "frame#time_relative"
            let rtt_syn_synack =  synack[0] - syn[0]
            let rtt_synack_ack =  ack[0] - synack[0]


            let client = {
                //   "frame#time_relative", 0
                // "ip#src", 1
                // "ip#dst", 2
                // "eth#src", 3
                // "eth#dst", 4 
                // "ip#ttl", 5
                // "tcp#dstport", 6
                // "tcp#srcport" 7
                src : syn[1],
                srcport: syn[7],
                devicetype: this.getMacType(syn[3]),
                ttl: syn[5],
                rtt: rtt_syn_synack,
                mac: syn[3]
            }

            let server = {
                dst: syn[2],
                dstport: syn[6],
                devicetype: this.getMacType(syn[4]),
                ttl: synack[5],
                rtt: rtt_synack_ack,
                mac: syn[4]
            }
            
    
            let location = this.getCpLocation(rtt_syn_synack, rtt_synack_ack, client, server)
            
            this.plotChart(client, server, location)
        })
        .catch(error => {
          var err = error.response.data["error"];
        });
    },
    getIndexedconnectionFromAggregationFormat(connection){
        let src, dst, proto, srcport, dstport;

        src= connection.src.ip;
        dst= connection.dst.ip;
        srcport = connection.src.port;
        dstport = connection.dst.port;
        proto = connection.type;
        return this.getIndexedconnection(src, dst, proto, srcport, dstport);
    },
    plotChart(client, server, location){

        // Icons
        var workstation = "M0 30l4-8h24l4 8zM4 2v18h24V2H4zm22 16H6V4h20v14z";
        var serverpic = "M6 0v30h2v2h4v-2h8v2h4v-2h2V0H6zm6 6H8V4h4v2z";
        var printer = "M28 26h2V16h-4V2H6v14H2v10h2l-2 4h28l-2-4zM8 4h16v12H8V4zm0 20h16l2 4H6l2-4z";
        //var router = "M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2M12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20M13 13V16H15L12 19L9 16H11V13M5 13H8V15L11 12L8 9V11H5M11 11V8H9L12 5L15 8H13V11M19 11H16V9L13 12L16 15V13H19"
        var router = "M26 20v-8a5 5 0 1 0-2 0v8l-2 1-11-11a5 5 0 0 0-4-8 5 5 0 0 0-1 10v8a5 5 0 1 0 2 0v-8l2-1 11 11-1 3a5 5 0 1 0 6-5z"
        var analyzer = "M 136.88045,347.36218 L 160.88045,347.36218 C 160.88045,347.36218 164.66365,299.17449 206.73183,298.5108 C 193.16146,319.74868 205.88045,347.36218 205.88045,347.36218 L 230.88045,347.36218"

        let clientmac = ""
        let servermac = ""
        let router1mac = ""
        let router2mac = ""

        let routerimg = "https://api.iconify.design/mdi:router.svg"
        let serverimg = "https://api.iconify.design/mdi:server.svg"
        let clientimg = "https://api.iconify.design/mdi:laptop-account.svg"
        let analyzerimg = "https://api.iconify.design/arcticons:audio-spectrum-analyzer.svg"
        let capturepointimg = "https://api.iconify.design/uil:capture.svg"

        let data = []

        let locationtable = {
            "client" : { // first level based on mac location
                 "on_or_close_to_client": {
                    "min": 15,
                    "max": 20
                 },
                 "closer_to_client": {
                    "min": 30,
                    "max": 70
                 },
                 "middle": {
                     "min" : 30,
                     "max" : 70,
                 }
            },
            "middle" : {
                 "on_or_close_to_client": {
                    "min": 15,
                    "max": 20
                 },
                 "closer_to_client": {
                    "min": 30,
                    "max": 70
                 },
                "middle" : {
                    "min": 30,
                    "max": 70
                },
                 "closer_to_server": {
                    "min": 30,
                    "max": 70
                 },
                "on_or_close_to_server": {
                    "min": 80,
                    "max": 85
                },
            },
            "server" : {
                 "on_or_close_to_server": {
                    "min": 80,
                    "max": 85
                 },
                 "closer_to_server": {
                    "min": 30,
                    "max": 70
                 },
                 "middle": {
                     "min" : 30,
                     "max" : 70,
                 }
            },
            "unknown" : {
                 "on_or_close_to_client": {
                    "min": 15,
                    "max": 20
                 },
                 "closer_to_client": {
                    "min": 30,
                    "max": 70
                 },
                 "middle": {
                     "min" : 30,
                     "max" : 70,
                 },
                 "on_or_close_to_server": {
                    "min": 80,
                    "max": 85
                 },
                 "closer_to_server": {
                    "min": 30,
                    "max": 70
                 },      
            }
        }

        let ranges = locationtable[location.maclocation1][location.ttl]

        let diff = ranges.max- ranges.min
        //Note better naming server rtt is synack to ack
        let temp = diff / (server.rtt + client.rtt)
        let fromleft = temp * server.rtt + ranges.min

        let directlyonendpointthresh = 0.01

        if(server.rtt < directlyonendpointthresh && (client.ttl == 64 && client.ttl == 128)){
            fromleft = 15
        }
        
        if(client.rtt < directlyonendpointthresh  && (server.ttl == 64 && server.ttl == 128)){
            fromleft = 85
        }

        if(fromleft <30){ // clientside
            clientmac = client.mac
            if(server.devicetype == "router"){
                router1mac = server.mac
            }
            if(server.devicetype == "endpoint"){
                servermac = server.mac
            }
        } else if (fromleft > 70){ // serverside
            servermac = server.mac
            if(client.devicetype == "router"){
                router2mac = client.mac
            }
            if(server.devicetype == "endpoint"){
                clientmac = client.mac
            }
        } else {
            router1mac = client.mac
            router2mac = server.mac
        }
        
    
        // Create chart instance
        var chart = am4core.create("hschart", am4charts.XYChart);
        
        /*client.rtt -> 0.4

        syn 0 -> 0.2
        synack 0.2 -> 0.4
        ack 0.4
*/
        this.rtt = parseFloat((server.rtt + client.rtt).toFixed(9))
        if (server.rtt > client.rtt){
            let tmp = client.rtt
            client.rtt = server.rtt
            server.rtt = tmp
        }

        // Add dat
        chart.data = [
            {
                "synX": 0,
                "synY": 0,
                "angle": 0,
                "width": 0,
                "height": 0
            },
            {
                "synX": 100,
                "synY": server.rtt / 2 + client.rtt / 2,
                "angle": 100,
                "width": 10,
                "height": 25,
            },
            {
                "synackX": 100,
                "synackY": server.rtt / 2 +  client.rtt / 2,
                "angle": 110,
            },
            {
                "synackX": 0,
                "synackY": server.rtt / 2 + client.rtt,
                "angle": 260,
                "width": 10,
                "height": 25,
            },
            {
                "ackX": 0,
                "ackY": client.rtt + server.rtt/2,
                "angle": 110,
            },
            {
                "ackX": 100,
                "ackY": client.rtt+server.rtt + client.rtt/2,
                "angle": 100,
                "width": 10,
                "height": 25,
            }
        ];
        // dont overlay the axis on the bullets
        chart.maskBullets = false
        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();

        // Create axes
        var valueXAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueXAxis.max = 100
        valueXAxis.numberFormatter = new am4core.NumberFormatter();
        valueXAxis.numberFormatter.numberFormat = ""; 


        // Create value axis
        var valueYAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueYAxis.renderer.inversed = true;
        valueYAxis.title.text = "Relative Time since SYN (s)";
        valueYAxis.title.fontWeight = "bold";
        //valueYAxis.logarithmic = true;

        // Create series
        var synseries = chart.series.push(new am4charts.LineSeries());
        synseries.dataFields.valueY = "synY";
        synseries.dataFields.valueX = "synX";
        synseries.name = "SYN";
        synseries.strokeWidth = 4;
        synseries.strokeOpacity = 0.6;

        var synackseries = chart.series.push(new am4charts.LineSeries());
        synackseries.dataFields.valueY = "synackY";
        synackseries.dataFields.valueX = "synackX";
        synackseries.name = "SYN / ACK";
        synackseries.strokeWidth = 4;
        synackseries.strokeOpacity = 0.6;

        var ackseries = chart.series.push(new am4charts.LineSeries());
        ackseries.dataFields.valueY = "ackY";
        ackseries.dataFields.valueX = "ackX";
        ackseries.name = "ACK";
        ackseries.strokeWidth = 4;
        ackseries.strokeOpacity = 0.6;

        // let synlabel = synseries.createChild(am4core.Label);
        // synlabel.strokeOpacity = 0;
        // synlabel.fontSize = 20;
        // synlabel.text="SYN Latency "+(client.rtt/2).toFixed(9)
        // synlabel.fill = ackseries.stroke;
        // synlabel.fillOpacity = 1;
        // //label.padding(-10, 0, 5, 0);
        // synlabel.dx = 300;
        // synlabel.align = "center";

        // let synacklabel = synackseries.createChild(am4core.Label);
        // synacklabel.strokeOpacity = 0;
        // synacklabel.fontSize = 20;
        // synacklabel.text="SYN/ACK Latency "+(client.rtt/2).toFixed(9)
        // synacklabel.fill = synackseries.stroke;
        // synacklabel.fillOpacity = 1;
        // //label.padding(-10, 0, 5, 0);
        // synacklabel.dx = 300;
        // synacklabel.dy = 140
        // synacklabel.align = "center";

        // let acklabel = ackseries.createChild(am4core.Label);
        // acklabel.strokeOpacity = 0;
        // acklabel.fontSize = 20;
        // acklabel.text="ACK Latency "+(client.rtt/2).toFixed(9)
        // acklabel.fill = ackseries.stroke;
        // acklabel.fillOpacity = 1;
        // //label.padding(-10, 0, 5, 0);
        // acklabel.dx = 300;
        // acklabel.dy = 270
        // acklabel.align = "center";



        function createTriangle(series){
            // Add a bullet
            var bullet = series.bullets.push(new am4charts.Bullet());

            // Bind `rotation` property to `angle` field in data
            bullet.propertyFields.rotation = "angle";
            // Add a triangle to act as am arrow
            var arrow = bullet.createChild(am4core.Triangle);
            arrow.horizontalCenter = "middle";
            arrow.verticalCenter = "middle";
            arrow.stroke = am4core.color("#fff");
            arrow.direction = "top";
            // make arrow invisble via properities
            arrow.propertyFields.width = "width";
            arrow.propertyFields.height = "height";
        }

        createTriangle(synseries)
        createTriangle(synackseries)
        createTriangle(ackseries)

        
        this.chart = chart
        return


        // let arrowimg = "https://image.flaticon.com/icons/png/512/17/17944.png"


        // let synsend = {
        //         x: am4core.percent(15),
        //         y: am4core.percent(0),
        //         fixed: true,
        //         //image: clientimg,
        //         color: am4core.color("#"),
        //         "name": "Client\n"+client.src+":"+client.srcport+"\n"+clientmac,
        //         "path": workstation,
        //         "value": 1,
        //         id: "client",
        //         "children": [
                    
        //             {
        //                 "id": "n2",
        //                 "name": "Router\n"+router1mac,
        //                 "value": 1,
        //                 "path": router,
        //                 //"scale": 1.5,
        //                 "image": arrowimg,
        //                 x: am4core.percent(85),
        //                 y: am4core.percent(10),
        //                 fixed: true,
        //             }
        //         ],
        //         //scale: 1.5
        // }

        // data.push(synsend)

 
        // series.nodes.template.outerCircle.adapter.add("disabled", function(disabled, target) {
        //     return true
        //     try{
        //         return target.dataItem.name == "Capture Point" || target.dataItem.name == "Analyzer"
        //     } catch(e){
        //         return false
        //     }
        // });

        // series.nodes.template.circle.adapter.add("disabled", function(disabled, target) {
        //     return true
        //     try{
        //         return target.dataItem.name == "Analyzer"
        //     } catch(e){
        //         return false
        //     }
        // });

        // // Set up data fields
        // series.dataFields.value = "value";
        // series.dataFields.name = "name";
        // series.dataFields.id = "id";
        // series.dataFields.children = "children";
        // series.dataFields.linkWith = "link";
        // series.dataFields.fixed = "fixed";
        // series.dataFields.color = "color";

        // series.nodes.template.propertyFields.scale = "scale";
        // series.nodes.template.propertyFields.x = "x";
        // series.nodes.template.propertyFields.y = "y";


        // // Add labels
        // series.nodes.template.label.text = "{name}";
        // series.nodes.template.label.valign = "bottom";
        // series.nodes.template.label.fill = am4core.color("#000");
        // //series.nodes.template.label.dy = 30;
        // series.nodes.template.label.adapter.add("dy", function(disabled, target) {

        //     try{
        //         if(target.dataItem.name == "Capture Point"){
        //             return -130
        //         }
        //     } catch(e){
        //         return 20
        //     }
        //     return 20
        // });

        // series.nodes.template.tooltipText = "{name}";
        // series.fontSize = 14;
        // series.fontFamily = '"Avenir", Helvetica, Arial, sans-serif;'
        // series.minRadius = 30;
        // series.maxRadius = 30;

        // // Configure circles
        // series.nodes.template.circle.fill = am4core.color("#fff");
        // series.nodes.template.circle.fill = am4core.color("#fff");

        // var icon = series.nodes.template.createChild(am4core.Image);
        // icon.propertyFields.href = "image";
        // icon.propertyFields.width = "size"
        // icon.propertyFields.height = "size"
        // icon.horizontalCenter = "middle";
        // icon.verticalCenter = "middle";
        // icon.width = 60;
        // icon.height = 60;

        // // // Configure icons
        // // var icon = series.nodes.template.createChild(am4core.Sprite);
        // // icon.propertyFields.path = "path";
        // // icon.horizontalCenter = "middle";
        // // icon.verticalCenter = "middle";

        // series.centerStrength = 0.4;
        // series.links.template.strokeWidth = 5;
        // series.links.template.strokeOpacity = 0.3;

        
        // series.data = data
        // this.chart = chart
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.hschartcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.hschart {
  width: 100%;
  height: 480px;
}

.container {
  height: 100%;
  max-width: 100%;
    overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
