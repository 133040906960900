<template>

  <div>

    <v-tabs-items v-model="currentAnalysis.analysistabs">

      <v-dialog v-model="currentAnalysis.showFollow" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="grey darken-3">
            <v-btn icon dark @click="currentAnalysis.showFollow = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Follow Stream</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="currentAnalysis.showFollow = false">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <follow v-if="currentAnalysis.showFollow"></follow>
        </v-card>
      </v-dialog>

      <v-dialog v-model="currentAnalysis.showTapstatistics" fullscreen hide-overlay
        transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="grey darken-3">
            <v-btn icon dark @click="currentAnalysis.showTapstatistics = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Connections</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="currentAnalysis.showTapstatistics = false">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <tapstatistics v-if="currentAnalysis.showTapstatistics" :pcapid="currentAnalysis.pcapid"
            :tap="currentAnalysis.currentTap" :key="currentAnalysis.pcapid + currentAnalysis.currentTap">
          </tapstatistics>
        </v-card>
      </v-dialog>

      <v-dialog v-model="currentAnalysis.showConnections" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="grey darken-3">
            <v-btn icon dark @click="currentAnalysis.showConnections = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Connections</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="currentAnalysis.showConnections = false">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <connections v-if="currentAnalysis.showConnections"></connections>
        </v-card>
      </v-dialog>

      <v-dialog v-if="analysismessage.show" hide-overlay v-model="analysismessage.show" persistent width="400px">
        <v-card>
          <v-img height="250" src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
          <v-card-title>{{ analysismessage.title }}</v-card-title>

          <v-card-text>{{ this.analysismessage.msg }}</v-card-text>

          <v-card-actions>
            <v-btn text @click="analysismessage.show = !analysismessage.show">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <iographdialog v-if="currentAnalysis.showIOGraph"></iographdialog>

      <v-tab-item :transition="false" :reverse-transition="false">

        <v-row :class="style"
          v-if="renderComponent && currentAnalysis.authorized && 'pcap' in currentAnalysis && currentAnalysis.pcap != null && ('indices' in currentAnalysis.pcap)"
          no-gutters>
          <v-col cols="12" v-if="
            1 == 2 &&
            currentAnalysis.profiles.selected.showTimeline &&
            style == 'horizontal'
          " class="pane timeline">
            <timeline :id="id" :indexid="index"></timeline>
          </v-col>

          <v-col cols="12" v-if="style == 'vertical'">
            <multipane>
              <div class="pane packetlist">
                <packetlistinfinite :id="id" :indexid="index" :key="id + index"></packetlistinfinite>

              </div>

              <multipane-resizer></multipane-resizer>
              <div class="pane" :style="{ flexGrow: 1 }">
                <decode :id="id"></decode>
              </div>
            </multipane>
          </v-col>

          <multipane v-else-if="style == 'horizontal'" class="horizontal-panes" layout="horizontal">
            <div class="pane" :style="styleForTimeline()" v-if="currentAnalysis.profiles.selected.showTimeline">
              <timeline :id="id" :indexid="index"></timeline>
            </div>
            <div class="pane" :style="styleForUpperPane()">
              <packetlistinfinite :id="id" :indexid="index" :key="id + index"></packetlistinfinite>

            </div>
            <template v-if="
              currentAnalysis.profiles.selected.showhexview ||
              currentAnalysis.profiles.selected.showdecode ||
              currentAnalysis.profiles.selected.showrfcview
            ">
              <multipane-resizer></multipane-resizer>

              <multipane layout="vertical" class="vertical-panes">
                <div v-if="currentAnalysis.profiles.selected.showdecode" class="pane" :style="styleForDecode()">
                  <decode :id="id" :key="id + index"></decode>
                </div>
                <multipane-resizer id="inner-vertical"></multipane-resizer>
                <div v-if="currentAnalysis.profiles.selected.showhexview" class="pane" :style="styleForHexview()">
                  <hexview :id="id" :key="id + index"></hexview>
                </div>
                <div v-if="currentAnalysis.profiles.selected.showrfcview" class="pane" :style="styleForRfcview()">
                  <rfcview :id="id"></rfcview>
                </div>
              </multipane>
            </template>
          </multipane>

        </v-row>

        <v-row class="mt-14" v-else>
          <error :error="currentAnalysis.error"></error>
        </v-row>
      </v-tab-item>


      <v-tab-item :transition="false" :reverse-transition="false">
        <connections :pcapid="currentAnalysis.pcapid" index="currentAnalysis.indexid" :pcap="currentAnalysis.pcap"
          :key="currentAnalysis.pcapid"></connections>
      </v-tab-item>


      <v-tab-item :transition="false" :reverse-transition="false">
        <dns :pcap="currentAnalysis.pcap" :key="currentAnalysis.pcapid"></dns>
      </v-tab-item>

      <v-tab-item :transition="false" :reverse-transition="false">
        <iographv5 :pcapid="currentAnalysis.pcapid" :index="currentAnalysis.indexid" :graphplot="graphplot"
          :key="currentAnalysis.pcapid"></iographv5>
      </v-tab-item>

      <v-tab-item :transition="false" :reverse-transition="false">
        <tapstatistics :pcapid="currentAnalysis.pcapid" :tap="currentAnalysis.currentTap"
          :key="currentAnalysis.pcapid + currentAnalysis.currentTap"></tapstatistics>
      </v-tab-item>
    </v-tabs-items>

  </div>

</template>

<script>
import ApiTask from "@/common/api/task";
import ApiProfile from "@/common/api/profile";
import Packetlistinfinite from "./Packetlistinfinite";
import Timeline from "./Timeline";
import Tapstatistics from "./Tapstatistics";
import Iographdialog from "./Iographdialog";
import Decode from "./Decode";
import Hexview from "./Hexview";
import Rfcview from "./Rfcview";
import Error from "./Error";
import Connections from "./Connections";
import Follow from "./Follow";
import { Multipane, MultipaneResizer } from "vue-multipane";
import Iographv5 from "./Iographv5";
import Iograph from "./Iograph";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { navigation } from "@/common/navigation";
import Dns from "./Dns";

export default {
  metaInfo() {
    if (this.currentAnalysis && this.currentAnalysis.pcap != null) {
      let title = this.currentAnalysis.pcap.origname;
      let description = "";
      if ("description" in this.currentAnalysis.pcap) {
        title =
          this.currentAnalysis.pcap.origname +
          " | " +
          this.currentAnalysis.pcap.description;

        description = this.currentAnalysis.pcap.description;
      }

      return {
        title: title,
        description: description,
        meta: [
          {
            property: "og:image",
            content:
              "https://images.unsplash.com/photo-1620715967716-cba6debe2e90?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
          },
          { property: "twitter:card", content: "summary_large_image" },
          {
            property: "twitter:image",
            content:
              "https://images.unsplash.com/photo-1620715967716-cba6debe2e90?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
          }
        ]
      };
    }
  },
  components: {
    Iographv5,
    Iograph,
    Dns,
    Tapstatistics,
    Multipane,
    MultipaneResizer,
    Packetlistinfinite,
    Decode,
    Hexview,
    Rfcview,
    Error,
    Iographdialog,
    Connections,
    Follow,
    Timeline
  },
  name: "analyze",
  props: {
    view: {
      type: String,
      default: "l"
    },
    id: {
      type: String,
      default: ""
    },
    index: {
      type: String,
      default: "none"
    },
    analysisid: {
      type: String,
      default: "none"
    },
    filter: {
      type: String,
      default: ""
    },
    graphplot: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      renderComponent: true,
      currentid: "",
      style: "horizontal",
      disablePointerEvents: false,
      statusToProgress: {
        "runningcapinfos": 0,
        "checkingtlssecrets": 20,
        "indexing": 40,
        "finished": 100,
        "processing": 50, // unused
        "runningeditcap": 90, // not during upload
      }
    };
  },
  watch: {
    /* rerender when profile changed in navigation bar*/
    "currentAnalysis.pcap": async function (n) {


      if (
        this.currentAnalysis &&
        "pcap" in this.currentAnalysis &&
        this.currentAnalysis.pcap != null &&
        !("indices" in this.currentAnalysis.pcap)
      ) {
        console.log("Starting pcap status polling: " + n)
        await this.taskPolling(this.id)
      } else {
        console.log("Not starting polling: " + n)
      }

    },
    "currentAnalysis.forcererender": function (n) {
      if (n) {
        this.forceRerender();
      }
    },
    "currentAnalysis.profiles.selected": function (n, o) {
      if (n) {
        //this.forceRerender();
      }
    },
    "currentAnalysis.showFollow": function (n, o) {
      this.disablePointerEvents = n;
    },
    "currentAnalysis.showTapstatistics": function (n, o) {
      this.disablePointerEvents = n;
    },
    "currentAnalysis.showConnections": function (n, o) {
      this.disablePointerEvents = n;
    },
    "currentAnalysis.showIOGraph": function (n, o) {
      this.disablePointerEvents = n;
    },
    // filter: function(n) {
    //   this.$eventHub.$emit("displayfilter-set", n);
    //   this.$eventHub.$emit("displayfilter", n);
    // }
    index: function (n) {
      this.currentAnalysis.index = this.index;
    }
  },
  mixins: [navigation],
  async mounted() {
    this.loading = true;
    if (
      this.currentAnalysis.profiles.selected &&
      this.currentAnalysis.profiles.selected.switchPanes
    ) {
      this.switchPanes();
    }
    var c = this.currentAnalysis;
    c.authorized = true;


    this.$store.dispatch(UPDATE_ANALYSIS, c);

    if (
      this.currentAnalysis &&
      "pcap" in this.currentAnalysis &&
      this.currentAnalysis.pcap != null &&
      !("indices" in this.currentAnalysis.pcap)
    ) {
      console.log("Starting pcap status polling: " + this.currentAnalysis.pcap)
      await this.taskPolling(this.id)
    } else {
      console.log("Not starting polling: " + this.currentAnalysis.pcap)
    }

    switch (this.view) {
      case "l":
        this.navigatePacketview(
          this.id,
          this.index,
          this.filter,
          this.analysisid
        );
        //this.$eventHub.$emit("active-pcap", this.id);
        break;
      case "i":
        this.navigateIOGraph(this.id, this.index, this.filter);
        break;
      case "c":
        this.navigateConnectionview(this.id, this.index, this.filter);
        break;
      case "d":
        this.navigateDnsView(this.id, this.index, this.filter);
        break;
      case "t":
        this.navigateTap(this.id, this.filter);
    }
    if (this.analysisid != null && this.analysisid != 'none') {
      this.$eventHub.$emit("next-step", null);
    }
  },
  beforeDestroy() {
    this.$eventHub.$off("active-pcap");
  },
  computed: {
    ...mapGetters(["currentAnalysis", "analysismessage", "error"])
  },
  methods: {
    loadProfilesAndForceRerender() {
      let args = {
        type: "private"
      };

      if (this.currentAnalysis.profiles.selected) {
        args["selected"] = this.currentAnalysis.profiles.selected.id;
      }

      ApiProfile.list(args)
        .then(({ data }) => {
          this.currentAnalysis.profiles.available = data.profiles;
          this.assureAnalysisprofile();
          this.forceRerender();
        })
        .catch(() => { });
    },
    forceRerender() {
      // Remove my-component from the DOM

      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
        this.currentAnalysis.forcererender = false;
      });
    },
    styleForTimeline() {
      return {
        height: "25%",
        maxHeight: "10%",
        minHeight: "120px"
      };
    },
    styleForUpperPane() {
      let hexview = this.currentAnalysis.profiles.selected.showhexview;
      let rfcview = this.currentAnalysis.profiles.selected.showrfcview;
      let decode = this.currentAnalysis.profiles.selected.showdecode;

      if (hexview || decode || rfcview) {
        return {
          height: "60%",
          maxHeight: "80%",
          minHeight: "100px"
        };
      } else {
        return {
          height: "100%",
          minHeight: "100px"
        };
      }
    },
    styleForLowerPane() {
      let hexview = this.currentAnalysis.profiles.selected.showhexview;
      let rfcview = this.currentAnalysis.profiles.selected.showrfcview;
      let decode = this.currentAnalysis.profiles.selected.showdecode;

      if ((hexview || rfcview) && decode) {
        return {};
      }
    },
    styleForDecode() {
      let hexview = this.currentAnalysis.profiles.selected.showhexview;
      let rfcview = this.currentAnalysis.profiles.selected.showrfcview;
      let decode = this.currentAnalysis.profiles.selected.showdecode;

      if ((hexview || rfcview) && decode) {
        return {
          minHeight: "100%",
          minWidth: "30%",
          width: "50%"
        };
      }

      if (decode && !(hexview || rfcview)) {
        return {
          flexGrow: 1
        };
      }
    },
    styleForHexview() {
      let hexview = this.currentAnalysis.profiles.selected.showhexview;
      let decode = this.currentAnalysis.profiles.selected.showdecode;

      if (hexview && decode) {
        return {
          flexGrow: 1,
          minWidth: "30%"
        };
      }
    },
    styleForRfcview() {
      let rfcview = this.currentAnalysis.profiles.selected.showrfcview;
      let decode = this.currentAnalysis.profiles.selected.showdecode;

      if (rfcview && decode) {
        return {
          flexGrow: 1,
          minWidth: "30%"
        };
      }
    },
    switchPanes() {
      if (this.style == "horizontal") {
        this.style = "vertical";
      } else {
        this.style = "horizontal";
      }
    }
  }
};
</script>

<style>
/* we overwrite vuetify defaults here 
 *
 * this causes the analyze view to not have a scrollbar
 * the other views to have scrollbars */
html {
  overflow-y: auto !important;
}

/* Grid layout */

/*
.theme--light.application {
  background: #ffffff !important;
}*/

.horizontal {
  height: calc(100vh - 48px);
}

.horizontal-nonav {
  height: 100vh;
}

.vertical {
  height: 100%;
}

.packetlist {
  width: 60%;
  min-width: 10%;
  max-width: 95%;
  height: 100%;
}

/* .multipane {
  height: 100%;
} */

.layout-v>.multipane-resizer {
  margin: 0;
  top: 0;
  width: 3px;
  left: 0px !important;
  height: 100%;
  background: lightgray;
}

.layout-h .multipane-resizer {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0px !important;
  height: 5px;
  background: lightgray;
}

#inner-vertical {
  margin: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: lightgray;
}

.multipane.layout-v {
  display: flex;
  min-height: 50px;
  width: 100%;
  flex-grow: 1;
}

.horizontal-panes {
  width: 100%;
  height: 100%;
  /*height: 100vh;*/
  /* needs to be here to show scrollbar in packetlist */
}

/*
.horizontal-panes > .pane {
}
.horizontal-panes > .pane ~ .pane {
}*/

.horizontal-panes .ag-theme-balham .ag-root {
  border-right: 0px solid #bdc3c7;
  height: 100%;
}

/*.vertical-panes {**/
/* necessary for equal height of two panes, avoid setting height 100vh or xxxpx not using and avoiding
   * height 100% issue
   */
/*display: flex; */
/*}*/
/*.vertical-panes > .pane {*/
/* necessary for resizing of ag grid */
/* overflow: hidden; */
/*}*/
/*
.vertical-panes > .pane ~ .pane {
}*/

.vertical-panes .ag-theme-balham .ag-root {
  border-bottom: 0px solid #bdc3c7;
}
</style>
