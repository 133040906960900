import { sharkdhelper } from "@/common/sharkdhelper"

export const expertinfo = {
    mixins: [sharkdhelper],
    data() {
        return {
            aggridhandler: {
                "expert": {
                    name: "Expert Information",
                    getColumnDefs: this.getColumnDefsExpert,
                    getContextMenuItems: this.getContextMenuItemsExpert,
                    formatData: this.formatDataExpert
                }
            }
        }
    },
    methods: {
        getColumnDefsExpert(stats, groupTable=true) {

            var h = [
                { headerName: "Type", field: "s", rowGroup: true, hide: false },
                { headerName: "Protocol", field: "p",rowGroup: true},
                { headerName: "Group", field: "g",rowGroup: true},
                { headerName: "Message", field: "m", rowGroup: true},
                { headerName: "Packet", field: "f"}
            ];

            return h;
        },
        formatDataExpert(stats) {

            return stats.details;
        }
    }
}