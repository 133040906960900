<template>
  <div>
    <v-autocomplete
      v-model="permissions"
      chips
      solo
      :items="people"
      :search-input.sync="searchuser"
      :filter="v => v"
      cache-items
      item-value="id"
      :label="label"
      multiple
      :disabled="!isowner"
    >
      <!--@keyup.enter="submitpermissions"-->
      <template slot="selection" slot-scope="data">
        <v-chip
          :selected="data.selected"
          :close="isowner"
          @input="remove(data.item)"
          class="chip--select-multi"
        >
          <v-avatar left>
            <img :src="avatarpic4user(data.item)">
          </v-avatar>
          {{ data.item.firstname }} {{data.item.lastname}}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <img :src="avatarpic4user(data.item)">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{data.item.firstname}} {{data.item.lastname}}</v-list-item-title>
            <v-list-item-subtitle v-text="data.item.username"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

import ApiUser from "@/common/api/user";

import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";

export default {
  props: ["value", "label", "isowner"],
  mounted() {
    if (this.value) {
      for (let p of this.value) {
        this.addUserToPermissions(p["username"]);
      }
    }
  },
  mixins: [commonmethods],
  components: {},
  name: "permissionsautocomplete",
  data() {
    return {
      people: [],
      permissions: [],
      searchuser: null
    };
  },
  computed: {
    ...mapGetters(["currentUser", "avatarPic"])
  },
  watch: {
    permissions(val) {
      this.updateModel();
    },
    searchuser(val) {
      val && this.queryPeople(val);
    }
  },
  methods: {
    addUserToPermissions(username) {
      ApiUser.search(username).then(({ data }) => {
        if (data.length == 1) {
          this.people.push(data[0]);
          this.permissions.push(data[0]["id"]);
        }
      });
    },
    queryPeople(v) {
      ApiUser.search(v).then(({ data }) => {
        this.people = data.filter(
          e => e["username"] !== this.currentUser.username
        );
      });
    },
    updateModel() {
      let newarr = [];

      for (let p of this.permissions) {
        let n = { username: p };
        newarr.push(n);
      }
      this.$emit("input", newarr);
    },
    remove(item) {
      const index = this.permissions.indexOf(item);
      this.permissions.splice(index, 1);
      this.updateModel();
    }
  }
};
</script>

<style>
</style>