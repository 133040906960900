import ApiService from "@/common/api.service";

const ApiPcaplist = {

    get(o){
        return ApiService.post("ajax/pcaplist", o)
    },
    deepsearch(term){
        return ApiService.query("ajax/pcaps/deepsearch/"+term)
    }
}

export default ApiPcaplist