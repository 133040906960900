import { sharkdhelper } from "@/common/sharkdhelper"

export const rtp = {
    mixins: [sharkdhelper],
    data() {
        return {
            aggridhandler: {
                "rtp-streams": {
                    name: "RTP",
                    getColumnDefs: this.getColumnDefsRTP,
                    getContextMenuItems: this.getContextMenuItemsRTP,
                    formatData: this.formatDataRTP
                }
            }
        }
    },
    methods: {
        getColumnDefsRTP(stats, groupTable=true) {
            
            var h = [
                { headerName: "Type", field: "s", rowGroup: true, hide: false },
                { headerName: "Protocol", field: "p",rowGroup: true},
                { headerName: "Group", field: "g",rowGroup: true},
                { headerName: "Message", field: "m", rowGroup: true},
                { headerName: "Packet", field: "f"}
            ];

            return h;
        },
        formatDataRTP(stats) {
            
            return stats.details;
        }
    }
}