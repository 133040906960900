<template>
  <div>
    <v-autocomplete
      v-model="permissions"
      chips
      solo
      :items="roles"
      :search-input.sync="searchrole"
      :filter="v => v"
      cache-items
      item-value="id"
      :label="label"
      multiple
      :disabled="!isowner"
    >
      <!--@keyup.enter="submitpermissions"-->
      <template slot="selection" slot-scope="data">
        <v-chip
          :selected="data.selected"
          :close="isowner"
          @input="remove(data.item)"
          class="chip--select-multi"
        >
          <v-avatar left>
            <img :src="avatarpic4role(data.item)">
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <img :src="avatarpic4role(data.item)">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{data.item.name}}</v-list-item-title>
            <v-list-item-subtitle v-text="data.item.description"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ApiRoles from "@/common/api/roles";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";

export default {
  props: ["value", "label", "isowner"],
  mounted() {
    if (this.value) {
      for (let p of this.value) {
        this.addRoleToPermissions(p);
      }
    }
  },
  mixins: [commonmethods],
  components: {},
  name: "rolepermissionsautocomplete",
  data() {
    return {
      roles: [],
      permissions: [],
      searchrole: null
    };
  },
  computed: {
    ...mapGetters(["currentUser", "avatarPic"])
  },
  watch: {
    permissions(val) {
      this.updateModel();
    },
    searchrole(val) {
      val && this.queryRoles(val);
    }
  },
  methods: {
    addRoleToPermissions(id) {
      if (!id) return;
      ApiRoles.get(id)
      .then(({ data }) => {
        if (data["id"] !== "admins") {
          this.roles.push(data);
          this.permissions.push(data["id"]);
        }
      })
      .catch(({ response }) => {});
    },
    queryRoles(v) {
      if (!v) return;
      ApiRoles.list(v).then(({ data }) => {
        if (data && data.length > 0) {
          this.roles = data;
        }
      });
    },
    updateModel() {
      let newarr = [];

      for (let p of this.permissions) {
        newarr.push(p);
      }
      this.$emit("input", newarr);
    },
    remove(item) {
      const index = this.permissions.indexOf(item);
      this.permissions.splice(index, 1);
      this.updateModel();
    }
  }
};
</script>

<style>
</style>