<template>
  <div id="fieldtreeview" style="width: 100%;overflow: hidden;">
    <ag-grid-vue style="width: 100%; height: 400px" class="ag-theme-balham" :gridOptions="treeOptions"></ag-grid-vue>
  </div>
</template>



<script>
import { AgGridVue } from "ag-grid-vue";
import ApiDisplayfilter from "@/common/api/displayfilter";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { AGGRID_LICENSE } from "@/common/config";
LicenseManager.setLicenseKey(
  AGGRID_LICENSE
);


export default {
  name: "fieldtreeview",
  props: {
    fields: null
  },
  components: {
    AgGridVue
  },
  data() {
    return {
                
      treeDefs: [

      ],
      treeData: [],
      fielddef: [],
      treeOptions: {
        context: {
          parent: this
        },

        treeData: true,
        defaultColDef: {
          sortable: true,
          comparator: this.fieldsorting,
        },
        suppressRowHoverHighlight: true,
        onRowClicked: params => {
          if (params.node.allChildrenCount > 0) {
            var select = true;
            var clearSelection = true;
            if (params.event.ctrlKey) {
              select = !params.node.isSelected();
              clearSelection = false;
            }
            params.node.setSelected(select, clearSelection);
          }
        },
        onGridReady: function() {
          
          this.treeOptions.api.sizeColumnsToFit();
          this.treeOptions.api.setColumnDefs(this.treeDefs);
          //this.treeOptions.api.setHeaderHeight(0);
          this.loadTreeData(this.fields);
        }.bind(this),
        getDataPath: function(data) {
          var currentrow = data.id.split(".");
          return currentrow; // path: "frame/number"
        },

        getRowNodeId: function(data) {
          return data.id;
        }
      }
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
  //   getFieldCellRenderer: function() {
  //     function FileCellRenderer() {}

  //     FileCellRenderer.prototype.init = function(params) {
  //       if (params.node.data) {
  //         this.eGui = params.node.data.description;
  //       } else {
  //         this.eGui = params.value;
  //       }
  //     };
  //     FileCellRenderer.prototype.getGui = function() {
  //       return this.eGui;
  //     };

  //     return FileCellRenderer;
    // },
    populateTree: function() {
      var rowData = this.fieldsToRowData(this.fields);
      this.treeOptions.api.setRowData(rowData);
      this.treeOptions.api.sizeColumnsToFit();
    },
    addfieldstofielddef(fields) {
      var found = false;
      for (let item of fields) {
        for (let cache of this.fielddef) {
          if (item["field"] == cache["field"]) {
            found = true;
          }
        }
        if (!found) {
          this.fielddef.push(item);
        }
        found = false;
      }
    },
    loadTreeData() {
      var f = this.fields;
      if (!f || f.length == 0) {
        return;
      }
      ApiDisplayfilter.getmultifields({fields: f})
        .then(({ data }) => {
          this.addfieldstofielddef(data);
          this.populateTree(this.fields);
        })
        .catch(() => {
          this.error.type = "error";
          this.error.msg = "Could not lookup multi thsark fields for profile";
        });
    },
    lookupdescription(fieldname) {
      for (let i of this.fielddef) {
        if (i["field"] == fieldname) return i["description"];
      }

      return "no description";
    },
    fieldsorting(sub1, sub22, node1, node2){
      
      if(! ("data" in node1)){
        return 0
      }
      if(! ("data" in node2)){
        return 0
      }
      let order = ["_ws", "frame", "eth", "arp", "ip", "ipv6", "icmp", "icmpv6", "udp", "tcp"]

      let field1 = node1.data.id
      let field2 = node2.data.id

      let o1= 1000
      let o2= 1000

       
      for(let i=0; i<order.length; i++){
        if(field1.startsWith(order[i]+".")){
          o1 = i
        }
      }

      for(let i=0; i<order.length; i++){
        if(field2.startsWith(order[i]+".")){
          o2 = i
        }
      }


      if(o1 < o1){
        return -1
      }

      if(o1 == o2){
        return 0
      }

      return 1

    },
    fieldsToRowData: function(fields) {
      var rowdata = [];

      for (let f of fields) {
        let n = {
          id: f.split("#").join("."),
          orig: f,
          description: f.split("#").join(".") + " (" + this.lookupdescription(f) + ")"
        };
        rowdata.push(n);
      }
      return rowdata;
    }
  }
};
</script>


<style>
#decode .ag-theme-balham .ag-cell {
  padding: 0px 0px 0px 5px !important;
}

/*
#decode .ag-row-odd:not(.ag-row-selected) {
  background-color: #fff !important;
}*/

/*
.ag-row-selected {
  background-color: #1a5bc9 !important;
		color: white !important;
}*/

#decode .ag-root {
  border: 0px solid #bdc3c7 !important;
}

#decode .ag-row {
  border-bottom: 0px solid #bdc3c7 !important;
}

#decode .ag-header {
  border-bottom: 0px solid #bdc3c7;
}

.application.theme--light {
  background: #ffffff;
}
</style>