import ApiService from "@/common/api.service";

const ApiTags = {

    list(params){
        return ApiService.post("ajax/tags/list", params)
    },
    update(changeset){
        return ApiService.put("ajax/tags", changeset)
    }
}

export default ApiTags