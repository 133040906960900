<template>
  <v-row   align="center" justify="center">

    <v-card class="pb-2" max-width="900px">
      <v-card-title class="headline" primary-title>Tasks</v-card-title>
      <v-combobox
        class="pa-3"
        v-model="type"
        :items="types"
      ></v-combobox>

        <v-card-text class="py-2">Currently Running Tasks
        <v-icon
          color="info"
          small
          v-on:click="getTasks()"
        >refresh</v-icon>
      </v-card-text>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="tasks" >
          <template slot="item" slot-scope="props">
            <tr>
              <td>
                {{props.item["pcapid"]}}
              </td>
              <td>{{props.item['indexer']}}</td>
              <td>{{props.item['status']}}</td>
              <td v-if="'error' in props.item">{{props.item["error"]}}</td>
              <td v-else></td>
              <td>{{props.item['initiated_by']}}</td>
              <td>
                <v-icon
                  v-if="isOwner(currentUser, props.item.permissions)"
                  color="info"
                  small
                  v-on:click="deleteTask(props.item)"
                >delete</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
  </v-row>
</template>

<script>
import ApiTask from "@/common/api/task";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  name: "tasklist",
  data() {
    return {
      tasks: [],
      types: ["failed", "running", "finished", "all"],
      type: "failed",
      adddialog: false,
      headers: [
        {
          text: "PCAP ID",
          align: "left",
          value: "current",
          sortable: false
        },
        { text: "Indexer", value: "indexer", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Error", value: "error", sortable: false },
        { text: "Initiated by", value: "initiated_by", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  created: function() {},
  mounted() {
    this.getTasks();
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"])
  },
  watch: {},
  methods: {
    getTasks: function() {
      ApiTask.list(this.type)
        .then(({ data }) => {
          this.tasks = data;
        })
        .catch(({ response }) => {});
    },
    deleteTask: function(task) {
      ApiTask.delete(task["id"])
        .then(({ data }) => {
          this.tasks.splice(this.tasks.indexOf(task), 1);
        })
        .catch(({ response }) => {});
    }
  }
};
</script>

<style >
</style>
