import ApiService from "@/common/api.service";

const ApiDisplayfilter = {
    complete(req){
        return ApiService.post("/ajax/pcap/completefilter", req)
    },
    possiblevalues(index, pcapid, field){
        return ApiService.query("ajax/pcap/possiblevalues/" + index + "/" + pcapid + "/" + field)
    },
    getmultifields(fields){
        return ApiService.post("ajax/tshark/getmultifields", fields)
    },
    explainfield(params){
        return ApiService.post("ajax/tshark/fieldexplain", params)
    },
    getfields(prefix){
        return ApiService.get("ajax/tshark/getfields", prefix) 
    },
    verify(filter){
        return ApiService.get("ajax/displayfilter/verify", filter)
    },
    displayfiltertocoloringrule(filter){
        return ApiService.get(
            "ajax/displayfilter/tocoloringrule",
            filter
          )
    }

}

export default ApiDisplayfilter