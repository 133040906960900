<template>
  <v-card v-if="packet!=null && packet.info" >

    <v-card-title v-if="illustration.explicitinfo" class=" headline" primary-title>{{illustration.explicitinfo}}</v-card-title>
    <v-card-title v-else class="headline white darken-2" primary-title >{{packet.info}}</v-card-title>
    <v-card-text class="title">
      
     <v-card color="#385F73" dark class="mb-3 mt-3">
        <v-card-subtitle class="body-1 font-weight-light"><vue-markdown>{{illustration.description}}</vue-markdown></v-card-subtitle>
      </v-card>


      <div v-for="(item, index) in illustration.interesting" :key="index">
        <v-card color="#952175" dark class="mb-3">
          <v-card-title class="headline">{{item.name}} <v-chip class="ma-2" color="secondary">{{item.rawfilter}}</v-chip></v-card-title>

          <v-card-subtitle class="body-1 font-weight-light ma-1">{{item.explaination}}</v-card-subtitle>

        </v-card>
      </div>

     <v-card color="#1F7087" dark class="mb-3">
        <v-card-subtitle class="body-1 font-weight-light">{{illustration.layerbytes}}</v-card-subtitle>
      </v-card>

    </v-card-text>

  </v-card>
</template>

<script>

import VueMarkdown from 'vue-markdown'


const ftypes = [
  "FT_NONE","FT_PROTOCOL","FT_BOOLEAN","FT_CHAR","FT_UINT8",
  "FT_UINT16","FT_UINT24","FT_UINT32","FT_UINT40","FT_UINT48",
  "FT_UINT56","FT_UINT64","FT_INT8","FT_INT16","FT_INT24",
  "FT_INT32","FT_INT40","FT_INT48","FT_INT56","FT_INT64",
  "FT_IEEE_11073_SFLOAT","FT_IEEE_11073_FLOAT","FT_FLOAT",
  "FT_DOUBLE","FT_ABSOLUTE_TIME","FT_RELATIVE_TIME","FT_STRING",
  "FT_STRINGZ","FT_UINT_STRING","FT_ETHER","FT_BYTES","FT_UINT_BYTES",
  "FT_IPv4","FT_IPv6","FT_IPXNET","FT_FRAMENUM","FT_PCRE","FT_GUID",
  "FT_OID","FT_EUI64","FT_AX25","FT_VINES","FT_REL_OID","FT_SYSTEM_ID",
  "FT_STRINGZPAD","FT_FCWWN"]

export default {
  name: "fieldillustration",
  props: ["value"],
  components: {VueMarkdown},
  data() {
   
    return {
      packet : null,
      fieldftypes : {
        "ip.src" : 32,
        "ip.dst" : 32,
        "eth.dst": 29,
        "eth.src": 29,
        "eth.addr": 29,
      },
      ftypesenum : {},
      illustration: {
        explicitinfo: "Client Hello",
        layer: "tls",
        layerbytes: "",
        description : `
The session begins with the client saying "Hello". The client provides the following:

- protocol version
- client random data (used later in the handshake)
- an optional session id to resume
- a list of cipher suites
- a list of compression methods
- a list of extensions
`,
        interesting : [],
        explainations : [
          {
            name: "TLS Record Version",
            explaination: "TLS sessions are broken into the sending and receiving of records, which are blocks of data with a type, a protocol version, and a length.",
            field: "tls.record.version",
            filter : "(data[\"tls.record.version\"] == 771)",
            rawfilter: 'tls.record.version == 771',
            commonvalues : [
              {
                "hex" : "0x0303",
                "description": "TLS1.2"
              }
            ]
          },
          {
            name : "Content type",
            explaination: "Content Type",
            field: "tls.record.content_type",
            filter : "(data[\"tls.record.content_type\"] == 21)",
            rawfilter: "tls.record.content_type == 21",
            commonvalues : [
              {
                "hex" : "0x16",
                "description": "Handshake"
              }
            ]
          }
        ]
      },
    };
  },

  watch: {

  },
  mounted() {
    this.loadIllustration(this.value);
  },
  methods: {
    ftypestoenum(f){
      let e = {}
      for(let i=0; i < f.length; i++){
        e[i] = f[i]
      }
      return e
    },
    ftypeoffield(field, value){
      if(field in this.fieldftypes){
        return this.fieldftypes[field]
      }

      if(value.charAt(0) == '"' && value.charAt(value.length-1) == '"'){
        return ftypes.indexOf("FT_STRING")
      } else {
        return ftypes.indexOf("FT_FLOAT")
      }

    },
    parsevalue(field, value){
      let val;



      if(value.charAt(0) == '"' && value.charAt(value.length-1) == '"'){
        val = value.substring(1, value.length-1)
      } else {
        val = value
      }
      
      let typeint = this.ftypeoffield(field, value)
      let type = this.ftypesenum[typeint]

      if(val.includes(":")){
        return val
      }

      if(val.startsWith("0x")){
        return parseInt(val.substring(2, val.length), 16)
      }
      if(type.includes("INT")){
        return parseInt(val);
      }
      if(type.includes("FLOAT")){
        return parseFloat(val)
      }
      if(type.includes("STRING")){
        return val
      }

      switch(type){
        case "FT_IPv4": return val;
        case "FT_BOOLEAN": return val; // FIXME
        default: return val; // FIXME
      }
    },
    decodetotable(packet){
      let table = {}
      let kv, key, value
      for (let i of packet.decode){
        kv = i.filter.split(" == ")
        key = kv[0]

        if(kv.length == 1){
          table[key] = true
        } else if(kv.length == 2){
          value = kv[1]
          //for debugging table[key] = [this.parsevalue(key, value), value]
          table[key] = this.parsevalue(key, value)
        }
      }
      
      return table
      
    },
    evaluateinteresting(packet){
      
      this.ftypesenum = this.ftypestoenum(ftypes);
      let data = this.decodetotable(packet);

      for(let i of this.illustration.explainations){
        if(this.matches(data, i.filter)){
          this.illustration.interesting.push(i)
        }
      }
    },
    matches(data, rule){
      
      let result = eval(rule)
      return result
    },
    toHexString(byteArray) {
      return Array.from(byteArray, function(byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
      }).join(' ')
    },
    extractlayerbytes(packet){
      // offsets are sorted
      let first = null
      let last = null
      let start, length, filter

      for(let i of packet.offsets){

        start = i[0]
        length = i[1]
        filter = i[2]

        if(filter == this.illustration.layer){
            if(first == null){
              first = start
            }
            last = start + length
        }
      }
      
      this.illustration.layerbytes = this.toHexString(packet.bytes.subarray(first, last))
    },
    loadIllustration(packet) {
      this.packet = packet;
      this.extractlayerbytes(packet.packet)
      this.evaluateinteresting(packet.packet)
    },
    gotoLink: function() {},
  }
};
</script>

<style >

</style>