<template>

    <v-row class="ma-10 pa-0">
      <v-col cols="11">
        <div class="cpchart" ref="chartdiv"></div>
      </v-col>
    </v-row>
</template>

<script>
import moment from "moment-timezone";
import oui from "oui";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_animated);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { navigation } from "@/common/navigation";

export default {
  name: "capturepointgraph",
  props: {
    connection: null,
    pcapid: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    }
  },

  components: {

  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      iconn: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
    };
  },
  watch: {
    "connection": function(n, o){
        this.iconn = this.getIndexedconnectionFromAggregationFormat(n);
    },
    "iconn": function(n, o){
        //this.plotChart(client, server, location)
    }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.iindex = this.index;
    this.ipcapid = this.pcapid
    this.iconn = this.getIndexedconnectionFromAggregationFormat(this.connection);
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  beforeDestroy() {
      
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    getMacType(mac){
        let vendor = oui(mac);

        let device = {
            "Cisco Systems Inc." : "router",
            "VMware, Inc." : "endpoint",
            "Riverbed Technology, Inc." : "router",
            "REALTEK SEMICONDUCTOR CORP." : "endpoint",
            "Apple, Inc." : "endpoint"
        }
        let type = "unknown"

        try {
            type = device[vendor]
        } catch (e) {
            type = "unknown"
        }
        if (type === undefined){
            type = "unknown"
        }
        return type
    },
    getCpLocation(rtt_syn_synack, rtt_synack_ack, client, server){
        let rttratio = 1;
        let loc = {
            rttratio: 1,
            rtt: "unknown",
            ttl: "unknown",
            routed: true,
            maclocation1: "middle"
        }
        let ttl1 = client.ttl
        let ttl2 = server.ttl

        loc.rttratio = rtt_syn_synack / rtt_synack_ack

        if(rttratio > 2){
            loc.rtt = "client"
        } else if (rttratio < 0.16){
            loc.rtt = "server"
        } else {
            loc.rtt = "middle"
        }

        if(ttl1 == 64 || ttl1 == 128){

            loc.ttl = "on_or_close_to_client"

            if(ttl2 == 64 ||ttl2 == 128){
                loc.routed = false
            }
        } else {

            if( ttl2 == 64 || ttl2 == 128){
                loc.ttl = "on_or_close_to_server"
            } else {
                ttl1 = ttl1 % 64
                ttl2 = ttl2 % 64

                if(ttl1 > ttl2){
                    loc.ttl = "closer_to_client"
                } else if( ttl1 < ttl2){
                    loc.ttl = "closer_to_server"
                } else {
                    loc.ttl = "middle"
                }
            }
        }

        let maclocationtbl = {
            "endpoint" : {
                "router" : "client",
                "endpoint" : "middle",
                "unknown": "unknown" // Note not sure
            },
            "router" : {
                "router" : "unknown",
                "endpoint" : "unknown",
                "unknown": "unknown" // Note not sure
            },
            "unknown" : { // Note not sure
                "endpoint": "middle",
                "router": "middle",
                "unknown": "unknown"
            }
        }

        loc.maclocation1 = maclocationtbl[client.devicetype][server.devicetype]

        return loc
    },
    getIndexedconnection(src, dst, proto, srcport, dstport){
        
        let filter = this.df_conv_filter(proto, src, srcport, dst, dstport)
        
        let options = {
            index : "none",
            pcapid: this.ipcapid,
            filter: filter,
            start: 0,
            end : 10,
            streamsforfilter: false,
            columns: [
                "frame#time_relative",
                "ip#src",
                "ip#dst",
                "eth#src",
                "eth#dst",
                "ip#ttl",
                "tcp#dstport",
                "tcp#srcport"
            ]
        }
    
            
        options["profileid"] =  this.currentAnalysis.profiles.selected.id;


      ApiPacketlist.packetlist(options)
        .then(({ data }) => {
            
            let packit = { data : []}

            for(let d of data.result.packets){
                packit["data"].push(d.c)
            }
            
            let syn = packit.data[0]
            let synack = packit.data[1]
            let ack = packit.data[2]


            //0 = "frame#time_relative"
            let rtt_syn_synack =  synack[0] - syn[0]
            let rtt_synack_ack =  ack[0] - synack[0]


            let client = {
                //   "frame#time_relative", 0
                // "ip#src", 1
                // "ip#dst", 2
                // "eth#src", 3
                // "eth#dst", 4 
                // "ip#ttl", 5
                // "tcp#dstport", 6
                // "tcp#srcport" 7
                src : syn[1],
                srcport: syn[7],
                devicetype: this.getMacType(syn[3]),
                ttl: syn[5],
                rtt: rtt_syn_synack,
                mac: syn[3]
            }

            let server = {
                dst: syn[2],
                dstport: syn[6],
                devicetype: this.getMacType(syn[4]),
                ttl: synack[5],
                rtt: rtt_synack_ack,
                mac: syn[4]
            }
            
    
            let location = this.getCpLocation(rtt_syn_synack, rtt_synack_ack, client, server)
            
            this.plotChart(client, server, location)
        })
        .catch(error => {
          var err = error.response.data["error"];
        });
    },
    getIndexedconnectionFromAggregationFormat(connection){
        let src, dst, proto, srcport, dstport;

        src= connection.src.ip;
        dst= connection.dst.ip;
        srcport = connection.src.port;
        dstport = connection.dst.port;
        proto = connection.type;
        return this.getIndexedconnection(src, dst, proto, srcport, dstport);
    },
    plotChart(client, server, location){

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart
        var chart = am4core.create("cpchart", am4plugins_forceDirected.ForceDirectedTree);

        // Create series
        var series = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());

        // Icons
        var workstation = "M0 30l4-8h24l4 8zM4 2v18h24V2H4zm22 16H6V4h20v14z";
        var serverpic = "M6 0v30h2v2h4v-2h8v2h4v-2h2V0H6zm6 6H8V4h4v2z";
        var printer = "M28 26h2V16h-4V2H6v14H2v10h2l-2 4h28l-2-4zM8 4h16v12H8V4zm0 20h16l2 4H6l2-4z";
        //var router = "M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2M12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20M13 13V16H15L12 19L9 16H11V13M5 13H8V15L11 12L8 9V11H5M11 11V8H9L12 5L15 8H13V11M19 11H16V9L13 12L16 15V13H19"
        var router = "M26 20v-8a5 5 0 1 0-2 0v8l-2 1-11-11a5 5 0 0 0-4-8 5 5 0 0 0-1 10v8a5 5 0 1 0 2 0v-8l2-1 11 11-1 3a5 5 0 1 0 6-5z"
        var analyzer = "M 136.88045,347.36218 L 160.88045,347.36218 C 160.88045,347.36218 164.66365,299.17449 206.73183,298.5108 C 193.16146,319.74868 205.88045,347.36218 205.88045,347.36218 L 230.88045,347.36218"

        let clientmac = ""
        let servermac = ""
        let router1mac = ""
        let router2mac = ""

        let routerimg = "https://api.iconify.design/mdi:router.svg"
        let serverimg = "https://api.iconify.design/mdi:server.svg"
        let clientimg = "https://api.iconify.design/mdi:laptop-account.svg"
        let analyzerimg = "https://api.iconify.design/arcticons:audio-spectrum-analyzer.svg"
        let capturepointimg = "https://api.iconify.design/uil:capture.svg"

        let data = []

        let locationtable = {
            "client" : { // first level based on mac location
                 "on_or_close_to_client": {
                    "min": 15,
                    "max": 20
                 },
                 "closer_to_client": {
                    "min": 30,
                    "max": 70
                 },
                 "middle": {
                     "min" : 30,
                     "max" : 70,
                 }
            },
            "middle" : {
                 "on_or_close_to_client": {
                    "min": 15,
                    "max": 20
                 },
                 "closer_to_client": {
                    "min": 30,
                    "max": 70
                 },
                "middle" : {
                    "min": 30,
                    "max": 70
                },
                 "closer_to_server": {
                    "min": 30,
                    "max": 70
                 },
                "on_or_close_to_server": {
                    "min": 80,
                    "max": 85
                },
            },
            "server" : {
                 "on_or_close_to_server": {
                    "min": 80,
                    "max": 85
                 },
                 "closer_to_server": {
                    "min": 30,
                    "max": 70
                 },
                 "middle": {
                     "min" : 30,
                     "max" : 70,
                 }
            },
            "unknown" : {
                 "on_or_close_to_client": {
                    "min": 15,
                    "max": 20
                 },
                 "closer_to_client": {
                    "min": 30,
                    "max": 70
                 },
                 "middle": {
                     "min" : 30,
                     "max" : 70,
                 },
                 "on_or_close_to_server": {
                    "min": 80,
                    "max": 85
                 },
                 "closer_to_server": {
                    "min": 30,
                    "max": 70
                 },      
            }
        }

        let ranges = locationtable[location.maclocation1][location.ttl]

        let diff = ranges.max- ranges.min
        // better naming server rtt is synack to ack
        let temp = diff / (server.rtt + client.rtt)
        let fromleft = temp * server.rtt + ranges.min

        let directlyonendpointthresh = 0.01

        if(server.rtt < directlyonendpointthresh && (client.ttl == 64 && client.ttl == 128)){
            fromleft = 15
        }
        
        if(client.rtt < directlyonendpointthresh  && (server.ttl == 64 && server.ttl == 128)){
            fromleft = 85
        }

        if(fromleft <30){ // clientside
            clientmac = client.mac
            if(server.devicetype == "router"){
                router1mac = server.mac
            }
            if(server.devicetype == "endpoint"){
                servermac = server.mac
            }
        } else if (fromleft > 70){ // serverside
            servermac = server.mac
            if(client.devicetype == "router"){
                router2mac = client.mac
            }
            if(server.devicetype == "endpoint"){
                clientmac = client.mac
            }
        } else {
            router1mac = client.mac
            router2mac = server.mac
        }
        
        
        let serverside = {
                x: am4core.percent(85),
                y: am4core.percent(50),
                fixed: true,
                "image": serverimg,
                "name": "Server\n"+server.dst+":"+server.dstport+"\n"+servermac,
                "path": serverpic,
                "children": [],
                "value": 1,
                //scale: 1.5
            }
        
        let serversidebridge = {
            "name": "Router\n"+router2mac,
            "value": 1,
            "path": router,
            "image": routerimg,
            //"scale": 1.5,
            x: am4core.percent(70),
            y: am4core.percent(50),
            fixed: true,
            "link": ["capturepoint"]
        }

        let capturepointlocation = am4core.percent(fromleft)

        let capturepoint = {
                "name": "",
                "path": "",
                "id": "capturepoint",
                //"image": capturepointimg,
                x: capturepointlocation,
                y: am4core.percent(50),
                fixed: true,
                //"size": 25,
                "value": 1,
                "link": ["n2"],
                "children": [
                    {
                        "name": "Capture Point",
                        y: am4core.percent(20),
                        x: capturepointlocation,
                        "size": 25,
                        "value": 1,
                        "children": [
                            {
                                //scale: 1.5,
                                "name": "Analyzer",
                                "value": 1,
                                "path": analyzer,
                                "image": analyzerimg,
                                x: am4core.percent(50),
                                y: am4core.percent(20),
                                fixed: true
                            }
                        ]
                    },

                ]
        }


        let clientside = {
                x: am4core.percent(15),
                y: am4core.percent(50),
                fixed: true,
                image: clientimg,
                color: am4core.color("#"),
                "name": "Client\n"+client.src+":"+client.srcport+"\n"+clientmac,
                "path": workstation,
                "value": 1,
                id: "client",
                "children": [
                    
                ],
                //scale: 1.5
        }

        let clientsidebridge = {
            "id": "n2",
            "name": "Router\n"+router1mac,
            "value": 1,
            "path": router,
            //"scale": 1.5,
            "image": routerimg,
            x: am4core.percent(30),
            y: am4core.percent(50),
            fixed: true,
        }



        if(location.routed){
            serverside["children"].push(serversidebridge)
            clientside["children"].push(clientsidebridge)
        } else {
            serverside["link"] = ["capturepoint"]
            clientside["link"] = ["capturepoint"]
        }

        data.push(serverside)
        data.push(capturepoint)
        data.push(clientside)

 
        series.nodes.template.outerCircle.adapter.add("disabled", function(disabled, target) {
            return true
            try{
                return target.dataItem.name == "Capture Point" || target.dataItem.name == "Analyzer"
            } catch(e){
                return false
            }
        });

        series.nodes.template.circle.adapter.add("disabled", function(disabled, target) {
            return true
            try{
                return target.dataItem.name == "Analyzer"
            } catch(e){
                return false
            }
        });

        // Set up data fields
        series.dataFields.value = "value";
        series.dataFields.name = "name";
        series.dataFields.id = "id";
        series.dataFields.children = "children";
        series.dataFields.linkWith = "link";
        series.dataFields.fixed = "fixed";
        series.dataFields.color = "color";

        series.nodes.template.propertyFields.scale = "scale";
        series.nodes.template.propertyFields.x = "x";
        series.nodes.template.propertyFields.y = "y";


        // Add labels
        series.nodes.template.label.text = "{name}";
        series.nodes.template.label.valign = "bottom";
        series.nodes.template.label.fill = am4core.color("#000");
        //series.nodes.template.label.dy = 30;
        series.nodes.template.label.adapter.add("dy", function(disabled, target) {

            try{
                if(target.dataItem.name == "Capture Point"){
                    return -130
                }
            } catch(e){
                return 20
            }
            return 20
        });

        series.nodes.template.tooltipText = "{name}";
        series.fontSize = 14;
        series.fontFamily = '"Avenir", Helvetica, Arial, sans-serif;'
        series.minRadius = 30;
        series.maxRadius = 30;

        // Configure circles
        series.nodes.template.circle.fill = am4core.color("#fff");
        series.nodes.template.circle.fill = am4core.color("#fff");

        var icon = series.nodes.template.createChild(am4core.Image);
        icon.propertyFields.href = "image";
        icon.propertyFields.width = "size"
        icon.propertyFields.height = "size"
        icon.horizontalCenter = "middle";
        icon.verticalCenter = "middle";
        icon.width = 60;
        icon.height = 60;

        // // Configure icons
        // var icon = series.nodes.template.createChild(am4core.Sprite);
        // icon.propertyFields.path = "path";
        // icon.horizontalCenter = "middle";
        // icon.verticalCenter = "middle";

        series.centerStrength = 0.4;
        series.links.template.strokeWidth = 5;
        series.links.template.strokeOpacity = 0.3;

        
        series.data = data
        this.chart = chart
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.cpchartcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.cpchart {
  width: 100%;
  height: 480px;
}

.container {
  height: 100%;
  max-width: 100%;
    overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
