<template>
  <v-list-item @click.stop>

    <v-list-item-action>
      <v-btn icon>
        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-content @click.prevent="toggleDialog">
      <v-list-item-title>{{title}}</v-list-item-title>
      <v-list-item-subtitle>{{description}}</v-list-item-subtitle>
    </v-list-item-content>
    
    <v-dialog v-model="dialogopen" >
      <v-toolbar>
        <v-btn icon  @click="dialogopen = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn v-if="!disabled" text @click="saveProfile">Save</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card >
        <v-card-text >
          <slot v-if="dialogopen" name="preferences"></slot>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-list-item>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "No title"
    },
    icon: {
      type: String,
      default: "add"
    },
    description: {
      type: String,
      default: "No description"
    }
  },
  components: {},
  name: "fullscreenpreference",
  watch: {},
  data() {
    return {
      dialogopen: false
    };
  },
  created: function() {},
  mounted() {},
  computed: {},
  methods: {
    toggleDialog: function() {
      this.dialogopen = !this.dialogopen;
    },
    saveProfile: function() {
      this.dialogopen = false;
      this.$emit("save");
    }
  }
};
</script>

<style >
</style>
