import ApiService from "@/common/api.service";

const ApiDecode = {

    decode(pcapid, start, end, profileid){
        return ApiService.query_w_retry("ajax/pcap/decode/" + pcapid + "/" + profileid + "/" + start + "/" + end)
    },
    /* instead of ranges we request directly specific frame */
    decoderange(params){

        // TODO introduce post_w_retry as query with retry
        // pcapid, frameids
        // {"pcapid": "abc", "frameids" : "11 23 40-44"}
        // tok_prev_frame: last before first displayed
        // tok_ref_frame: 
        // 

        return ApiService.post("ajax/pcap/decode", params)
    },
    decodetshark(pcapid, start, end){
        return ApiService.query("ajax/pcap/decode/" + pcapid + "/" + start + "/" + end)
    }
}

export default ApiDecode