<template>
  <v-row class="ma-5 pa-0">
    <v-col cols="10">
      <div
        class="iograph"
        ref="chartdiv"
      ></div>
    </v-col>
    <v-col cols="2">
      <v-card class="ml-5" min-width="274">
        <v-card-title>Graph Settings</v-card-title>

        <v-card-text>
          <v-switch class="ml-5 ma-2" v-model="zoom" hide-details solo>
            <template slot="label">Zoom Mode</template>
          </v-switch>

          <v-switch class="ml-5 ma-2" v-model="usesharkd" hide-details solo>
            <template slot="label">Use Sharkd</template>
          </v-switch>

          <v-switch
            class="ml-5 ma-2"
            color="info"
            v-model="normalize_to_seconds"
            hide-details
            solo
          >
            <template slot="label">Normalize to seconds</template>
          </v-switch>

          <v-switch
            class="ml-5 ma-2"
            color="info"
            v-model="oneaxis"
            hide-details
            solo
          >
            <template slot="label">One Axis</template>
          </v-switch>

          <!--
          <v-btn v-on:click="showConnections" class="ml-3 mt-5 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">reorder</v-icon>
              </template>
              
              <span>Show Connections</span>
            </v-tooltip>
          </v-btn>-->

          <v-btn v-on:click="resetZoom" class="ml-2 mt-5 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">undo</v-icon>
              </template>

              <span>Reset zoom</span>
            </v-tooltip>
          </v-btn>

          <v-btn v-on:click="loadColoringRules" class="ml-2 mt-5 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">invert_colors</v-icon>
              </template>

              <span>Load Coloringrules</span>
            </v-tooltip>
          </v-btn>

          <!--<v-btn v-on:click="showpcap" class="ml-10 ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">folder</v-icon>
              </template>
              
              <span>Show PCAP</span>
            </v-tooltip>
          </v-btn>-->

          <v-combobox
            class="ml-2 ml-2"
            hide-details
            v-model="interval"
            :items="intervals"
            label="Time interval"
            solo
            item-text="Interval"
          ></v-combobox>

          <v-combobox
            class="ml-2 ml-2"
            hide-details
            v-model="iograph_preset"
            :items="iograph_presets"
            label="IOGraph Presets"
            solo
            item-text="name"
          ></v-combobox>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" class="mt-3">
      <v-card>
        <v-card-title>Plotted graphs ({{numdatapoints}} datapoints)</v-card-title>
        <v-card-text>
          <v-row
            class="py-0 mt-0 pt-0"
            v-for="(filter, index) in iofilters"
            v-bind:key="filter['key']"
          >
            <v-col v-if="1 == 2" cols="12" class style="height: 24px">
              <v-subheader class="pl-1 ml-0 pb-3 mb-3">{{
                filterToString(filter["name"])
              }}</v-subheader>
            </v-col>
            <v-col cols="2" class="py-0 px-1">
              <v-text-field
                solo
                v-model="filter['name']"
                label="Filter Name"
                hide-details
                maxlength="20"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter['filter']"
                label="Select a filter"
                item-text="Filter"
              ></v-combobox>

              <!--<filterbox v-model="filter['filter']" :livemode="true"></filterbox>-->
            </v-col>
            <v-col cols="1" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter['color']"
                :items="colors"
                label="Select a color"
                item-text="Color"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter['style']"
                :items="styles"
                label="Style"
                item-text="Style"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter['type']"
                :items="types"
                label="Type"
                item-text="Type"
              ></v-combobox>
            </v-col>
            <v-col cols="2" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter['aggregation_field']"
                :items="currentAnalysis.indexedcolumns"
                label="Aggregation field"
                item-text="Aggregation field"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="pt-3 px-1">
              <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="red"
                    v-on:click="removeFilter(filter)"
                    dark
                    v-on="on"
                    >delete</v-icon
                  >
                </template>
                <span>Delete filter</span>
              </v-tooltip>

              <v-tooltip bottom v-if="index == iofilters.length - 1">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" v-on:click="newFilter" dark v-on="on"
                    >add</v-icon
                  >
                </template>
                <span>Add filter</span>
              </v-tooltip>
            </v-col>
            <v-col cols="11"></v-col>
            <v-col cols="1">
              <v-tooltip bottom v-if="index == iofilters.length - 1">
                <template v-slot:activator="{ on }">
                  <v-btn v-on:click="apply" color="green" v-on="on">
                    Apply
                  </v-btn>
                </template>
                <span>Apply all filters</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-3" v-if="usesharkd">
      No zooming available in sharkd mode
    </v-col>
    <v-col cols="12" class="mt-3" v-if="!usesharkd">
      Note that filters like "tcp.analysis.flags" or "icmp" do not work in
      indexing mode. Rewrite your filters so they contain indexed fields like
      "ip.proto == 1" (ICMP) or "tcp.analysis.retransmission"
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_material);


import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { IOGRAPH_PRESETS, iographhelper } from "@/common/iograph";
import { navigation } from "@/common/navigation";

export default {
  name: "Iograph",
  props: {
    pcapid: {
      type: String,
      default: null,
    },
    index: {
      type: String,
      default: null,
    },
    graphplot: {
      type: String,
      default: null,
    },
  },

  components: {},
  mixins: [displayfilter, navigation, iographhelper],
  data() {
    return {
      dateAxis: undefined,
      numdatapoints: 0,
      oneaxis: false,
      isautointerval: false,
      numaxes: 0,
      valueaxis: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
      iograph_preset: null,
      iograph_presets: IOGRAPH_PRESETS,
      interval: "1s",
      intervals: [
        "1s",
        "100ms",
        "10ms",
        "1ms",
        "10s",
        "1h",
        "1d",
        "1w",
        "1M",
        "1y",
      ],
      colors: ["black", "red", "blue", "green", "yellow"],
      styles: ["fill", "line"],
      dataUnit: "bytes",
      aggregations: ["sum", "avg", "min", "max"],
      dataUnitsSharkd: ["bytes", "bits"],
      dataUnits: ["bytes", "bits", "kbit", "Mbit", "Gbit"],
      iofilters: [
        {
          name: "Packets per second",
          key: 0,
          type: "packets",
          filter: "",
          color: "blue",
          style: "fill",
        },
      ],
      defaultfilter: [
        {
          name: "Packets per second",
          key: 0,
          type: "packets",
          filter: "",
          color: "blue",
          style: "fill",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      zoom: true,
      usesharkd: true,
      normalize_to_seconds: true,
      timelinestart: 0,
      timelineend: 0,
    };
  },
  watch: {
    zoom: function(n, o ){
      if(!n){
        this.dateAxis.zoom({start:0, end:1})

        this.chart.cursor.behavior = "selectX";
        this.chart.cursor.events.on("selectended", this.handleChanged);
      } else {
         this.chart.cursor.events.on("selectended", null);
         this.chart.cursor.behavior =  "zoomXY";
      }
    },
    oneaxis: function (n, o) {
      this.apply();
    },
    "pcapid": function(){
      this.ipcapid = this.pcapid;
    },
    "currentAnalysis.analysistabs": function (n, o) {
      if (n == 1) {
        this.numaxes = 0;
    
        this.oneaxis = false;
        this.iofilter = this.defaultfilter;
        this.addCurrentPacketFilter();

        this.loadCustomGraph(this.currentAnalysis.customgraph);
        this.apply();
      }
    },
    "currentAnalysis.customgraph": function (n, o) {
      this.numaxes = 0;
      this.oneaxis = false;
      this.iofilter = this.defaultfilter;
      if (n) {
        this.loadCustomGraph(n);
        this.apply();
      }
    },
    iograph_preset: function (n, o) {
      var newfilters = n.values;
      this.iofilters = newfilters;
      this.apply();
    },
    // "usesharkd": function(n, o){
    //   if(n){
    //     this.chart.cursor.behavior =  null;
    //     this.chart.cursor.events.on("selectended", null);
    //   }else{
    //     this.chart.cursor.behavior = "selectX";
    //     this.chart.cursor.events.on("selectended", this.handleChanged);
    //   }
    // }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.iindex = this.index;
    this.ipcapid = this.pcapid;
    this.timelinestart = this.currentAnalysis.timelinestart;
    this.timelineend = this.currentAnalysis.timelineend;
    this.addCurrentPacketFilter();
    this.loadCustomGraph(this.currentAnalysis.customgraph);
    this.apply();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    types: function () {
      if(this.usesharkd){
        return ["packets"].concat(this.dataUnitsSharkd).concat(this.aggregations);
      }
      return ["packets"].concat(this.dataUnits).concat(this.aggregations);
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    loadCustomGraph(graph) {
      this.oneaxis = false;
      this.numaxes = 0;
      if (Object.keys(graph).length != 0) {
        this.iofilters = graph.values;
        if ("oneaxis" in graph) {
          this.oneaxis = graph.oneaxis;
        } else {
          this.oneaxis = false;
        }
      }
    },
    resetZoom() {
      this.timelinestart = undefined;
      this.timelineend = undefined;
      this.currentAnalysis.timelinestart = undefined;
      this.currentAnalysis.timelineend = undefined;
      this.fetchTimeline();
    },
    plotChart(data, series, sharkdDataArray=null) {
      am4core.options.minPolylineStep = 10;
      if (this.chart != null) {
        this.chart.dispose();
      }
      this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      if (this.currentAnalysis.profiles.selected.theme) {
        this.chart.stroke = am4core.color("#A0CA92");
      }

      // Increase contrast by taking evey second color
      //this.chart.colors.step = 2;

      // Add data
      
      //this.chart.data = data;

      // Create axes
      let dA = new am4charts.DateAxis()
      var dateAxis = this.chart.xAxes.push(dA);
      dateAxis.renderer.minGridDistance = 50;

      dateAxis.renderer.labels.template.rotation = 40;
      dateAxis.groupInterval = { timeUnit: "second", count: 1 };
      this.dateAxis = dA;
      this.chart.colors.list = [];

      this.numaxes = 0;
      let color 
      for(let i = 0; i<series.length; i++){
        let s = series[i]
        color = am4core.color(s["color"]);
        this.chart.colors.list.push(color);
        
        this.createAxisAndSeries(s["name"], s["name"], false, "circle", color, sharkdDataArray[i]);
      }

      /*this.createAxisAndSeries(chart, "visits", "Visits", false, "circle");
      this.createAxisAndSeries(chart, "views", "Views", true, "triangle");
      this.createAxisAndSeries(chart, "hits", "Hits", true, "rectangle");*/

      // Add legend
      this.chart.legend = new am4charts.Legend();
    
      // Add cursor
      this.chart.cursor = new am4charts.XYCursor();

      //use cursor for selection by default
      this.chart.cursor.behavior = "zoomXY";
      this.zoom = true

      //
      // if(this.usesharkd){
      //   this.chart.cursor.behavior =  null;
      //   this.chart.cursor.events.on("selectended", null);
      // }else{
      //   this.chart.cursor.behavior = "selectX";
      //   this.chart.cursor.events.on("selectended", this.handleChanged);
      // }
    },
    getToolTipText(name, valueY) {
      
      try{
      var iofilter = this.getIOFilterByName(name);
      var divider = this.interval;
      var divident = iofilter["type"];
      var normalized = valueY;
      var text = "{name}: [bold]{valueY}";

      var myRegexp = /^([0-9]{0,9})([a-zA-Z]{1,2})/g;
      var match = myRegexp.exec(this.interval);

      if (match[1] == "1") {
        divider = match[2];
      }

      if (this.aggregations.includes(divident)) {
        divident = divident + "(" + iofilter["aggregation_field"] + ")";
      }

      /* if we have throughput, not packets and normalization actiated */
      if (!this.isautointerval && this.normalize_to_seconds && this.dataUnits.includes(divident)) {
        switch (this.interval) {
          case "100ms":
            normalized = valueY * 10;
            break;
          case "10ms":
            normalized = valueY * 100;
            break;
          case "1ms":
            normalized = valueY * 1000;
            break;
          case "10s":
            normalized = valueY / 10;
            break;
        }
        normalized = Math.round(normalized * 100) / 100;
        text = name + ": " + normalized + " " + divident + "/s";
      } else {
        if(this.normalize_to_seconds){
          text = text + " " + divident + "/" + divider;
        } else {
            text = text + " " + divident + "/" + divider;
        }
      }
      } catch (e){
        ;
      }
      return text;
    },
    createAxisAndSeries(field, name, opposite, icon, color, data) {
      //if(field == "packets"){
      let valueAxis;

      if (this.numaxes < 1) {
        valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        this.valueaxis = valueAxis;
      } else {
        if (this.oneaxis) {
          valueAxis = this.valueaxis;
        } else {
          valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        }
      }
      this.numaxes++;

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 2;

      valueAxis.renderer.line.stroke = color;
      valueAxis.renderer.labels.template.fill = color;
      valueAxis.renderer.opposite = opposite;
      valueAxis.renderer.grid.template.disabled = true;

      var series = this.chart.series.push(new am4charts.LineSeries());

      series.segments.template.interactionsEnabled = true;
      series.segments.template.events.on("hit", this.handleClicked);

      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 2;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY}[/]";

      // series.tooltipText = this.getToolTipText(name);

      series.adapter.add("tooltipText", (text, target) => {
        let valueY = target.tooltipDataItem.valueY;
        if (valueY) {
          return this.getToolTipText(name, valueY);
        } else {
          return text;
        }
      });

      //this.chart.scrollbarX = new am4charts.XYChartScrollbar();

      //this.chart.scrollbarX.series.push(series);
      
      series.data = data
      var iofilter = this.getIOFilterByName(name);

      series.tensionX = 0.8;

      if (iofilter != null) {
        switch (iofilter["style"]) {
          case "fill":
            series.fillOpacity = 0.3;
            break;
          case "line":
            break;
          default:
            series.fillOpacity = 0.3;
        }
      }

      if (this.currentAnalysis.profiles.selected.theme) {
        series.stroke = am4core.color("#A0CA92");
      }
    },
    handleChanged(ev) {
      var range = ev.target.xRange;
      var axis = ev.target.chart.xAxes.getIndex(0);

      var startnum = axis.toAxisPosition(range.start);
      var endnum = axis.toAxisPosition(range.end);

      
      if (this.zoom) {
        var c = this.currentAnalysis;
        var t = c.total;

        var s = this.moment.utc(c.startDate);
        var e = this.moment.utc(c.endDate);

        if (t == 0) {
          return;
        }

        /* duration in milliseconds */
        var duration = e - s;

        let start = this.moment.utc(s + duration * startnum).toISOString();
        let end = this.moment.utc(s + duration * endnum).toISOString();
        this.timelinestart = start;
        this.timelineend = end;

        this.fetchTimeline();
      } else {
        
        //var from = axis.getPositionLabel(startnum);
        //var to = axis.getPositionLabel(endnum);

        if (startnum == 0 && endnum == 1) {
          return;
        }

        // this.error.type = "Info";
        // this.error.msg =
        //   "Filtered for the selection in the packet list with maximum " +
        //   this.currentAnalysis.profiles.selected.maxPackets +
        //   " packets (you can edit this value in the settings)";

        this.currentAnalysis.showIOGraph = false;
        this.updateDatesFromSelection(startnum, endnum);
      }
    },
    updateDatesFromSelection(start, end) {
      var c = this.currentAnalysis;
      var t = c.total;

      var s = this.moment.utc(c.pcap.starttime);
      var e = this.moment.utc(c.pcap.endtime);

      if (t == 0) {
        return;
      }

      /* duration in milliseconds */
      var duration = e - s;

      // Note: just some boundaries, maybe throw error
      if (duration > 10000000) {
        return;
      }
      // check

      //c.iostart = parseInt(t * start);
      //c.ioend = parseInt(t * end);
      
      c.selectStartDate = this.moment.utc(s + duration * start).toISOString();
      c.selectEndDate = this.moment.utc(s + duration * end).toISOString();

      this.$store.dispatch(UPDATE_ANALYSIS, c);
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;

      this.navigatePacketview(this.ipcapid, this.iindex);
    },
    showpcap() {
      this.navigatePacketview(this.ipcapid, this.iindex);
    },

    filterToString(name) {
      var filter = this.getIOFilterByName(name);

      var out = filter["name"];

      if (out) {
        if (filter["filter"]) {
          out = out + " filtering for " + filter["filter"];
        }

        if (filter["dataunit"]) {
          out =
            out + " showing " + filter["dataunit"] + " per " + this.interval;
        }
      }

      return out;
    },
    handleClicked(ev) {
      //var item = ev.target.dataItem.component.tooltipDataItem.dataContext;
      var filtername = ev.target.dataItem.component.dataFields.valueY;
      //var date = ev.target.dataItem.component.tooltipDataItem.dataContext.date;

      //alert("Clicked on "+filtername+" with value "+item[filtername]+" at date "+date)

      var filter = null;

      for (var f of this.iofilters) {
        if (f["name"] == filtername) {
          filter = f["filter"];
        }
      }

      if (filter != null) {
        this.df_apply_to_packetlist(filter);
        // this.$eventHub.$emit("displayfilter-set", filter);
      }
    },

    convertUnit(input, unit) {
      var tv = input;
      switch (unit) {
        case "bits":
          tv = tv * 8;
          break;
        case "kbit":
          tv = (tv * 8) / 1024;
          break;
        case "Mbit":
          tv = (tv * 8) / 1024 / 1024;
          break;
        case "Gbit":
          tv = (tv * 8) / 1024 / 1024 / 1024;
          break;
        default:
          break;
      }
      return tv;
    },
    calculatedate(startdate, factor) {
      let unit = this.interval;

      let digits = unit.replace(/[^\d]/g, "");
      let runit = unit.replace(/[\d]/g, "");

      switch (runit) {
        case "s":
          return new Date(0, 0, 1, 0, 0, factor * digits, 0);
        case "ms":
          return new Date(0, 0, 1, 0, 0, 0, digits * factor);
        case "m":
          return new Date(0, 0, 1, 0, digits * factor, 0, 0);
        case "h":
          return new Date(0, 0, 1, digits * factor, 0, 0, 0);
        case "d":
          return new Date(0, 0, digits * factor, 0, 0, 0, 0);
        case "w":
          return new Date(0, 0, digits * factor * 7, 0, 0, 0, 0);
        case "M":
          return new Date(0, digits * factor, 0, 0, 0, 0, 0);
        case "y":
          return new Date(digits * factor, 0, 0, 0, 0, 0, 0);
      }
    },
    showSharkdTimeline(data) {
      let outdata = [];
      let i = 0;
      let date = 0;



      for (let series of data["iograph"]) {
        let chartdata = []
        try{
        let items = series["items"];
        this.numdatapoints = items.length
        let iofilter = this.iofilters[i++];

        let prepitemsi = 0;

        for (let i = 0; i < items.length; i++) {
          if (typeof items[i] == "string") {
            let next_item = parseInt(items[i], 16);
            while (prepitemsi < next_item) {
              let newelem = {};
              newelem[iofilter["name"]] = 0.0;
              newelem["date"] = this.calculatedate(date, prepitemsi);
              chartdata.push(newelem);

              prepitemsi++;
            }
          } else {
            let newelem = {};
            newelem[iofilter["name"]] = items[i];
            newelem["date"] = this.calculatedate(date, prepitemsi);
            chartdata.push(newelem);
            prepitemsi++;
          }
        }
        } catch (e){
          if("errmsg" in series){
            this.error.type ="error"
            this.error.msg=series["errmsg"];  
          }
          
        }
        outdata.push(chartdata);
      }
      this.plotChart(undefined, this.iofilters, outdata);
    },
    showIndexTimeline(data) {
      let chartdata = [];

      var c = this.currentAnalysis;
      c.startDate = data["histogram"][0]["key_as_string"];
      c.endDate =
        data["histogram"][data["histogram"].length - 1]["key_as_string"];
      this.$store.dispatch(UPDATE_ANALYSIS, c);

      if("autointerval" in data){
        this.isautointerval = data["autointerval"]
        this.interval = data["interval"]
      } else {
        this.isautointerval = false
      }
      
      for (let elem of data["histogram"]) {
        var moment = this.moment;
        var mydate = moment
          .utc(elem["key_as_string"])
          .tz(moment.tz.guess())
          .toDate();

        var newelem = {
          date: mydate,
        };

        for (var f of this.iofilters) {
          if (elem[f["name"]][f["name"] + "_aggs"] != undefined) {
            newelem[f["name"]] = this.convertUnit(
              elem[f["name"]][f["name"] + "_aggs"]["value"],
              f["type"]
            );
          } else {
            newelem[f["name"]] = elem[f["name"]]["doc_count"];
          }
        }

        chartdata.push(newelem);
      }

      this.plotChart(chartdata, this.iofilters);
    },
    fetchTimeline() {
      if (!this.ipcapid) {
        return;
      }

      let params = {
        pcapid: this.ipcapid,
        filters: this.iofilters,
        interval: this.interval,
        type: "index",
      };

      if (this.usesharkd) {
        params.type = "sharkd";
      }

      for (let f of params.filters) {
        if (!f["filter"]) {
          delete f["filter"];
        }
      }

      if (this.iindex && this.iindex != "none") {
        params.index = this.iindex;
      }

      if (this.timelinestart != 0) {
        params.starttime = this.timelinestart;
        delete params.start;
      }

      if (this.timelineend != 0 && this.timelineend) {
        params.endtime = this.timelineend;
        delete params.end;
      }

      ApiPacketlist.iograph(params).then(({ data }) => {
        
        if (params.type == "index") {
          if (data["autointerval"] == true) {
            this.error.msg =
              "Interval setting of " +
              this.interval +
              " would result in too many data points, doing auto interval of 50 values, resulting in a smoothed curve. Select a larger interval or select a smaller time frame.";
            this.error.type = "Info";
          }

          this.showIndexTimeline(data);
        }
        if (params.type == "sharkd") {
          this.showSharkdTimeline(data);
        }
      });
    },
    getIOFilterByName(name) {
      for (var f of this.iofilters) {
        if (f.name == name) {
          return f;
        }
      }
      return null;
    },
    newFilter() {
      var n = {};
      n["key"] = this.iofilters.length + 1;
      n["name"] = "";
      n["type"] = "packets";
      n["filter"] = "";
      n["color"] = "black";
      n["aggregation_field"] = "";
      n["style"] = "fill";
      this.iofilters.push(n);
    },
    removeFilter(filter) {
      this.iofilters.splice(this.iofilters.indexOf(filter), 1);
    },
    addCurrentPacketFilter() {
      if (this.currentAnalysis.filter) {
        var n = {};
        n["key"] = this.iofilters.length + 1;
        n["name"] = "Current";
        n["type"] = "packets";
        n["filter"] = this.currentAnalysis.filter;
        n["color"] = "red";
        n["aggregation_field"] = "";
        n["style"] = "fill";
        this.iofilters.push(n);
      }
    },
    apply() {
      this.fetchTimeline();
    },
    showConnections() {
      this.currentAnalysis.timelinestart = this.timelinestart;
      this.currentAnalysis.timelineend = this.timelineend;
      this.currentAnalysis.showConnections = true;
    },
    loadColoringRules() {
      var rules = this.currentAnalysis.profiles.selected.coloringrules;
      var newfilters = [];
      for (let r of rules) {
        let n = {
          name: r["rulename"],
          key: newfilters.length + 1,
          type: "packets",
          filter: r["rule"],
          color: r["foreground"],
          style: "fill",
        };
        newfilters.push(n);
      }
      this.iofilters = newfilters;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.iographcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.iograph {
  width: 100%;
  height: 480px;
}

.container {
  height: 100%;
  max-width: 100%;
  overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
