<template>
  <v-row align="center" justify="center" class="pt-6">
    <v-col cols="12">
      <v-card class="pb-2">
        <v-card-title class="headline" primary-title>
          Profile Export

          <v-spacer></v-spacer>
        </v-card-title>

        <v-col cols="12">
          <v-textarea
            outlined
            readonly
            rows="20"
            label="Profile Export"
            class="monofont"
            :value="profiletext"
          ></v-textarea>
          <v-btn @click="copyText" small color="info" class="ma-2"
            >Copy<v-icon right dark small>content_copy</v-icon></v-btn
          >
          <v-btn @click="download" small color="info" class="ma-2"
            >Download<v-icon right dark> cloud_download</v-icon></v-btn
          >

          <v-btn
            v-if="currentUser"
            @click.native="openFileDialog"
            primary
            class="ma-2"
            small
            color="info"
            >Import<v-icon right dark> cloud_upload</v-icon></v-btn
          >

          <input
            type="file"
            id="file-upload"
            style="display: none"
            @change="onFileChange"
          />
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ApiProfile from "@/common/api/profile";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  name: "profileexport",
  props: {
    profileid: null,
    value: null,
  },
  data() {
    return {
      profiletext: "",
      uploadedprofile: null,
    };
  },
  created: function () {},
  mounted() {
    this.loadprofile(this.value);
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  watch: {
    uploadedprofile: function (n) {
      let parsed = JSON.parse(n);
      parsed.name = "Imported: " + parsed.name;
      parsed.id = this.value.id;
      this.loadprofile(parsed);
      this.$emit("input", parsed);
    },
  },
  methods: {
    loadprofile(profile) {
      var copyofprofile = JSON.parse(JSON.stringify(profile));

      // cleanup

      delete copyofprofile.permissions;
      delete copyofprofile.metadata;
      delete copyofprofile.id;
      if ("coloringrules" in copyofprofile) {
        for (let i = 0; i < copyofprofile.coloringrules.length; i++) {
          delete copyofprofile.coloringrules[i].colorrule;
          delete copyofprofile.coloringrules[i].id;
        }
      }

      this.profiletext = JSON.stringify(copyofprofile, null, 4);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (files.length > 0) {
        var reader = new FileReader();
        reader.readAsText(files[0]);
        reader.onload = () => {
          this.uploadedprofile = reader.result;
        };
      }
    },

    download: function () {
      let filename = this.profile.name + ".json";
      let data = this.profiletext;

      var blob = new Blob([data], { type: "application/json" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.profiletext);
      this.error.type = "info";
      this.error.msg = "Copied";
    },
  },
};
</script>

<style >
</style>
