<template>
  <div>
    <v-dialog v-model="errorDialog" width="500">

      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          {{ errorTitle }}
        </v-card-title>

        <v-card-text class="mt-8 text-body-1" v-html="errorMessage" />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card style="z-index:0" v-show="showDropzone">
      <v-card-text>
        <v-row align="center" justify="center" class="pa-2 grid-list-md full-width">
          <v-col cols="12" class="text-center pt-4">
            <v-row justify="center">
              <div class="upload">
                <vue-dropzone ref="myVueDropzone" id="dropzone" :class="dropzonestyleclass" :options="dropzoneOptions"
                  v-on:vdropzone-sending="sendingEvent" v-on:vdropzone-max-files-exceeded="maxFilesExceeded"
                  v-on:vdropzone-success="completeUpload" v-on:vdropzone-files-added="filesAdded"
                  v-on:vdropzone-error="handleError"></vue-dropzone>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-4">
            By submitting your file to this site you are asking us to store, process,
            and <b>share the PCAP with the public</b>. You also agree to the
            <b><a :href="tosurl">Terms of Service</a></b> and
            <b><a :href="privacyurl">Privacy Policy</a></b>. Please do not submit any personal
            information; we are not responsible for the contents of your submission. PCAPs uploaded by <b>registered
              users are private by default</b>.
          </v-col>
          <v-col cols="12" class="mt-2 text-center" v-if="currentUser">
            <template v-if="this.currentAnalysis.profiles.selected.massuploadtagging">
              Select tags for your uploaded files <template v-if="currentUser">(<router-link
                  :to="{ name: 'Tags' }">create the tag</router-link>
                first)</template>
              <tags label="Tags..." type="pcap" :isowner="true" :setvuex="true" class="px-10 pt-2"></tags>
            </template></v-col>

        </v-row>

      </v-card-text>
    </v-card>
    <v-row v-if="successfulUploads.length > 0" align="center" justify="center" class="pa-2 grid-list-md full-width">
      <v-card class="mx-auto full-width" max-width="none" width="100%" tile>
        <v-card-title>Uploaded files (click to open)</v-card-title>

        <v-list dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in successfulUploads" :key="i">
              <v-list-item-icon>
                <v-icon>check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title @click.stop.prevent="gotoPcap(item.response.id, item)"
                  v-text="item.file.upload.filename"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <p class=" mt-4 ml-4">Click <a @click.prevent.stop="enableUpload">here to upload more</a></p>
      </v-card>
    </v-row>
    <v-row style="z-index:1">
      <v-dialog v-model="showMetadataDialog" max-width="500px" v-if="showMetadataDialog">
        <upload-pcap-dialog v-model="pcapMetadata" @close="metadataDialogClosed" @cancel="metadataDialogCanceled"
          :show="showMetadataDialog" :pcaps="droppedUploads"></upload-pcap-dialog>
      </v-dialog>
    </v-row>
    <v-row v-if="false">
      <v-btn @click="showMetadataDialog = true">Show dialog</v-btn>
      {{ pcapMetadata }}
    </v-row>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import UploadPcapDialog from "./dialogs/UploadPcapDialog";

import { PCAP_URL } from '@/common/config'

import {
  UPLOAD_URL_CHUNKED,
  UPLOAD_URL_CHUNKED_FAST,
  UPLOAD_ANON_URL,
  PRIVACY_URL,
  CONTACT_URL,
  TOS_URL,
} from "@/common/config";
import { mapGetters } from "vuex";
import JwtService from "@/common/jwt.service";
import Tags from "./settings/Tags";

import axios from 'axios'

const errorTooLargeAnon = "File too large, anonymous upload is restricted to <b>1MB</b>.<br/><a href='https://app.packetsafari.com/register'>Register for uploading larger files</a>."

export default {
  name: "upload",
  components: {
    vueDropzone: vue2Dropzone,
    UploadPcapDialog,
    Tags,
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser"]),
    maxFiles: function () {
      if (!this.currentUser) {
        return 1
      } else {
        return null
      }
    },
    dropzoneOptions: function () {
      let o = {
        url: UPLOAD_URL_CHUNKED_FAST,
        thumbnailWidth: 80,
        maxFilesize: 1000,
        maxFiles: this.maxFiles,
        autoQueue: false, //we do this manually to show a dialog first
        //chunking: true,
        //forceChunking: true,
        //chunkSize: 1000000,
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
        },
        dictDefaultMessage: "Upload PCAP<br><br><img src='/pcapicon.png'>",
      }
      return o
    },
    dropzonestyleclass: function () {
      if (this.currentAnalysis.profiles.selected.theme) {
        return "dropzone-dark"
      }
      return "dropzone";
    },
  },
  beforeMount() {
    if (!this.currentUser) {
      this.dropzoneOptions.url = UPLOAD_ANON_URL;
    }
  },
  data() {
    return {
      errorTitle: undefined,
      errorMessage: undefined,
      errorDialog: false,
      dropzoneImage: "",
      metadataAdded: false,
      showMetadataDialog: false,
      pcapMetadata: {
        description: "",
        email: "",
        wanthelp: true
      },
      showDropzone: true,
      selectedItem: null,
      droppedUploads: [],
      successfulUploads: [],
      privacyurl: PRIVACY_URL,
      tosurl: TOS_URL
    };
  },
  methods: {
    handleError(file, message, xhr) {
      debugger
      this.errorTitle = "Upload error"
      if (xhr.status == 413) {
        if (this.currentUser) {
          this.errorMessage = "File to large for your pricing plan."
        } else{
          this.errorMessage = errorTooLargeAnon
        }
        
      } else if (xhr.status == 429) {
        this.errorMessage = "Too many uploads, wait some time before you upload more PCAPs." + "<br>See <a href='https://www.packetsafari.com/docs/features/'>pricing plans</a> to upgrade capacity "
      } else {
        this.errorMessage = "Error " + message.error
      }
      this.$refs.myVueDropzone.removeAllFiles()
      this.errorDialog = true
    },
    metadataDialogClosed() {
      this.$refs.myVueDropzone.getAcceptedFiles().forEach(f => this.$refs.myVueDropzone.dropzone.enqueueFile(f));
      this.$refs.myVueDropzone.processQueue()
      this.metadataAdded = true
      this.showMetadataDialog = false

    },
    metadataDialogCanceled() {
      this.$refs.myVueDropzone.removeAllFiles()
      this.metadataAdded = false
      this.showMetadataDialog = false
      this.showDropzone = true
    },
    filesAdded(files) {
      console.log("Files added")
      console.log(files)

      // default for anon
      let myMBlimit = 1


      if (this.currentUser) {
        myMBlimit = this.currentUser.ratelimiting.max_upload_file_size

      }

      let maxFileSize = myMBlimit * 1024 * 1024

      // less than 0 means infinite
      if (myMBlimit > 0) {
        for (let file of files) {
          if (file.size > maxFileSize) {
            this.errorTitle = "Upload error"

            if (this.currentUser) {
              this.errorMessage = "File too large, your upload is restricted to <b>" + myMBlimit + " MB</b>.<br/><a href='https://www.packetsafari.com/docs/features/'>Upgrade the subscription plan for higher limits.</a>."
            } else {
              this.errorMessage = errorTooLargeAnon
            }

            this.$refs.myVueDropzone.removeAllFiles()
            this.metadataAdded = false
            this.errorDialog = true
            return
          }
        }
      }



      this.pcapMetadata.description = ""
      this.pcapMetadata.email = ""
      this.pcapMetadata.wanthelp = true
      this.droppedUploads = files
      //this.$refs.myVueDropzone.dropzone.enqueueFile(file)
      this.showMetadataDialog = true
      if (this.metadataAdded) {
        //this.$refs.myVueDropzone.processQueue()
      } else {
        this.showMetadataDialog = true
      }
    },
    maxFilesExceeded(file) {
      console.log("Max files exceeded")
      console.log(file)
    },
    completeUpload(file, response) {
      let f = {
        file: file,
        description: this.pcapMetadata.description,
        email: this.pcapMetadata.email,
        wanthelp: this.pcapMetadata.wanthelp,
        response: response
      }
      this.successfulUploads.push(f)
      this.$refs.myVueDropzone.removeAllFiles()
      this.showDropzone = false
    },
    gotoPcap(pcapid, item) {

      window.location.replace(PCAP_URL + pcapid);
    },
    enableUpload() {
      this.$refs.myVueDropzone.removeAllFiles()
      this.pcapMetadata.description = ""
      this.pcapMetadata.email = ""
      this.pcapMetadata.wanthelp = true
      this.metadataAdded = false
      this.showDropzone = true
    },
    sendingEvent(file, xhr, formData) {
      formData.append("tags", JSON.stringify(this.currentAnalysis.uploadtags));
      formData.append("description", JSON.stringify(this.pcapMetadata.description));
      if (this.pcapMetadata.email) {
        formData.append("email", JSON.stringify(this.pcapMetadata.email));
      } else if (this.currentUser) {
        formData.append("email", JSON.stringify(this.currentUser.email));
      }

      formData.append("wanthelp", JSON.stringify(this.pcapMetadata.wanthelp));
      this.notifyLambda(formData, file)
    },
    notifyLambda(formData, file) {
      let description = "New upload " + file.upload.filename + " by " + this.currentUser?.username

      if (this.pcapMetadata?.wanthelp) {
        if (this.currentUser) {
          description = "Help request for " + file.upload.filename + " by " + this.currentUser.username
        } else {
          description = "Help request for " + file.upload.filename + " by " + this.pcapMetadata.email
        }

      }

      let c = { data: { file: file, user: this.currentUser, metadata: this.pcapMetadata }, description: description };
      axios
        .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", c)
        .then((res) => { })
        .catch((error) => {
        })
        .finally(() => {
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.upload {
  width: 100%;
}

.dropzone {
  width: 100%;
  border: 2px dashed #002007;
  border-radius: 5px;

}

.dropzone-dark {
  width: 100%;
  border: 2px dashed #002007;
  border-radius: 5px;
  background: rgb(12, 11, 11) !important;
}


.dropzone .dz-complete .dz-success-mark {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}

/* 
.dropzone .dz-preview .dz-details {
  z-index: 0 !important;
}

.dropzone .dz-preview .dz-image {
  z-index: 0 !important;
} */
</style>
