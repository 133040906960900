import { sharkdhelper } from "@/common/sharkdhelper"

export const endpoints = {
    mixins: [sharkdhelper],
    data() {
        return {
            aggridhandler: {
                "endpt": {
                    name: "Endpoint Statistics",
                    getColumnDefs: this.getColumnDefsEndpt,
                    getContextMenuItems: this.getContextMenuItemsEndpt,
                    formatData: this.formatDataEndpt
                }
            }
        }
    },
    methods: {
        getColumnDefsEndpt(stats, groupTable=false) {
            
            var h = [
                { headerName: "Host", field: "host", rowGroup: groupTable, hide: groupTable },
                { headerName: "Packets", field: "totalxf", aggFunc: 'sum' },
                { headerName: "Bytes", field: "totalxb", aggFunc: 'sum' },
                { headerName: "Tx Packets", field: "txf", aggFunc: 'sum' },
                { headerName: "Tx Bytes", field: "txb", aggFunc: 'sum' },
                { headerName: "Tx Packets", field: "rxf", aggFunc: 'sum' },
                { headerName: "Tx Bytes", field: "rxb", aggFunc: 'sum' },
                { headerName: "Filter", field: "filter", hide: true }
            ];

            if (stats.proto == "TCP" || stats.proto == "UDP") {
                h.splice(1, 0, { headerName: "Port", field: "port" });
            }
            return h;
        },
        formatDataEndpt(stats) {
            
            let indata = [];

            for (let c of stats.hosts) {
                if ("port" in c)
                    c.port = parseInt(c.port)
                c.totalxf = c.txf + c.rxf
                c.totalxb = c.txb + c.rxb
                indata.push(c)
            }
            return indata;
        }
    }
}