<template>
  <v-dialog
    v-if="currentAnalysis.pcaplist.decrypt"
    v-model="currentAnalysis.pcaplist.decrypt"
    max-width="800px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h3>Decrypt PCAP</h3>
            <br />
          </v-col>
   
          <v-col cols="12">
            <v-row>
              <v-col cols="12" v-if="numselected == 0">
                <v-alert  type="error" >
                  Please select one PCAP
                </v-alert>
              </v-col>                
              <v-col cols="12" v-else-if="numselected > 1">
                <v-alert  type="error" >
                  Too many PCAPs selected, please just select one.
                </v-alert>
              </v-col>
              <v-col cols="12" v-else>
                <template v-if="currentUser">
                  Upload TLS Keys for {{selectedpcap.id}}
                  <v-row
                    align="center"
                    justify="center"
                    class="pa-2 ma-2 grid-list-md full-width"
                  >
                    <v-col cols="12" class="text-center pt-4">
                      <v-row justify="center">
                        <div class="upload">
                          <vue-dropzone
                            ref="tlsDropZone"
                            id="tlsdropzone"
                            :options="dropzoneOptions"
                            v-on:vdropzone-sending="sendingEvent"
                          ></vue-dropzone>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="text-wrap pt-4" style="word-break: normal">
                      By submitting your file to this site you are asking us to
                      store, process, and
                      <b>share the decryption keys with the public</b>. You also
                      agree to the
                      <b
                        ><router-link :to="tosurl"
                          >Terms of Service</router-link
                        ></b
                      >
                      and
                      <b
                        ><router-link :to="privacyurl"
                          >Privacy Policy</router-link
                        ></b
                      >. Please do not submit any personal information; we are
                      not responsible for the contents of your submission.
                    </v-col>
                  </v-row>
                </template>
              </v-col>
              <v-col cols="12">
                <v-btn @click="currentAnalysis.pcaplist.decrypt = false"
                  >Close</v-btn
                ></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { commonmethods } from "@/common/common";
import JwtService from "@/common/jwt.service";
import { mapGetters } from "vuex";
import ApiPcapmeta from "@/common/api/pcapmeta";

import {
  UPLOAD_TLS_URL_CHUNKED_FAST,
  PRIVACY_URL,
  CONTACT_URL,
  TOS_URL,
} from "@/common/config";

export default {
  props: ["pcaps"],
  mixins: [commonmethods],
  components: {
    vueDropzone: vue2Dropzone,
  },
  name: "decrypt",
  data() {
    return {
      numselected: 0,
      selectedpcap: null,
      value: "",
      privacyurl: PRIVACY_URL,
      tosurl: TOS_URL,
      dropzoneOptions: {
        url: UPLOAD_TLS_URL_CHUNKED_FAST,
        thumbnailWidth: 80,
        maxFilesize: 2,
        maxFiles: 1,

        maxfilesexceeded: function () {
          this.showdropzone = false;
        },
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
        },
        dictDefaultMessage: "Upload TLS Keys<br><br><img src='/pcapicon.png'>",
      },
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error"]),
  },
  mounted() {
    this.selectpcap(this.pcaps);
  },
  created: function () {},
  watch: {
    pcaps(n) {
      this.selectpcap(n);
    },
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("pcapid", this.selectedpcap.id);
    },
    selectpcap(n) {

        this.numselected = n.length
      if (n.length == 1) {

        this.selectedpcap = n[0];
      }
    },
  },
};
</script>

<style>
.upload {
  width: 100%;
}
.tlsdropzone {
  width: 100%;
  border: 2px dashed #002007;
  border-radius: 5px;
}
.tlsdropzone .dz-complete .dz-success-mark {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}
</style>