import { sharkdhelper } from "@/common/sharkdhelper"

export const exportobjects = {
    mixins: [sharkdhelper],
    data() {
        return {
            groupTable: false,
            headernames : {
                "pkt" : { headerName: "Packet", field: "pkt" },
                "hostname": { headerName: "Hostname", field: "hostname", rowGroup: true, hide: true},
                "type" : { headerName: "Type", field: "type" },
                "filename" : { headerName: "File name", field: "filename" },
                "_download" : { headerName: "Download", field: "_download", hide: true},
                "len" : { headerName: "Length", field: "len", aggFunc: 'sum' },
            },
            aggridhandler: {
                "eo": {
                    name: "Export Objects",
                    getColumnDefs: this.getColumnDefsEO,
                    getContextMenuItems: this.getContextMenuItemsEO,
                    formatData: this.formatDataEO,
                }
            }
        }
    },
    methods: {
        getDataPathStats(data){
            
            return data.path.split('§');
        },
        getHeaderName(field){
            if (field in this.headernames)
                return this.headernames[field]
            return undefined
        },
        getColumnDefsEO(stats, groupTable=false) {
            
            this.groupTable = groupTable
            var h = []
            
            let first = stats.objects[0]

            for (const entry in first) {
                let n = this.getHeaderName(entry)

                if ("rowGroup" in n){
                    n["rowGroup"] = groupTable
                    n["hide"] = groupTable
                }
                
                if(n !== undefined){
                    h.push(n)
                }

                if("sub" == entry){
                    for(const subval in first.sub){
                        let n = this.getHeaderName(subval)

                        if(n !== undefined){
                            h.push(n)
                        }
                    }
                }

            }
            return h;
        },
        getContextMenuItemsEO: function (params) {
            
            var currentitems = params.defaultItems.slice(0);
            var result = [
                {
                    name: "Filter Conversation",
                    action: function () {

                        var o = params.node.data["filter"]
                        this.df_apply_to_packetlist(o)
                        this.currentAnalysis.showTapstatistics = false
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                },
            ];

            for (var item of result) {
                currentitems.unshift(item);
            }

            return currentitems;
        },
        formatDataEO(stats) {
            let indata = [];

            for (let c of stats.objects) {
                indata.push(c)

            }
            return indata;
        }
    }
}