<template>
  <div class="analysis">
    <v-list two-line>
      <template v-for="(a) in analysis">
        <v-list-item :key="a.id" avatar>
          <v-list-item-avatar>
            <img :src="avatarpic4user(a)">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <router-link
                v-if="a.index !==undefined && a.index != ''"
                :to="{ name: 'Analysis', params: { id: pcapid, index: a.index, analysisid: a.id }}"
              >{{ a.name }}</router-link>
              <router-link
                v-else
                :to="{ name: 'Analysis', params: { id: pcapid, index: 'default' , analysisid: a.id }}"
              >{{ a.name }}</router-link>
              by {{a.permissions.permissions_owner[0].username}}
              <template
                v-if="currentUser && a.permissions.permissions_owner[0].username==currentUser.username"
              >
                <v-tooltip bottom>
                  <v-icon small slot="activator" color="primary" @click="deleteAnalysis(a)">delete</v-icon>
                  <span>Delete this analysis</span>
                </v-tooltip>
                <v-tooltip bottom v-if="a.permissions.permissions_public">
                  <v-icon slot="activator" color="primary" small @click="togglePublic(a)">cloud</v-icon>
                  <span>Analysis is public, click to deny public access</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                  <v-icon slot="activator" color="primary" small @click="togglePublic(a)">lock</v-icon>
                  <span>Analysis is private, click to allow public access</span>
                </v-tooltip>
              </template>
            </v-list-item-title>
            <v-list-item-subtitle v-if="a.description">{{a.description | truncate(80, '...')}}</v-list-item-subtitle>
            <v-list-item-subtitle v-else>No description</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { commonmethods } from "@/common/common";
import ApiAnalysisrecipe from "@/common/api/analysisrecipe";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    analysis: null,
    pcapid: null
  },
  mixins: [commonmethods],
  name: "analysis",
  filters: {
    truncate: function(text, length, suffix) {
      return text.substring(0, length) + suffix;
    }
  },
  computed: {
    ...mapGetters(["currentUser", "avatarPic"])
  },
  methods: {
    deleteAnalysis: function(a){
      ApiAnalysisrecipe.delete(a.id)
        .then(({  }) => {
          this.analysis.splice(this.analysis.indexOf(a), 1);
        })
        .catch(({ response }) => {
          this.analysis.splice(this.analysis.indexOf(a), 1);
        });
    },
    togglePublic: function(a) {
      if (a.permissions.permissions_public) {
        this.lockAnalysis(a);
      } else {
        this.publishAnalysis(a);
      }
    },
    publishAnalysis: function(a) {
      ApiAnalysisrecipe.publish(a).then(
        ({ data }) => {
          this.errormsg = "";
          a.permissions.permissions_public = true;
        }
      );
    },
    lockAnalysis: function(a) {
      ApiAnalysisrecipe.lock(a).then(
        ({ data }) => {
          this.errormsg = "";
          a.permissions.permissions_public = false;
        }
      );
    }
  }
};
</script>

<style scoped>
</style>
