<template>
  <v-row justify="center" class="mt-5">
    <v-card width="400px" class="pa-2">
      <v-card-title>
        <span class="headline">Login</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="submit">
          <v-row  >
            <v-col cols="12">
              <v-text-field
                v-model="username"
                :rules="usernameRules"
                :counter="25"
                label="Username"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="password"
                :append-icon="showpassword ? 'visibility_off' : 'visibility'"
                :rules="[rules.required, rules.min]"
                :type="showpassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showpassword = !showpassword"
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="currentAnalysis.features.ENABLE_COGNITO">
              <router-link :to="{ name: 'Forgot Password', }">Forgot Password</router-link>
            </v-col>
            <v-col cols="6" v-if="currentAnalysis.features.ENABLE_COGNITO">
              <router-link :to="{ name: 'Signup code', }">Resend sign up code</router-link>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!valid" @click="submit">submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { LOGIN } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error", "avatarPic"]),
  },
  mixins: [permissionchecks],
  name: "login",
  data: () => ({
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    valid: true,

    password: "",
    passwordRules: [v => !!v || "Password is required"],
    showpassword: false,
    username: "",
    usernameRules: [v => !!v || "Username is required"],
    select: null
  }),

  methods: {
    submit() {
      this.$store
        .dispatch(LOGIN, { username: this.username, password: this.password })
        .then(data => {
          
          if(this.hasSubscription(this.currentUser)){
            this.$router.push({ name: "Packet Analyzer" })
          } else {
            this.$router.push({ name: "Pricing" })
          }          
        })
        .catch(err => {
          
          this.error.type = "error";
          this.error.msg = "Could not login";
        });
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>