<template>
  <div id="register">
    <v-dialog v-model="userDeletionDialog" width="400px">


      <v-card class="pa-2">
        <v-card-title>
            <p class="display-1 text--primary" v-if="!deleted">
              Delete user
            </p>
            <p class="display-1 text--primary" v-else>
              User deleted!
            </p>
        </v-card-title>
    <v-card-text  v-if="!deleted" class="">
       This will delete your user and all associated data from the database.
    </v-card-text>
    <v-card-text class="" v-else>

      <simplelistitem :content="`${deletedresult.tags} tags deleted`"></simplelistitem>
      <simplelistitem :content="`${deletedresult.profiles} profiles deleted`"></simplelistitem>
      <simplelistitem :content="`${deletedresult.roles} roles deleted`"></simplelistitem>
      <simplelistitem :content="`${deletedresult.analysisrecipies} analysis deleted`"></simplelistitem>
      <simplelistitem :content="`${deletedresult.tasks} tasks deleted`"></simplelistitem>
      <simplelistitem :content="`${deletedresult.indexerprofiles} indexer profiles deleted`"></simplelistitem>
      <simplelistitem :content="`${deletedresult.pcapmeta} PCAPs deleted`"></simplelistitem>
  
    </v-card-text>
        <v-card-actions v-if="!deleted">
          <v-btn color="red" @click="deleteuser">Confirm deletion</v-btn>
          <v-btn color="green" @click="userDeletionDialog = false">Cancel</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn @click="finishdeletion">Ok</v-btn>
        </v-card-actions>
      </v-card>

      
    </v-dialog>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card ref="form">
            <v-img :src="avatarPic" height="300px">
              <v-row class="fill-height" column>
                <v-card-title>
                  <v-spacer></v-spacer>
                </v-card-title>

                <v-spacer></v-spacer>

                <v-card-title class="pt-5">
                  <div class="display-1">{{user.firstname}} {{user.lastname}}</div>
                </v-card-title>
              </v-row>
            </v-img>

            <v-card-text>
              <v-text-field
                v-model="user.firstname"
                :rules="firstnameRules"
                :counter="25"
                label="Firstname"
                required
              ></v-text-field>

              <v-text-field
                v-model="user.lastname"
                :rules="lastnameRules"
                :counter="25"
                label="Lastname"
                required
              ></v-text-field>

              <v-text-field v-model="user.email" :rules="emailRules" label="E-mail" required></v-text-field>

              <v-text-field
                v-model="user.password"
                :append-icon="showpassword ? 'visibility_off' : 'visibility'"
                :type="showpassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showpassword = !showpassword"
              ></v-text-field>

              <v-text-field
                v-model="user.motto"
                :counter="50"
                label="Packet analaysis motto"
                required
              ></v-text-field>

              <v-text-field v-model="user.twitter" :counter="25" label="Twitter handle" required></v-text-field>

              <v-text-field v-model="user.ask" :counter="25" label="Ask Wireshark handle" required></v-text-field>

              <v-text-field v-model="user.website" :counter="50" label="Website" required></v-text-field>

              <v-text-field v-model="user.location" :counter="50" label="Location" required></v-text-field>Upload an avatar picture (jpg and png accepted)
              <avatarupload class="pt-3"></avatarupload>
            </v-card-text>
            <v-divider class="mt-4"></v-divider>
            <v-card-actions>
              <v-btn color="info" :disabled="!valid" @click="submit">submit</v-btn>
              <v-btn color="info" @click="clear">clear</v-btn>
              <v-btn color="red" @click="userDeletionDialog = true">delete user</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiUser from "@/common/api/user";
import Avatarupload from "./Avatarupload";
import Simplelistitem from "@/components/basics/Simplelistitem"
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/actions.type";
export default {
  name: "edituser",
  components: {
    Avatarupload,
    Simplelistitem
  },
  data: () => ({
    userDeletionDialog: false,
    deleted: false,
    deletedresult: null,
    user: {
      firstname: "",
      lastname: "",
      motto: "",
      email: "",
      password: "",
      username: "",
      twitter: "",
      ask: "",
      website: "",
      location: ""
    },
    rules: {
      required: value => !!value || "Required.",
      min: v =>
        (v !== undefined && (v == "" || v.length >= 8)) || "Min 8 characters",
      max: v => (v !== undefined && v.length <= 8) || "Max 50 characters",
      emailMatch: () => "The email and password you entered don't match"
    },

    firstnameRules: [
      v => !!v || "Firstname is required",
      v => (v && v.length <= 25) || "Firstname must be less than 25 characters"
    ],
    mottoRules: [
      v => (v && v.length <= 50) || "Motto must be less than 50 characters"
    ],
    valid: true,
    lastnameRules: [
      v => !!v || "Lastname is required",
      v => (v && v.length <= 25) || "Lastname must be less than 25 characters"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    passwordRules: [v => !!v || "Password is required"],
    showpassword: false,
    usernameRules: [v => !!v || "Username is required"],
    select: null,
    checkbox: false
  }),
  watch: {
    "currentUser": function(n,o)
    {
      
      this.user = this.currentUser;
    }
  },
  mounted() {
    
    this.user = this.currentUser;
  },
  computed: {
    ...mapGetters(["currentUser", "error", "avatarPic"])
  },
  methods: {
    finishdeletion(){
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "Packet Analyzer" }).catch(err => {}));
    },
    deleteuser(){
        ApiUser.delete(this.user.id)
          .then(({data}) => {
            this.deleted = true;
            this.deletedresult = data.result
            this.error.type = "info"
            this.error.msg = "User deleted!"
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "Packet Analyzer" }).catch(err => {}));
          })
          .catch(({ response }) => {
            this.deleted = true;
            this.deletedresult = data.message
          });
    },
    clean(input) {
      let obj = input
      for (var propName in obj) { 
        if (obj[propName] === null || obj[propName] === undefined ||obj[propName] == "") {
          delete obj[propName];
        }
      }
      return obj
    },
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        let p = this.clean(this.user)
        ApiUser.update(p)
          .then(({  }) => {
            this.error.type = "info"
            this.error.msg = "Updated!"
            this.$router.push({ name: "Packet Analyzer" }).catch(err => {});
          })
          .catch(({ response }) => {
            this.error.type = "error"
            this.error.msg = "Update failed!"
          });
      }
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>