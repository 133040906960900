<template>
  <layout>
    <v-col cols="12">
      PCAP ID: {{currentAnalysis.pcapid}}
    </v-col>
    <v-col cols="12">
      Index: {{currentAnalysis.index}}
    </v-col>
    <v-col cols="12">
      Start: {{currentAnalysis.iostart}} - End {{currentAnalysis.ioend}}
    </v-col>    
  </layout>
</template>



<script>
import { mapGetters } from "vuex";
export default {
  name: "analysisdebug",
  props: {},
  components: {},
  mounted() {},
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "avatarPic"])
  },
  data() {
    return {
      headers: [
        {
          text: "Key",
          align: "left",
          sortable: false,
          value: "name"
        },
        { text: "Value", value: "value" }
      ]
    };
  },
  beforeUpdate: function() {},
  methods: {}
};
</script>

<style>
</style>
