<template>
  <!-- all 100% and overflow: hidden absolutely necessary here 
  update: overflow: hidden; removed replaced by height 100%-->
  <div style="width: 100%; height: 100%" id="decode">
    <v-dialog v-model="dialogIllustration" width="70%">
      <fieldillustration
        v-model="illustrate"
        v-if="dialogIllustration"
      ></fieldillustration>
    </v-dialog>
    <ag-grid-vue
      style="width: 100%; height: 100%"
      :class="theme"
      :gridOptions="treeOptions"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";

import ApiProfile from "@/common/api/profile";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import Fieldillustration from "@/components/Fieldillustration";
import packethandling from "@/common/packethandling";
import { commonmethods } from "@/common/common";

import { AGGRID_LICENSE } from "@/common/config";
LicenseManager.setLicenseKey(AGGRID_LICENSE);

import { displayfilter, df_convtypes } from "@/common/displayfilter";
//import FieldtreeviewVue from "./settings/Fieldtreeview.vue";

export default {
  name: "decode",
  mixins: [displayfilter, packethandling, commonmethods],
  computed: {
    theme: function () {
      if (this.currentAnalysis.profiles.selected.theme) {
        return "ag-theme-balham-dark";
      }
      return "ag-theme-balham";
    },
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      decodefilters: [],
      filtereddecode: false,
      highlighfield: undefined,
      dialogIllustration: false,
      illustrate: null,
      rowData: null,
      currentid: "",
      start: "",
      end: "",
      ignorefields: [
        "udp.port",
        "ip.addr",
        "tcp.port",
        "ip.host",
        "geninfo",
        "ipv6.addr",
        "ipv6.src_host",
        "ipv6.dst_host",
        "ipv6.host",
      ],
      treeDefs: [
        {
          headerName: "id",
          field: "id",
          hide: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Decode",
          field: "show",
          suppressSizeToFit: false,
          cellRenderer: "agGroupCellRenderer",
          showRowGroup: true,
          enableCellChangeFlash: false,
          cellRendererParams: {
            suppressCount: true,
          },
          keyCreator: function (params) {
            return params.id;
          },
        },
      ],
      treeData: [],
      treeOptions: {
        /* child parent communication
         * https://www.ag-grid.com/best-vuejs-data-grid/
         */
        context: {
          parent: this,
        },

        groupSuppressAutoColumn: true,
        columnDefs: this.treeDefs,
        paginationPageSize: 1000,
        rowBuffer: 2000,
        rowData: this.treeData,
        enableRangeSelection: false,
        treeData: true,
        suppressRowHoverHighlight: true,
        rowSelection: "single",
        getContextMenuItems: this.getContextMenuItems,
        rowClass: "",
        rowClassRules: {
          "decode-highlight": function (params) {
            if (params.node.selected) {
              return true;
            }
            if (this.highlighfield === undefined) {
              return false;
            }

            return this.fieldshouldbehighlightednew(params);
          }.bind(this),
          "field-indexed": function (params) {
            if (params.data !== undefined) {
              var searchfor = this.df_wireshark_field_to_es(params.data.field);
              return this.df_field_is_indexed(searchfor);
            }
          }.bind(this),
          "field-unindexed": function (params) {
            if (params.data !== undefined) {
              var searchfor = this.df_wireshark_field_to_es(params.data.field);
              return !this.df_field_is_indexed(searchfor);
            }
          }.bind(this),
        },
        onCellMouseOver: this.cellHovered,
        onRowClicked: this.cellClicked,
        onGridReady: function (params) {
          
          this.preparestyledef();
          this.treeDefs[1].enableCellChangeFlash = this.currentAnalysis.profiles.selected.highlightDecodeChanges;
          this.treeDefs[1].cellStyle = {
            "font-family": this.getFont(),
            "font-size": this.getFontSize(),
          };
          this.treeOptions.api.setColumnDefs(this.treeDefs);
          this.treeOptions.api.setHeaderHeight(0);

          /* Note: fsize for caching may actually be performacne relevant check  */
          
          this.fetchShowPacket(this.id, 1, 10);

          this.treeOptions.api.sizeColumnsToFit();
        }.bind(this),
        getDataPath: function (data) {
          var currentrow = data.id.split("/");
          return currentrow; // path: "frame/number"
        },
        immutableData: true,
        getRowNodeId: function (data) {
          return data.id;
        },
        onModelUpdated: function (data) {
          this.treeOptions.api.sizeColumnsToFit();
        }.bind(this),
      },
    };
  },
  components: {
    AgGridVue,
    Fieldillustration,
  },
  mounted() {
    
    this.resetCache()
    this.$eventHub.$on("highlight-decode", (field) => {
      this.treeOptions.api.forEachNode(function (node) {
        //FIXME eliminate fasle positives
        if (node.key.includes(field)) {
          node.setSelected(true);

          var cur = node;

          while (cur.parent) {
            if (cur.parent != null) {
              cur.parent.expanded = true;
              cur = cur.parent;
            }
          }
          return;
        }
      });
    });

    this.$eventHub.$on("cachepackets", (range) => {
      var start = parseInt(range[0]) + 1;
      var end = range[1];

      this.pcapcache(this.id, start, end);
      //this.gridOptions.api.purgeInfiniteCache();
    });

    this.$eventHub.$on("clicked-in-hex", (field) => {
      
      this.highlighfield = field;
      let shortestcandidate = this.getshortestcandidate();
      this.treeOptions.api.forEachNode(
        function (rowNode, index) {
          
          let shouldbehighlighted = shortestcandidate[2] == rowNode.data.filter;
          //          console.log("Shortest is "+shortestcandidate +" filter is "+rowNode.data.filter)
          if (shouldbehighlighted) {
            //              console.log("Found a match!")
            //rowNode.setSelected(true);

            var cur = rowNode;
            this.currentAnalysis.curfield.fieldname = rowNode.data.field
            while (cur.parent) {
              if (cur.parent != null) {
                cur.parent.setExpanded(true);
                //cur.parent.expanded = true;
                cur = cur.parent;
              }
            }
            this.treeOptions.api.deselectAll();
            this.treeOptions.api.ensureNodeVisible(rowNode);
            
          }
        }.bind(this)
      );

      this.treeOptions.api.redrawRows();
      //this.treeOptions.api.refreshCells()
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("highlight-decode");
    this.$eventHub.$off("cachepackets");
    this.$eventHub.$off("clicked-in-hex");
  },
  watch: {
    "currentAnalysis.cpacket": function (n, o) {
      
      if (n) {
        this.showPacketDecode(n);
      }
    },
    "currentAnalysis.timereferences": function(n, o){
      if(this.currentAnalysis.timereferences){
        let newref = n[0]
        if(o){
          let difference = n.filter(x => !o.includes(x));
          newref = difference[0]
        } 
        this.smartCacheEviction(newref)
      }
    },
    "currentAnalysis.illustratepaket.framenumber": function (n, o) {
      if (n) {
        this.currentAnalysis.illustratepaket.packet = this.checkCache(n);
      }
    },
    "currentAnalysis.decodecacheinvalidate": function (n, o) {
      if (n) {
        
        this.currentAnalysis.decodecacheinvalidate = false;
        this.currentAnalysis.applyfilter = true;
        this.currentAnalysis.filterneedsupdate = true;
      }
    },
    "currentAnalysis.profiles.selected.highlightDecodeChanges": function (
      n,
      o
    ) {
      this.treeDefs[1].enableCellChangeFlash = this.currentAnalysis.profiles.selected.highlightDecodeChanges;
      this.treeOptions.api.setColumnDefs(this.treeDefs);
    },
  },
  methods: {
    cellHovered(params) {},
    preparestyledef() {
      if (this.styledef === undefined) {
        this.styledef = this.generateStyleDef(
          "decode-highlight",
          this.currentAnalysis.profiles.selected.selectedfg,
          this.currentAnalysis.profiles.selected.selectedbg,
          true
        );
        if (this.styledef) this.applyStyle(this.styledef);
      }
    },
    getshortestcandidate() {
      // this is a column number not a field
      let hexhighlight = this.highlighfield;

      let packetnum = localStorage.getItem(this.id);
      let packet = this.checkCache(packetnum);

      if (packet === null) {
        return false;
      }

      let candidates = this.getintervalsforvalzero(
        hexhighlight,
        packet.offsets
      );
      //let intervalsforfield = this.getintervalsforfield(filter, packet.offsets)

      if (candidates && candidates.length > 0) {
        let shortest = this.shortestinterval(candidates);
        return shortest;
      }
      return null;
    },

    fieldshouldbehighlightednew(params) {
      // this is a column number not a field
      let hexhighlight = this.highlighfield;

      if (!params) {
        console.log("No params received");
        return false;
      }
      let filter = params.data.filter;

      let packetnum = localStorage.getItem(this.id);
      let packet = this.checkCache(packetnum);

      if (packet === null) {
        return false;
      }

      let candidates = this.getintervalsforvalzero(
        hexhighlight,
        packet.offsets
      );
      //let intervalsforfield = this.getintervalsforfield(filter, packet.offsets)

      if (candidates && candidates.length > 0) {
        let shortest = this.shortestinterval(candidates);
        return shortest[2] == filter;
      }

      return false;
    },
    fieldshouldbehighlighted(params) {
      let hexhighlight = this.highlighfield;
      let filter = params.data.filter;

      let packetnum = localStorage.getItem(this.id);
      let packet = this.checkCache(packetnum);

      if (packet === null) {
        return false;
      }

      for (let o of packet.offsets) {
        let min = o[0] + 1;
        let len = o[1];

        if (hexhighlight >= min && hexhighlight < min + len) {
          if (o[2] == filter) {
            console.log(filter + " matches");
            return true;
          }
        }
      }
      return false;
    },
    onFilterTextBoxChanged: function () {
      this.treeOptions.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    },
    cellClicked(params) {
      this.highlighfield = undefined;
      this.treeOptions.api.redrawRows();
      this.currentAnalysis.decodefocus = "decode";
      this.$eventHub.$emit("clicked-in-decode", params.data.filter);
      this.currentAnalysis.curfield.fieldname = params.data.field
    },
    decodedPacketContainsKey(key, packet) {
      if (!key) {
        return true;
      }
      for (let field of packet) {
        if (field.field == key) {
          return true;
        }
      }
      return false;
    },
    generate_dependencies(key) {
      let tmparr = key.split(".");
      let out = "";
      let outarray = [];

      if (tmparr.length < 2) {
        return outarray;
      }

      for (let i = 0; i < tmparr.length - 1; i++) {
        out = this.df_to_decode_path(tmparr.slice(0, i + 1).join("."));
        var newfield = {
          id: out,
          field: tmparr.slice(0, i + 1).join("."),
          show: out,
          value: out,
        };
        outarray.push(newfield);
      }

      return outarray;
    },
    df_to_decode_path(key) {
      let tmparr = key.split(".");
      let out = "";
      for (let i = 0; i < tmparr.length; i++) {
        if (i == 0) {
          out = tmparr[i];
        } else {
          let prefix = tmparr.slice(0, i + 1).join(".");
          out = out + "/" + prefix;
        }
      }
      return out;
    },
    packetlistPacketToDecodePacket(info, decodedpacket) {
      var tobemerged = [];
      for (let key in info) {
        let decodekey = this.df_to_decode_path(key);
        if (
          this.decodedPacketContainsKey(key, decodedpacket) ||
          info[key] == ""
        ) {
          continue;
        }
        var newfield = {
          id: decodekey,
          field: key,
          show: key + ": " + info[key],
          value: info[key],
        };
        tobemerged.push(newfield);

        let dependencies = this.generate_dependencies(key);

        for (let dep of dependencies) {
          if (!this.decodedPacketContainsKey(dep.field, decodedpacket)) {
            decodedpacket.push(dep);
          }
        }
      }
      // for (let item of tobemerged) {
      //   decodedpacket.push(item);
      // }
      decodedpacket.push(tobemerged[0]);
      return decodedpacket;
    },
    mySetRowData: function (packet) {
      /* do some magic */

      // if (this.currentAnalysis.packetlistpacket) {
      //   var packet = this.packetlistPacketToDecodePacket(
      //     this.currentAnalysis.packetlistpacket,
      //     packet
      //   );
      // }

      // apply row filtering

      var filteredpacket = [];
      if (this.decodefilters.length > 0) {
        filteredpacket = packet.filter(
          function (item) {
            for (let filter of this.decodefilters) {
              if (filter.includes(item.field)) return true;
            }
            return false;
          }.bind(this)
        );

        this.filtereddecode = true;
        packet = filteredpacket;

        this.treeOptions.api.forEachNode(function (node) {
          node.expanded = true;
        });
      }

      this.treeOptions.api.setRowData(packet);
      this.treeOptions.api.sizeColumnsToFit();
    },
    resetfilters() {
      this.decodefilters = [];
      this.showPacketDecode(this.currentAnalysis.cpacket);
    },
    adddecodefilter(node) {
      this.decodefilters.push(node.field);
    },
    showPacketDecode(packet) {
      this.mySetRowData(packet.decode);
    },
    // returns the value
    getRowNodebyField(field) {
      let found = undefined;
      this.treeOptions.api.forEachNode(function (node) {
        if (node.data.field == field) {
          found = node;
        }
      });
      if (found !== undefined) {
        return found.data["filter"].split(" == ")[1];
      }
      return found;
    },
    getConversationTuple(params, type) {
      var t = this.treeOptions.api;
      var o = {};

      if (type == "eth") {
        o.src = this.getRowNodebyField("eth.src");
        o.dst = this.getRowNodebyField("eth.dst");
      }

      if (type == "ip" || type == "ipv6" || type == "udp" || type == "tcp") {
        o.src = this.getRowNodebyField("ip.src");
        if (o.src == undefined) {
          o.src = this.getRowNodebyField("ipv6.src");
        }

        o.dst = this.getRowNodebyField("ip.dst");
        if (o.dst == undefined) {
          o.dst = this.getRowNodebyField("ipv6.dst");
        }
      }
      if (type == "udp") {
        o.srcport = this.getRowNodebyField("udp.srcport");
        o.dstport = this.getRowNodebyField("udp.dstport");
      } else if (type == "tcp") {
        o.srcport = this.getRowNodebyField("tcp.srcport");
        o.dstport = this.getRowNodebyField("tcp.dstport");
      }
      return o;
    },
    apply_as_column: function (field) {
      
      var ws_field = this.df_wireshark_field_to_es(field);
      var myprofile = this.currentAnalysis.profiles.selected;
      
      var newcolumn = {
        field: ws_field,
        headerName: field,
        hide: false,
        suppressSizeToFit: true,
        width: 90,
      };
      myprofile.columns.push(newcolumn);

      /* new column invalidates the packetlist cache */
      this.currentAnalysis.packetlistcacheinvalidate = true;

      /* only authorized users can update a profile */
      if(this.currentUser){
        ApiProfile.update(this.currentAnalysis.profiles.selected)
          .then(({ data }) => {
            this.currentAnalysis.profileneedsupdate = true;
          })
          .catch(({ response }) => {});
      }
    },
    cyberchefAICook: function (field, outputName) {
      let recipe = "";

      let recipies = {
        "dns.qry.name": {
          name: "name",
          recipe:
            "DNS_over_HTTPS('https://dns.google.com/resolve','A',false,false)",
        },

        "ipv6.src": {
          name: "IPv6",
          recipe: "Parse_IPv6_address()",
        },
        "ipv6.dst": {
          name: "IPv6",
          recipe: "Parse_IPv6_address()",
        },
      };
      if (field != null) {
        let lhs = field;
        if (lhs in recipies) {
          if (outputName) {
            return recipies[lhs].name;
          } else {
            return recipies[lhs].recipe;
          }
        }
        return null;
      }
      return null;
    },
    getContextMenuItems: function (params) {
      var id = this.id;

      var t = this.treeOptions.api;
      let guessName = null;
      var field = params.node.data["field"];
      var value = params.node.data["value"];
      if (
        "filter" in params.node.data &&
        params.node.data["filter"].includes("==")
      ) {
        let tmp = params.node.data["filter"].split("==");
        value = tmp[1].trim();
      } else {
        value = null;
      }
      var type = params.node.data["field"];

      var o = this.getConversationTuple(params, type);

      var result = [];

      var l_openexternal = {
        name: "Open in external tab",
        action: function () {
          window.open("/decode/" + id);
        },
        cssClasses: ["redFont", "bold"],
      };

      let filter;
      if ("filter" in params.node.data) {
        filter = params.node.data["filter"];
      } else {
        filter = this.df_filter(field, value);
      }

      if (value) {
        var l_explain = {
          name: "Explain " + field,
          action: function () {
            this.illustrate = { field: field, value: value };
            this.dialogIllustration = true;
          }.bind(this),
          cssClasses: ["redFont", "bold"],
        };

        guessName = this.cyberchefAICook(field, true);
        if (guessName) {
          var l_cyberchef = {
            name: "Analyze " + guessName + " in Cyberchef",
            action: function () {
              let url = "https://gchq.github.io/CyberChef/#recipe=";

              let guessOptions = this.cyberchefAICook(field, false);
              if (guessOptions != null) {
                url = url + guessOptions;
              }

              url = url + "&input=";

              value = value.replace(/^"(.+(?="$))"$/, "$1");
              let b64 = btoa(value);
              let b64c = b64.replace(/=/g, "");
              url = url + b64c;
              window.open(url);
            }.bind(this),
            cssClasses: ["redFont", "bold"],
          };
        }

        var l_cyberchef_plain = {
          name: "Analyze value in Cyberchef",
          action: function () {
            let url = "https://gchq.github.io/CyberChef/";
            url = url + "#input=";

            let b64 = btoa(value);
            let b64c = b64.replace(/=/g, "");
            url = url + b64c;
            window.open(url);
          }.bind(this),
          cssClasses: ["redFont", "bold"],
        };
      }
      var l_field = {
        name: "Filter for " + filter,
        action: function () {
          if ("filter" in params.node.data) {
            this.df_explicit(filter);
          } else {
            this.df_filter(field, value, "==", true);
          }
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      var l_rst_filters = {
        name: "Reset decode filters",
        action: function () {
          this.resetfilters();
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      var l_add_decode_filter = {
        name: "Decode filter for " + filter,
        action: function () {
          if ("filter" in params.node.data) {
            this.adddecodefilter(params.node.data);
          }
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      var l_conv = {
        name: "Conversation filter for " + field,
        action: function () {
          this.df_conv_filter(type, o.src, o.srcport, o.dst, o.dstport, true);
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      var l_apply_as_column = {
        name: "Apply as column: " + field,
        action: function () {
          this.apply_as_column(field);
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      if (df_convtypes.includes(field)) {
        result.push(l_conv);
      } else {
        result.push(l_field);
        result.push(l_explain);
      }

      result.push(l_apply_as_column);

      if (this.decodefilters.length > 0) {
        result.push(l_rst_filters);
      }

      if (!this.decodefilters.includes(field)) {
        result.push(l_add_decode_filter);
      }

      if (guessName) {
        result.push(l_cyberchef);
      }
      result.push(l_cyberchef_plain);
      result.push(l_openexternal);
      result.push("copy");
      return result;
    },
  },
};
</script>


<style>
/*
#decode .ag-row-odd:not(.ag-row-selected) {
  background-color: #fff !important;
}
*/
/* #decode .ag-body-viewport {
  overflow-x: hidden;
} */

/* selected rows overwrite normal coloring */

/*
.ag-row-selected {
    background-color: #0e151d !important;
		color: black !important;
}*/

#decode .ag-tool-panel {
  visibility: hidden;
  width: 0px;
}

#decode .ag-theme-balham .ag-root {
  border: 0px !important;
}

#decode .ag-theme-balham,
#decode .ag-theme-balham-dark .ag-cell {
  padding: 0px 0px 0px 0px !important;
}

#decode .ag-root {
  border: 0px solid #bdc3c7 !important;
}

#decode .ag-row {
  border-bottom: 0px solid #bdc3c7 !important;
}

#decode .ag-header {
  border-bottom: 0px solid #bdc3c7;
}

#decode .ag-theme-balham .field-unindexed {
  color: #000;
}

#decode .ag-theme-balham .field-indexed {
  color: #000000;
  background: #dedede;
}

#decode.ag-theme-balham ag-row:not(.ag-row-first) {
  border-width: 0px 0 0;
}

#decode .ag-theme-balham .ag-row:not(.ag-row-first) {
  border-width: 0px 0 0;
}

/*
#decode .ag-theme-balham, #decode .ag-theme-balham-dark {
  font-family: monospace !important;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;

}*/

#decode .ag-row {
  border-bottom: 0px solid #bdc3c7 !important;
}

#decode .ag-cell,
#decode .ag-cell-focus,
#decode .ag-cell-no-focus {
  border: none !important;
}

#decode .no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

#decode .ag-cell:focus {
  border: none !important;
  outline: none;
}

#decode .marked-frame {
  background: black;
  color: white;
}

#decode .ag-status-bar {
  display: none !important;
}

.application.theme--light {
  background: #ffffff;
}
</style>