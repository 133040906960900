<template>
  <v-row class="mx-7 mt-3 mb-3">

    <v-dialog v-model="showcapturepoint" max-width="1200">
      <v-card>
        <v-card-title class="headline">Capture point graph per connection. Location is based on TTL, MAC adresses and
          RTT during handshake.</v-card-title>

        <v-card-text>
          The client is the TCP layer client that sends the SYN which might differ from the layer 7 client for protocols
          like active FTP.
        </v-card-text>
        <capturepointgraph v-if="selectedconn" :pcapid="currentAnalysis.pcapid" :connection="selectedconn">
        </capturepointgraph>
        <handshakegraph v-if="selectedconn" :pcapid="currentAnalysis.pcapid" :connection="selectedconn">
        </handshakegraph>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showtcpgraph" max-width="1200">
      <v-card>
        <v-card-title class="headline">TCP Stream Graph</v-card-title>

        <v-card-text>
          Description
        </v-card-text>
        <tcptrace v-if="selectedconn" :pcapid="currentAnalysis.pcapid" :connection="selectedconn"></tcptrace>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-col cols="12" class="mt-8">

      <v-card class="mx-auto">
        <v-card-title>{{ type | capitalize }} connections in PCAP</v-card-title>
        <v-card-text>


          <v-data-table class="connections elevation-1" :headers="headers" :items="connections"
            :hide-default-footer="totalitems < 100" :options.sync="tableoptions" hide-default-footer disable-pagination>

            <template slot="item" slot-scope="props">
              <tr>
                <!--<td @contextmenu="contextMenu($event, props.item)">{{ props.item.src.ip }}</td>-->
                <td v-if="props.item.src.eth">


                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'eth.src == ' + props.item.src.eth, null)">
                    {{ props.item.src.eth }}
                  </a>
                </td>
                <td v-if="props.item.src.ip" @contextmenu="contextMenu($event, props.item)">

                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'ip.src == ' + props.item.src.ip, null)">
                    {{ props.item.src.ip }}
                  </a>

                </td>
                <td v-if="props.item.src.port">

                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'tcp.port == ' + props.item.src.port, null)">
                    {{ props.item.src.port }}
                  </a>
                </td>

                <td v-if="props.item.dst.eth">
                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'eth.dst == ' + props.item.dst.eth, null)">
                    {{ props.item.dst.eth }}
                  </a>

                </td>
                <td v-if="props.item.dst.ip">

                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'ip.src == ' + props.item.dst.ip, null)">
                    {{ props.item.dst.ip }}
                  </a>
                </td>
                <td v-if="props.item.dst.port">
                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'tcp.port == ' + props.item.dst.port, null)">
                    {{ props.item.dst.port }}
                  </a>
                </td>

                <td v-if="props.item.flags">

                  <v-tooltip top v-if="lookupflagdef(props.item.flags)">
                    <template v-slot:activator="{ on, attrs }">
                      <!-- hide the underline link -->
                      <a style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', getFilter(props.item, type), null)">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon :color="lookupflagdef(props.item.flags).color"
                            :class="lookupflagdef(props.item.flags).class">{{ lookupflagdef(props.item.flags).icon
                            }}</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    <span>
                      {{ lookupflagdef(props.item.flags).tooltip }} (Flags: {{ props.item.flags }})
                    </span>
                  </v-tooltip>

                </td>

                <td v-if="props.item.packets_over_time">

                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', getFilter(props.item, type), null)">
                    <pcapsparkline :packetsovertime="props.item.packets_over_time"></pcapsparkline>
                  </a>
                </td>

                <td v-else class="py-4">

                </td>
                <td>{{ props.item.total.doc_count }}</td>
                <td>{{ props.item.total.cummulative_bytes }}</td>
                <td>{{ props.item.src.doc_count }}</td>
                <td>{{ props.item.src.cummulative_bytes }}</td>
                <td>{{ props.item.dst.doc_count }}</td>
                <td>{{ props.item.dst.cummulative_bytes }}</td>
                <td>{{ props.item.total.delta | formatDelta }}</td>

                <td v-for="c in getcustomcolumns()" v-bind:key="c['name']">

                  <a style="text-decoration: none; color: inherit;"
                    @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', '(' + getFilter(props.item, type) + ') and ' + c['field'], null)">

                    <v-chip v-if='c["field"] == "tcp.analysis.retransmission"' color="red lighten-1" small ripple>{{
                        props.item.total[df_wireshark_field_to_es(c["field"])]
                    }}</v-chip>
                    <template v-else-if='props.item.total[df_wireshark_field_to_es(c["field"])]'>
                      {{ props.item.total[df_wireshark_field_to_es(c["field"])] }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </a>

                </td>

                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', getFilter(props.item, type), null)">
                        <v-icon small v-on="on" color="primary">filter_list</v-icon>
                      </a>
                    </template>
                    <span>Filter</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="type == 'tcp'">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pl-1" small color="primary"
                        @click="showCapturePointGraph(props.item)">mdi-lan</v-icon>
                    </template>
                    <span>
                      Show capture graph
                    </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="type == 'tcp'">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pl-1" small color="primary"
                        @click="showTcpGraph(props.item)">mdi-chart-bell-curve-cumulative
                      </v-icon>
                    </template>
                    <span>
                      Show TCP graph
                    </span>
                  </v-tooltip>



                </td>
              </tr>
            </template>

          </v-data-table>

          <v-expansion-panels v-model="advancedpanel" class="mt-7">

            <v-expansion-panel key="1">
              <v-expansion-panel-header>Advanced</v-expansion-panel-header>
              <v-expansion-panel-content v-if="advancedpanel == 0">
                <v-row class="ma-7">
                  <v-menu v-model="menu.showMenu" :position-x="menu.x" :position-y="menu.y" absolute offset-y>
                    <v-list>
                      <v-list-item v-for="(item, index) in menu.items" :key="index" @click>
                        <v-list-item-title v-on:click="item.action">{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-col cols="12" class="py-2">Filter connections based on displayfilter</v-col>
                  <v-col cols="8">

                    <filterbox :livemode="true" v-model="displayfilter"></filterbox>
                  </v-col>

                  <v-col cols="2">
                    <v-select hide-details v-model="type" :items="endpointtypes" label="Endpoint Type" solo
                      item-text="Type"></v-select>
                  </v-col>

                  <v-col cols="12" class="py-2 mt-5">Add aggregated fields to connection overview</v-col>
                  <v-col cols="12" class="ml-5 ">

                    <v-row class="py-0 mt-0 pt-0" v-for="c in customcolumns" v-bind:key="c['key']">
                      <v-col cols="2" class="py-0 px-1">
                        <v-text-field solo v-model="c['name']" label="Name" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="3" class="py-0 px-1">
                        <v-combobox solo hide-details v-model="c['type']" :items="types" label="Type"
                          item-text="Type"></v-combobox>
                      </v-col>
                      <v-col cols="5" class="py-0 px-4 mr-2">
                        <v-combobox solo hide-details v-model="c['field']" :items="indexedcolumns"
                          item-text="wiresharkfield" item-value="wiresharkfield" label="Aggregation field"></v-combobox>
                      </v-col>
                      <v-col cols="1" class="pt-3 px-1">
                        <v-icon color="info" v-on:click="removeColumn(c)">delete</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="8" class="mt-4"></v-col>
                  <v-col cols="2" class="mt-4">
                    <v-btn color="info" v-on:click="addColumn">
                      Add column
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="2" class="mt-4">
                    <v-btn color="info" v-on:click="getConnectionsCustom">
                      Refresh
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-col>

                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12" class="mt-8">


      <v-expansion-panels v-model="connectionpanels">

        <v-expansion-panel key="1">
          <v-expansion-panel-header>Timeline of {{ type | capitalize }} connections</v-expansion-panel-header>
          <v-expansion-panel-content>
            <timelinechart v-if="connectionpanels == 0" :pcapid="currentAnalysis.pcapid" :connections="connections"
              :ctype="type"></timelinechart>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel key="2">
          <v-expansion-panel-header>Chord diagram of {{ type | capitalize }} connections</v-expansion-panel-header>
          <v-expansion-panel-content>

            <connectionchord v-if="connectionpanels == 1" :pcapid="currentAnalysis.pcapid" :connections="connections"
              :ctype="type"></connectionchord>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel key="3">
          <v-expansion-panel-header>Protocol word cloud</v-expansion-panel-header>
          <v-expansion-panel-content>

            <protocoldistribution v-if="connectionpanels == 2" :pcapid="currentAnalysis.pcapid" :pcap="pcap">
            </protocoldistribution>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-col>



  </v-row>
</template>

<script>
import ApiConnections from "@/common/api/connections";
import ApiIndexerprofile from "@/common/api/indexerprofile";
import Filterbox from "./Filterbox";
import Timelinechart from "./Timelinechart";
import Connectionchord from "./Connectionchord";

import Capturepointgraph from "./Capturepointgraph"
import Tcptrace from "./Tcptrace"
import Handshakegraph from "./Handshakegraph"
import { navigation } from "@/common/navigation";
import Pcapsparkline from "./basics/Pcapsparkline";

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import Protocoldistribution from './Protocoldistribution.vue';

export default {
  name: "Connections",
  props: {
    index: null,
    pcapid: null,
    pcap: null
  },
  components: {
    Connectionchord,
    Filterbox,
    Capturepointgraph,
    Tcptrace,
    Handshakegraph,
    Timelinechart,
    Pcapsparkline,
    Protocoldistribution
  },
  mixins: [displayfilter, navigation],
  watch: {
    "type": function (n, o) {
      if (n != "tcp") {
        this.customcolumns = []
      }
    },
    "advancedpanel": function (n, o) {

      if (n == 0) {
        this.loadindexedcolumns();
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      return value.toUpperCase()
    }
  },
  data() {
    return {
      totalitems: 0,
      tableoptions: {
        itemsPerPage: 10
      },
      connectionpanels: null,
      advancedpanel: null,
      expanded: [],
      precision: 3,
      selectedconn: null,
      showcapturepoint: false,
      showtcpgraph: false,
      flagdefs: [
        {
          flagstr: "SR",
          class: "",
          color: "red",
          icon: "mdi-circle-outline",
          tooltip: "Connection handshake was reset"
        },
        {
          flagstr: "SREC",
          class: "",
          color: "red",
          icon: "mdi-circle-outline",
          tooltip: "Connection handshake of connection supporting RFC3168 was reset"
        },
        {
          flagstr: "SF",
          class: "",
          color: "orage",
          icon: "mdi-circle-outline",
          tooltip: "Connection reset was closed"
        },
        {
          flagstr: "S",
          class: "",
          color: "blue",
          icon: "mdi-circle-outline",
          tooltip: "Handshake not answered"
        },
        {
          flagstr: "SA",
          class: "",
          color: "blue",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection in progress"
        },
        {
          flagstr: "SAP",
          class: "",
          color: "blue darken-2",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection in progress with push bit "
        },
        {
          flagstr: "SAPE",
          class: "",
          color: "blue darken-2",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection supporting RFC3168 in progress with push bit "
        },
        {
          flagstr: "SAPEC",
          class: "",
          color: "blue darken-2",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection in progress with push bit and supporting RFC3168"
        },
        {
          flagstr: "AP",
          class: "icon__rotate",
          color: "blue",
          icon: "mdi-circle-slice-3",
          tooltip: "Connection in progress but start and end not in trace"
        },
        {
          flagstr: "AF",
          class: "icon__flip",
          color: "green",
          icon: "mdi-circle-slice-3",
          tooltip: "Connection without handshake closed"
        },
        {
          flagstr: "AR",
          class: "icon__flip",
          color: "red",
          icon: "mdi-circle-slice-3",
          tooltip: "Connection without handshake reset"
        },
        {
          flagstr: "SAF",
          class: "",
          color: "green",
          icon: "mdi-circle-slice-8",
          tooltip: "Complete transfer successful"
        },
        {
          flagstr: "SAPF",
          class: "",
          color: "green",
          icon: "mdi-circle-slice-8",
          tooltip: "Complete transfer successfull"
        },
        {
          flagstr: "SAPR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer completed but closed with reset"
        },
        {
          flagstr: "SAFR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer completed but connection was reset after FIN"
        },
        {
          flagstr: "SAPFR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer with PUSH bit completed but connection was reset after FIN"
        },
        {
          flagstr: "SAPFEC",
          class: "",
          color: "green darken-2",
          icon: "mdi-circle-slice-8",
          tooltip: "Connection in progress with push bit8"
        },
        {
          flagstr: "SAPREC",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer supporting RFC316 completed but closed with reset"
        },
        {
          flagstr: "SAR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer completed but closed with reset"
        }

      ],
      rpcapid: null,
      rindex: "none",
      indexedcolumns: [],
      displayfilter: "",
      types: ["sum", "avg", "min", "max"],
      customcolumns: [
        {
          name: "Initial RTT",
          type: "avg",
          field: "tcp.analysis.initial_rtt",
        },
        {
          name: "Retransmissions",
          type: "sum",
          field: "tcp.analysis.retransmission",
        }
      ],
      connections: [],
      cmap: {},
      type: "tcp",
      activetype: this.type,
      endpointtypes: ["ipv4", "ipv6", "tcp", "udp", "eth"],
      endpointtypevis: {
        ipv4: {
          ipv4: true,
          ipv6: false,
          ipmerged: false,
          tcp: false,
          udp: false,
          eth: false
        },
        ipv6: {
          ipv4: false,
          ipv6: true,
          ipmerged: false,
          tcp: false,
          udp: false,
          eth: false
        },
        tcp: {
          ipv4: false,
          ipv6: false,
          ipmerged: true,
          tcp: true,
          udp: false,
          eth: false
        },
        udp: {
          ipv4: false,
          ipv6: false,
          ipmerged: true,
          tcp: false,
          udp: true,
          eth: false
        },
        eth: {
          ipv4: false,
          ipv6: false,
          ipmerged: false,
          tcp: false,
          udp: false,
          eth: true
        }
      },
      menu: {
        showMenu: false,
        x: 0,
        y: 0,
        items: []
      }
    };
  },
  created() { },
  mounted() {
    if (this.pcapid) {
      this.currentAnalysis.pcapid = this.pcapid
    }

    if (this.index) {
      this.currentAnalysis.index = this.index
    }

    this.getConnectionsCustom();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),

    headers: function () {
      var h = [];

      switch (this.activetype) {
        case "eth":
          h.push({ text: "Source", align: "left", value: "src.eth" });
          h.push({ text: "Destination", align: "left", value: "dst.eth" });
          break;
        case "ipv4":
          h.push({ text: "Source", align: "left", value: "src.ip" });
          h.push({ text: "Destination", align: "left", value: "dst.ip" });
          break;
        case "ipv6":
          h.push({ text: "Source", align: "left", value: "src.ip" });
          h.push({ text: "Destination", align: "left", value: "dst.ip" });
          break;
        case "tcp":
          h.push({ text: "Source", align: "left", value: "src.ip" });
          h.push({ text: "SrcPort", align: "left", value: "src.port" });
          h.push({ text: "Destination", align: "left", value: "dst.ip" });
          h.push({ text: "DstPort", align: "left", value: "dst.port" });
          h.push({ text: "TCP Flags", align: "left", value: "flags" });
          break;
        case "udp":
          h.push({ text: "Source", align: "left", value: "src.ip" });
          h.push({ text: "Destination", align: "left", value: "dst.ip" });
          h.push({ text: "Source Port", align: "left", value: "src.port" });
          h.push({
            text: "Destination Port",
            align: "left",
            value: "dst.port"
          });
          break;
      }
      var defaults = [
        { text: "Graph", align: "left", value: "flags" },
        { text: "Packets", align: "left", value: "total.doc_count" },
        { text: "Bytes ", align: "left", value: "total.cummulative_bytes" },
        { text: "Packets S->D", value: "src.doc_count" },
        { text: "Bytes S->D", value: "src.cummulative_bytes" },
        { text: "Packets D->S", value: "dst.doc_count" },
        { text: "Bytes D->s", value: "dst.cummulative_bytes" },
        { text: "Duration", value: "total.delta" }
      ];

      h.push(...defaults);

      for (let c of this.customcolumns) {

        var n = {};
        n["text"] = c["name"];
        n["align"] = "left";
        n["value"] = "total." + this.df_wireshark_field_to_es(c["field"]);
        //n["value"] = "total.tcp#analysis#retransmission";
        h.push(n);
      }

      h.push({ text: "Actions", value: null });
      h.push({ text: "", value: 'data-table-expand' });
      return h;
    }
  },
  beforeDestroy() { },
  methods: {
    lookupflagdef: function (flag) {

      for (let f of this.flagdefs) {
        if (flag == f.flagstr) {
          return f
        }
      }

      let def = {
        flagstr: "-",
        class: "",
        color: "yellow",
        icon: "mdi-help-circle",
        tooltip: "No help for this flag combination"
      }
      return def
    },
    showCapturePointGraph(item) {
      this.selectedconn = item;
      this.showcapturepoint = true
    },
    showTcpGraph(item) {
      this.selectedconn = item
      this.showtcpgraph = true
    },
    loadindexedcolumns: function () {

      let i = this.currentAnalysis.index
      ApiIndexerprofile.get(i).then(({ data }) => {
        for (let f of data.fields) {
          this.indexedcolumns.push(
            {
              esfield: f,
              wiresharkfield: this.df_es_field_to_wireshark(f)
            }
          )
        }
      });
    },
    filterflags(flags) {
      var f = "tcp.flags == " + flags
      this.df_apply_to_packetlist(f)
      this.currentAnalysis.showConnections = false;
      this.currentAnalysis.showIOGraph = false;
    },
    filter(item) {
      var f = this.getFilter(item, this.type);
      this.df_apply_to_packetlist(f)
      this.currentAnalysis.showConnections = false;
      this.currentAnalysis.showIOGraph = false;
      this.navigatePacketview(this.currentAnalysis.pcapid, this.currentAnalysis.index, this.f);
    },
    makekey(a, b) {

      let port1 = a.split(",")[1]
      let port2 = b.split(",")[1]

      if (parseInt(port1) > parseInt(port2)) {
        return a + "->" + b;
      }
      return b + "->" + a;
    },
    aggregationoperation(s, d, c) {
      var src = parseFloat(s);
      var dst = parseFloat(d);
      var val;
      switch (c["type"]) {
        case "sum":
          val = src + dst;
          break;
        case "min":
          val = src < dst ? src : dst;
          break;
        case "max":
          val = src > dst ? src : dst;
          break;
        case "avg":
          val = (src + dst) / 2;
      }

      return val;
    },
    aggregate(connections) {
      var out = {};
      let custom = this.custom_filter;

      let aggs = [
        { name: "Total Packets", type: "sum", field: "doc_count" },
        { name: "Total Bytes", type: "sum", field: "cummulative_bytes" },
        { name: "Total Time", type: "max", field: "delta" }
      ];

      let totals = this.getcustomcolumns().concat(aggs);

      for (let src of connections) {

        for (let dest of src["dest"]["buckets"]) {

          var key = this.makekey(src["key"], dest["key"]);

          /* already in dict so fill in dest side */

          if (out[key]) {
            for (var c in dest) {
              if (c == "doc_count") {
                out[key].dst[c] = dest[c];
              } else if (c == "key") {
                continue;
              } else {
                out[key].dst[c] = dest[c]["value"];
              }
            }

            for (var c of totals) {
              var s = out[key].src[c["field"]];
              var d = out[key].dst[c["field"]];
              out[key].total[c["field"]] = this.aggregationoperation(s, d, c);
            }
          } else {
            let e = {
              src: {},
              dst: {},
              total: {},
              type: this.type
            };
            switch (this.type) {
              case "ipv4":
                e.src.ip = src["key"];
                e.dst.ip = dest["key"];
                break;
              case "ipv6":
                e.src.ip = src["key"];
                e.dst.ip = dest["key"];
                break;
              case "tcp":

                let srcs = src["key"].split(",");
                let dests = dest["key"].split(",");
                //FIXME: we can have multiple ips TOOD not implemented yet
                e.src.ip = srcs[0];
                e.src.port = parseInt(srcs[1]);
                e.dst.ip = dests[0];
                e.dst.port = parseInt(dests[1]);
                let flags = []

                for (let flag of dest.flags.buckets) {
                  flags.push(flag.key)
                }

                let mergedflags = this.tcp_merge_flags(flags)
                e.flagsraw = mergedflags
                e.flags = this.tcp_flags_human_readable(mergedflags)
                e.packets_over_time = dest.packets_over_time.buckets
                break;
              case "udp":
                let srcsudp = src["key"].split(",");
                let destsudp = dest["key"].split(",");
                //FIXME: we can have multiple ips TOOD not implemented yet
                e.src.ip = srcsudp[0];
                e.src.port = parseInt(srcsudp[1]);
                e.dst.ip = destsudp[0];
                e.dst.port = parseInt(destsudp[1]);
                e.packets_over_time = dest.packets_over_time.buckets

                break;
              case "eth":
                e.src.eth = src["key"];
                e.dst.eth = dest["key"];
                break;
            }

            for (var c in dest) {
              if (c == "doc_count") {
                e.src[c] = dest[c];

              } else if (c == "key") {
                continue;
              } else {
                e.src[c] = dest[c]["value"];
              }
              // set defaults for other side in case it is one directional
              e.dst[c] = 0

            }

            /* set total to the current in case it is a one way connection */
            /* if it is two way it will be updated later in the loop */
            // already built totals in case we have a unidirectional connection
            // FIXME: two options a cusotm field or just an aggeregated field
            for (var c of totals) {


              let field = c["field"]

              if (field.includes(".")) {
                let esfield = this.df_wireshark_field_to_es(field)
                try {
                  if ((Number.isInteger(parseFloat(dest[esfield]["value"])))) {
                    e.total[esfield] = parseFloat(dest[esfield]["value"]);
                  } else {
                    e.total[esfield] = parseFloat(dest[esfield]["value"]).toFixed(this.precision);
                  }
                } catch (e) {
                  e.total[esfield] = dest[esfield]["value"];
                }
              } else {
                var s = e.src[field];
                var d = 0;
                e.total[field] = this.aggregationoperation(s, d, c);
              }
            }
            out[key] = e;
          }
        }
      }

      //change to order so it goes from high port to low port

      if (this.type == "tcp" || this.type == "udp") {

        for (let t in out) {
          let tmpsrc = out[t].src
          let tmpdst = out[t].dst

          if (this.comparePorts(tmpsrc.port, tmpdst.port)) {
            out[t].src = tmpdst
            out[t].dst = tmpsrc
          }
        }
      }

      this.connections = Object.values(out);
    },
    comparePorts(a, b) {
      // active ftp
      let activeftpport = 20
      if (b > a && a != activeftpport) {
        return true
      }
      return false
    },
    addColumn() {
      var n = {};
      n["key"] = this.customcolumns.length + 1;
      n["name"] = "";
      n["type"] = "sum";
      n["field"] = "";
      this.customcolumns.push(n);
    },

    removeColumn(column) {
      this.customcolumns.splice(this.customcolumns.indexOf(column), 1);
    },
    getcustomcolumns() {
      let out = []
      let f

      for (let c of this.customcolumns) {

        if (typeof c.field === 'string' || c.field instanceof String) {
          f = c.field
        } else {
          f = c.field.wiresharkfield
        }

        out.push(
          {
            key: c.key,
            name: c.name,
            type: c.type,
            field: f
          }
        )
      }
      return out;
    },
    getConnectionsCustom() {

      let { sortBy, sortDesc, page, itemsPerPage } = this.tableoptions;
      let offset = (page - 1) * itemsPerPage;

      if (offset == null) {
        offset = 0;
      }


      const params = {
        sortBy: sortBy,
        sortDesc: sortDesc,
        perpage: 100,
        offset: offset,
        page: page,
        "pcapid": this.currentAnalysis.pcapid,
        "index": this.currentAnalysis.index,
        "type": this.type,
        "start": this.currentAnalysis.timelinestart,
        "end": this.currentAnalysis.timelineend,
        "filter": this.displayfilter,
        "customcolumns": this.getcustomcolumns()
      }


      ApiConnections.list(params).then(({ data }) => {
        this.activetype = this.type;
        this.aggregate(data);
        this.totalitems = data.length
      });
    },
    contextMenu(e, item) {
      e.preventDefault();
      this.menu.showMenu = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;

      this.menu.items = [
        {
          title: "Conversation Filter",
          action: function () {
            this.filter(item);
          }.bind(this)
        }
      ];

      this.$nextTick(() => {
        this.menu.showMenu = true;
      });
    }
  }
};
</script>

<style>
.connections td {
  height: 24px !important;
}

.icon__flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.icon__rotate {
  -moz-transform: rotate(80deg);
  -o-transform: rotate(80deg);
  -webkit-transform: rotate(80deg);
  transform: rotate(80deg);

}
</style>
