<template>
    <span>
        {{ val }}
    </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentAnalysis"]),
  },
    data() {
        return {
            val: null,
        }   
    },
  mounted() {
    this.val = this.relativeToMax(this.params.value)
  },
  methods: {
      relativeToMax(v){
          if("field" in this.params.colDef){
            let colfield = this.params.colDef.field
            
            if(colfield in this.currentAnalysis.rendering.fields){
                if (v in this.currentAnalysis.rendering.fields[colfield]){
                    return this.currentAnalysis.rendering.fields[colfield][v]
                }
            }
          }

          return v
      }
  }
};
</script>

<style scoped>
.cell {
    color: blue;
}
</style>