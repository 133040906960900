<template>
  <v-row class="text-center pt-5" justify="center">
    <v-card max-width="500px">
      <v-card-title class="display-1">Registration</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container class="text-center justify" grid-list-xl>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.username"
                :rules="usernameRules"
                :counter="25"
                label="Username"
                required
              ></v-text-field>
              <span class="caption grey--text text--darken-1">
                This is the username you will use to login to your account
              </span>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="user.firstname"
                :rules="firstnameRules"
                :counter="25"
                label="Firstname"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="user.lastname"
                :rules="lastnameRules"
                :counter="25"
                label="Lastname"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="user.password"
                :append-icon="showpassword ? 'visibility_off' : 'visibility'"
                :rules="[rules.required, rules.min]"
                :type="showpassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showpassword = !showpassword"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-checkbox v-if="offeradmincreation" v-model="user.makeadmin">
                <template slot="label"
                  >There has not been an administrator set yet. Add this user to
                  the administrator role?</template
                >
              </v-checkbox>
            </v-col>
            <v-col cols="12" class="py-4">
              Agree to the
              <a href="https://www.packetsafari.com/legal/tos/legal/tos">Terms of Service</a>
              and <a href="https://www.packetsafari.com/legal/privacy/">Privacy Policy</a>
              <v-checkbox
                v-model="checkbox"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                required
              >
                <template slot="label">I agree to both</template>
              </v-checkbox>
            </v-col>

            <v-col class="x12v-if">{{ submitresponse }}</v-col>
          </v-row>

          <v-card-actions>
            <!--<v-btn @click="clear">clear</v-btn>-->
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" @click="submit" depressed color="primary"
              >submit</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-row>
</template>

<script>
import ApiService from "@/common/api.service";
import ApiUser from "@/common/api/user";
import { CURRENT_TOS_VERSION } from "@/common/config";
import { mapGetters } from "vuex";
import axios from 'axios'
export default {
  name: "register",
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error", "avatarPic"]),
  },
  data: () => ({
    submitresponse: "",
    offeradmincreation: false,
    user: {
      firstname: "",
      lastname: "",
      // motto: "",
      email: "",
      password: "",
      username: "",
      // twitter: "",
      // ask: "",
      // website: "",
      // location: "",
      // makeadmin: false
      acceptedtosversion: CURRENT_TOS_VERSION,
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match",
    },

    firstnameRules: [
      (v) => !!v || "Firstname is required",
      (v) =>
        (v && v.length <= 25) || "Firstname must be less than 25 characters",
    ],
    mottoRules: [
      (v) => v.length <= 50 || "Motto must be less than 50 characters",
    ],
    valid: true,
    lastnameRules: [
      (v) => !!v || "Lastname is required",
      (v) =>
        (v && v.length <= 25) || "Lastname must be less than 25 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v || "Password is required"],
    showpassword: false,
    usernameRules: [(v) => !!v || "Username is required"],
    select: null,
    checkbox: false,
  }),
  mounted: function () {
    this.checkadmincreated();
  },
  methods: {
    checkadmincreated() {
      ApiUser.checkadmincreated()
        .then(({ data }) => {
          if (data["admincreated"] == false) {
            this.offeradmincreation = true;
          }
        })
        .catch(({ response }) => {});
    },
    submit() {
      this.submitresponse = "";
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported

        ApiService.post("user", this.user)
          .then(({ data }) => {
            this.error.type = "info";
            this.error.msg = "Registered!";
            if(this.currentAnalysis.features.ENABLE_COGNITO){
              this.$router
                .push({
                  name: "Signup code",
                  params: { userin: this.user.username },
                })
                .catch((err) => {});
            } else {
              
                 this.$router
                .push({
                  name: "Login",
                  params: { userin: this.user.username },
                })
                .catch((err) => {});           
            }

          
            this.notifyLambda()
          })
          .catch(({ response }) => {
            this.submitresponse = response.data.error;
          });
      }
    },
    notifyLambda() {
      debugger
      let description = "New user registration "+this.user.username
      let c = { data: {}, description: description};
      axios
        .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", c)
        .then((res) => {})
        .catch((error) => {
        })
        .finally(() => {
        });
    },

    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>