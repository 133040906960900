var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-2"},[_c('v-card-title',[_vm._v("List of profiles")]),_c('v-card-text',{staticClass:"py-2"},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" Analysis Profiles allow you to configure various settings of the analyzer. Including layout, decoding settings, coloring rules, columns, and much more. Read the documentation for more info "),_c('linktodoc',{attrs:{"doc":"settings/profiles","text":"Learn why and how to use analysis profiles"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"solo":"","items":_vm.types},model:{value:(_vm.selectedtype),callback:function ($$v) {_vm.selectedtype=$$v},expression:"selectedtype"}}),(_vm.selectedtype == 'search')?_c('v-text-field',{model:{value:(_vm.searchterm),callback:function ($$v) {_vm.searchterm=$$v},expression:"searchterm"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.profiles,"hide-default-footer":_vm.totalitems < 10,"options":_vm.tableoptions,"server-items-length":_vm.totalitems},on:{"update:options":function($event){_vm.tableoptions=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[(
                    props.item.id != _vm.currentAnalysis.profiles.selected['id']
                  )?_c('v-icon',{attrs:{"disabled":!_vm.currentUser},on:{"click":function($event){$event.preventDefault();return _vm.selectProfile(props.item)}}},[_vm._v("radio_button_unchecked")]):_c('v-icon',{attrs:{"disabled":!_vm.currentUser}},[_vm._v("radio_button_checked")])],1),_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'Analysis Profile',
                    params: { profileid: props.item['id'] },
                  }}},[_vm._v(_vm._s(props.item["name"]))])],1),_c('td',[_vm._v(_vm._s(props.item["description"]))]),_c('td',[('permissions_owner' in props.item.permissions)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(props.item.permissions["permissions_owner"][0]["username"]))]):_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v("Default")])],1),_c('td',[(props.item.permissions.permissions_public)?_c('v-chip',{attrs:{"color":"green lighten-2"}},[_vm._v("public")]):_c('v-chip',{attrs:{"color":"red lighten-2","primary":""}},[_vm._v("private")])],1),_c('td',[(
                    _vm.isOwner(_vm.currentUser, props.item.permissions) &&
                    _vm.isPublic(props.item.permissions)
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"slot":"activator","color":"primary","small":""},on:{"click":function($event){return _vm.togglePublic(props.item)}},slot:"activator"},on),[_vm._v("cloud")])]}}],null,true)},[_c('span',[_vm._v("Profile is public, click to deny public access to profile")])]):(
                    _vm.isOwner(_vm.currentUser, props.item.permissions) &&
                    !_vm.isPublic(props.item.permissions)
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"slot":"activator","color":"primary","small":""},on:{"click":function($event){return _vm.togglePublic(props.item)}},slot:"activator"},on),[_vm._v("lock")])]}}],null,true)},[_c('span',[_vm._v("Profile is private, click to allow public access to profile")])]):_vm._e(),(_vm.isOwner(_vm.currentUser, props.item.permissions))?_c('v-icon',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.editProfile(props.item)}}},[_vm._v("edit")]):_vm._e(),(_vm.isOwner(_vm.currentUser, props.item.permissions))?_c('v-icon',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.removeProfile(props.item)}}},[_vm._v("delete")]):_vm._e(),_c('v-icon',{attrs:{"color":"info","disabled":!_vm.currentUser,"small":""},on:{"click":function($event){return _vm.copyProfile(props.item)}}},[_vm._v("file_copy")])],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }