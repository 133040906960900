<template>

    <v-row   class="ma-10 pa-0">
      <v-col cols="11">
        <div class="connectionchordchart" ref="chartdiv"></div>
      </v-col>
    </v-row>
</template>

<script>
import moment from "moment-timezone";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_animated);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { navigation } from "@/common/navigation";

export default {
  name: "connectionchord",
  props: {
    pcapid: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    },
    connections: null,
    ctype: null
  },

  components: {

  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      c: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
    };
  },
  watch: {
    "connections": function(n, o){
        this.c = this.connections
        this.apply()
    }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to away observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.c = this.connections
    this.iindex = this.index;
    this.ipcapid = this.pcapid
    this.apply();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {

    apply(){
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create("connectionchordchart", am4charts.ChordDiagram);



        // colors of main characters
        chart.colors.saturation = 0.45;
        chart.colors.step = 3;


        let data = []


        for(let o of this.c){
            
            try{
   
                let from = ""
                let to = ""
                switch(this.ctype){
                  case "tcp":
                    from = o.src.ip+":"+o.src.port
                    to = o.dst.ip+":"+o.dst.port
                    break;
                  case "udp":
                    from = o.src.ip+":"+o.src.port
                    to = o.dst.ip+":"+o.dst.port
                    break
                  case "ipv4":
                    from = o.src.ip
                    to = o.dst.ip
                    break
                  case "ipv6":
                    from = o.src.ip
                    to = o.dst.ip
                    break
                  case "eth":
                    from = o.src.eth
                    to = o.dst.eth
                    break
                }

                data.push(
                    {
                        from: from,
                        to: to,
                        value: o.total.cummulative_bytes,
                        object: o
                    }
                );
            } finally{}

        }

        chart.data = data

        chart.dataFields.fromName = "from";
        chart.dataFields.toName = "to";
        chart.dataFields.value = "value";


        chart.nodePadding = 0.5;
        chart.minNodeSize = 0.01;
        chart.startAngle = 80;
        chart.endAngle = chart.startAngle + 360;
        chart.sortBy = "value";
        chart.fontSize = 10;

        var nodeTemplate = chart.nodes.template;
        nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.tooltipText = "{name}'s bytes: {total}";

        // when rolled over the node, make all the links rolled-over
        nodeTemplate.events.on("over", function(event) {    
            var node = event.target;
            node.outgoingDataItems.each(function(dataItem) {
                if(dataItem.toNode){
                    dataItem.link.isHover = true;
                    dataItem.toNode.label.isHover = true;
                }
            })
            node.incomingDataItems.each(function(dataItem) {
                if(dataItem.fromNode){
                    dataItem.link.isHover = true;
                    dataItem.fromNode.label.isHover = true;
                }
            }) 

            node.label.isHover = true;   
        })

        // when rolled out from the node, make all the links rolled-out
        nodeTemplate.events.on("out", function(event) {
            var node = event.target;
            node.outgoingDataItems.each(function(dataItem) {        
                if(dataItem.toNode){
                    dataItem.link.isHover = false;                
                    dataItem.toNode.label.isHover = false;
                }
            })
            node.incomingDataItems.each(function(dataItem) {
                if(dataItem.fromNode){
                    dataItem.link.isHover = false;
                dataItem.fromNode.label.isHover = false;
                }
            })

            node.label.isHover = false;
        })

        var label = nodeTemplate.label;
        label.relativeRotation = 90;

        label.fillOpacity = 0.4;
        let labelHS = label.states.create("hover");
        labelHS.properties.fillOpacity = 1;

        nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        // link template
        var linkTemplate = chart.links.template;
        linkTemplate.strokeOpacity = 0;
        linkTemplate.fillOpacity = 0.15;
        linkTemplate.tooltipText = "{fromName} & {toName}:{value.value}";

        var hoverState = linkTemplate.states.create("hover");
        hoverState.properties.fillOpacity = 0.7;
        hoverState.properties.strokeOpacity = 0.7;


        linkTemplate.events.on("hit", function(ev) {
            
          let obj = ev.target.dataItem.dataContext.object
          let filter = this.df_conv_filter(obj.type, obj.src.ip, obj.src.port, obj.dst.ip, obj.dst.port);
          this.df_apply_to_packetlist(filter)
          this.currentAnalysis.showConnections = false;
          this.currentAnalysis.showIOGraph = false;
          this.navigatePacketview(this.currentAnalysis.pcapid, this.currentAnalysis.index, filter);
        }, this);




        this.chart = chart
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.timelinechartcontainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.connectionchordchart {
  min-height: 500px;
  width: 100%;
  height: 600px;
}

.container {
  height: 100%;
  max-width: 100%;
    overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
