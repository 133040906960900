<template>
    <v-sparkline
        :value="value"
        color="blue"
        line-width="4"
        stroke-linecap="round"
        fill
        smooth=15
        :gradient="['#42b3f4', '#99b7d8']"
        class="pcapsparkline"

    ></v-sparkline>
</template>

<script>

export default {
  data: () => ({
      labels: [

      ],
      value: [

      ],


    histogram: []


  }),


  props: {
    content: null,
    pcap: null,
    packetsovertime: null,

  },
  beforeMount(){
      
    if(this.pcap && this.pcap.histogram){
        this.histogram = this.pcap.histogram
        this.convertHistographToSparkline();
    }

     if(this.packetsovertime){
        this.histogram = this.packetsovertime
        this.convertHistographToSparkline();
    } 

  },
  inheritAttrs: false,
  components: {},
  name: "pcapsparkline",
  mixins: [],
  methods : {
      convertHistographToSparkline(){
          
          if (this.histogram.length == 1){
            // if length one just push it twice to make the diagram show *something*
            for(let i = 0; i< 2; i++){
              this.value.push(this.histogram[0]["doc_count"])
              this.labels.push(this.histogram[0]["key_as_string"])
            }
          }else{
            for(let i of this.histogram){
                this.value.push(i["doc_count"])
                this.labels.push(i["key_as_string"])
            }
          }

      }
  }
};
</script>

<style >
.pcapsparkline{
  max-height: 30px;
}
</style>