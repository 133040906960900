<template>
  <v-row class="text-center" justify="center">
    <v-card width="400px">
      <v-card-title class="display-1">Send password reset code via e-mail</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container class="text-center justify" >
          <v-row  >

            <v-col class="x12" v-if='submitresponse != ""' >{{ submitresponse }}</v-col>

            <v-col cols="12">
              <v-text-field
                v-model="user.username"
                :counter="25"
                label="Username"
                required
              ></v-text-field>
              
            </v-col>

            <v-col cols="4"><v-btn v-if="!codesent" @click="submit">submit</v-btn>
            </v-col> 
            <v-col cols="8"></v-col>

            <v-col cols="12" v-if="codesent">Code sent {{senttoemail}}</v-col>
            <v-col cols="12" v-if="codesent">
              <v-text-field
                v-model="user.code"
                :counter="6"
                label="Recovery up code"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="codesent">
            
              <v-text-field
                v-model="user.password"
                :append-icon="showpassword ? 'visibility_off' : 'visibility'"
                :rules="[rules.required, rules.min]"
                :type="showpassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showpassword = !showpassword"
              ></v-text-field>
                      </v-col>
          </v-row>

          <v-card-actions v-if="codesent">
            <v-btn :disabled="!valid" @click="setnewpassword">set password</v-btn>
          </v-card-actions>



        </v-container>
      </v-form>
    </v-card>

  </v-row>
</template>

<script>
import ApiUser from "@/common/api/user";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["error", "currentUser"]),
  },
  name: "forgotpassword",
  props: [],
  data: () => ({
    submitresponse: "",
    senttoemail: "",
    codesent : false,
    valid: true,
    shownewpassword : false,
    user : {
        username : "",
        code : "",
        password : ""
    },
    showpassword: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match"
    },
    passwordRules: [v => !!v || "Password is required"],
  }),
  mounted: function() {
      if(this.userin){
          this.user.username = this.userin
      }
  },
  methods: {

    submit() {
      this.submitresponse = "";
      if (this.$refs.form.validate()) {
        ApiUser.forgotpassword(this.user)
          .then(({ data }) => {
            this.codesent = true;
            this.senttoemail = data.email
          })
          .catch(({ response }) => {
            this.submitresponse = response.data.error
            this.codesent = false;
          });
      }
    },
    setnewpassword() {
        this.submitresponse = "";
        if (this.$refs.form.validate()) {

        ApiUser.setnewpassword(this.user)
            .then(({ data }) => {
            this.error.type = "info"
            this.error.msg = "Passwort set!"
            this.$router.push({ name: "Login" }).catch(err => {});
            })
            .catch(({ response }) => {
            
                this.submitresponse = response.data.error
            });
        }       
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>