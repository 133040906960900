<template>
  <v-dialog v-model="currentAnalysis.showfilterbuttondialog" width="400px">
    <v-card>
      <v-card-title>New filter button</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field solo label="Button Name" v-model="newbutton['rulename']"></v-text-field>
              <displayfilterbox
                v-model="newbutton['rule']"
                class="monofont"
              ></displayfilterbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="savebutton">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Displayfilterbox from "./Displayfilterbox";
import Vue from "vue";
import ApiProfile from "@/common/api/profile";
export default {
  props: {
    value: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Displayfilterbox,
  },
  name: "filterbuttondialog",
  data() {
    return {
      isnew: false,
      newbutton: {
        rulename: "",
        rule: "",
      },
    };
  },
  created: function () {},
  mounted() {
    this.assurerightselection(this.value)
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
  },
  methods: {
    
    assurerightselection(n){
      
      if(n >= 0
      ){
        this.isnew = false
        this.newbutton = this.currentAnalysis.profiles.selected.filterbuttons[n]
      } else {
        this.isnew = true
        this.newbutton = {"rulename": "", "rule": ""}
      }
    },
    savebutton() {
      
      if (!("filterbuttons" in this.currentAnalysis.profiles.selected)) {
        Vue.set(this.currentAnalysis.profiles.selected, "filterbuttons", []);
      }

      if(!this.isnew){
         this.currentAnalysis.profiles.selected.filterbuttons[this.editbutton] = this.newbutton
      } else{
        this.currentAnalysis.profiles.selected.filterbuttons.push(this.newbutton);
      }

      this.$emit('updated')
      this.currentAnalysis.showfilterbuttondialog = false

      ApiProfile.update(this.currentAnalysis.profiles.selected)
        .then(({ data }) => {
          //this.currentAnalysis.profileneedsupdate = true     
          this.currentAnalysis.profileneedsupdate = true     
        })
        .catch(({ response }) => {
      });
    },
  },
};
</script>

<style >
</style>
