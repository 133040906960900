<template>
  <!-- all 100% and overflow: hidden absolutely necessary here 
  update: overflow: hidden; removed replaced by height 100%-->

  <div id="hexviewgrid" style="width: 100%; height: 100%">
    <v-tabs v-if="hasdecodings" v-model="tab" dark height="20">
      <v-tab>Hexview</v-tab>
      <v-tab v-for="d in decodings" v-bind:key="d.name">{{ d.name }}</v-tab>
    </v-tabs>

    <div id="hexview" style="width: 100%; height: 100%">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        :class="theme"
        :gridOptions="gridOptions"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

import { AGGRID_LICENSE } from "@/common/config";
LicenseManager.setLicenseKey(AGGRID_LICENSE);

import { bytesToBase64 } from "@/common/base64";

import { displayfilter, df_convtypes } from "@/common/displayfilter";
import packethandling from "@/common/packethandling";
import { commonmethods } from "@/common/common";
export default {
  name: "hexview",
  mixins: [displayfilter, packethandling, commonmethods],
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      decodenum: 0,

      tab: null,
      currentpacket: null,
      decodings: [],
      hoveredcell: -1,
      hasdecodings: false,
      fromdecode: {
        start: null,
        end: null,
        ds: -1,
        filter: null,
      },
      fromhexview: {
        start: null,
        end: null,
      },

      currentbytes: null,
      currentoffsetdefs: [],
      styledef: undefined,
      gridOptions: {
        context: {
          parent: this,
        },
        defaultColDef: {
          width: 20,
          sortable: false,
          resizable: true,
          cellClassRules: {
            "rag-red": function (params) {
              let verdict = this.isPartOfHovererInterval(params)
              if (params.colDef.hasOwnProperty("id")) {
                let anycell = params.colDef.id +  16 * params.rowIndex;
                if(verdict){
                 
                  if(this.fromhexview.start == null || anycell < this.fromhexview.start)
                    this.fromhexview.start = anycell
                  if(this.fromhexview.end == null || anycell > this.fromhexview.end)
                    this.fromhexview.end = anycell
                }
              }
              return verdict
            }.bind(this),
          },
        },
        onCellClicked: this.cellClicked,
        onCellMouseOver: this.cellHovered,
        columnDefs: [],
        getMainMenuItems: function (params) {}.bind(this),
        getContextMenuItems: this.getContextMenuItems,
        onGridReady: function () {
          this.setupGrid();

          
          this.gridOptions.api.setHeaderHeight(0);

          this.preparestyledef();
          if (this.packet !== undefined) {
            this.showPacketHex(this.packet);
          }

          //this.fetchShowPacket(this.id, this.packet)
          //this.fetchShowPacket(this.id, 1, 10);
        }.bind(this),
        onRowDataChanged: function () {}.bind(this),
        onRowClicked: function () {},
        onRowSelected: function (params) {}.bind(this),
      },
    };
  },
  components: {
    AgGridVue,
  },
  computed: {
    theme: function () {
      if (this.currentAnalysis.profiles.selected.theme) {
        return "ag-theme-balham-dark";
      }
      return "ag-theme-balham";
    },
  },
  watch: {
    tab: function (n, o) {
      this.decodenum = n;
    },
    "currentAnalysis.cpacket": function (n, o) {
      this.decodings.length = 0;
      if (n != undefined && "ds" in n) {
        this.hasdecodings = true;
        this.decodings.push(...n.ds);
      } else {
        this.hasdecodings = false;
        this.tab = 0;
      }
      this.packet = n;
      this.showPacketHex(n);
    },
    decodenum: function (n, o) {
      if (n != o) this.showPacketHex(this.packet);
    },
  },
  mounted() {
    // this.$eventHub.$on("active-packet", num => {

    // });
    this.$eventHub.$on("clicked-in-decode", (filter) => {
      let candidates = this.getintervalsforfilter(
        filter,
        this.currentoffsetdefs
      );
      // there should be only one
      if (!candidates || candidates.length < 1) {
        return;
      }

      let can = candidates[0];
      // select the last byte so always the largest match, add one for
      // because counting of cells is offset by one (first column)
      let sel = can[0] + can[1];
      this.hoveredcell = sel;

      this.fromdecode.filter = filter;
      this.fromdecode.start = can[0];
      this.fromdecode.offset = can[1];
      this.fromdecode.ds = can[3];

      if (this.decodenum != this.fromdecode.ds) {
        this.decodenum = this.fromdecode.ds;
        this.tab = this.decodenum;
        this.showPacketHex(this.packet);
      }

      this.gridOptions.api.ensureIndexVisible(this.offsettorow(can[0]), "top");

      this.gridOptions.api.refreshCells();
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("clicked-in-decode");
  },
  methods: {
    isPartOfHovererInterval(params) {
      if (params.colDef.hasOwnProperty("id")) {
        let anycell = params.colDef.id + 16 * params.rowIndex;

        if (params.colDef.field == "bytepadding") {
          return this.belongstoselectedfield(anycell, true);
        }
        return this.belongstoselectedfield(anycell);
      } else if (params.colDef.hasOwnProperty("charid")) {
        let anycell = params.colDef.charid + 16 * params.rowIndex;
        if (params.colDef.field == "charpadding") {
          return this.belongstoselectedfield(anycell, true);
        }
        return this.belongstoselectedfield(anycell);
      }

      return false;
    },

    setupGrid() {
      let byteMinWidth = 24;
      let byteWidth = 24;
      let asciiMinWidth = 10;
      let asciiWidth = 10;

      let columnDefs = [
        { headerName: "Row", field: "row", width: 85, suppressSizeToFit: true },
        {
          headerName: "byte1",
          field: "byte1",
          id: 1,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
          
        },
        {
          headerName: "byte2",
          field: "byte2",
          id: 2,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,

        },
        {
          headerName: "byte3",
          field: "byte3",
          id: 3,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte4",
          field: "byte4",
          id: 4,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte5",
          field: "byte5",
          id: 5,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte6",
          field: "byte6",
          id: 6,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte7",
          field: "byte7",
          id: 7,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte8",
          field: "byte8",
          id: 8,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "bytepadding",
          field: "bytepadding",
          id: 8,
          width: 13,
          minWidth: 13,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte9",
          field: "byte9",
          id: 9,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte10",
          field: "byte10",
          id: 10,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte11",
          field: "byte11",
          id: 11,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte12",
          field: "byte12",
          id: 12,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte13",
          field: "byte13",
          id: 13,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte14",
          field: "byte14",
          id: 14,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte15",
          field: "byte15",
          id: 15,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "byte16",
          field: "byte16",
          id: 16,
          width: byteWidth,
          minWidth: byteMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "padding",
          field: "padding",
          width: 40,
          suppressSizeToFit: true,
        },
        {
          headerName: "char1",
          field: "char1",
          charid: 1,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char2",
          field: "char2",
          charid: 2,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char3",
          field: "char3",
          charid: 3,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char4",
          field: "char4",
          charid: 4,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char5",
          field: "char5",
          charid: 5,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char6",
          field: "char6",
          charid: 6,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char7",
          field: "char7",
          charid: 7,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char8",
          field: "char8",
          charid: 8,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "charpadding",
          field: "charpadding",
          charid: 8,
          width: 20,
          minWidth: 20,
          suppressSizeToFit: true,
        },
        {
          headerName: "char9",
          field: "char9",
          charid: 9,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char10",
          field: "char10",
          charid: 10,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char11",
          field: "char11",
          charid: 11,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char12",
          field: "char12",
          charid: 12,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char13",
          field: "char13",
          charid: 13,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char14",
          field: "char14",
          charid: 14,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char15",
          field: "char15",
          charid: 15,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "char16",
          field: "char16",
          charid: 16,
          width: asciiWidth,
          minWidth: asciiMinWidth,
          suppressSizeToFit: true,
        },
        {
          headerName: "endpadding",
          field: "endpadding",
          width: 20,
          suppressSizeToFit: false,
        },
      ];


      this.gridOptions.defaultColDef.cellStyle= { 'font-family': this.getFont(), 'font-size': this.getFontSize()}
      this.gridOptions.api.setColumnDefs(columnDefs);
      this.gridOptions.api.sizeColumnsToFit();
    },
    offsettorow(offset) {
      return Math.max(0, Math.floor(offset / 16) - 1);
    },
    preparestyledef() {
      if (this.styledef === undefined) {
        this.styledef = this.generateStyleDef(
          "rag-red",
          this.currentAnalysis.profiles.selected.selectedfg,
          this.currentAnalysis.profiles.selected.selectedbg
        );
        if (this.styledef) this.applyStyle(this.styledef);
      }
    },
    cellClicked(params) {

      this.currentAnalysis.decodefocus = "hexview";
      this.fromdecode.start = null;
      this.fromdecode.offset = null;
      this.fromdecode.filter = null;

      if (params.colDef.hasOwnProperty("id")) {
        this.hoveredcell = params.colDef.id + params.rowIndex * 16;
      } else if (params.colDef.hasOwnProperty("charid")) {
        this.hoveredcell = params.colDef.charid + params.rowIndex * 16;
      }

      // we send -1 because here we start with 1 but wiresharkd starts at 0
      this.$eventHub.$emit("clicked-in-hex", this.hoveredcell - 1);
    },
    cellHovered(params) {
      if(this.currentAnalysis.decodefocus == "decode"){
        return 
      }

      this.fromhexview.start = null;
      this.fromhexview.end = null;

      this.fromdecode.start = null;
      this.fromdecode.offset = null;
      this.fromdecode.filter = null;


      if (params.colDef.hasOwnProperty("id")) {
        this.hoveredcell = params.colDef.id + params.rowIndex * 16;
      } else if (params.colDef.hasOwnProperty("charid")) {
        this.hoveredcell = params.colDef.charid + params.rowIndex * 16;
      }
      this.gridOptions.api.refreshCells();
    },
    hoveredintervals() {
      return this.getintervalsforval(this.hoveredcell, this.currentoffsetdefs);
    },
    belongstoselectedfield(acell, ispadding = false) {
      let hoveredintervals;

      if (this.fromdecode.start != null) {
        hoveredintervals = [[this.fromdecode.start, this.fromdecode.offset, ""]];
      } else {
        if (
          acell == this.hoveredcell &&
          acell <= this.currentbytes.length &&
          !ispadding
        ) {
          return true;
        }
        hoveredintervals = this.hoveredintervals();
      }

      if (hoveredintervals && hoveredintervals.length > 0) {
        let shortest = this.shortestinterval(hoveredintervals);
        return this.isinintervalnew(acell, shortest, ispadding);
      }

      return false;
    },
    toprintableascii(keycode) {
      var valid = keycode > 32 && keycode < 126; // [\]' (in order)

      if (valid) {
        return String.fromCharCode(keycode);
      } else {
        return ".";
      }
    },
    formatascii(bytes, index) {
      if (index < bytes.length) return this.toprintableascii(bytes[index]);
      else return "";
      /*
        for(let i=index; i<index+8; i++){
            if(i<bytes.length){
                outstr = outstr + this.toprintableascii(bytes[i]);
            }
        }
        return outstr;*/
    },
    formatbyte(bytes, index) {
      // indexe  0 1 2 3
      // length: 4
      if (index < bytes.length) {
        return this.decimalToHex(bytes[index], 2);
      } else {
        return "";
      }
    },
    getCurrentbytes: function () {
      let bytes;

      if (this.decodenum == 0) {
        bytes = this.packet.bytes;
      } else {
        // 0 is default tab so we substract 1 as offset for ds array
        let dsi = this.decodenum - 1;
        bytes = this.base64ToArrayBuffer(this.packet.ds[dsi].bytes);
      }
      return bytes;
    },
    showPacketHex: function (packet) {
      if (packet === undefined || packet == null || !("bytes" in packet)) {
        return;
      }

      this.fromdecode.start == null;
      this.fromdecode.offset == null;

      let bytes = this.getCurrentbytes();
      this.currentbytes = bytes;
      this.currentoffsetdefs = packet.offsets;


      var rowdata = [];

      for (let i = 0; i < bytes.length; i = i + 16) {
        var n = {
          row: this.decimalToHex(i, 8),
          byte1: this.formatbyte(bytes, i),
          byte2: this.formatbyte(bytes, i + 1),
          byte3: this.formatbyte(bytes, i + 2),
          byte4: this.formatbyte(bytes, i + 3),
          byte5: this.formatbyte(bytes, i + 4),
          byte6: this.formatbyte(bytes, i + 5),
          byte7: this.formatbyte(bytes, i + 6),
          byte8: this.formatbyte(bytes, i + 7),
          byte9: this.formatbyte(bytes, i + 8),
          byte10: this.formatbyte(bytes, i + 9),
          byte11: this.formatbyte(bytes, i + 10),
          byte12: this.formatbyte(bytes, i + 11),
          byte13: this.formatbyte(bytes, i + 12),
          byte14: this.formatbyte(bytes, i + 13),
          byte15: this.formatbyte(bytes, i + 14),
          byte16: this.formatbyte(bytes, i + 15),
          char1: this.formatascii(bytes, i),
          char2: this.formatascii(bytes, i + 1),
          char3: this.formatascii(bytes, i + 2),
          char4: this.formatascii(bytes, i + 3),
          char5: this.formatascii(bytes, i + 4),
          char6: this.formatascii(bytes, i + 5),
          char7: this.formatascii(bytes, i + 6),
          char8: this.formatascii(bytes, i + 7),
          char9: this.formatascii(bytes, i + 8),
          char10: this.formatascii(bytes, i + 9),
          char11: this.formatascii(bytes, i + 10),
          char12: this.formatascii(bytes, i + 11),
          char13: this.formatascii(bytes, i + 12),
          char14: this.formatascii(bytes, i + 13),
          char15: this.formatascii(bytes, i + 14),
          char16: this.formatascii(bytes, i + 15),
        };

        rowdata.push(n);
      }

      this.gridOptions.api.setRowData(rowdata);
    },
    decimalToHex: function (d, padding) {
      var hex = Number(d).toString(16);
      padding =
        typeof padding === "undefined" || padding === null
          ? (padding = 2)
          : padding;

      while (hex.length < padding) {
        hex = "0" + hex;
      }

      return hex;
    },
    sendblob: function (filename, data) {
      var blob = new Blob([data], { type: "application/octet-stream" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    cyberchefAICook: function (outputName) {
      let recipe = "";

      let recipies = {
        "tls.handshake.certificate": {
          name: "certificate",
          recipe: "Parse_X.509_certificate('Raw')",
        },
      };
      if (this.fromdecode.filter != null) {
        let lhs = this.fromdecode.filter.split("==")[0].trim();
        if (lhs in recipies) {
          if (outputName) {
            return recipies[lhs].name;
          } else {
            return recipies[lhs].recipe;
          }
        }
        return null;
      }
      return null;
    },
    getContextMenuItems: function (params) {
      var id = this.id;

      var field = params.node.data["field"];
      var value = params.node.data["value"];
      var type = params.node.data["field"];

      var result = [];

      var l_savebinary = {
        name: "Export",
        action: function () {
          let start = this.fromdecode.start;
          let end = this.fromdecode.offset;

          let filename =
            this.currentAnalysis.pcapid +
            "-export-packet-" +
            this.currentAnalysis.currentpacket +
            ".bin";

          let data = this.getCurrentbytes();

          let slice = data.slice(start, end + start);
          this.sendblob(filename, slice);
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      var l_cyberchef_plain = {
        name: "Analyze raw bytes in Cyberchef",
        action: function () {
          let start = this.fromdecode.start;
          let end = start + this.fromdecode.offset;
          
          if (this.currentAnalysis.decodefocus == "hexview") {
            //off by one
            start = this.fromhexview.start-1
            end = this.fromhexview.end
          }

          let url = "https://gchq.github.io/CyberChef/#recipe=From_Hex('Auto')";
          url = url + "&input=";

          let data = this.getCurrentbytes();
          let slice = data.slice(start, end);

          let hex = slice.reduce(function (memo, i) {
            return memo + ("0" + i.toString(16)).slice(-2);
          }, "");
          let b64 = btoa(hex);
          let b64c = b64.replace(/=/g, "");
          url = url + b64c;
          window.open(url);
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      let guessName = this.cyberchefAICook(true);
      if (guessName) {
        var l_cyberchef = {
          name: "Analyze " + guessName + " in Cyberchef ",
          action: function () {
            let start = this.fromdecode.start;
            let end = start+ this.fromdecode.offset;

            let url =
              "https://gchq.github.io/CyberChef/#recipe=From_Hex('Auto')";

            let guessOptions = this.cyberchefAICook(false);
            if (guessOptions != null) {
              url = url + guessOptions;
            }

            url = url + "&input=";

            let data = this.getCurrentbytes();

            let slice = data.slice(start, end);

            let hex = slice.reduce(function (memo, i) {
              return memo + ("0" + i.toString(16)).slice(-2);
            }, "");
            let b64 = btoa(hex);
            let b64c = b64.replace(/=/g, "");
            url = url + b64c;
            window.open(url);
          }.bind(this),
          cssClasses: ["redFont", "bold"],
        };

        result.push(l_cyberchef);
      }
      result.push(l_cyberchef_plain);
      result.push(l_savebinary);
      result.push("copy");

      return result;
    },
  },
};
</script>

<style>
#hexview .ag-theme-balham .ag-root {
  border: 0px !important;
}

#hexview .ag-theme-balham,
#hexview .ag-theme-balham-dark .ag-cell {
  padding-left: 0px;
  padding-right: 0px;
}

#hexview .ag-theme-balham .ag-row:not(.ag-row-first) {
  border-width: 0px 0 0;
}

/*
#hexview .ag-theme-balham,
#hexview .ag-theme-balham-dark {
  font-family: monospace !important;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}*/

#hexview .ag-row {
  border-bottom: 0px solid #bdc3c7 !important;
}

#hexview .ag-cell,
#hexview .ag-cell-focus,
#hexview .ag-cell-no-focus {
  border: none !important;
}

#hexview .no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

#hexview .ag-cell:focus {
  border: none !important;
  outline: none;
}

#hexview .marked-frame {
  background: black;
  color: white;
}

#hexview .ag-status-bar {
  display: none !important;
}
</style>