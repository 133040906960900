
export const sharkdhelper = {
  methods: {
    averageSrtCellRender(params){
      let p = params.value;
      if (typeof p === 'object')
        return p.value.toFixed(6)
      return p.toFixed(6);
    },
    durationCellRenderer(params) {
      let p = params.value;
      if (typeof p === 'object')
        return p.value.toFixed(4)
      return p.toFixed(4)
    },
    throughPutCellRenderer(params) {

      let p = params.value;
      if (typeof p === 'object')
        return this.humanThroughput(p.value, 2);
      return this.humanThroughput(p, 2);
    },
    througputCalc(bits, seconds) {
      if (bits == 0 || seconds == 0) {
        return 0
      }
      return bits / seconds
    },
    humanThroughput(bitspersecond, decimals = 2) {
      if (bitspersecond === 0) return '';

      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['bit/s', 'kbit/s', 'Mbit/s', 'Gbit/s', 'Tbit/s', 'Pbit/s', 'Ebit/s', 'Zbit/s', 'Ybit/s'];

      const i = Math.floor(Math.log(bitspersecond) / Math.log(k));

      return parseFloat((bitspersecond / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    sharkd_get_convs_normalized: function () {
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.convs) {
        if (t.name.includes("Conversation List")) {
          types.push(
            {
              name: t.name.replace("Conversation List/", ""),
              tap: t.tap
            }
          )
        }
      }
      return types;
    },
    sharkd_get_endpt_normalized: function () {
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.convs) {
        if (t.name.includes("Endpoint")) {
          types.push(
            {
              name: t.name.replace("Endpoint/", ""),
              tap: t.tap
            }
          )
        }
      }
      return types;
    },
    sharkd_get_srt_normalized: function () {
      let types = []
      
      for (let t of this.currentAnalysis.sharkdinfo.srt) {
        if (t.name.includes("Service Response Time/")) {
          types.push(
            {
              name: t.name.replace("Service Response Time/", ""),
              tap: t.tap
            }
          )
        }
      }
      return types;
    },
    sharkd_get_stats_normalized: function(){
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.stats) {

          types.push(
            {
              name: t.name,
              tap: t.tap
            }
          )
      }
      return types;     
    },
    sharkd_get_eo_normalized: function(){
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.eo) {

          types.push(
            {
              name: t.name,
              tap: t.tap
            }
          )
      }
      return types;     
    },
    skarkd_get_seqa_normalized: function(){
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.seqa) {

          types.push(
            {
              name: t.name,
              tap: t.tap
            }
          )
      }
      return types;        
    },
    sharkd_get_taps_normalized: function(){
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.taps) {

          types.push(
            {
              name: t.name,
              tap: t.tap
            }
          )
      }
      return types;        
    },
    sharkd_get_nstat_normalized: function(){
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.nstat) {

          types.push(
            {
              name: t.name,
              tap: t.tap
            }
          )
      }
      return types;        
    },
    sharkd_get_rtd_normalized: function(){
      let types = []
      for (let t of this.currentAnalysis.sharkdinfo.rtd) {

          types.push(
            {
              name: t.name,
              tap: t.tap
            }
          )
      }
      return types;        
    },
    sharkd_normalize_taps: function(tapname){
      let types = []
      let tapnamereal = tapname
      let cleanstrings = [
        "Service Response Time/",
        "Endpoint/",
        "Conversation List/",
        "Export Object/",
        "Response Time Delay/",
        " Flows"
      ]

      if(tapname == "endpt"){
        tapnamereal = "convs"
      }
      
      for (let t of this.currentAnalysis.sharkdinfo[tapnamereal]) {
          let cleaned = t.name;
          let add = true;

          for(let marker of cleanstrings){
             cleaned =  cleaned.replace(marker, "")
          }
      
          if(tapname == "endpt" && !t.name.includes("Endpoint/")){
            add = false
          }

          if(tapname == "convs" && !t.name.includes("Conversation List/")){
            add = false
          }
 
          if(add){
              types.push(
                {
                  name: cleaned,
                  tap: t.tap
                }
              ) 
          }    
      }
      return types; 

    }
  }
}