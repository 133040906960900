<template>
  <v-row justify="center">
    <vue-dropzone ref="avatarUpload" id="avatarUpload" :class="dropzonestyleclass" :options="dropzoneOptions"></vue-dropzone>
  </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { AVATAR_UPLOAD_URL, AVATAR_GENERIC } from "@/common/config";
import { mapGetters } from "vuex";
import JwtService from "@/common/jwt.service";

export default {
  name: "avatarupload",
  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser"]),
    dropzonestyleclass: function() {
      if (this.currentAnalysis.profiles.selected.theme) {
        return "dropzone-dark"
      }
      return "dropzone";
    },
  },
  data() {
    return {
      showdropzone: true,
      dropzoneOptions: {
        url: AVATAR_UPLOAD_URL,
        maxFilesize: 2,
        maxFiles: 1,

        maxfilesexceeded: function() {
          this.showdropzone = false;
        },
        headers: {
          Authorization: "Bearer " + JwtService.getToken()
        },
        dictDefaultMessage:
          "Upload avatar<br><br><img height='80px' src='" +
          AVATAR_GENERIC +
          "'>"
      }
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upload {
  width: 100%;
}
.dropzone {
  width: 100%;
  border: 2px dashed #002007;
  border-radius: 5px;
}

.dropzone-dark {
  width: 100%;
  border: 2px dashed #002007;
  border-radius: 5px;
  background: rgb(12, 11, 11) !important;
}
</style>
