<template>
  <v-row class="grid-list-md"   align="center" justify="center">
    <v-col cols="1" class="pl-5">
      <h3>Owner</h3>
    </v-col>

    <v-col cols="2" class="pr-2">
      <v-chip v-if="owner">
        <v-avatar left>
          <img :src="avatarpic4user(owner)">
        </v-avatar>
        {{owner.firstname}} {{owner.lastname}}
      </v-chip>
    </v-col>

    <v-col cols="1" class="pl-3">
      <h3>Read Users</h3>
    </v-col>

    <v-col cols="8" class="pr-4 pt-3">
      <permissionsautocomplete
        v-model="value.permissions_read"
        :isowner="isOwner(currentUser, value)"
        label="Enter users with read access..."
        @
      ></permissionsautocomplete>
    </v-col>

    <v-col cols="1" class="pl-5">
      <h3>Status</h3>
    </v-col>

    <v-col cols="2" class="pr-2" v-if="value.permissions_public">
      <v-chip primary>public</v-chip>
    </v-col>
    <v-col cols="2" class="pr-2" v-else>
      <v-chip primary>private</v-chip>
    </v-col>

    <v-col cols="1" class="pl-3">
      <h3>Write Users</h3>
    </v-col>

    <v-col cols="8" class="pr-4 pt-3">
      <permissionsautocomplete
        v-model="value.permissions_write"
        :isowner="isOwner(currentUser, value)"
        label="Enter users with write access..."
      ></permissionsautocomplete>
    </v-col>

    <v-col cols="3" class="pl-3"></v-col>

    <v-col cols="1" class="pl-3">
      <h3>Read Roles</h3>
    </v-col>

    <v-col cols="8" class="pr-4 pt-3">
      <rolepermissionsautocomplete
        v-model="value.permissions_roles_read"
        :isowner="isOwner(currentUser, value)"
        label="Enter roles with read access..."
      ></rolepermissionsautocomplete>
    </v-col>

    <v-col cols="3" class="pl-3"></v-col>

    <v-col cols="1" class="pl-3">
      <h3>Write Roles</h3>
    </v-col>

    <v-col cols="8" class="pr-4 pt-3">
      <rolepermissionsautocomplete
        v-model="value.permissions_roles_write"
        :isowner="isOwner(currentUser, value)"
        label="Enter roles with write access..."
      ></rolepermissionsautocomplete>
    </v-col>
  </v-row>
</template>

<script>
import ApiUser from "@/common/api/user";
import Permissionsautocomplete from "./Permissionsautocomplete";
import Rolepermissionsautocomplete from "./Rolepermissionsautocomplete";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  props: ["value"],
  mounted() {
    // create permissions if they are not there already
    if (!this.value.permissions_read)
      this.$set(this.value, "permissions_read", []);
    if (!this.value.permissions_write)
      this.$set(this.value, "permissions_write", []);
    if (!this.value.permissions_roles_read)
      this.$set(this.value, "permissions_roles_read", []);
    if (!this.value.permissions_roles_write)
      this.$set(this.value, "permissions_roles_write", []);

    this.loadOwner();
  },
  mixins: [commonmethods, permissionchecks],
  components: { Permissionsautocomplete, Rolepermissionsautocomplete },
  name: "newpermissions",
  data() {
    return {
      owner: null,
      loading: false,
      searchuser: null,
      permissions: []
    };
  },
  computed: {
    ...mapGetters(["currentUser", "avatarPic"])
  },
  watch: {
    searchuser(val) {
      val && this.queryPeople(val);
    }
  },
  methods: {
    updateModel() {
      this.$emit("input", this.value);
    },
    loadOwner: function() {
      var user = this.value.permissions_owner[0].username;
      ApiUser.getprofile(user).then(({ data }) => {
        if (data) {
          this.owner = data;
        }
      });
    }
  }
};
</script>

<style>
</style>