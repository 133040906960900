<template>
  <!-- need to use combobox here as v-autocomplete resets the value once we loose focus-->

  <v-combobox
    ref="searchbox"
    :background-color="searchbackground"
    class="px-3"
    full-width
    light
    solo
    dense
    no-filter
    clearable
    hide-no-data
    prepend-inner-icon="mdi-filter"
    hide-details
    hide-selected
    :items="availablefields"
    :loading="currentAnalysis.loadingpackets"
    item-text="text"
    item-value="value"
    v-model="valueinternal"
    :search-input.sync="newfield"
    placeholder="Start typing to search for fields..."
    :menu-props="{ openOnClick: false }"
    v-on:keyup.enter="enterpressed"
    @change="selectionclicked"
    v-on:keyup.esc="escapepressed"
    @drop.prevent="handleDrop"
    @dragover.prevent="handleDragOver"
  >
 
    <template slot="item" slot-scope="data"  >
      <v-list-item-content v-if="data.item.value" class="filtersearchtile">
        <v-list-item-title v-if="lookupdescription(data.item.value)"
          >{{ data.item.text }}

          <v-chip color="pl-2 redlighten-3" dark label small
            >{{ lookupdescription(data.item.value) }}
          </v-chip>
        </v-list-item-title>

        <v-list-item-title v-else>{{ data.item.text }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-content v-else class="history filtersearchtile">
        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-combobox>

</template>

<script>
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import ApiDisplayfilter from "@/common/api/displayfilter";

export default {
  props: {
    value: "",
    filterok: undefined,
    livemode: false,
  },
  components: {},
  name: "filterbox",
  mixins: [displayfilter],
  watch: {

    "currentAnalysis.filter": function (n, o) {
      this.valueinternal = { value: n, text: n };
      this.availablefields = [{ value: n, text: n }];
    },
    "currentAnalysis.filterstate": function (n, o) {
      this.setfilterstatus(n);
    },
    newfield: function (val, old) {
      console.log(val)
      this.currentAnalysis.filterboxoverlay.text = val
      /* no completion for quick filters */
      if (this.currentAnalysis.quickfilter) {
        return;
      }
      /* this happens when clicking x in the filter bar */
      if (val == null || val === undefined) {
        
        this.df_apply_to_packetlist("");
        this.setfilterstatus(true);
        this.availablefields.splice(0,this.availablefields.length);
        this.availablefields.push(...this.matchingsearchhistory(""))


        this.$nextTick(() => { 
          this.$refs.searchbox.reset()
          this.$refs.searchbox.isMenuActive = false
        })


        return;
      }

      if (this.livemode) {
        this.$emit("input", this.newfield);
      }

      if(this.selectionwasclicked){
        this.selectionwasclicked = false
        this.enterpressed(null)
        return
      }

      if (!val) {
        return;
        this.df_apply_to_packetlist("");
      }
      if (!val || val.length < 3) {
        this.availablefields = [];
        return;
      }

      this.debounceCompletion(val);
      //this.completefilter(val)
      return;
      // var start = 0;
      // var end = val.length;

      // if (old) {
      //   var d = this.findFirstDiffPos(val, old);
      //   /* if a char was deleted in the end */
      //   if (d >= end) {
      //     d = d - 1;
      //   }
      // } else {
      //   if(val)
      //     this.autocompletefields(val, val, 0, val.length);
      //   return;
      // }

      // if (this.isrighthand(d, end, val)) {
      //   var lefthand = this.getlefthand(val, d, end);
      //   this.completefilter(val)
      //   //this.completevalues(lefthand, val, d + 1, end);
      //   return;
      // }

      // if (!this.possiblyFilterChar(val.charAt(d))) {

      //   this.availablefields = this.matchingsearchhistory(this.newfield);
      //   return;
      // }

      // for (let i = d; i >= 0; i--) {
      //   if (this.possiblyFilterChar(val.charAt(i))) {
      //     start = i;
      //   } else {
      //     break;
      //   }
      // }

      // for (let j = d; j < val.length; j++) {
      //   if (this.possiblyFilterChar(val.charAt(j))) {
      //     end = j;
      //   } else {
      //     break;
      //   }
      // }

      // var searchfield = val.substring(start, end + 1);

      // this.autocompletefields(searchfield, val, start, end);
      // val && val !== this.select && this.autocompletefields(searchfield)
    },
  },
  data() {
    return {
      isSavedFilter: false,
      isLoading: false,
      timerId: null,
      valueinternal: null,
      filterok_internal: true,
      manual: false,
      searchbackground: "white",
      availablefields: [],
      newfield: "",
      fielddef: [],
      selectionwasclicked : false,
      validfilters: [] // valid filters applied during this session
    };
  },
  created: function () {},
  mounted() {
    this.valueinternal = this.value;
    this.setfilterstatus(true);
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "avatarPic"]),
  },
  methods: {
    handleDragOver(event){
      return
      var dragSupported = event.dataTransfer.length;

      if (dragSupported) {
        event.dataTransfer.dropEffect = 'move';
      }

      event.preventDefault();
      
    },
    handleDrop(event) {
      return
      if (this.currentAnalysis.dndfilter) {
        this.currentAnalysis.filter = this.currentAnalysis.dndfilter;
      }
      this.currentAnalysis.dndfilter = null;
    },
    debounceCompletion(val) {
      // cancel pending call
      clearTimeout(this.timerId);

      this.isLoading = true;

      // delay new call 500ms
      this.timerId = setTimeout(() => {
        this.completefilter(val);
      }, 200);
    },
    setfilterstatusmanual: function (val) {
      if (val) this.searchbackground = "green lighten-4";
      else if (val == false) this.searchbackground = "red lighten-4";
    },
    setfilterstatus: function (val) {
      
      this.isLoading = false;
      if (val === undefined) {
        // we use undefined as a reset, before changing it to force a change
        // so do not do anything
        return;
      }

      if (val == null) {
        // we use null to make it white
        this.searchbackground = "white";
      }

      let inval = this.newfield;
      if (val && inval) {
        this.currentAnalysis.sessionfilterhistory.unshift(inval);

        this.searchbackground = "green lighten-4";
        if (inval != "" && inval != null) {
          //running into this bug
          //https://github.com/vuetifyjs/vuetify/issues/4663
          // waiting for this pull request to pass
          //https://github.com/vuetifyjs/vuetify/pull/4879
          this.currentAnalysis.sessionfilterhistory.unshift(inval);
          this.searchbackground = "green lighten-4";
        } else {
          this.searchbackground = "white";
        }
      } else if (val == false) {
        this.searchbackground = "red lighten-4";
      } else {
        this.searchbackground = "white";
      }
    },
    getlefthand: function (input, start, end) {
      var lefthand = "";
      var ignore = ["(", ")", " ", '"', "=", "!", "<", ">"];

      var mystart = 0;
      var myend = 0;

      for (let i = start - 1; i >= 0; i--) {
        let c = input.charAt(i);
        if (this.possiblyFilterChar(c)) {
          if (myend == 0) {
            myend = i;
          }
          continue;
        } else if (ignore.includes(c)) {
          if (myend != 0) {
            mystart = i + 1;
            break;
          }
          continue;
        } else {
          mystart = i;
          break;
        }
      }
      return input.substring(mystart, myend + 1);
    },
    findFirstDiffPos: function (a, b) {
      var i = 0;
      if (a === b) return -1;
      while (a[i] === b[i]) i++;
      return i;
    },
    isrighthand: function (start, end, full) {
      var candidates = [
        full.substring(start - 3, end),
        full.substring(start - 2, end),
        full.substring(start - 1, end),
      ];

      var operators = ["==", ">=", "<=", "<", ">", "!="];

      var found = false;
      for (let c of candidates) {
        for (let o of operators) {
          if (c.includes(o)) {
            return true;
          }
        }
      }

      return false;
    },
    possiblyRightHandChar: function (char) {
      if (/[^a-zA-Z0-9"' ([.:]/.test(char)) {
        return false;
      }
      return true;
    },
    possiblyFilterChar: function (char) {
      if (/[^a-zA-Z0-9_.]/.test(char)) {
        return false;
      }
      return true;
    },
    selectionclicked: function (e) {

      if(this.newfield == e){
        return
      } else {
        this.selectionwasclicked = true
      }
    },
    enterpressed: function (e) {
      
      if (!this.newfield) {
        this.setfilterstatus(undefined);
      }

      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;
      this.$emit("input", this.newfield);
    },
    escapepressed: function (e) {
      this.resetDisplayfilter();
      this.newfield = "";
      this.df_apply_to_packetlist(this.newfield);
    },
    resetDisplayfilter: function () {
      this.newfield = "";
      this.searchbackground = "white";
    },

    completefilterfromsaved: function (expression) {
      let out = [];
      for (let l of this.currentAnalysis.profiles.selected.filterbuttons) {
        if (l.rulename.toLowerCase().includes(expression.toLowerCase()) || l.rule.toLowerCase().includes(expression.toLowerCase())) {
          let r = l.rulename;
          if (r.includes("//")) {
            r = l.rulename.split("//")[1];
          }
          out.push({
            text: l.rule,
            value: l.rule,
          });
        }
      }
      return out;
    },
    completefilter: function (expression) {
      var index = this.currentAnalysis.index;
      var id = this.currentAnalysis.pcapid;

      let req = { pcapid: id, index: index, expression: expression };

      ApiDisplayfilter.complete(req)
        .then(({ data }) => {
          let frombackend = [];
          let buttons = []
          let savedfilters = this.completefilterfromsaved(expression)
          let searchhistory = this.matchingsearchhistory(this.newfield);


          for (let r of this.currentAnalysis.profiles.selected.coloringrules) {
            if (r.rulename.toLowerCase().includes(expression.toLowerCase()) || r.rule.toLowerCase().includes(expression.toLowerCase())) {
              buttons.push({
                text: r.rule,
                value: r.rule,
              });

              this.addfieldstofielddef([
                {
                  description: r.rulename,
                  field: this.df_wireshark_field_to_es(r.rule),
                },
              ]);
            }
          }


          for (let d of data.completions) {
            frombackend.push({ text: d, value: d });
          }

          if (data.filterstatus == "pass") {
            this.setfilterstatusmanual(true);
          } else {
            this.setfilterstatusmanual(false);
          }

          this.addfieldstofielddef(data.fields);

          this.availablefields = searchhistory.concat(buttons).concat(savedfilters).concat(frombackend);
        })
        .catch(({ response }) => {});
    },
    completevalues: function (field, input, start, end) {
      var index = this.currentAnalysis.index;
      var id = this.currentAnalysis.pcapid;

      var currentstring = input.substr(0, start) + input.substr(end + 1);

      ApiDisplayfilter.possiblevalues(index, id, field)
        .then(({ data }) => {
          var newlist = [];

          for (let item of data) {
            let newexpression =
              currentstring.slice(0, start + 1) +
              '"' +
              item +
              '"' +
              currentstring.slice(end, currentstring.length);

            let n = {
              value: newexpression,
              text: newexpression,
            };
            newlist.push(n);
          }
          var searchhistory = this.matchingsearchhistory(this.newfield);
          var out = searchhistory.concat(newlist);

          this.availablefields = out;
        })
        .catch(({ response }) => {});
    },
    matchingsearchhistory: function (current) {

      var out = [];

      for (let entry of this.currentAnalysis.sessionfilterhistory) {
        if (entry.startsWith(current)) {
          let n = {
            text: entry,
          };
          out.push(n);
        }
      }


      var howmany = 10;
      if (this.currentAnalysis.profiles.selected.displayfilterHistoryMax) {
        howmany =
          this.currentAnalysis.profiles.selected.displayfilterHistoryMax;
      }

      return out.slice(0, howmany);
    },
    autocompletefields(field, input, start, end) {
      //Note FIXME do not autocomplete when it is an expression already
      if (field.length < 3 /*|| this.iscached()*/) return;

      var currentstring = input.substr(0, start) + input.substr(end + 1);

      ApiDisplayfilter.getfields(field)
        .then(({ data }) => {
          var newlist = [];

          for (let item of data) {
            let isindexed = false;

            let field_normalized = item["field"].replace(".", "#");

            // if we have an index then we filter for the possible fields that are indexed
            if (this.currentAnalysis.index != "none") {
              for (let f of this.currentAnalysis.indexedfields) {
                if (f.includes(field_normalized)) {
                  isindexed = true;
                }
              }
              if (!isindexed) {
                continue;
              }
            }

            let newexpression =
              currentstring.slice(0, start + 1) +
              item["field"] +
              currentstring.slice(end, currentstring.length);

            let n = {
              value: item["field"],
              text: newexpression,
            };
            newlist.push(n);
          }
          this.addfieldstofielddef(data);

          
          var searchhistory = this.matchingsearchhistory(this.newfield);
          var out = searchhistory.concat(newlist);

          /* always assure we add the current term so it does not get lost */
          //
          // var same = {
          //   value: this.newfield,
          //   text: this.newfield
          // };

          // out.push(same);

          this.availablefields = out;
        })
        .catch(({ response }) => {});
    },
    addfieldstofielddef(fields) {
      var found = false;
      for (let item of fields) {
        for (let cache of this.fielddef) {
          if (item["field"] == cache["field"]) {
            found = true;
          }
        }
        if (!found) {
          this.fielddef.push(item);
        }
        found = false;
      }
    },
    iscached() {
      for (let item of this.availablefields) {
        if (item.startsWith(this.newfield)) return true;
      }
      return false;
    },
    lookupdescription(fieldname) {
      let c = this.df_wireshark_field_to_es(fieldname);


      let out = [];
      for (let l of this.currentAnalysis.profiles.selected.filterbuttons) {
        if (l.rule.toLowerCase() == fieldname.toLowerCase()) {
          return l.rulename
        }
      }

      for (let i of this.fielddef) {
        if (i["field"] == c) {
          return i["description"];
        }
      }


      return "";
    },
  },
};
</script>

<style >
.history {
  background-color: #edfcee;
}

.v-input__slot {
  margin-top: 7px !important;
  min-height: 32px !important;
}

/* This allows for a denser list but we need the selector to
 * be more specific
 *
div.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content
  .v-list__tile {
  height: 24px;
}*/
</style>
