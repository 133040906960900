import ApiService from "@/common/api.service";

const ApiDecodeas = {

    get(entries){
        return ApiService.get("ajax/pcap/decodeas", entries["pcapid"])
    },
    create(entries){
        return ApiService.post("ajax/pcap/decodeas", entries)
    },
    reset(entries){
        return ApiService.delete("ajax/pcap/decodeas", entries["pcapid"]);
    }
}

export default ApiDecodeas