<template>
  <v-row align="center" justify="center" class="pt-6">
    <v-col cols="12">
      <v-card class="pb-2">
        <v-card-title class="headline" primary-title>
          Settings

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
           <v-spacer></v-spacer>

          <v-switch v-model="showonlymodified" label="Show only modified"></v-switch>

        </v-card-title>


        <v-col cols="12">
    
          <v-data-table
            :headers="headers"
            :items="settings"
            :footer-props="footerprops"
            :search="search"
            dense
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{props.item.key}}</td>
                <td>{{props.item.type}}</td>
              
                <!-- boolean --> 
                <td v-if="props.item.type == 'b'">
                  <v-select :disabled="!currentUser" hide-details solo :items="props.item.values" v-model="props.item.selected" @input="logchange(props.item.key, props.item.selected, props.item.type)">
                    <template v-slot:selection="{ item, index }">
                      <template v-if="item==0">false</template>
                      <template v-if="item==1">true</template>
                    </template>
                    <template v-slot:item="{ item, index }">
                      <template v-if="item==0">false</template>
                      <template v-if="item==1">true</template>
                    </template>
                  </v-select>
                </td>

                <!-- choice -->
                <td v-else-if="props.item.type == 'e'">
                  <v-select :disabled="!currentUser" solo hide-details :items="props.item.values" v-model="props.item.selected" item-value="v" item-text="d">
                  </v-select>
                </td>
                <!-- UAT --> 
                <!--<td v-else-if="props.item.type == 't'">{{props.item.selected}}</td>-->
                <!-- strring or range or integer -->
                <td v-else-if="['s', 'r', 'u', 't'].includes(props.item.type)">
                  <v-text-field :disabled="!currentUser" solo hide-details v-model="props.item.selected"></v-text-field>
                </td>
                <td v-else>{{props.item.value}}</td>
                <td >{{props.item.modified}}
                  
                  <v-btn icon text v-if="props.item.modified == 'modified' && currentUser">
                    <v-icon v-on:click="removechange(props.item.key)">delete</v-icon>
                  </v-btn>
                  </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ApiProfile from "@/common/api/profile";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  name: "wiresharksettings",
  props: {
    indexerid: null,
    profileid: null,
    value: null,
  },
  data() {
    return {
      profile: null,
      showonlymodified: true,
      oldsettings: [],
      changes: {},
      changesarr: [],
      settings: [],
      footerprops: {
        "items-per-page-options": [10, 50, 100, 200, 400, -1],
      },
      search: "modified",
      headers: [
        {
          text: "Key",
          align: "left",
          value: "key",
          sortable: false, width: "300px"
        },
        { text: "Type", value: "type", sortable: false, align: "left", width: "50px" },
        { text: "Value", value: "value", sortable: false },
        { text: "Modified", value: "modified", sortable: false },
      ],
    };
  },
  created: function () {},
  mounted() {
    this.profile = this.profileid
    for(let s of this.value){
      this.changes[s["name"]] = s["value"]
    }
    this.getCurrentSettings();
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  watch: {
    "showonlymodified" : function(n, o){
      if(n){
        this.search = "modified"
      } else {
        this.search = ""
      }
    }
  },
  methods: {
    logchange: function(key, value, type) {    
      this.changes[key] = {"key": key, "value": value, "type": type}
      this.updateModel()
    },
    removechange: function(key){
      for(let s of this.settings){
        if(s.key == key){
          s.selected = null
          s.modified = ""
        }
      }
      delete this.changes[key]
      this.updateModel()
    },
    updateModel: function() {
      this.changesarr.length = 0;
      for(const [key, value] of Object.entries( this.changes)){
        let n = {
          "name": key,
          "value": value["value"]+""
        }
        // omg sharkd, some consistency please
        if(value["type"] == "b"){
          if(value["value"] == "0"){
            n["value"]= "FALSE"
          } else{
            n["value"] = "TRUE"
          }
        }
        this.changesarr.push(n)
      }
      this.$emit("input", this.changesarr);
    },
    getCurrentSettings: function () {
      ApiProfile.getcurrentsharkdconfig(this.profile)
        .then(({ data }) => {
          this.settings = data.prefs;

          let tmp = [];
          let types = ["s", "b", "u", "r", "t", "e"];

          // t and e apparently can be array

          for (let key in data.prefs) {


            for (let type of types) {
              if (type in data.prefs[key]) {

                let item = {
                  key: key,
                  type: null,
                  values: null,
                  selected: null
                };

                item.type = type;

                switch(item.type){
                  case "b": // boolean
                    item.values = [0, 1]
                    item.selected = data.prefs[key][type];
                    break
                  case 'u': // integer
                    // TODO might have ub clnp.tp_nsap_selector: {u: 33, ub: 16} -> hex 0x21
                    item.values = data.prefs[key][type];
                    item.selected = data.prefs[key][type];
                    break
                  case 'e': // choice e: [{v: 0, s: 1, d: "Hexadecimal"}, {v: 1, d: "Decimal"}]
                     item.values = data.prefs[key][type];
                     for(let cand of item.values){
                       if( "s" in cand && cand["s"] == 1){
                         item.selected = cand;
                       }
                     }
                    break
                  case 't': // UAT
                    item.values = data.prefs[key][type];
                    item.selected = data.prefs[key][type];
                    break
                   default: // s: string, r: range (string like)
                    item.values = data.prefs[key][type];
                    item.selected = data.prefs[key][type];
                }

                if(key in this.changes){
                  item.modified= "modified"
                  switch(item.type){
                    case 'b':
                      if(this.changes[key] == "TRUE"){
                        item.selected = 1
                      } else{
                        item.selected = 0
                      }
                      break;
                    case 'u':
                      item.selected = parseInt(this.changes[key])
                      break;
                    case 'e':
                      item.selected = parseInt(this.changes[key])
                      break;
                    default:
                      item.selected = this.changes[key]
                  }
                } else {
                  item.modified = ""
                }

                tmp.push(item);
              }
            }
          }
          this.settings = tmp;
        })
        .catch(({ response }) => {});
    },
  },
};
</script>

<style >
</style>
