<template>
    <span>
        {{ val }}
    </span>
</template>

<script>
export default {
    data() {
        return {
            val: null,
        }
    },
    mounted() {
        this.val = this.params.value.toFixed(9)
    },
    methods: {

    }
};
</script>