import ApiService from "@/common/api.service";

const ApiAnalysisrecipe = {
    get(id){
        return ApiService.query("/ajax/analysisrecipe/" + id)
    },
    list(params){
        return ApiService.post("ajax/analysisrecipes", params)
    },
    create(analysis){ // changed api and removed object hierarchy
        return ApiService.post("ajax/analysisrecipe",  analysis)
    },
    update(analysis){ // changed api remove object
        return ApiService.put("ajax/analysisrecipe/" + analysis.id,
            analysis
        )
    },
    publish(recipe){ // FIXME deprecate api
        return ApiService.get("ajax/analysisrecipe/setpublic/true", recipe.id)
    },
    lock(recipe){ // FIXME deprecate api
        return  ApiService.get("ajax/analysisrecipe/setpublic/false", recipe.id)
    },
    delete(id){
        return ApiService.delete("ajax/analysisrecipe", id)
    }
}

export default ApiAnalysisrecipe