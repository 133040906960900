import { sharkdhelper } from "@/common/sharkdhelper"

export const conversations = {
    mixins: [sharkdhelper],
    data() {
        return {
            aggridhandler: {
                "conv": {
                    name: "Conversation Statistics",
                    getColumnDefs: this.getColumnDefsConv,
                    getContextMenuItems: this.getContextMenuItemsConv,
                    formatData: this.formatDataConv
                }
            }
        }
    },
    methods: {
        getColumnDefsConv(stats, groupTable=false) {

            var h = [
                { headerName: "Source", field: "saddr", rowGroup: groupTable, hide: groupTable, enableRowGroup: true },
                { headerName: "Destination", field: "daddr", enableRowGroup: true },
                { headerName: "Packets", field: "totalxf", aggFunc: 'sum' },
                { headerName: "Bytes", field: "totalxb", aggFunc: 'sum' },
                { headerName: "Packets A to B", field: "txf", aggFunc: 'sum' },
                { headerName: "Bytes A to B", field: "txb", aggFunc: 'sum' },
                { headerName: "Packets B to A", field: "rxf", aggFunc: 'sum' },
                { headerName: "Bytes B to A", field: "rxb", aggFunc: 'sum' },
                { headerName: "Start", field: "start" },
                { headerName: "Stop", field: "stop" },
                { headerName: "Duration", field: "duration", aggFunc: 'avg', cellRenderer: this.durationCellRenderer },
                { headerName: "Throughput A to B", field: "throughtx", aggFunc: 'avg', cellRenderer: this.throughPutCellRenderer },
                { headerName: "Throughput B to a", field: "throughrx", aggFunc: 'avg', cellRenderer: this.throughPutCellRenderer },
                { headerName: "Filter", field: "filter", hide: true }
            ];

            if (stats.proto == "TCP" || stats.proto == "UDP") {
                h.splice(1, 0, { headerName: "Source Port", field: "sport", enableRowGroup: true });
                h.splice(3, 0, { headerName: "Destination Port", field: "dport", enableRowGroup: true });
            }

            return h;
        },
        getContextMenuItemsConv: function (params) {

            var currentitems = params.defaultItems.slice(0);
            var result = [
                {
                    name: "Filter Conversation",
                    action: function () {

                        var o = params.node.data["filter"]
                        this.df_apply_to_packetlist(o)
                        this.currentAnalysis.showTapstatistics = false
                    }.bind(this),
                    cssClasses: ["redFont", "bold"]
                }
            ];

            for (var item of result) {
                currentitems.unshift(item);
            }

            return currentitems;
        },
        formatDataConv(stats) {
            let indata = [];

            for (let c of stats.convs) {
                c.duration = c.stop - c.start;
                if ("sport" in c)
                    c.sport = parseInt(c.sport)
                if ("dport" in c)
                    c.dport = parseInt(c.dport)
                c.totalxf = c.txf + c.rxf
                c.totalxb = c.txb + c.rxb
                c.throughtx = this.througputCalc(c.txb * 8, c.duration)
                c.throughrx = this.througputCalc(c.rxb * 8, c.duration)
                indata.push(c)
            }
            return indata;
        }
    }
}