<template>
  <v-card class="pb-2">
    <v-card-title>List of users</v-card-title>
    <v-card-text class="py-2">
      <v-row class="pt-2">
        <v-col cols="10">
          Wave Analyzer Users
        </v-col>

        <v-col cols="2">
          <v-text-field placeholder="Search" v-model="searchterm"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="users"
            :hide-default-footer="totalitems < 10"
            :options.sync="tableoptions"
            :server-items-length="totalitems"
          >
            <template
              slot="item"
              slot-scope="props"
            >
              <tr>
                <td>
                  {{props.item['firstname']}}

                </td>
                <td>
                  {{props.item['lastname']}}
                </td>
                <td>
                  <router-link :to="{ name: 'User Profile', params: { username: props.item['username']}}">{{props.item['username']}}</router-link>
                </td>
                <td>
                  {{props.item['date_registered'] | formatDateMonth}}
                </td>
                <td>
                  {{props.item['date_last_login'] | formatDateMonth}}
                </td>
                <td>
                  <v-data-table
                    :headers="rcuheaders"
                    :items="props.item['recentlyUsed']"
                    :hide-default-footer="true"
                    :sort-by.sync="rcuSortBy"
                    :sort-desc.sync="rcuSortDesc"
                  >
                    <template
                      slot="item"
                      slot-scope="props"
                    >
              <tr>
                <td>
                  {{props.item['count']}}
                </td>
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="{
                      name: 'Analysis',
                      params: {
                        view: 'l',
                        id: props.item['id'],
                        index: 'none',
                        analysisid: 'none',
                      },
                    }"
                  >{{props.item['id']}}</router-link>

                </td>
                <td>
                  {{props.item['lastAccessed'] | formatDate}}
                </td>
              </tr>
            </template>

          </v-data-table>

          </td>
          </tr>
</template>
</v-data-table>
</v-col>
</v-row>
</v-card-text>
</v-card>
</template>

<script>
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import ApiProfile from "@/common/api/profile";
import { permissionchecks } from "@/common/permissions";
import Vue from "vue";
import Linktodoc from "@/components/basics/Linktodoc";
import ApiUser from "@/common/api/user";

export default {
  props: ["value"],
  components: { Linktodoc },
  name: "profilelist",
  data() {
    return {

      searchterm: "",
      totalitems: 0,
      tableoptions: {
        itemsPerPage: 10
      },
      users: [],
      c: [],
      headers: [
        {
          text: "Name",
          align: "left",
          value: "firstname",
          sortable: false
        },
        {
          text: "Lastname",
          align: "left",
          value: "lastname",
          sortable: false
        },
        {
          text: "Username",
          align: "left",
          value: "username",
          sortable: true
        },
        {
          text: "Date registered",
          align: "left",
          value: "date_registered",
          sortable: true
        },
        {
          text: "Last login",
          align: "left",
          value: "date_last_login",
          sortable: true
        },
        {
          text: "Recently used",
          align: "left",
          value: "recentlyUsed",
          sortable: true
        }
      ],
      rcuheaders: [
        {
          text: "Count",
          align: "left",
          value: "count",
          sortable: true
        },
        {
          text: "PCAP",
          align: "left",
          value: "id",
          sortable: false
        },
        {
          text: "Last accessed",
          align: "left",
          value: "lastAccessed",
          sortable: true
        }
      ],
      rcuSortBy: "count",
      rcuSortDesc: true,
    };
  },
  created: function() {},
  mounted() {
    this.loadusers();
  },
  mixins: [permissionchecks],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"])
  },
  watch: {
    tableoptions: {
      handler() {
        this.loadusers();
      },
      deep: true
    },
    searchterm() {
      this.loadusers();
    }
  },
  methods: {
    loadusers: function() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.tableoptions;
      let offset = (page - 1) * itemsPerPage;

      if (offset == null) {
        offset = 0;
      }

      if (this.searchterm.length < 3) {
        this.searchterm == "";
      }

      let args = {
        sortBy: sortBy,
        sortDesc: sortDesc,
        term: this.searchterm,
        page: page,
        perpage: itemsPerPage,
        offset: offset
      };

      ApiUser.list(args)
        .then(({ data }) => {
          this.users.length = 0;
          this.totalitems = data.users.length;
          this.users.push(...data.users);
        })
        .catch(() => {});
    }
  }
};
</script>

<style >
</style>
