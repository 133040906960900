var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getPcapField(_vm.pcapfield) || (!_vm.getPcapField(_vm.pcapfield) && !_vm.hideempty))?_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"my-1 py-1",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.items[_vm.pcapfield])+" ")]),(_vm.pcapfield == 'hastlssecrets' && _vm.getPcapField(_vm.pcapfield))?_c('v-col',{staticClass:"my-1 py-1",staticStyle:{"word-break":"break-all"},attrs:{"cols":"8"}},[_vm._v(" Yes, "),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadSecrets.apply(null, arguments)}}},[_vm._v("download")])]):_c('v-col',{staticClass:"my-1 py-1",attrs:{"cols":"8"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'PCAPs',
        params: {
          type: _vm.currentAnalysis.pcaplist.type,
          query: _vm.pcapfield + ':' + _vm.getPcapFieldQuoted(_vm.pcapfield),
        },
      }}},[(
          _vm.pcapfield == 'endtime' ||
          _vm.pcapfield == 'starttime' ||
          _vm.pcapfield == 'timestamp'
        )?[_vm._v(_vm._s(_vm._f("formatDate")(_vm.getPcapField(_vm.pcapfield))))]:(_vm.pcapfield == 'filesize' || _vm.pcapfield == 'datasize' || _vm.pcapfield == 'averagepacketsize')?[_vm._v(" "+_vm._s(_vm._f("humbytes")(_vm.getPcapField(_vm.pcapfield)))+" / "+_vm._s((_vm.getPcapField(_vm.pcapfield)))+" bytes ")]:(_vm.pcapfield == 'databitrate')?[_vm._v(" "+_vm._s(_vm._f("humbitrate")(_vm.getPcapField(_vm.pcapfield)))+" / "+_vm._s((_vm.getPcapField(_vm.pcapfield)))+" bits/s ")]:(_vm.pcapfield == 'databyterate')?[_vm._v(" "+_vm._s(_vm._f("humbyterate")(_vm.getPcapField(_vm.pcapfield)))+" / "+_vm._s((_vm.getPcapField(_vm.pcapfield)))+" byte/s ")]:(_vm.pcapfield == 'captureduration')?[_vm._v(" "+_vm._s(_vm._f("formatDurationHuman")(_vm.getPcapField(_vm.pcapfield)))+" / "+_vm._s((_vm.getPcapField(_vm.pcapfield)))+" seconds ")]:[_vm._v(_vm._s(_vm.getPcapField(_vm.pcapfield)))]],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }