<template>
  <v-row   class="pt-2">
    <v-dialog v-model="dialogni" width="900">
      <v-card>
        <v-card-title class="headline" primary-title>Edit an Indexer Profile</v-card-title>
        <v-card-text v-if="dialogni">
          <indexerprofilenew
            :edit="editprofile"
            :key="editprofilekey"
            @close="dialogni = !dialogni"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="currentAnalysis.indexerprofiles.available"
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td>
              <a href="#" @click.prevent="editProfile(props.item)">{{props.item['name']}}</a>
            </td>
            <td>{{props.item['description']}}</td>
            <td>{{props.item['inherit']}}</td>
            <td>
              <v-chip v-if="props.item.permissions.permissions_public" primary>public</v-chip>
              <v-chip v-else primary>private</v-chip>
            </td>
            <td>
              <v-icon
                v-if="isOwner(currentUser, props.item.permissions) && !isPublic(props.item.permissions)"
                color="info"
                small
                v-on:click="editProfile(props.item)"
              >edit</v-icon>
              <v-icon
                v-if="isOwner(currentUser, props.item.permissions) && !isPublic(props.item.permissions)"
                color="info"
                small
                v-on:click="removeProfile(props.item)"
              >delete</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="10"></v-col>
    <v-col cols="2" class="pt-3">
      <v-btn small color="info" v-on:click="newProfile">
        Add Profile
        <v-icon>add</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ApiIndexerprofile from "@/common/api/indexerprofile";
import ApiProfile from "@/common/api/profile";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";
import Indexerprofilenew from "./Indexerprofilenew";

export default {
  props: {},
  components: { Indexerprofilenew },
  name: "indexerprofilelist",
  data() {
    return {
      dialogni: false,
      editprofile: null,
      editprofilekey: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false
        },
        { text: "Description", value: "description", sortable: false },
        { text: "Inheritance", value: "inheritance", sortable: false },
        { text: "Permissions", value: "permissions", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  created: function() {},
  mounted() {},
  mixins: [permissionchecks],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"])
  },

  methods: {
    editProfile(i) {
      this.editprofile = i;
      this.editprofilekey += 1;
      this.dialogni = !this.dialogni;
    },
    newProfile() {
      //todo
      this.$emit("newindexer");
    },
    removeProfile(profile) {
      ApiIndexerprofile.delete(profile["id"])
      .then(
        ({  }) => {
          this.currentAnalysis.indexerprofiles.available.splice(
            this.currentAnalysis.indexerprofiles.available.indexOf(profile),
            1
          );
          this.updateModel();
        }
      );
    }
  }
};
</script>

<style >
</style>
