export const df_operations = ["==", "!=", "<", ">"]
export const df_conjugations = ["and", "or", "&&", "||"]
export const df_convtypes = ["ip", "ipv4", "ipv6", "tcp", "udp", "eth"]

export const df_field_seperator = /#/g
export const df_field_seperator_str = "#"



const tcpflags = [
    {i: 1, s: "S"},
    {i: 4, s: "A"},
    {i: 3, s: "P"},
    {i: 0, s: "F"},
    {i: 2, s: "R"},
    {i: 5, s: "U"},
    {i: 6, s: "E"},
    {i: 7, s: "C"},
    {i: 8, s: "N"},
    {i: 9, s: "R"}
]

export const df_unfilterable = [
    "_ws#col#Info"
]


export const displayfilter = {

    methods: {
        tcp_flags_human_readable: function fd(intflags){
            let out = ""
            for (let k of tcpflags){
                if( intflags & (1 << k.i )){
                    out = out + k.s
                }
            }
            return out
        },
        tcp_merge_flags: function ma(flagsarr){
            let out = 0
            for (let intflags of flagsarr){
                out = out | intflags
            }
            return out
        },
        df_indexed_fields_for: function () {

        },
        df_es_fields_to_wireshark: function(fields){
            let out = []
            for(let f of fields){
                out.push(this.df_es_field_to_wireshark(f));
            }
            return out;
        },
        df_wireshark_field_to_es: function (field) {
            return field.split(".").join(df_field_seperator_str);
        },
        df_es_field_to_wireshark: function (field) {
            return field.split(df_field_seperator_str).join(".");
        },

        df_apply_to_packetlist: function (filter) {
            if(this.currentAnalysis.filter != filter ){
                this.currentAnalysis.filter = filter
                this.currentAnalysis.applyfilter = true
                this.currentAnalysis.filterneedsupdate = true;
            }
        },
        df_prepare_for_packetlist: function(filter){
            this.currentAnalysis.filter = filter
            this.currentAnalysis.applyfilter = false
            this.currentAnalysis.filterstate = null;
            this.currentAnalysis.filterneedsupdate = false;
        },
        df_field_is_indexed: function(field) {    
            
            for (var l of this.currentAnalysis.fielddefinitions) {
                if (field == l["esfield"]) {
                    return true;
                }
            }

            return false;
        },
        df_quotefield: function (field) {
            return '"' + field + '"'
        },
        df_explicit: function (filter, apply = true) {
            if (apply) {
                this.df_apply_to_packetlist(filter)
            }
        },
        df_isunfilterablefield: function(field){
            return df_unfilterable.includes(field)
        },
        df_build_filter: function(field, value, op = "=="){
            var f="";
        
            /* in normal sharkd mode this it is ok if not indexed */
            // if (!this.df_field_is_indexed(field)) {
            //     console.log("Field is not indexed: " + field)
            //     return ""
            // }

            if (df_operations.includes(op)) {
                f = this.df_es_field_to_wireshark(field) + " " + op + " " + value//+ this.df_quotefield(value)
            } else {
                console.log("Unknown operation type")
                
            }
            return f
        },
        df_filter: function (field, value, op = "==", apply = false) {
            var f = this.df_build_filter(field, value, op);

            if (apply) {
                this.df_apply_to_packetlist(f)
            } else {
                this.df_prepare_for_packetlist(f)
            }
        },
        df_join: function (left, right, co) {
            let result
            if (df_conjugations.includes(co)) {
                result = "( " + left + " " + co + " " + right + " )"
                return result
            }
        },
        getFilter(item, ctype) {
            
        let f;
        let f1;
        let f2;
        let src1;
        let src2;
        let dst1;
        let dst2;

        let sep = '"'
        
        if(this.currentAnalysis.index == "none"){
            sep = ""
        }

        return this.df_conv_filter(ctype, item.src.ip, item.src.port, item.dst.ip, item.dst.port)
        },
        df_conv_filter: function (type, src, srcport, dst, dstport, apply = false, unidirectional = false) {
            let f, src1, src2, left, dst1, dst2, right;

            if (df_convtypes.includes(type)) {
                switch (type) {
                    case "ip":
                    case "ipv4":
                        src1 = this.df_join(this.df_build_filter("ip.src", src), this.df_build_filter("ip.dst", dst), "and")
                        
                        if(!unidirectional){
                            dst1 = this.df_join(this.df_build_filter("ip.src", dst), this.df_build_filter("ip.dst", src), "and")
                            f = this.df_join(src1, dst1, "or")
                        } else {
                            f = src1
                        }

                        break
                    case "tcp":
                        src1 = this.df_join(this.df_build_filter("ip.src", src), this.df_build_filter("tcp.srcport", srcport), "and")
                        dst1 = this.df_join(this.df_build_filter("ip.dst", dst), this.df_build_filter("tcp.dstport", dstport), "and")
                        left = this.df_join(src1, dst1, "and")

                        if(!unidirectional){
                            src2 = this.df_join(this.df_build_filter("ip.src", dst), this.df_build_filter("tcp.srcport", dstport), "and")
                            dst2 = this.df_join(this.df_build_filter("ip.dst", src), this.df_build_filter("tcp.dstport", srcport), "and")
                            right = this.df_join(src2, dst2, "and")
                            f = this.df_join(left, right, "or")
                        } else{
                            f=left
                        }
                        break;
                    case "udp":

                        src1 = this.df_join(this.df_build_filter("ip.src", src), this.df_build_filter("udp.srcport", srcport), "and")
                        dst1 = this.df_join(this.df_build_filter("ip.dst", dst), this.df_build_filter("udp.dstport", dstport), "and")
                        left = this.df_join(src1, dst1, "and")

                        if(!unidirectional){
                            
                            src2 = this.df_join(this.df_build_filter("ip.src", dst), this.df_build_filter("udp.srcport", dstport), "and")
                            dst2 = this.df_join(this.df_build_filter("ip.dst", src), this.df_build_filter("udp.dstport", srcport), "and")
                            right = this.df_join(src2, dst2, "and")
                            f = this.df_join(left, right, "or")
                        } else {
                            f= left
                        }
                        break;
                    case "eth":

                        src1 = this.df_join(this.df_build_filter("eth.src", src), this.df_build_filter("eth.dst", dst), "and")
                        if(!unidirectional){
                            dst1 = this.df_join(this.df_build_filter("eth.src", dst), this.df_build_filter("eth.dst", src), "and")
                            f = this.df_join(src1, dst1, "or")
                        } else{
                            f= src1
                        }

                        break
                }

            } else {
                console.log("Unknown conversation type")
            }
            if (apply) {
                this.df_apply_to_packetlist(f)
            }
            return f
        }
    }
}