<template>
 
  <v-menu offset-x left  v-if="currentAnalysis" v-model="menustate">
    <template v-slot:activator="{ on: menu }">
      <v-btn  icon v-on="{ ...menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark icon><v-icon dark color="light-blue lighten-1">list</v-icon></v-btn>
          </template>
          Statistics
        </v-tooltip>
      </v-btn>
    </template>

    <v-list v-if="menuitems && Object.keys(currentAnalysis.sharkdinfo).length !== 0">
      <v-menu  v-for="(item, index) in menuitems" :key="index" open-on-hover offset-x left scrollable max-height="450" >
        <template v-slot:activator="{ on }" internal-activator>

          <v-list-item
            v-on="on">
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>

        </template>
        <v-list>


          <v-list-item
            v-for="(sub, index) in sharkd_normalize_taps(item.tap)" :key="index"
            @click.stop="openTap(sub.tap)">

            <v-list-item-title>{{ sub.name }}</v-list-item-title>
          </v-list-item>

    
        </v-list>
      </v-menu>

      <!--<v-list-item v-for="(item, index) in menuitems" :key="index" @click="item.action">
        <v-list-item-title >{{item.text}}</v-list-item-title>
      </v-list-item>-->
    </v-list>


  </v-menu>
</template>

<script>

import { mapGetters } from "vuex";
import { sharkdhelper } from "@/common/sharkdhelper"
import { navigation } from "@/common/navigation";

export default {
  props: [],
  name: "statisticsmenu",
  data() {
    return {
      menustate: false,
      menuitems : [
        {
          text: "Conversation Statistics",
          tap: "convs",
          icon: "swap_horiz"
        },
        {
          text: "Endpoint Statistics",
          tap: "endpt",
          icon: "adjust"
        },
        {
          text: "Service Responsetime",
          tap: "srt",
          icon: "access_time"
        },
        {
          text: "Statistics",
          tap: "stats",
          icon: "sort"
        },
        {
          text: "Export Objects",
          tap: "eo",
          icon: "cloud_download"
        },
        { 
          text: "All flows",
          tap: "seqa",
          icon: "waves"
        },
        {
          text: "Misc Taps",
          tap: "taps",
          icon: "polymer"
        },
        {
          text: "Network stats",
          tap: "nstat",
          icon: "settings_ethernet"
        },
        {
          text: "RTD",
          tap: "rtd",
          icon: "settings_phone"
        }
        /*{
          text: "Connections",
          action: this.openConnections
        },*/        
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic", "error"]),
    infoisavailable(){
      return Object.keys(this.currentAnalysis.sharkdinfo).length != 0
    },
  },
  mixins : [sharkdhelper, navigation],
  mounted() {
  },
  created: function() {
  },
  watch: {
  },
  methods: {
    openTap(tap="conv:TCP"){
      this.menustate = false
      this.navigateTap(this.currentAnalysis.pcapid, tap)
    },
  }
};
</script>

<style>
</style>