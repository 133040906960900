<template>
  <div class="pa-0 ma-0"  style="display: inline">
    <v-menu
      v-model="showfilterbuttonmenu"
      :position-x="filterbuttonmenux"
      :position-y="filterbuttonmenuy"
      absolute
      offset-y
      v-if="canEditCurrentProfile"
    >
      <v-card>
        <v-list>
          <v-list-item @click="editfilterbutton(menubutton)">
            <v-list-item-action>
              <v-icon>edit</v-icon>
            </v-list-item-action>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>

          <v-list-item @click="removefilterbutton(menubutton)">
            <v-list-item-action>
              <v-icon>delete</v-icon>
            </v-list-item-action>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <template v-if="$vuetify.breakpoint.mobile">
      <v-chip @click="showbuttons = !showbuttons" label small color="secondary" class="mx-1 my-0">Filters</v-chip>
    </template>

    <template v-if="groupedbuttons != null && (($vuetify.breakpoint.mobile && showbuttons) || !$vuetify.breakpoint.mobile)">
      <template v-for="(grouporbutton, index) in groupedbuttons">
        <v-chip
          v-if="grouporbutton.type == 'button'"
          v-bind:key="index"
          label
          small
          class="mx-1 my-0"
          color="primary"
          @contextmenu.prevent="
            doshowfilterbuttonmenu($event, grouporbutton.idx)
          "
          @click="applyDisplayFilterAndClose(grouporbutton.rule)"
          >{{ grouporbutton.rulename }}</v-chip
        >

        <v-menu v-else offset-y v-bind:key="index">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              small
              :class="$vuetify.breakpoint.mobile? 'mx-1 mt-1' : 'mx-1'"
              color="primary"
              v-bind="attrs"
              v-on="on"
              >{{ grouporbutton.groupname }}</v-chip
            >
          </template>
          <v-list dense>
            <v-list-item
              link
              v-for="(button, index) in grouporbutton.buttons"
              :key="index"
            >
              <v-list-item-title
                v-text="button.rulename"
                @click="applyDisplayFilterAndClose(button.rule)"
                @contextmenu.prevent="
                  doshowfilterbuttonmenu($event, button.idx)
                "
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>

    <!--<v-chip
      v-for="(button, index) in currentAnalysis.profiles.selected.filterbuttons"
      v-bind:key="index"
      label
      small
      class="mx-1"
      color="primary"
      @contextmenu.prevent="doshowfilterbuttonmenu($event, index)"
      @click="df_apply_to_packetlist(button.rule)"
      >{{ button.rulename }}</v-chip
    >-->

    <!-- introduce definition of default profile -->
    <v-tooltip
      v-if="
        currentAnalysis.profiles.selected.filterbuttons &&
        currentAnalysis.profiles.selected.filterbuttons.length <= 20 &&
        canEditCurrentProfile
      "
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn icon x-small v-on="on" slot="activator" text @click="addbutton">
          <v-icon small>mdi-filter-plus</v-icon>
        </v-btn>
      </template>
      <span>Add filter button</span>
    </v-tooltip>

    <v-tooltip v-else-if="false" bottom>
      <template v-slot:activator="{ on }">
        <v-btn disabled icon small v-on="on" slot="activator" text>
          <v-icon small>mdi-filter-plus</v-icon>
        </v-btn>
      </template>
      <span>To add new buttons please create a new profile</span>
    </v-tooltip>

    <filterbuttondialog
      v-if="currentAnalysis.showfilterbuttondialog"
      v-model="menubutton"
      @updated="groupbuttons"
    />
  </div>
</template>

<script>
import { displayfilter } from "@/common/displayfilter";
import ApiProfile from "@/common/api/profile";
import Filterbuttondialog from "@/components/settings/Filterbuttondialog";
import { permissionchecks } from "@/common/permissions";
import { mapGetters } from "vuex";
import { DEFAULT_PROFILE } from "@/common/config";
export default {
  props: {
    index: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: null,
  },
  components: { Filterbuttondialog },
  mixins: [displayfilter, permissionchecks],
  name: "filterbuttons",
  data() {
    return {
      defaultprofile: DEFAULT_PROFILE,
      groupedbuttons: null,
      filterbuttonmenux: 0,
      showbuttons: false,
      filterbuttonmenuy: 0,
      menubutton: -1,
      showfilterbuttonmenu: false,
      filterbutton: {
        name: "",
        rulename: "",
      },
    };
  },
  created: function () {},
  mounted() {
    this.groupbuttons();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  watch: {
  },
  methods: {
    groupbuttons() {
      
      let buttons = this.currentAnalysis.profiles.selected.filterbuttons
      
      this.groupedbuttons = null
      let groups = [];

      for (const [index, button] of buttons.entries()) {
        if (button.rulename.includes("//")) {
          let out = button.rulename.split("//");
          let group = out[0].trim();
          let label = out.slice(1,out.length).join(" // ")
          let findex = groups.findIndex(({ groupname }) => groupname === group);
          if (findex < 0) {
            groups.push({
              type: "group",
              groupname: group,
              buttons: [
                {
                  rulename: label,
                  rule: button.rule,
                  idx: index,
                },
              ],
            });
          } else {
            groups[findex].buttons.push({
              idx: index,
              rulename: label,
              rule: button.rule,
            });
          }
        } else {
          groups.push({
            type: "button",
            idx: index,
            rulename: button.rulename,
            rule: button.rule,
          });
        }
      }
      this.groupedbuttons = groups;
    },
    editfilterbutton(n) {
      this.currentAnalysis.showfilterbuttondialog = true;
    },
    addbutton() {
      this.menubutton = -1;
      this.currentAnalysis.showfilterbuttondialog = true;
    },
    applyDisplayFilterAndClose(rule){
      if(this.$vuetify.breakpoint.mobile){
        this.showbuttons = false
      }
      this.df_apply_to_packetlist(rule)
    },
    removefilterbutton(button) {
      
      if(button){
        this.currentAnalysis.profiles.selected.filterbuttons.splice(button, 1);

        ApiProfile.update(this.currentAnalysis.profiles.selected)
          .then(({ data }) => {
            this.groupbuttons()
            this.currentAnalysis.profileneedsupdate = true;
          })
          .catch(({ response }) => {});
      }

    },
    doshowfilterbuttonmenu(e, button) {
      this.menubutton = button;
      this.showfilterbuttonmenu = false;
      this.filterbuttonmenux = e.clientX;
      this.filterbuttonmenuy = e.clientY;
      this.$nextTick(() => {
        this.showfilterbuttonmenu = true;
      });
    },
  },
};
</script>

<style >
</style>
