<template>
  <v-row class="ma-10 pa-0">
    <v-col cols="11">
      <v-switch v-model="frames" label="Frames instead of bytes"></v-switch>
    </v-col>
    <v-col cols="11">
      <div class="protocoldistribtionchart" ref="chartdiv"></div>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import ApiPacketlist from "@/common/api/packetlist";
am4core.useTheme(am4themes_animated);

import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { navigation } from "@/common/navigation";

export default {
  name: "protocoldistribution",
  props: {
    pcapid: {
      type: String,
      default: null,
    },
    pcap: null,
  },

  components: {},
  mixins: [displayfilter, navigation],
  data() {
    return {
      c: null,
      ipcapid: null,
      frames: false,
      iindex: null,
      displayfilter: "none",
      moment: moment,
    };
  },
  watch: {
    "frames": function(){
      this.apply()
    }
  },
  created() {
    // IMPORTANT we set these variables here so that they are _NOT_ reactive
    // to avoid observer overhead
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.ipcapid = this.pcapid;
    this.apply();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    apply() {
      let clean = [];
      for (let s of this.pcap.protocolstatistics) {
        
        clean.push({
          tag: s.protocol,
          weight: frames ? s.frames: s.bytes,
          path: s.path
        });
        
      }

      am4core.useTheme(am4themes_animated);
      // Themes end

      let chart = am4core.create(
        "protocoldistribtionchart",
        am4plugins_wordCloud.WordCloud
      );
      let series = chart.series.push(
        new am4plugins_wordCloud.WordCloudSeries()
      );

      series.accuracy = 4;
      series.step = 15;
      series.rotationThreshold = 0.7;

      series.labels.template.tooltipText = "{path}: {value}";

      series.maxFontSize = am4core.percent(30);
      series.data = clean;
      series.dataFields.word = "tag";
      series.dataFields.value = "weight";
      series.dataFields.path = "path";
      series.colors = new am4core.ColorSet();
      series.colors.passOptions = {};
      series.colors.reuse = true;
      series.labels.template.propertyFields.fill = "color";
      series.labels.template.url = "/pcaps/private/{path}";
      series.labels.template.urlTarget = "_blank";
      this.chart = chart;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.protocoldistribtionchart {
  min-height: 500px;
  width: 100%;
}

.container {
  height: 100%;
  max-width: 100%;
  overflow-x: auto !important;
}

g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}

.mycheckbox.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
