<template>
  <vue-cookie-accept-decline
    :ref="'myPanel1'"
    :elementId="'myPanel1'"
    :debug="false"
    :position="'bottom-right'"
    :type="'floating'"
    :disableDecline="false"
    :transitionName="'slideFromBottom'"
    :showPostponeButton="false"
    @status="cookieStatus"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline"
  >
    <!-- Optional -->
    <div slot="postponeContent">&times;</div>

    <!-- Optional -->
    <div slot="message">
      We use Google Analytics to ensure you get the best experience on this website
      <a href="/legal/privacy/" target="_blank">Learn More...</a>
    </div>

    <!-- Optional -->
    <div slot="declineContent">OPT OUT</div>

    <!-- Optional -->
    <div slot="acceptContent">GOT IT!</div>
  </vue-cookie-accept-decline>
</template>

<script>
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";

import VueCookieAcceptDecline from "vue-cookie-accept-decline";

import { setOptions, bootstrap } from 'vue-gtag'

export default {
  components: { VueCookieAcceptDecline },
  data() {
    return {
    };
  },
  mounted () {

    let ga = localStorage.getItem('google-analytics');
    if (true || (ga != undefined && ga == "true")){
      this.enablePlugin()
    }
  },
  methods: {
    cookieStatus(status) {

    },
    enablePlugin () {
      //console.log("Enabling analytics")
      localStorage.setItem('google-analytics', 'true');
      // setOptions({
      //     config: {
      //         id: "G-KQYCEC361N",
      //     },
      //     enabled: true,
      //     disableScriptLoad: false
      // })
      bootstrap().then((gtag) => {
        
      })
    },
    cookieClickedAccept() {
      this.enablePlugin()
    },
    cookieClickedDecline() {},
  },
};
</script>

<style lang="scss">
.cookie__floating__wrap{
  color: black;
}
</style>
