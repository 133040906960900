<template>
  <v-card>
    <v-dialog v-model="dialogDetails" width="900px">
      <v-card v-if="details">
        <v-card-title class="display-1">{{illustration.name}} is {{details.value}}</v-card-title>
        <v-card-text v-if="details.description">{{details.description}}</v-card-text>
        <v-card-text v-else>Submit a new description for this field value</v-card-text>

        <v-list>
          <v-subheader>Analyses related to this field</v-subheader>
          <v-list-item two-line v-for="a in details.analyses" v-bind:key="a.field" @click="gotoAnalysis(i)">
            <v-list-item-content>
              <v-list-item-title>{{a.name}}</v-list-item-title>
              <v-list-item-subtitle>{{a.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list>
          <v-subheader>Documents related to this field</v-subheader>
          <v-list-item two-line v-for="a in details.documents" v-bind:key="a.field" @click="gotoLink(a.link)">
            <v-list-item-content>
              <v-list-item-title>{{a.name}}</v-list-item-title>
              <v-list-item-subtitle>{{a.link}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-subheader>ASK Wireshark Questions related to this field</v-subheader>
          <v-list-item two-line v-for="a in details.ask" v-bind:key="a.field" @click="gotoLink(a.link)">
            <v-list-item-content>
              <v-list-item-title>{{a.name}}</v-list-item-title>
              <v-list-item-subtitle>{{a.link}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    
    <v-card-title>
      <v-icon>{{illustration.icon}}</v-icon>
      <div class="my-1 display-1">{{illustration.fieldinfo.description}} - {{illustration.fieldinfo.field}}: {{illustration.value}}
        <v-btn v-if="currentAnalysis.features.iplookup && isIpInfo" text v-on:click="loadipinfo(illustration.value)">Load IP Info</v-btn>
      </div>
    </v-card-title>

    <v-card-text>
      

      <template v-if="ipdata">

      <div class="my-4 subtitle-1">
        IP Information {{illustration.value}}
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Key</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>IP Address</td><td><v-chip outlined label>{{ ipdata.ip }}</v-chip></td></tr>
            <tr><td>Continent</td><td><v-chip outlined label>{{ ipdata.continent_name }}</v-chip></td></tr>
            <tr><td>Country</td><td><v-chip outlined label>{{ ipdata.country_name }}</v-chip></td></tr>
            <tr><td>Region</td><td><v-chip outlined label>{{ ipdata.region_code }}</v-chip></td></tr>
            <tr><td>City</td><td><v-chip outlined label>{{ ipdata.city }}</v-chip></td></tr>
            <tr><td>Zip</td><td><v-chip outlined label>{{ ipdata.zip }}</v-chip></td></tr>
            <tr><td>Latitude</td><td><v-chip outlined label>{{ ipdata.latitude }}</v-chip></td></tr>
            <tr><td>Longitude</td><td><v-chip outlined label>{{ ipdata.longitude }}</v-chip></td></tr>
          </tbody>
        </template>
      </v-simple-table>

      </template>

      <div class="my-4 subtitle-1">
        Information about {{illustration.fieldinfo.field}}
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Indexed
              </th>
              <th class="text-left">
                Encoding
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Is Proto
              </th>

              <template v-if="extendedstats">
                <th class="text-left">Avg</th>
                <th class="text-left">Min</th>
                <th class="text-left">Max</th>
                <th class="text-left">Sum</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="isindexed">✅</td>
              <td v-else>❌</td>
              <td><v-chip outlined label>{{ illustration.fieldinfo.encoding }}</v-chip></td>
              <td><v-chip outlined label>{{ illustration.fieldinfo.fieldtype }}</v-chip></td>
              <td><v-chip outlined label>{{ illustration.fieldinfo.fieldisproto }}</v-chip></td>
              <template v-if="extendedstats">
                <td><v-chip label outlined>{{ extendedstats.avg | formatDelta }}</v-chip></td>
                <td><v-chip label outlined>{{ extendedstats.min }}</v-chip></td>
                <td><v-chip label outlined>{{ extendedstats.max }}</v-chip></td>
                <td><v-chip label outlined>{{ extendedstats.sum }}</v-chip></td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="my-4 subtitle-1" v-if="histogram">
        Typical values for {{illustration.fieldinfo.field}} in this trace
      </div>

      <fieldstatistics v-if="histogram" :histogram="histogram"></fieldstatistics>

      <v-data-table :headers="headers" :items="illustration.displayfilter" hide-default-footer>
        <template slot="item" slot-scope="props">
          <tr>

            <td>{{props.item.description}}</td>
            <td>
              <v-text-field
                class="pt-3"
                hide-details
                solo
                outline
                :value="props.item.filter"
                append-icon="navigate_next"
                @click:append="df_apply_to_packetlist(props.item.filter)"

              ></v-text-field>
              <!--<a href="#">{{illustration.field }} == {{props.item.value}}</a>-->
            </td>
            <!--<td @click="showDetails(illustration.field, props.item)">
              <a href="#">details</a>
            </td>-->
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>

</template>

<script>

import ApiDisplayfilter from "@/common/api/displayfilter";
import Fieldstatistics from "./Fieldstatistics";
import { displayfilter } from "@/common/displayfilter";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import axios from 'axios'

export default {
  name: "fieldillustration",
  props: ["value"],
  mixins: [displayfilter],
  computed: {
    ...mapGetters(["currentAnalysis"]),
    isIpInfo: function() {
      let valid = ["ip.src", "ip.dst", "ipv6.src", "ipv6.dst"]
      if (valid.includes(this.illustration.fieldinfo.field)){
        return true
      }
      return false
    }
  },
  components: {
    Fieldstatistics
  },
  data() {
    return {
      extendedstats: undefined,
      histogram: null,
      isindexed: false,
      ipdata: null,
      ipflag: null,
      headers: [
        {
          text: "Description",
          align: "left",
          value: "description",
          sortable: false,
          width: "500px"
        },
        {
          text: "Display Filter",
          align: "left",
          value: "actions",
          sortable: false
        }
      ],
      dialogDetails: false,
      details: null,
      illustration: {
        field: null,
        value: null,
        displayfilter: [],
        fieldinfo: {
          field: "ip.src",
          type: "FT_TEXT",
          description: `This field contains the source address`,     
        },
        rfcs: [],
        analyses: [],
        values: [
        ]
      }
    };
  },

  watch: {
    value() {
      this.loadIllustration(this.value.field, this.value.value);
    }
  },
  mounted() {
    this.loadIllustration(this.value.field, this.value.value);
  },
  methods: {
    loadipinfo(ip){
      var instance = axios.create();
      delete instance.defaults.headers.common['Authorization'];
      instance.get('http://api.ipstack.com/'+ip+'?access_key=1c28fe98ab7bcba98ed34941b4edb620')
      .then(response => {
        this.ipdata = response.data

      })    
    },
    loadIllustration(field, value) {
      
      this.illustration.field = field;
      this.illustration.value = value;

      ApiDisplayfilter.explainfield({
        "field": field,
        "pcapid": this.currentAnalysis.pcapid,
        "index": this.currentAnalysis.index
      })
        .then(({ data }) => {
          if(data["extendedstats"]){
            
            this.extendedstats = data["extendedstats"]
          }else{
            this.extendedstats = undefined
          }
          
          if (data["histogram"].length != 0){
            this.histogram = data["histogram"]
          } else{
            this.histogram = undefined
          }

          this.isindexed = data["isindexed"]

          if(data["fieldinfo"]){
            data["fieldinfo"]["field"] = this.df_es_field_to_wireshark(data["fieldinfo"]["field"])
            this.illustration.fieldinfo = data["fieldinfo"]
          }

          if("displayfilter" in data){
            this.illustration.displayfilter = data["displayfilter"]
          }
          
      });
    },

    displayfilter: function(i, v) {
      switch (i.fieldinfo.type) {
        case "FT_TEXT":
          return i.field + ' == "' + v.value + '"';
        default:
          return i.field + ' == "' + v.value + '"';
      }
    },
    applyDisplayfilter: function(e) {

      this.displayfilter.filter = e;
      // this.$eventHub.$emit("displayfilter-set", e);
    },
    gotoLink: function() {},
    showDetails: function(field, i) {
      this.details = i;
      this.details.field = field;
      this.details.filter = this.details.field + " == " + this.details.value;
      this.dialogDetails = true;
    }
  }
};
</script>

<style >
.illustration-text-field .v-input__append-inner {
  margin-top: 3px !important;
}
.illustration-text-field .v-input__slot {
  margin-top: 0px !important;
}

.illustration-text-field input {
  margin-top: 0px !important;
  min-height: 12px !important;
}
</style>