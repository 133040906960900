<template>
  <v-snackbar :timeout="timeout" v-if="type == 'error'" color="red" v-model="snackbar" bottom>{{
  snackbartext
  }}</v-snackbar>
  <v-snackbar :timeout="timeout" v-else-if="type == 'success'" color="green" v-model="snackbar" bottom>{{ snackbartext
  }}</v-snackbar>
  <v-snackbar :timeout="timeout" v-else-if="type == 'clientsidewarning'" color="primary" v-model="snackbar" text bottom>
    <v-row>
      <v-col cols="12">
        {{ snackbartext }}
      </v-col>
    </v-row>

    <template v-slot:action="{ attrs }">
      <v-row class="py-2">
        <v-col cols="12">
          <v-btn color="red" text v-bind="attrs" @click="loadallpackets">
            Load all packets
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn color="red" text v-bind="attrs" @click="gotosettings">
            Change permanently
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close dialog
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-snackbar>

  <v-snackbar :timeout="timeout" v-model="snackbar" v-else-if="type == 'analysis'" bottom>
    <span class="headline">
      {{snackbartext}}</span>

    <template v-slot:action="{ attrs }">
      <v-btn color="gray" text v-bind="attrs" @click="snackbar=false">
        Close
      </v-btn>

      <v-btn color="pink" text v-bind="attrs" @click="nextAnalysisStep()">
        ➡️ Next
      </v-btn>

    </template>
  </v-snackbar>
  <v-snackbar :timeout="timeout" v-else v-model="snackbar" bottom>
    {{snackbartext}}
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "errorbar",
  computed: {
    ...mapGetters(["error", "currentAnalysis"]),
  },
  watch: {
    error: {
      handler: function (n, o) {
        if (n.msg) {
          this.snackbartext = n.msg;
          this.type = n.type;
          
          if (n.msg == null) {
            this.snackbar = false
            return
          } else {
            this.snackbar = true;
          }

          if ("timeout" in n) {
            this.timeout = n.timeout;
          } else {
            this.timeout = 2000
          }
          if ("headline" in n) {
            this.headline = true;
          } else {
            this.headline = false
          }

          if ("val" in n) {
            this.val = n.val;
          }
          n.val = 0;
          n.msg = null;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      timeout: 2000,
      headline: false,
      snackbar: false,
      snackbartext: "",
      val: 0,
      type: "",
    };
  },
  methods: {
    nextAnalysisStep: function () {
      this.snackbar = false
      this.$eventHub.$emit("next-step", null);
    },
    gotosettings() {
      this.$router
        .push({
          name: "Analysis Profile",
          params: { profileid: this.currentAnalysis.profiles.selected.id },
        })
        .catch((err) => { });
    },
    loadallpackets() {
      this.currentAnalysis.profiles.modifications.maxPackets = this.currentAnalysis.profiles.selected.maxPackets
      this.currentAnalysis.profiles.selected.maxPackets = this.val;
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;

      this.type = "success";
      this.snackbartext = "Packets loaded";
    },
  },
};
</script>

<style>

</style>