import ApiService from "@/common/api.service";

const ApiTask = {

    list(type){
        if (!!type){
            return ApiService.query("ajax/tasks/"+type)
        } else {
            return ApiService.query("ajax/tasks/running") 
        }


    },
    delete(id){
        return ApiService.delete("ajax/task", id)
    }
}

export default ApiTask