<template>

  <v-dialog
    v-if="currentAnalysis.pcaplist.massedit"
    v-model="currentAnalysis.pcaplist.massedit"
    max-width="700px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-row  >
          <v-col cols="12">
            <h3>Mass edit PCAP</h3>
            <br>
          </v-col>
          <v-col cols="12">
          </v-col>
          <v-col cols="12">
            <v-row  >
                <v-col cols="12">
                    <template v-if="currentUser">
                        <v-select
                            :items="availablefields"
                            v-model="selectedfield"
                            item-text="name"
                            item-value="id"
                            return-object
                            label="Select field..."
                        ></v-select>

                        <v-textarea v-if="selectedfield.id == 'description'" v-model="value"></v-textarea>
                        <v-text-field v-else v-model="value"></v-text-field>
                        
                
                    </template>
                </v-col>
                <v-col cols="12">
                <v-btn @click="apply">Apply</v-btn>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import ApiPcapmeta from "@/common/api/pcapmeta";

export default {
  props: ["indices", "pcaps"],
  mixins: [commonmethods],
  name: "massedit",
  data() {
    return {
      selectedpcaps: [],
      availablefields: [
        {name : "Credits", id : "credits"},
        {name : "Source URL", id : "source_url"},
        {name : "License", id : "license"},
        {name : "Description", id: "description"}
      ],
      selectedfield: {name : "Credits", id : "credits"},
      value : ""
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error"])
  },
  mounted() {
    this.selectedpcaps = this.pcaps
    if(this.selectedpcaps.length == 1){
      
    }
  },
  created: function() {
  },
  watch: {
    pcaps(n) {
      this.selectedpcaps = n
    },
  },
  methods: {
    apply() {
        for(let p of this.pcaps){
            p[this.selectedfield.id] = this.value
            ApiPcapmeta.update(p)
            .then(() => {
                this.error.msg = "";
            })
            .catch(({ response }) => {
                this.error.msg = response["error"];
            });
        }
    }
  }
};
</script>

<style>
</style>