<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="tags"
        chips
        solo
        :items="availabletags"
        :search-input.sync="searchtag"
        :filter="(v) => v"
        cache-items
        item-value="id"
        no-data-text="No tags available"
        :label="label"
        multiple
        :disabled="!isowner"
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <tag
            close
            @click:close="remove(item)"
            :tag="item"
            :selected="selected"
            class="chip--select-multi"
            @click="select"
            @input="remove(item)"
          ></tag>
        </template>
        <template slot="item" slot-scope="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title>
                <tag
                  :goto="false"
                  :tag="data.item"
                  class="chip--select-multi"
                ></tag>
                {{ data.item.description }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" v-if="isowner && !setvuex">
      <v-btn @click="updateTags">Save</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ApiTag from "@/common/api/tag";
import ApiTags from "@/common/api/tags";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import Tag from "../Tag";
export default {
  props: ["label", "objectid", "type", "isowner", "setvuex"],
  mounted() {
    this.loadTags();
  },
  mixins: [commonmethods],
  components: { Tag },
  name: "tagsautocomplete",
  data() {
    return {
      availabletags: [],
      tags: [],
      searchtag: null,
    };
  },
  computed: {
    ...mapGetters(["error", "currentUser", "avatarPic", "currentAnalysis"]),
  },
  watch: {
    searchtag(val) {
      val && this.lookupTag(val);
    },
    tags(n, o) {
      if (this.setvuex) {
        this.currentAnalysis.uploadtags.length = 0;
        this.currentAnalysis.uploadtags.push(...this.tags);
      }
    },
  },
  methods: {
    loadTags() {
      let p = {
        listtype: "list",
        type: "pcap",
      };

      if (this.objectid) {
        (p["objectids"] = [this.objectid]), (p["listtype"] = "objectids");
      }

      // dont load all tags
      ApiTags.list(p).then(({ data }) => {
        if (data) {
          let addtags = data.tags;
          this.availabletags.push(...addtags);
          if (p.listtype == "objectids") {
            for (let t of addtags) {
              this.tags.push(t["id"]);
            }
          }
        }
      });
    },
    lookupTag(v) {
      ApiTag.search({ term: this.searchtag, type: "pcap" }).then(({ data }) => {
        this.availabletags = data;
      });
    },
    updateTags() {
      if (!this.setvuex) {
        var u = {
          type: "pcap",
          objectids: [this.objectid],
          tagids: [],
        };

        for (let tag of this.tags) {
          u.tagids.push(tag);
        }
        ApiTags.update(u).then(({ data }) => {
          this.error.type = "info";
          this.error.msg = "Successfully tagged";
        });
      }
    },
    remove(item) {
      const index = this.tags.indexOf(item);
      this.tags.splice(index, 1);
    },
  },
};
</script>

<style>
</style>