
export const renderers = {
    "Default" : {}
    ,
    "IpRenderer" : {
        "Description" : "Renders an IP Adress",
        "fields" : [
            "_ws#col#Source",
            "_ws#col#Destinaion",
            "ip#src",
            "ip#dst"
        ]
    },
    "IntRenderer" : {
        "Description" : "Renders a numeric value",
        "max" : 100,
        "fieldtype" : [
            "F_INT" // FIXME
        ],
        "fields" : [
            "frame#len"
        ]
    },
    "ChipRenderer" : {
        "Description" : "Renders a numeric value",
        "max" : 100,
        "fieldtype" : [
            "F_INT" // FIXME
        ],
        "fields" : [
            "frame#len"
        ]
    },
    "FlagRenderer" : {
        "Description" : "Renders a geo country flag",
        "fieldtype" : [
            "F_INT" // FIXME
        ],
        "fields" : [
            "ip#geoip#dst_country_iso", "ip#geoip#src_country_iso"
        ]
    }, 
    "RelativeTimeRenderer" : {
        "Description" : "Renders relative time to decimal unites",
        "fieldtype" : [
            "F_INT" // FIXME
        ],
        "fields" : [
            "frame#time_relative", "frame#time_delta_displayed"
        ]
    },
    
}

export const renderermethods = {
    methods: {
        isCompatible: function (field, renderer) {
            
        },
        getRenderers: function(){
            return Object.keys(renderers)
        }
    }
}