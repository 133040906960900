<template>
  <v-row
    v-if="error"
    align="center"
    justify="center"
    class="display-1 pt-5 full-width"
  >
    <v-col class="text-center" cols="12">{{ this.error }}</v-col>

    <v-col class="text-center" cols="12">
      <v-icon size="200">error</v-icon>
    </v-col>

    <v-col cols="12" class="pt-3 text-center">
      <slot name="actions"></slot>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12" class="mt-14 pb-14 mb-4" justify="center" align="center">
         <!-- <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: "",
    },
  },
  name: "error",
  data() {
    return {};
  },
  mounted() {},
  methods: {

  },
};
</script>

<style>
</style>