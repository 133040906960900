import ApiService from "@/common/api.service";

// const LRU = require("lru-cache");
// export const cache = new LRU({ max: 5 });

const ApiIndexerprofile = {

    get(id){
        return ApiService.query("ajax/indexerprofile/"+id)
    },
    list(){
        return ApiService.query("ajax/indexerprofiles")
    },
    create(profile){
        // cache.reset()
        return ApiService.post("ajax/indexerprofile", profile)
    },
    update(profile){
        // cache.reset()
        return ApiService.put("ajax/indexerprofile/"+profile.id, profile)
    },
    delete(id){
        // cache.reset()
        return ApiService.delete("ajax/indexerprofile/" + id)
    },
    copyprofile(id){
        // cache.reset()
        return ApiService.post("ajax/indexerprofilecopy", {id : id})
    }
}

export default ApiIndexerprofile