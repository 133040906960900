import ApiService from "@/common/api.service";

const ApiComment = {

    list(pcapid){
        return ApiService.get("ajax/pcap/comments", pcapid)
    },
    create(comment, pcapid){
        return ApiService.post("ajax/pcap/comment", {
            comment: comment,
            pcapid: pcapid
          })
    },
    update(comment, pcapid, commentid){
        return ApiService.put("ajax/pcap/comment/"+commentid, {
            comment: comment.content,
            pcapid: pcapid
          })
    },
    delete(commentid){
        return ApiService.delete("ajax/pcap/comment/" + commentid)
    }
}

export default ApiComment