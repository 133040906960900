<template>
  <v-row justify="center" v-if="value">
    <vue-dropzone :key="value.id" ref="avatarUpload"  :class="dropzonestyleclass" id="avatarUpload" :options="dropzoneOptions" v-on:vdropzone-sending="sendingEvent"></vue-dropzone>
  </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { ROLE_AVATAR_UPLOAD_URL, AVATAR_GENERIC } from "@/common/config";

import JwtService from "@/common/jwt.service";
import { mapGetters } from "vuex";
export default {
  name: "roleavatarupload",
  components: {
    vueDropzone: vue2Dropzone
  },
  mounted() {
    this.roleid = this.value["id"]
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser"]),
    dropzonestyleclass: function() {
      if (this.currentAnalysis.profiles.selected.theme) {
        return "dropzone-dark"
      }
      return "dropzone";
    },
  },
  props: ["value"],
  watch: {
    value(val) {
      this.roleid =val["id"]
    }
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append('roleid', this.roleid);
    }
  },
  data() {
    return {
      roleid : null,
      showdropzone: true,
      dropzoneOptions: {
        url: ROLE_AVATAR_UPLOAD_URL,
        maxFilesize: 2,
        maxFiles: 1,

        maxfilesexceeded: function() {
          this.showdropzone = false;
        },
        headers: {
          Authorization: "Bearer " + JwtService.getToken()
        },
        dictDefaultMessage:
          "Upload avatar<br><br><img height='80px' src='" +
          AVATAR_GENERIC +
          "'>"
      }
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upload {
  width: 100%;
}


.dropzone-dark {
  width: 100%;
  border: 2px dashed #002007;
  border-radius: 5px;
  background: rgb(12, 11, 11) !important;
}


</style>
