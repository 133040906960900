<template>
    <v-chip
        small

      color="primary"
    >
      {{val}}
    </v-chip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentAnalysis"]),
  },
    data() {
        return {
            val: null,
        }   
    },
  mounted() {
    this.val =this.params.value 
  },
  methods: {

  }
};
</script>

<style scoped>
.cell {
    color: blue;
}
</style>