<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-dialog
      v-model="adddialog"
      width="400px"
    >
      <v-card>
        <v-card-title>Add Tag</v-card-title>
        <v-card-text>
          <v-text-field
            label="Name"
            v-model="newtag.name"
          ></v-text-field>
          <v-text-field
            label="Decription"
            v-model="newtag.description"
          ></v-text-field>
          <!--<roleavatarupload v-if="newtag['id']" v-model="newtag" class="pt-3"></roleavatarupload>-->
          <v-checkbox
            v-model="newtag.pinned"
            label="Pinned to sidebar"
          ></v-checkbox>
          <v-checkbox
            v-if="newtag.permissions"
            v-model="newtag.permissions.permissions_public"
            label="Public"
          ></v-checkbox>

          <v-color-picker
            class="ma-2"
            v-model="color"
            hide-canvas
            hide-inputs
            flat
            :swatches="swatches"
            show-swatches
          ></v-color-picker>

          <tagavatarupload
            v-if="newtag['id']"
            v-model="newtag"
            class="pt-3"
          ></tagavatarupload>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="newtag['id']"
            @click="updateTag"
          >Save</v-btn>
          <v-btn
            v-else
            @click="addTag"
          >Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card
      class="pb-2 mx-10 mt-10"
      max-width="800"
    >
      <v-card-title
        class="headline"
        primary-title
      >Tag Settings</v-card-title>

      <v-card-text class="py-2">With tags you can organize your PCAPs and later search for them. Before you can use tags here you need to create the tag first here. PCAPs can then be searched by using the "tagname: MyTag" term. Tags names are case sensitive.</v-card-text>
      <v-col cols="12">

        <v-col cols="2">
          <v-text-field placeholder="Search" v-model="searchterm"></v-text-field>
        </v-col>

        <v-data-table
          :headers="headers"
          :items="tags"
          :hide-default-footer="totalitems < 10"
          :options.sync="tableoptions"
          :server-items-length="totalitems"
        >
          <template
            slot="item"
            slot-scope="props"
          >
            <tr>
              <td>
                <tag :tag="props.item"></tag>
              </td>
              <td>{{props.item['description']}}</td>
              <td>
                <template v-if="props.item['objectids']">{{props.item['objectids'].length}}</template>
              </td>
              <td>
                <template v-if="isPublic(props.item.permissions)">Yes</template>
                <template v-else>No</template>
              </td>
              <td>
                <v-icon
                  v-if="isOwner(currentUser, props.item.permissions)"
                  color="info"
                  small
                  v-on:click="editTag(props.item)"
                >edit</v-icon>
                <v-icon
                  v-if="isOwner(currentUser, props.item.permissions)"
                  color="info"
                  small
                  v-on:click="deleteTag(props.item)"
                >delete</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        cols="12"
        class="px-2 py-2"
      >
        <v-btn @click="newTag">Add Tag</v-btn>
      </v-col>
    </v-card>
  </v-row>
</template>

<script>
import ApiTag from "@/common/api/tag";
import ApiTags from "@/common/api/tags";
import { commonmethods } from "@/common/common";

import Tagavatarupload from "./Tagavatarupload";
import { mapGetters } from "vuex";
import Tag from "../Tag";
import { permissionchecks } from "@/common/permissions";

export default {
  components: { Tagavatarupload, Tag },
  name: "taglist",
  data() {
    return {
      searchterm: "",
      totalitems: 0,
      tableoptions: {
        itemsPerPage: 10
      },
      swatches: [
        ["#C62828", "#AD1457", "#6A1B9A"],
        ["#4527A0", "#283593", "#1565C0"],
        ["#0277BD", "#00838F", "#00695C"],
        ["#2E7D32", "#558B2F", "#9E9D24"],
        ["#F9A825", "#FF8F00", "#EF6C00"]
        /* ['#D84315', '#4E342E', '#37474F'],*/
      ],
      simple: false,
      tags: [],
      color: null,
      newtag: {
        id: "",
        name: null,
        pinned: false,
        color: null,
        pic: "",
        order: null,
        tagtype: "pcap",
        objectids: [],
        description: null
      },
      adddialog: false,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "current",
          sortable: false
        },
        { text: "Description", value: "description", sortable: false },
        { text: "Member Count", value: "memcount", sortable: false },
        { text: "Public", value: "permissions", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  created: function() {},
  mounted() {
    this.getTags();
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"])
  },
  watch: {
    tableoptions: {
      handler() {
        this.getTags();
      },
      deep: true
    },
    searchterm() {
      this.getTags();
    }
  },
  methods: {
    gotoTag: function(i) {
      this.$router
        .push({
          name: "PCAPs",
          params: {
            type: "search",
            query: "tagname: " + i.name
          }
        })
        .catch(err => {});
    },
    newTag: function() {
      this.color = "#C62828";
      this.newtag = {
        name: "",
        description: "",
        permissions: {
          permissions_public: false
        },
        tagtype: "pcap"
      };
      this.adddialog = !this.adddialog;
    },
    addTag: function() {
      this.newtag.color = this.color;
      ApiTag.create(this.newtag)
        .then(({ data }) => {
          this.adddialog = false;
          // wait one second before update
          setTimeout(() => this.getTags(), 1000);
        })
        .catch(({ response }) => {});
    },
    updateTag: function() {
      this.newtag.color = this.color;
      ApiTag.update(this.newtag)
        .then(({ data }) => {
          this.adddialog = false;
          setTimeout(() => this.getTags(), 1000);
        })
        .catch(({ response }) => {});
    },
    getTags: function() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.tableoptions;

      let offset = (page - 1) * itemsPerPage;

      if (offset == null) {
        offset = 0;
      }

      let args = {
        listtype: "list",
        type: "pcap",
        sortBy: sortBy,
        sortDesc: sortDesc,
        page: page,
        perpage: itemsPerPage,
        offset: offset
      };

      if (this.searchterm.length > 0) {
        args["search"] = this.searchterm;
      }

      ApiTags.list(args)
        .then(({ data }) => {
          this.tags = data.tags;
          this.totalitems = data.total;
        })
        .catch(({ response }) => {});
    },

    deleteTag: function(tag) {
      ApiTag.delete(tag["id"])
        .then(({ data }) => {
          this.tags.splice(this.tags.indexOf(tag), 1);
        })
        .catch(({ response }) => {});
    },
    editTag: function(tag) {
      this.newtag = tag;

      if ("color" in tag) {
        this.color = tag.color;
      } else {
        this.color = "#C62828";
      }

      this.adddialog = true;
    }
  }
};
</script>

<style >
.v-color-picker__controls {
  display: none;
}
</style>
