import ApiService from "@/common/api.service";

const ApiPcapmeta = {

    update(pcap) {
        return ApiService.put(
            "ajax/pcapmeta/"+pcap.id,
            { pcap: pcap}
        )
    },
    get(id){
        return ApiService.get("ajax/pcapmeta", id);
    },
    delete(id){
        return ApiService.delete("ajax/pcapmeta", id)
    },
    async loadstatus(id){
        return ApiService.post("state/pcaploadstatus", {"pcapid": id});
    },
}

export default ApiPcapmeta