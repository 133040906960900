<template>
  <v-list-item @click :disabled="disabled">
    <v-list-item-action>

      <v-switch :disabled="disabled" v-model="localval"></v-switch>

    </v-list-item-action>

    <v-list-item-content @click.prevent="toggle">
      <v-list-item-title>{{title}}</v-list-item-title>
      <v-list-item-subtitle>{{description}}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "No title"
    },
    value: null,
    description: {
      type: String,
      default: "No description"
    }
  },
  components: {},
  name: "togglepreference",
  watch: {
    localval: function() {
      this.$emit("input", this.localval);
    }
  },
  data() {
    return {
      localval: null
    };
  },
  created: function() {},
  mounted() {
    this.localval = this.value;
  },
  computed: {},
  methods: {
    toggle: function() {
      this.localval = !this.localval;
    }
  }
};
</script>

<style >
</style>
