import ApiService from "@/common/api.service";

const ApiCase = {

    list(type){
        return ApiService.query("ajax/cases")
        
    }
}

export default ApiCase