<template>
  <!-- all 100% and overflow: hidden absolutely necessary here 
  update: overflow: hidden; removed replaced by height 100%-->
  <div id="rfcview" style="width: 100%; height: 100%; " >
    
    <ag-grid-vue
      id="rfcvue"
      style="width: 100%; height: 100%;"
      class="ag-theme-balham"
      :gridOptions="gridOptions"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

import { AGGRID_LICENSE } from "@/common/config";
LicenseManager.setLicenseKey(
  AGGRID_LICENSE
);

import { displayfilter, df_convtypes } from "@/common/displayfilter";
import packethandling from "@/common/packethandling";
import { commonmethods } from "@/common/common";
export default {
  name: "rfcview",
  mixins: [displayfilter, packethandling, commonmethods],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hoveredcell: -1,
      fromdecode : {
        start: null,
        end: null
      },
      highlighfield: null,
      currentbytes: null,
      currentoffsetdefs: [],
      gridOptions: {
        context: {
          parent: this
        },
        defaultColDef: {
             resizable: true,
            suppressSizeToFit :false,
        colSpan: this.calccolspan,

          cellClassRules: {
                'rag-red': function(params) {
                    return false
                }.bind(this),
                'proto-row': function(params){

                    if(this.isProtoRow(params)){

                        return true
                    }
                    return false
                }.bind(this),
                'ruler-row': function(params){
                    let col = params.colDef.id;
                    if(this.isRulerRow(params)){
                        if(![1,15,16,32].includes(col))
                            return true;
                    }
                    return false
                }.bind(this),
                'ruler-row-0': function(params){
                    if(this.isRulerRow(params) && (params.colDef.id==1)){
                        return true;
                    }
                    return false
                }.bind(this),
                'ruler-row-15': function(params){
                    if(this.isRulerRow(params) && (params.colDef.id==15)){
                        return true;
                    }
                    return false
                }.bind(this),
                'ruler-row-16': function(params){
                    if(this.isRulerRow(params) && (params.colDef.id==16)){
                        return true;
                    }
                    return false
                }.bind(this),
                'ruler-row-30': function(params){
                    if(this.isRulerRow(params) && (params.colDef.id==31)){
                        return true;
                    }
                    return false
                }.bind(this),
                'ruler-row-31': function(params){
                    if(this.isRulerRow(params) && (params.colDef.id==32)){
                        return true;
                    }
                    return false
                }.bind(this),
                'data-row-left': function(params){
                    if(this.isDataRow(params) && (params.colDef.id==1)){
                        return true;
                    }
                    return false
                }.bind(this),
                'data-row-right': function(params){
                    if(this.isDataRow(params) && (params.colDef.id==32)){
                        return true;
                    }
                    return false
                }.bind(this),
                'data-row-bottom': function(params){
                    if(this.isDataRow(params)){
                        return true;
                    }
                    return false
                }.bind(this),
                'field-row-left': function(params){
                    if(this.isFieldRow(params) && (params.colDef.id==1)){
                        return true;
                    }
                    return false
                }.bind(this),
                'field-row-right': function(params){
                    if(this.isFieldRow(params) && (params.colDef.id==1)){
                        return true;
                    }
                    return false
                }.bind(this), 
          },
        },
        onCellClicked: this.cellClicked,
        onCellMouseOver: this.cellHovered,
        columnDefs: [],
        getMainMenuItems: function(params) {

        }.bind(this),
        getContextMenuItems: this.getContextMenuItems,
        onGridReady: function() {
            this.gridOptions.api.setHeaderHeight(0);
            this.fetchShowPacket(this.id, 1, 10)
        }.bind(this),
        onRowDataChanged: function() {

        }.bind(this),
        onRowClicked: function() {
            
        },
        onRowSelected: function(params) {

        }.bind(this)
      },
    }
  },
  components: {
    AgGridVue
  },
  watch: {
    "currentAnalysis.cpacket": function(n, o) {
      if(n){
        this.showPacketRfc(n)
      }
    }
  },
  mounted() {

        let columnDefs = [

            {headerName: 'byte1', field: 'byte1', id:1},
            {headerName: 'byte2', field: 'byte2', id:2},
            {headerName: 'byte3', field: 'byte3', id:3},
            {headerName: 'byte4', field: 'byte4', id:4},
            {headerName: 'byte5', field: 'byte5', id:5},
            {headerName: 'byte6', field: 'byte6', id:6},
            {headerName: 'byte7', field: 'byte7', id:7},
            {headerName: 'byte8', field: 'byte8', id:8},
            {headerName: 'byte9', field: 'byte9', id:9},
            {headerName: 'byte10', field: 'byte10', id:10},
            {headerName: 'byte11', field: 'byte11', id:11},
            {headerName: 'byte12', field: 'byte12', id:12},
            {headerName: 'byte13', field: 'byte13', id:13},
            {headerName: 'byte14', field: 'byte14', id:14},
            {headerName: 'byte15', field: 'byte15', id:15},
            {headerName: 'byte16', field: 'byte16', id:16},
            {headerName: 'byte17', field: 'byte17', id:17},
            {headerName: 'byte18', field: 'byte18', id:18},
            {headerName: 'byte19', field: 'byte19', id:19},
            {headerName: 'byte20', field: 'byte20', id:20},
            {headerName: 'byte21', field: 'byte21', id:21},
            {headerName: 'byte22', field: 'byte22', id:22},
            {headerName: 'byte23', field: 'byte23', id:23},
            {headerName: 'byte24', field: 'byte24', id:24},
            {headerName: 'byte25', field: 'byte25', id:25},
            {headerName: 'byte26', field: 'byte26', id:26},
            {headerName: 'byte27', field: 'byte27', id:27},
            {headerName: 'byte28', field: 'byte28', id:28},
            {headerName: 'byte29', field: 'byte29', id:29},
            {headerName: 'byte30', field: 'byte30', id:30},
            {headerName: 'byte31', field: 'byte31', id:31},
            {headerName: 'byte32', field: 'byte32', id:32}
        ];

    this.gridOptions.api.setColumnDefs(columnDefs);

    this.$eventHub.$on("clicked-in-decode", filter => {
        this.gridOptions.api.ensureIndexVisible(this.offsettorow(sel), "top")
        this.gridOptions.api.refreshCells()
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("clicked-in-decode");
  },
  methods: {
    isProtoRow(params){
        if(params.node.rowIndex == 0){
            return true;
        }
        return false;
    },
    isRulerRow(params){
        if(params.node.rowIndex == 1){
            return true;
        }
        return false;
    },
    isDataRow(params){
        if(params.node.rowIndex % 2 == 1){
            return true;
        }
        return false;        
    },
    isFieldRow(params){
        if(params.node.rowIndex > 0 && params.node.rowIndex % 2 == 0){
            return true;
        }
        return false;   
    },
    calccolspan(params){
        if(params.node.rowIndex == 0){
            return 32
        }
        /* description row */
        if(params.node.rowIndex % 2 == 0){

            return 1
        }
        return 1
    },
    offsettorow(offset){
      return Math.max(0, Math.floor(offset / 16) - 1)
    },
    preparestyledef(){
      if(this.styledef === undefined){
        this.styledef = this.generateStyleDef(
            "rag-red",
            this.currentAnalysis.profiles.selected.selectedfg,
            this.currentAnalysis.profiles.selected.selectedbg
        );
        if(this.styledef)
        this.applyStyle(this.styledef);
      }
    },
    cellClicked(params)
    {
        this.fromdecode.start = null;
        this.fromdecode.end = null

        this.highlighfield = null
        if(params.colDef.hasOwnProperty('id'))
        {
            this.hoveredcell = params.colDef.id + (params.rowIndex * 16);
        }else if(params.colDef.hasOwnProperty('charid')){
            this.hoveredcell = params.colDef.charid + (params.rowIndex * 16);
        }

        // we send -1 because here we start with 1 but wiresharkd starts at 0 
        this.$eventHub.$emit("clicked-in-hex", this.hoveredcell - 1 );  
    },
    cellHovered(params)
    {
        this.fromdecode.start = null;
        this.fromdecode.end = null;
        
        this.highlighfield = null
        if(params.colDef.hasOwnProperty('id'))
        {
            this.hoveredcell = params.colDef.id + (params.rowIndex * 16);
        }else if(params.colDef.hasOwnProperty('charid')){
            this.hoveredcell = params.colDef.charid + (params.rowIndex * 16);
        }
        this.gridOptions.api.refreshCells()        
    },
    hoveredintervals(){
      return this.getintervalsforval(this.hoveredcell, this.currentoffsetdefs);
    },
    belongstoselectedfield(acell, ispadding=false){
        let hoveredintervals

        if(this.fromdecode.start != null){
          
          hoveredintervals = [[this.fromdecode.start, this.fromdecode.end, ""]]

        } else{
          if(acell == this.hoveredcell && acell <= this.currentbytes.length && !ispadding){
            return true;
          }
          hoveredintervals = this.hoveredintervals()
        }

        if(hoveredintervals && hoveredintervals.length > 0){
          let shortest = this.shortestinterval(hoveredintervals)
          return this.isinintervalnew(acell, shortest, ispadding)
        }

        return false
    },
    toprintableascii(keycode){

        var valid = 
            (keycode > 32 && keycode < 126);   // [\]' (in order)

        if(valid){
            return String.fromCharCode(keycode) 
        } else{
            return "."
        }
    },
    formatascii(bytes, index){
        if(index < bytes.length)
            return this.toprintableascii(bytes[index]);
        else
            return ""
        /*
        for(let i=index; i<index+8; i++){
            if(i<bytes.length){
                outstr = outstr + this.toprintableascii(bytes[i]);
            }
        }
        return outstr;*/
    },
    formatbyte(bytes, index){

        // indexe  0 1 2 3
        // length: 4
        if(index < bytes.length){
            return this.decimalToHex(bytes[index], 2)
        } else{
            return ""
        }
    },
    masktobytearray: function(maskstr, length){
        // mask: 007000000
        // length: 10
        let array = []
        let fullhexlength = length * 2

        let fullstr = maskstr.padStart(fullhexlength, "0");

        for (var i = 0; i < fullstr.length; i += 2) {
            array.push(fullstr.substr(i, 2));
        }

        return array
    },
    getdecode: function(entry, packet){
        let maskstr;

        for(let l of packet.decode){
            if(entry[2] == l["filter"] && l["bitmask"] !== undefined){
                return l
            }
        }
    },
    entrytobitmask: function(entry, shift, absindex){
        let start = entry[0]
        let end = entry[0] + entry[1]

    },
    getFieldRow: function(packet, relindex, absindex, shift){
        // we get the index into the first byte
        // a row can have up to 4 bytes
        let bytes = packet.bytes
        let index = relindex

        // absindex is the absolute byte offset in the packet
        // shift is the shifting within that particular byte


        // step 1: get most specific row from packet.offsets

        let candidates = this.getintervalsforvalzero(absindex, packet.offsets)
        let shortest;
        if(candidates && candidates.length > 0){
             shortest = this.shortestintervals(candidates)
        }

        // step 2: among candidates find the one which matches the bitmask

        // create a bit mask from the lengh of shortest array
        for(let i = 0; i< shortest.length; i++){
            //shortest[i] = this.entrytobitmask(shortest[i], shift, absindex)
            let decode = this.getdecode(shortest[i], packet)
            
            shortest[i].decode = decode
            if(decode.bitmask !== undefined){
                shortest[i].maskarray = this.masktobytearray(decode.bitmask, shortest[i][1])
                let reloffset = absindex - shortest[i][0]
                let currentrelbytemask = decode.bitmask[reloffset];
                let isset = currentrelbytemask >> shift & 1
                if(isset == 1){
                    return decode["field"]
                }
            }

            
        }

        return ""
//         0: (3) [0, 3, "eth.dst.lg == 0", __ob__: Observer]
//         1: (3) [0, 3, "eth.dst.ig == 0", __ob__: Observer]

// bitmask: "20000"
// field: "eth.dst.lg"
// filter: "eth.dst.lg == 0"
// id: (...)


// bitmask: "10000"
// field: "eth.dst.ig"
// filter: (...)
// id: (...)
// show: (...)
// value: (...)

        // step 3: output fieldname but only if different from previous field name
        // maybe put that into packet as an array so we have it cached for the next
        

    },
    showPacketRfc: function(packet){
        this.fromdecode.start == null;
        this.fromdecode.end == null;
      
        var bytes = packet.bytes;
        this.currentbytes = bytes;
        this.currentoffsetdefs = packet.offsets;


        let protorow = {
            byte1: "Ethernet"
        }

        let headerrow = {
            byte1: "0",
            byte15: "15",
            byte16: "16",
            byte32: "31"
        }

        this.preparestyledef()

        var rowdata = [];
        
        rowdata.push(protorow)
        rowdata.push(headerrow)

        for(let i=0; i< (bytes.length); i=i+4){
            let h = {};
            var n = {}

            let ind = 1;

            for(let j = 0; j< 4; j++){

                if(i+j>=bytes.length){
                    break;
                }

                for(let shift=7; shift >=0; shift--){
                    
                    let curb = bytes[i+j]
                    n["byte"+ind] = (curb >> shift) & 1;
                    
                    h["byte"+ind] = this.getFieldRow(packet, ind%8 -1, i+j, shift) // ind is from 1 - 32 so over the 4 bytes but we jsut need to adress one byte
                    ind++; 
                }
            }
            

            rowdata.push(h)
            rowdata.push(n)
        }


        this.gridOptions.api.setRowData(rowdata);
        this.gridOptions.api.sizeColumnsToFit();
     },
    decimalToHex: function(d, padding){
        var hex = Number(d).toString(16);
        padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

        while (hex.length < padding) {
            hex = "0" + hex;
        }

        return hex;
    },
    getContextMenuItems: function(params) {
        
      var result = [];
      result.push("copy");
      return result;
    }
  }
};
</script>

<style>
.ag-theme-balham .ag-cell {
    padding-left: 0px;
    padding-right: 0px;
}
.proto-row {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 6pt;
}
.ruler-row {

    border-left: 1px solid rgb(0, 0, 0) !important;
    border-image: linear-gradient(to bottom, rgba(0,0,0,0) 55%,rgba(0,0,0,0) 25%,rgba(0,0,0,1) 75%,rgba(0,0,0,1) 75%) !important;
    border-image-slice: 1 !important;
    border-bottom: 1px solid rgb(0, 0, 0) !important;
}
.ruler-row-0 {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border-left: 1px solid black !important;
    border-bottom: 1px solid rgb(0, 0, 0) !important;
}
.ruler-row-15 {
    border-left: 1px solid rgb(0, 0, 0) !important;
    border-image: linear-gradient(to bottom, rgba(0,0,0,0) 55%,rgba(0,0,0,0) 25%,rgba(0,0,0,1) 75%,rgba(0,0,0,1) 75%) !important;
    border-image-slice: 1 !important;


    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border-bottom: 1px solid rgb(0, 0, 0) !important;
}
.ruler-row-16 {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border-left: 1px solid black !important;
    border-bottom: 1px solid rgb(0, 0, 0) !important;
}
.ruler-row-30 {
    border-right: 1px solid rgb(0, 0, 0) !important;
    border-image: linear-gradient(to bottom, rgba(0,0,0,0) 55%,rgba(0,0,0,0) 25%,rgba(0,0,0,1) 75%,rgba(0,0,0,1) 75%) !important;
    border-image-slice: 1 !important;

    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border-bottom: 1px solid rgb(0, 0, 0) !important;
}
.ruler-row-31 {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    
    border-bottom: 1px solid rgb(0, 0, 0) !important;
    border-right: 1px solid  rgb(0, 0, 0) !important;
}

.field-row-left {
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    border-left: 1px solid  rgb(0, 0, 0) !important;
}

.field-row-right {
    align-items: center;
    border-right: 1px solid  rgb(0, 0, 0) !important;
}

.data-row-left {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border-left: 1px solid  rgb(0, 0, 0) !important;
    font-size: 60%;
}

.data-row-right {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    border-right: 1px solid  rgb(0, 0, 0) !important;
    font-size: 60%;
}

.data-row-bottom {
    font-size: 60%;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    
    border-bottom: 1px solid rgb(0, 0, 0) !important;

}

</style>