var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-2"},[_c('v-card-title',[_vm._v("List of users")]),_c('v-card-text',{staticClass:"py-2"},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" Wave Analyzer Users ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.searchterm),callback:function ($$v) {_vm.searchterm=$$v},expression:"searchterm"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"hide-default-footer":_vm.totalitems < 10,"options":_vm.tableoptions,"server-items-length":_vm.totalitems},on:{"update:options":function($event){_vm.tableoptions=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(props.item['firstname'])+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item['lastname'])+" ")]),_c('td',[_c('router-link',{attrs:{"to":{ name: 'User Profile', params: { username: props.item['username']}}}},[_vm._v(_vm._s(props.item['username']))])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateMonth")(props.item['date_registered']))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateMonth")(props.item['date_last_login']))+" ")]),_c('td',[_c('v-data-table',{attrs:{"headers":_vm.rcuheaders,"items":props.item['recentlyUsed'],"hide-default-footer":true,"sort-by":_vm.rcuSortBy,"sort-desc":_vm.rcuSortDesc},on:{"update:sortBy":function($event){_vm.rcuSortBy=$event},"update:sort-by":function($event){_vm.rcuSortBy=$event},"update:sortDesc":function($event){_vm.rcuSortDesc=$event},"update:sort-desc":function($event){_vm.rcuSortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(props.item['count'])+" ")]),_c('td',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                      name: 'Analysis',
                      params: {
                        view: 'l',
                        id: props.item['id'],
                        index: 'none',
                        analysisid: 'none',
                      },
                    }}},[_vm._v(_vm._s(props.item['id']))])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item['lastAccessed']))+" ")])])]}}],null,true)})],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }