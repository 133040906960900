<template>
  <v-row   align="center" justify="center">


    <v-dialog v-model="adddialog" width="400px">
      <v-card>
        <v-card-title>Add Role</v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="newrole.name"></v-text-field>
          <v-text-field required label="Decription" v-model="newrole.description"></v-text-field>
          <v-combobox label="Members" v-model="newrole.members" small-chips multiple></v-combobox>
          <roleavatarupload v-if="newrole['id']" v-model="newrole" class="pt-3"></roleavatarupload>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="newrole['id']" @click="updateRole">Save</v-btn>
          <v-btn v-else @click="addRole">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="pb-2 mx-10 mt-10" max-width="800">
      <v-card-title class="headline" primary-title>Role Settings</v-card-title>

      <v-card-text class="py-2">With roles you can organize the permissions to PCAPs. In order to use roles. First create are role here and assign users to this role. Then navigate to the PCAP you want this role to give access to and add the role to the read or write permissions in the PCAP permissions settings. The PCAPs will then show up in the "Shared PCAPs" of the users the have the corresponding roles.</v-card-text>

      <v-col cols="12">
        <v-data-table :headers="headers" :items="roles" hide-default-footer disable-pagination>
          <template slot="item" slot-scope="props">
            <tr>
              <td>
                <v-chip color="primary">
                  <v-avatar left>
                    <img :src="avatarpic4role(props.item)" />
                  </v-avatar>
                  {{props.item['name']}}
                </v-chip>
              </td>
              <td>{{props.item['description']}}</td>
              <td>{{props.item['nummembers']}}</td>
              <td v-if="props.item['id'] != adminrole">
                <v-icon
                  v-if="isOwner(currentUser, props.item.permissions)"
                  color="info"
                  small
                  v-on:click="editRole(props.item)"
                >edit</v-icon>
                <v-icon
                  v-if="isOwner(currentUser, props.item.permissions)"
                  color="info"
                  small
                  v-on:click="deleteRole(props.item)"
                >delete</v-icon>
              </td>
              <td v-else></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="py-2 px-2">
        <v-btn @click="newRole">Add Role</v-btn>
      </v-col>
    </v-card>
  </v-row>
</template>

<script>

import ApiRoles from "@/common/api/roles";
import Roleavatarupload from "./Roleavatarupload";
import { mapGetters } from "vuex";

import { commonmethods } from "@/common/common";
import { ADMIN_ROLE_ID } from "@/common/config";

import { permissionchecks } from "@/common/permissions";

export default {
  props: ["value"],
  components: { Roleavatarupload },
  name: "rolelist",
  data() {
    return {
      adminrole: ADMIN_ROLE_ID,
      roles: [],
      newrole: {
        id: "invalid",
        name: null,
        members: [],
        description: null
      },
      adddialog: false,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "current",
          sortable: false
        },
        { text: "Description", value: "description", sortable: false },
        { text: "Member Count", value: "memcount", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  created: function() {},
  mounted() {
    this.getRoles();
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"])
  },
  watch: {},
  methods: {
    newRole: function() {
      this.newrole = {
        name: null,
        members: [],
        description: null
      };
      this.adddialog = !this.adddialog;
    },
    addRole: function() {
      ApiRoles.create(this.newrole)
        .then(({ }) => {
          this.adddialog = false;
          setTimeout(() => this.getRoles(), 1000);
        })
        .catch(({ response }) => {});
    },
    updateRole: function() {
      ApiRoles.update(this.newrole)
        .then(({ }) => {
          this.adddialog = false;
          setTimeout(() => this.getRoles(), 1000);
          
        })
        .catch(({ response }) => {});
    },
    getRoles: function() {
      ApiRoles.list()
        .then(({ data }) => {
          this.roles = data;
        })
        .catch(({ response }) => {});
    },
    deleteRole: function(role) {
      ApiRoles.delete(role["id"])
        .then(({  }) => {
          this.roles.splice(this.roles.indexOf(role), 1);
        })
        .catch(({ response }) => {});
    },
    editRole: function(role) {
      this.newrole = role;
      this.adddialog = true;
    }
  }
};
</script>

<style >
</style>
