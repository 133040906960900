<template>
  <v-form>
    <v-snackbar v-model="snackbar" top>{{snackbartext}}</v-snackbar>
    <v-container>
      <v-row  >
        <v-col cols="6">
          <v-row  >
            <v-col cols="12">
              <v-text-field
                v-model="cur.name"
                label="Index name"
                placeholder="Enter indexer name..."
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                @change="selectprofile"
                :items="getprofiles"
                v-model="selectedprofile"
                label="Select saved profile..."
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-on:keyup.enter="enterpressed"
                :items="availablefields"
                label="New field"
                hide-no-data
                :search-input.sync="newfield"
                cache-items
                placeholder="Start typing to search for fields..."
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="cur.inherit !== undefined">Inherits from: {{cur.inherit[0]}}</v-col>

            <v-col cols="12">
              <v-btn @click="addindexprofile">Add profile</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-list subheader two-line>
            <v-subheader>Fields</v-subheader>
            <v-list-item v-for="(key) in cur.fields" :key="key">
              <!--disable the checkbox <v-list-item-action>
                <v-checkbox v-model="cur.fields[key]"></v-checkbox>
              </v-list-item-action>-->
              <v-list-item-content @click="isset = !isset">
                <v-list-item-title>{{lookupdescription(key)}}</v-list-item-title>
                <v-list-item-subtitle>{{key}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-on:click="deletefield(key)">delete</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ApiIndexerprofile from "@/common/api/indexerprofile";
import ApiDisplayfilter from "@/common/api/displayfilter";
export default {
  props: {
    id: null,
    index: null
  },
  components: {},
  name: "indexer",
  data() {
    return {
      snackbar: false,
      snackbartext: "",
      isLoading: false,
      availablefields: [],
      selectedprofile: null,
      availableprofiles: [],
      fielddef: [],
      newfield: "",
      cur: {
        name: null,
        fields: []
      }
    };
  },
  created: function() {
    this.loaddata();
    /* if we get a parameter load the profile */
    if (this.id != null) {
      this.loadprofile();
    }
  },
  mounted() {},
  computed: {
    getprofiles() {
      var profiles = [];
      for (let p of this.availableprofiles) profiles.push(p.name);

      return profiles;
    }
  },
  watch: {
    newfield(val) {
      val && val !== this.select && this.autocompletefields();
    }
  },
  methods: {
    loaddata() {
      ApiIndexerprofile.list()
        .then(({ data }) => {
          //appending array to array with spread operator
          this.availableprofiles.push(...data);
        })
        .catch(({ response }) => {
          this.snackbartext = "Could not lookup profile";
          this.snackbar = true;
        });
    },
    loadprofile() {
      ApiIndexerprofile.get(this.id)
        .then(({ data }) => {
          this.cur = data;
        })
        .catch(({ response }) => {
          this.snackbartext = "Could not lookup profile";
          this.snackbar = true;
        });
    },
    iscached() {
      for (let item of this.availablefields) {
        if (item.startsWith(this.newfield)) return true;
      }
      return false;
    },
    autocompletefields() {
      if (this.newfield.length < 3 || this.iscached()) return;

      ApiDisplayfilter.getfields(this.newfield)
        .then(({ data }) => {
          var newlist = [];
          this.isLoading = true;

          for (let item of data) {
            newlist.push(item["field"]);
          }
          this.addfieldstofielddef(data);

          this.availablefields = newlist;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.snackbartext = "Could not lookup profile";
          this.snackbar = true;
        });
    },
    addfieldstofielddef(fields) {
      var found = false;
      for (let item of fields) {
        for (let cache of this.fielddef) {
          if (item["field"] == cache["field"]) {
            found = true;
          }
        }
        if (!found) {
          this.fielddef.push(item);
        }
        found = false;
      }
    },
    lookupdescription(fieldname) {
      for (let i of this.fielddef) {
        if (i["field"] == fieldname) return i["description"];
      }

      return "no description";
    },
    copyprofilefields(to, from) {
      for (let field in from) this.$set(to, field, from[field]);
    },
    selectprofile() {
      for (let profile of this.availableprofiles) {
        if (profile.name == this.selectedprofile) {
          //this.cur.fields = []
          this.cur.inherit = profile.inherit;

          ApiDisplayfilter.getmultifields({fields: profile.fields})
            .then(({ data }) => {
              this.addfieldstofielddef(data);
              this.cur.fields = profile.fields;
            })
            .catch(({ response }) => {
              this.snackbartext =
                "Could not lookup multi thsark fields for profile " +
                profile.name;
              this.snackbar = true;
            });
        }
      }
    },
    enterpressed() {
      this.addfield();
    },
    deletefield(field) {
      this.cur.fields.splice(this.cur.fields.indexOf(field), 1);
    },
    addfield() {
      if (
        this.newfield != "" &&
        this.iscached() &&
        !this.cur.fields.includes(this.newfield)
      ) {
        this.cur.fields.push(this.newfield);
        //this.$set(this.cur.fields, this.newfield, true)
      } else {
        //FIXME display error message
      }
    },
    addindexprofile() {
      ApiIndexerprofile.create(this.cur)
        .then(({ data }) => {
          this.snackbartext = "Profile added";
          this.snackbar = true;
        })
        .catch(({ response }) => {
          this.snackbartext = "Profile not added";
          this.snackbar = true;
        });
    }
  }
};
</script>

<style scoped>
</style>
