<template>

  <v-list-item v-if="hasDescriptionSlot()" :disabled="disabled">

    <v-list-item-action>
      <v-btn  icon v-if="icon">
        <v-icon >{{icon}}</v-icon>
      </v-btn>
      <slot name="customaction"></slot>
    </v-list-item-action>

    
    <v-list-item-content @click.prevent>
      <v-list-item-title >
        <slot name="descriptionSlot"></slot>
      </v-list-item-title>
      <v-list-item-subtitle>{{description}}</v-list-item-subtitle>
    </v-list-item-content>

  </v-list-item>


  <v-list-item v-else @click :disabled="disabled">
    
    <v-dialog v-model="dialogopen" :max-width="width">
      <v-card :max-width="width">
        <v-card-title>{{title}} {{value}}</v-card-title>
        <v-card-text>
          <slot name="inputslot"></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="confirm">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-list-item-action>
      <v-row class="pr-8">
      <v-btn icon v-if="icon">
        <v-icon>{{icon}}</v-icon>
      </v-btn>
      <slot name="customaction"></slot>
      </v-row>
    </v-list-item-action>


    <v-list-item-content @click.prevent="dialogopen =! dialogopen">
      <v-list-item-title >
        {{title}}<template v-if="value">:<b> {{value}}</b></template>
      </v-list-item-title>
      <v-list-item-subtitle>{{description}}</v-list-item-subtitle>
    </v-list-item-content>

  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "No title"
    },
    icon: {
      type: String,
      default: "add"
    },
    value: null,
    width: {
      type: String,
      default: "300px"
    },
    description: {
      type: String,
      default: "No description"
    }
  },
  components: {},
  name: "preference",
  data() {
    return {
      dialogopen: false,
      localval: null
    };
  },
  created: function() {},
  mounted() {
    this.localval = this.value;
  },
  computed: {},
  methods: {
    confirm: function() {
      this.dialogopen = false;
      this.$emit("input", this.value);
    },
    hasDescriptionSlot() {
      return !!this.$slots["descriptionSlot"]
    }
  }
};
</script>

<style >
</style>
