<template>
    <v-row align="center" style="height: 100%" class="pl-5" >
      <!--
      <v-col cols="4" >
        <!--
        <v-select
          v-if="true"
          class="pa-4"
          hide-details
          v-model="tapinternal"
          :items="conversationtypes"
          label="Conversation Type"
          solo
          item-text="name"
          item-value="tap"
          >
        </v-select>
        <v-select
          v-if="false"
          class="pa-4"
          hide-details
          v-model="tapinternal"
          :items="endpointtypes"
          label="Endpoint Type"
          solo
          item-text="name"
          item-value="tap"
          >
        </v-select>
      </v-col>
      <v-col cols="6">
      </v-col>
      <v-col cols="2">
      </v-col>-->

      <v-col cols="12" style="height: 100%" >
        <taptable v-if="stats" v-model="stats" :pcapid="id" :key="id+stats.type" style="height: 100%; "></taptable>
      </v-col>
    </v-row>


</template>

<script>

import { mapGetters } from "vuex";
import { sharkdhelper } from "@/common/sharkdhelper";
import ApiPacketlist from "@/common/api/packetlist";
import Taptable from "./statistics/Taptable";


export default {
  mixins : [sharkdhelper],
  props: {
    pcapid: {
      type: String,
      default: "public"
    },
    tap: {
      type: String,
      default: ""
    }
  },
  components: {
    Taptable
  },
  name: "tapstatistics",
  watch: {
    "currentAnalysis.currentTap": function(n, o) {
      
      if(o == null){
        return
      }
      this.updateTap([n], this.id)
    },
    "pcapid": function (n,o){
      this.tapinternal = this.tap
      this.id = n;
      this.updateTap([this.tapinternal], this.id)
    },
    "tap" : function(n,o){
      this.tapinternal = n
      this.id = this.pcapid;
      this.updateTap([this.tapinternal], this.id)    
    },
    tapinternal(n, o) {
      // prevent double loading on mount
      if(o == null){
        return
      }
      this.updateTap([n], this.id)
    }
  },
  data() {
    return {
      tapinternal: null,
      id: null,
      stats : null
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic", "error"]),
  },
  mounted() {
    this.tapinternal = this.tap
    this.id = this.pcapid;
    this.updateTap([this.tapinternal], this.id)
  },
  created: function() {
  },
  methods: {
    updateTap(taps, pcapid){
      
      let params = {
        taps : taps,
        pcapid : pcapid,
      }

      ApiPacketlist.tap(params).then(({ data }) => {
        this.stats = data["taps"][0]
      })
      .catch(() => {});
    }

  }
};
</script>

<style>
</style>