<template>
  <v-row class="text-center" justify="center">
    <v-card width="400px">
      <v-card-title class="display-1">Confirm the code that was sent to you via e-mail</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container class="text-center justify" grid-list-xl>
          <v-row  >

            <v-col cols="12">
              <v-text-field
                v-model="user.username"
                :rules="usernameRules"
                :counter="25"
                label="Username"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="user.code"
                :counter="6"
                label="Sign up code"
                required
              ></v-text-field>
            </v-col>


          <v-col class="x12" v-if='submitresponse != ""' >{{ submitresponse }}</v-col>
            
          </v-row>

          <v-card-actions>
            <v-btn :disabled="!valid" @click="submit">submit</v-btn>
            <v-btn  @click="resendcode">resend code</v-btn>

          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-row>
</template>

<script>

import ApiUser from "@/common/api/user";
import { mapGetters } from "vuex";
export default {
  name: "signupcode",
  props: ["userin"],
  computed: {
    ...mapGetters(["currentUser", "error", "avatarPic"])
  },
  data: () => ({
    valid: true,
    submitresponse: "",

    user : {
        username : "",
        code : ""
    }

  }),
  mounted: function() {
      if(this.userin){
          this.user.username = this.userin
      }
  },
  methods: {
    resendcode(){
      this.submitresponse = "";
      if (this.$refs.form.validate()) {
        ApiUser.resendsignupcode(this.user)
          .then(({ }) => {
            this.error.type = "info"
            this.error.msg = "Code resend!"
          })
          .catch(({ response }) => {
            
             this.submitresponse = response.data.error
          });
      }
    },
    submit() {
      this.submitresponse = "";
      if (this.$refs.form.validate()) {
        ApiUser.confirmsignupcode(this.user)
          .then(({ data }) => {
            this.error.type = "info"
            this.error.msg = "Code confirmed!"
            this.$router.push({ name: "Login" });
          })
          .catch(({ response }) => {
            
             this.submitresponse = response.data.error

          }).catch(err => {});
      }
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>
